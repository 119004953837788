import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getShopGroupOptionsSelector } from '@/store/selectors/filters';
import { getShopGroupOptionsThunk } from '@/store/thunk/filters';
import { noop } from '@/utils/noop';
export const useUserShopFilters = (customItems = [], savedFilters = []) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopGroupsTree = useAppSelector(getShopGroupOptionsSelector(savedFilters, t));
    const defaultItems = [
        {
            type: 'leaf',
            id: 'shop',
            label: t('Точки продаж'),
            template: 'checkboxes-tree-loader',
            loadTree: () => dispatch(getShopGroupOptionsThunk()),
            loadBranch: noop,
            options: shopGroupsTree,
            filterInBrowser: true,
            branchPrefix: 'Group',
            leafPrefix: '',
            hideTitle: true,
        },
    ];
    const tree = [...customItems, ...defaultItems];
    return tree;
};
