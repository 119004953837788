import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { draggable, dropTargetForElements, } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { attachClosestEdge, extractClosestEdge, } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { ArbitrationGroupItemIcons } from '~/src/components/arbitration/ArbitrationGroup/ArbitrationGroupItemIcons';
import { DragPreview } from '~/src/components/arbitration/ArbitrationGroup/dnd/DragPreview';
import { Line } from '~/src/components/arbitration/ArbitrationGroup/helpers/Line';
import { Operator } from '~/src/components/arbitration/ArbitrationGroup/helpers/Operator';
import stylesArbitrationGroup from '~/src/components/arbitration/ArbitrationGroup/styles.module.scss';
import { Drag } from '~/src/icons/Drag';
import styles from './styles.module.scss';
const idle = { type: 'idle' };
export const OfferItem = ({ offerTitle, arbitrationPriorityGuid, group, index, parentType, currency, handleChangeList, }) => {
    const { setValue } = useFormContext();
    const ref = useRef(null);
    const lastClosestEdge = useRef(null);
    const [closestEdge, setClosestEdge] = useState(null);
    const isCanDrag = (group === null || group === void 0 ? void 0 : group.guid) === arbitrationPriorityGuid || !!group.newOffer;
    const [draggableState, setDraggableState] = useState(idle);
    useEffect(() => {
        const element = ref.current;
        if (!element)
            return;
        if (isCanDrag)
            setValue('newOfferItemOffsetTop', element === null || element === void 0 ? void 0 : element.offsetTop);
        const draggableCleanup = draggable({
            element,
            getInitialData() {
                return { group: Object.assign(Object.assign({}, group), { offerTitle }), index };
            },
            canDrag() {
                return isCanDrag;
            },
            onDrop() {
                setDraggableState(idle);
            },
            onGenerateDragPreview({ nativeSetDragImage }) {
                disableNativeDragPreview({ nativeSetDragImage });
            },
            onDrag(args) {
                const { location } = args;
                setDraggableState({
                    type: 'is-dragging',
                    x: location.current.input.pageX,
                    y: location.current.input.pageY,
                });
            },
        });
        const dropTargetCleanup = dropTargetForElements({
            element,
            getData({ input }) {
                return Object.assign({}, attachClosestEdge({ group, index }, {
                    element,
                    input,
                    allowedEdges: ['top', 'bottom'],
                }));
            },
            onDrag: ({ self, source }) => {
                const isSource = source.element === element;
                if (isSource) {
                    setClosestEdge(null);
                    return;
                }
                const closestEdge = extractClosestEdge(self.data);
                setClosestEdge(closestEdge);
                lastClosestEdge.current = closestEdge;
            },
            onDragLeave: () => {
                setClosestEdge(null);
            },
            onDrop({ self }) {
                var _a, _b, _c, _d, _e, _f, _g;
                setClosestEdge(null);
                if (
                // @ts-ignore
                ((_b = (_a = self.data) === null || _a === void 0 ? void 0 : _a.group) === null || _b === void 0 ? void 0 : _b.guid) === arbitrationPriorityGuid ||
                    (
                    // @ts-ignore
                    (_d = (_c = self.data) === null || _c === void 0 ? void 0 : _c.group) === null || _d === void 0 ? void 0 : _d.newOffer))
                    return;
                const offsetTop = (_e = ref.current) === null || _e === void 0 ? void 0 : _e.offsetTop;
                setValue('newOfferItemOffsetTop', offsetTop);
                // @ts-ignore
                if (((_f = self.data) === null || _f === void 0 ? void 0 : _f.group.guid) && lastClosestEdge.current)
                    handleChangeList({
                        // @ts-ignores
                        guid: (_g = self.data) === null || _g === void 0 ? void 0 : _g.group.guid,
                        edge: lastClosestEdge.current,
                    });
                lastClosestEdge.current = null;
            },
        });
        return () => {
            draggableCleanup();
            dropTargetCleanup();
        };
    }, [parent]);
    return (_jsxs(_Fragment, { children: [_jsxs("li", Object.assign({ ref: ref, className: classNames(stylesArbitrationGroup.item, stylesArbitrationGroup.itemGroupWrapper, styles.offerItem, {
                    [styles.itemHighlightTop]: closestEdge === 'top',
                    [styles.itemHighlightBottom]: closestEdge === 'bottom',
                    [styles.newOfferItemInList]: isCanDrag,
                }), style: {
                    cursor: 'auto',
                } }, { children: [isCanDrag && _jsx(Drag, {}), _jsxs("span", Object.assign({ className: styles.offerTitle }, { children: [_jsx(ArbitrationGroupItemIcons, { priority: group, currency: currency }), isCanDrag ? offerTitle : group.offerTitle] })), _jsx(Line, { initialGap: 18 }), parentType && _jsx(Operator, { levelGap: -24, operator: parentType })] })), draggableState.type === 'is-dragging'
                ? createPortal(_jsx(DragPreview, Object.assign({ className: styles.offerItemPreview, x: draggableState.x, y: draggableState.y }, { children: offerTitle })), document.body)
                : null] }));
};
