import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BonusMovementSmall as BonusIcon } from '@/icons/BonusMovementSmall';
import { CartSmall } from '@/icons/CartSmall';
import { Email } from '@/icons/flow/Email';
import { Pause } from '@/icons/flow/Pause';
import { Push } from '@/icons/flow/Push';
import { SMS } from '@/icons/flow/SMS';
import { Telegram } from '@/icons/flow/Telegram';
import { Viber } from '@/icons/flow/Viber';
import { GiftCardSmall as GiftCardIcon } from '@/icons/GiftCardSmall';
import { OfferSmall as OfferIcon } from '@/icons/OfferSmall';
import { PromocodeSmall as PromocodeIcon } from '@/icons/PromocodeSmall';
import { StarEmail } from '@/icons/StarEmail';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { NodeBody } from '../helpers/NodeBody/NodeBody';
import { NodeDeleteButton } from '../helpers/NodeDeleteButton/NodeDeleteButton';
import { NodePlusButton } from '../helpers/NodePlusButton/NodePlusButton';
import { NodeTitle } from '../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../helpers/NodeWrapper/NodeWrapper';
import { ChannelNodeStats } from './ChannelNodeStats';
import { ChannelNodeStatsLoader } from './ChannelNodeStatsLoader';
import { hasCartIcon } from './hasCartIcon';
import { hasStarIcon } from './hasStarIcon';
import styles from './styles.module.scss';
export const ChannelNode = ({ id, data, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { triggerType, selectedNode, validationErrorMap } = useInteractiveFlow();
    const thisNode = flow.getNode(id);
    const isInvalid = !!(validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[(thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid) || '']);
    const { status = 'active', name = '', channels = [], hasCascadeEnabled = false, rewards = [], } = data;
    const rewardTypes = rewards.map((i) => i.type);
    const isCascade = hasCascadeEnabled;
    const isPush = !isCascade && channels.length === 1 && channels[0] === 'push';
    const isSMS = !isCascade && channels.length === 1 && channels[0] === 'sms';
    const isViber = !isCascade && channels.length === 1 && channels[0] === 'viber';
    const isEmail = !isCascade && channels.length === 1 && channels[0] === 'email';
    const isTelegram = !isCascade && channels.length === 1 && channels[0] === 'telegram';
    const cascadeChannels = [
        channels.includes('push') ? 'push' : null,
        channels.includes('viber') ? 'viber' : null,
        channels.includes('sms') ? 'sms' : null,
    ].filter(notNull);
    let title = 'Push';
    let icon = _jsx(Push, {});
    if (isPush) {
        title = 'Push';
        icon = _jsx(Push, {});
    }
    else if (isSMS) {
        title = 'SMS';
        icon = _jsx(SMS, {});
    }
    else if (isViber) {
        title = 'Viber';
        icon = _jsx(Viber, {});
    }
    else if (isEmail) {
        title = 'Email';
        icon = _jsx(Email, {});
    }
    else if (isTelegram) {
        title = 'Telegram';
        icon = _jsx(Telegram, {});
    }
    else if (isCascade) {
        title = t('Каскад');
        icon = (_jsx("div", Object.assign({ className: styles.cascadeIcon }, { children: cascadeChannels.map((channel, i) => {
                return (_jsxs("div", Object.assign({ style: { left: -8 * i } }, { children: [channel === 'push' && _jsx(Push, {}, 'push'), channel === 'sms' && _jsx(SMS, {}, 'sms'), channel === 'viber' && _jsx(Viber, {}, 'viber')] }), channel + i));
            }) })));
    }
    const handleClick = useCallback(() => {
        if (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid)
            selectedNode.selectUUID(thisNode.uuid);
    }, [thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid, selectedNode.selectUUID]);
    const hasOutputEdges = flow.getEdges().filter((e) => e.source === id).length > 0;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames({
                    [styles.cascadeWrapper]: isCascade,
                    [styles.selected]: selectedNode.uuid === (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid),
                    [styles.invalid]: isInvalid,
                }) }, { children: [_jsxs(NodeWrapper, Object.assign({ className: classNames(styles.wrapper, {
                            [styles.push]: isPush,
                            [styles.sms]: isSMS,
                            [styles.viber]: isViber,
                            [styles.email]: isEmail,
                            [styles.telegram]: isTelegram,
                            [styles.selected]: selectedNode.uuid === (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid),
                            [styles.invalid]: isInvalid,
                        }), onClick: handleClick, isSelected: selectedNode.uuid === (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid), isInvalid: isInvalid, isInteractive: true }, { children: [_jsxs(NodeTitle, Object.assign({ className: styles.title, iconClassName: classNames({ [styles.telegramIcon]: isTelegram }), icon: !channels.length ? undefined : icon, isMuted: status === 'pause' }, { children: [_jsx("span", Object.assign({ className: classNames(styles.cascadeTitle, {
                                            [styles.cascadeTitleFull]: cascadeChannels.length === 3,
                                        }), style: channels.length
                                            ? { left: -8 * (channels.length - 1) }
                                            : undefined }, { children: title })), status === 'pause' && _jsx(Pause, { className: styles.pause })] })), _jsx(NodeBody, Object.assign({ className: classNames(styles.body, {
                                    [styles.italic]: !name,
                                }) }, { children: name || t('Название рассылки') })), ((rewards === null || rewards === void 0 ? void 0 : rewards.length) > 0 ||
                                hasCartIcon(triggerType, (_a = data.email) === null || _a === void 0 ? void 0 : _a.emailBody) ||
                                hasStarIcon(triggerType, (_b = data.email) === null || _b === void 0 ? void 0 : _b.emailBody)) && (_jsxs("div", Object.assign({ className: styles.rewardIcons }, { children: [hasCartIcon(triggerType, (_c = data.email) === null || _c === void 0 ? void 0 : _c.emailBody) && _jsx(CartSmall, {}), hasStarIcon(triggerType, (_d = data.email) === null || _d === void 0 ? void 0 : _d.emailBody) && _jsx(StarEmail, {}), rewardTypes.includes('bonuses') && _jsx(BonusIcon, {}), rewardTypes.includes('offer') && _jsx(OfferIcon, {}), rewardTypes.includes('gift-card') && _jsx(GiftCardIcon, {}), rewardTypes.includes('promocode') && _jsx(PromocodeIcon, {})] }))), _jsx(ChannelNodeStatsLoader, { id: id }), _jsx(ChannelNodeStats, { id: id })] })), _jsx("div", { className: styles.cascadeBorder })] })), _jsx(NodePlusButton, { nodeId: id }), _jsx(NodeDeleteButton, { nodeId: id }), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', {
                    'flow-handle-cascade': isCascade,
                }), type: 'target', position: Position.Left }), _jsx(Handle, { className: classNames('flow-handle', {
                    'flow-handle-cascade': isCascade,
                    'flow-handle-hidden': !hasOutputEdges,
                }), type: 'source', position: Position.Right })] }));
};
