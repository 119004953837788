var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MailingFlowsModal } from '@/app/pages/Mailings/MailingFlowsModal/MailingFlowsModal';
import { useFlowCandidates } from '@/app/pages/Mailings/MailingFlowsModal/useFlowCandidates';
import { ROUTES } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { FlowTriggerModal } from '@/components/flow/FlowTriggerModal/FlowTriggerModal';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { numberRound } from '@/utils/numbers';
import { FlowsTable } from './FlowsTable';
import styles from './styles.module.scss';
export const FlowsPanel = ({ flows, isLoading, isFetching, onEditFlow, onDeleteFlow, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [mailingsModalIsOpen, setMailingsModalIsOpen] = useState(false);
    const [triggerModalIsOpen, setTriggerModalIsOpen] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    const { mailings, getFlowCandidates } = useFlowCandidates();
    const handleOpenCandidatesModal = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getFlowCandidates();
        setMailingsModalIsOpen(true);
    });
    const handleCloseCandidatesModal = () => __awaiter(void 0, void 0, void 0, function* () {
        window.history.replaceState({}, '');
        setMailingsModalIsOpen(false);
    });
    useEffect(() => {
        var _a;
        if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.openMailginsModal)
            handleOpenCandidatesModal();
    }, []);
    const createButton = (_jsx(Dropdown, Object.assign({ placement: 'bottom', items: [
            {
                id: 'create',
                label: t('Создать цепочку'),
                onClick: () => setTriggerModalIsOpen(true),
                isNew: true,
            },
            {
                id: 'assemble',
                label: t('Собрать из рассылок'),
                onClick: handleOpenCandidatesModal,
            },
        ] }, { children: _jsx("div", { children: _jsx(Tooltip, Object.assign({ title: userHasFlowsManage ? undefined : t('Недоступно для вашей роли') }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), disabled: !userHasFlowsManage, theme: 'primary', size: 'extra-large' }, { children: t('Добавить цепочку') })) })) }) })));
    const tabs = [
        {
            id: 'flows',
            label: t('FLOWS_PANEL_HEADER', {
                amount: numberRound(flows.length),
                count: flows.length,
            }),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(TabsPanel, Object.assign({ className: styles.panel, tabs: tabs, rightContent: createButton, isSkeleton: isLoading }, { children: _jsx(FlowsTable, { flows: flows, isLoading: isFetching, onCreateFlow: () => setTriggerModalIsOpen(true), onComposeFlow: handleOpenCandidatesModal, onEditFlow: onEditFlow, onDeleteFlow: onDeleteFlow }) })), _jsx(MailingFlowsModal, { isOpen: mailingsModalIsOpen, onClose: handleCloseCandidatesModal, from: 'flow-list', mailings: mailings }), _jsx(FlowTriggerModal, { isOpen: triggerModalIsOpen, onClose: () => setTriggerModalIsOpen(false), onSelectTrigger: (trigger, triggerLabel) => {
                    navigate(ROUTES.FLOWS_CREATE, {
                        state: { from: 'flow-trigger-modal', trigger, triggerLabel },
                    });
                } })] }));
};
