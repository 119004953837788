import { useEffect, useState } from 'react';
import { useGetFlowStatsManualMutation } from '@/api/query/flows';
import { getPresetPeriod, PresetPeriod } from '@/utils/getPresetPeriod';
export const useFlowStats = (flow, isEditMode = false) => {
    const [stats, setStats] = useState(null);
    const [periodStart, periodEnd] = getPresetPeriod(PresetPeriod.AllTime);
    const [getFlowStats, { isLoading }] = useGetFlowStatsManualMutation();
    useEffect(() => {
        if (!isEditMode && (flow === null || flow === void 0 ? void 0 : flow.id))
            getFlowStats({
                id: flow.id,
                periodStart: periodStart.format(),
                periodEnd: periodEnd.format(),
            }).then((result) => {
                var _a;
                if ('error' in result)
                    return;
                const newStats = {};
                (_a = flow.mailings) === null || _a === void 0 ? void 0 : _a.forEach((m) => {
                    const stats = result.data.mailingStats.find((s) => s.mailingBrandId === m.id);
                    if (stats && m.guid)
                        newStats[m.guid] = Object.assign({ mailingId: m.id }, stats.totals);
                });
                setStats(newStats);
            });
    }, [isEditMode]);
    return {
        stats,
        isLoadingStats: isLoading,
    };
};
