import { useReactFlow } from '@xyflow/react';
import * as triggers from '@/const/mailing-triggers';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { createEdge } from '../nodes/createEdge';
import { createDelayNode } from '../nodes/DelayNode/utils';
import { createLoopNode } from '../nodes/LoopNode/utils';
import { TRIGGER_DEFAULT_REPEAT_MAP, TRIGGER_FILTERS_REQUIRED_MAP, } from '../nodes/TriggerNode/utils';
import { branchHasNode, findEdgeById, findFirstEdgeToNode, findNodeById, findNodeByType, updateFlow, } from '../nodes/utils';
const DEFAULT_DELAY_FOR_PURCHASE_IN_MINUTES = 30;
const DEFAULT_DELAY_FOR_BIRTHDAY_AND_BONUS_EXPIRING_IN_DAYS = 7;
const noop = () => { };
export const useUpdateFlowWithNewNode = () => {
    const flow = useReactFlow();
    const { triggerType, newStepPopup, selectedNode, onChange } = useInteractiveFlow();
    const newStepNode = findNodeByType(flow, 'new-step');
    const newStepInEdge = findFirstEdgeToNode(flow, newStepNode);
    const newStepPrev = findNodeById(flow, newStepInEdge === null || newStepInEdge === void 0 ? void 0 : newStepInEdge.source);
    const selectedEdge = findEdgeById(flow, newStepPopup.selectedEdgeId);
    const selectedEdgeSource = findNodeById(flow, selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.source);
    // fromNode -- нода, после которой следует разметить новую ноду
    // несколько вариантов:
    // 1) fromNode -- это нода, на которой был вызван попап нового шага
    let fromNode = findNodeById(flow, newStepPopup.selectedNodeId);
    // 2) fromNode -- это нода, предшествующая ноде нового шага
    if (!fromNode && newStepNode)
        fromNode = newStepPrev;
    // 3) fromNode -- это источник ребра, на котором был вызван попап нового шага
    if (!fromNode && selectedEdgeSource)
        fromNode = selectedEdgeSource;
    // 4) иначе ничего не делаем
    if (!fromNode)
        return noop;
    return (newNode, shouldSelect) => {
        const addNodes = [newNode];
        const removeNodes = [newStepNode];
        const addEdges = [];
        const removeEdges = [newStepInEdge, selectedEdge];
        if (!fromNode)
            return;
        const update = () => {
            updateFlow(flow, { addNodes, removeNodes, addEdges, removeEdges });
            onChange === null || onChange === void 0 ? void 0 : onChange();
            if (shouldSelect)
                setTimeout(() => selectedNode.selectUUID(newNode.uuid), 1);
            newStepPopup.reset();
        };
        // для триггеров с задержкой "ЗА"
        // если в ветку добавляется первая рассылка
        // и перед ней нет задержки,
        // то добавляем стандартную задержку
        if ((triggerType === triggers.TRIGGER_TYPE_BIRTHDAY ||
            triggerType === triggers.TRIGGER_TYPE_CHILD_BIRTHDAY ||
            triggerType === triggers.TRIGGER_TYPE_BONUS_EXPIRING) &&
            newNode.type === 'channel' &&
            !branchHasNode({
                flow,
                toNode: fromNode,
                predicate: (node) => node.type === 'channel',
            }) &&
            !branchHasNode({
                flow,
                toNode: fromNode,
                predicate: (node) => node.type === 'delay',
            })) {
            injectDefaultDelay(triggerType, fromNode, newNode, newStepPopup.selectedHandleId, addNodes, addEdges);
            update();
            return;
        }
        // если начинается новая ветка от триггера,
        // то необходимо не только добавить новую ноду,
        // но еще и вставить loop между триггером и новой нодой
        if (fromNode.type === 'trigger' &&
            triggerType !== triggers.TRIGGER_TYPE_SCHEDULE) {
            injectLoopNode(triggerType, fromNode, newNode, addNodes, addEdges);
            update();
            return;
        }
        // если после рассылки добавляется нода "сделал покупку",
        // то необходимо не только добавить новую ноду,
        // но еще и вставить небольшое ожидание перед новой нодой
        if ((newNode.type === 'email-received' &&
            newNode.data.type === 'purchased') ||
            (newNode.type === 'channel-received' &&
                newNode.data.type === 'purchased_channel')) {
            const branchHasDelayBackward = branchHasNode({
                flow,
                toNode: fromNode,
                predicate: (node) => node.type === 'delay',
                stopPredicate: (node) => node.type === 'channel',
            });
            if (!branchHasDelayBackward) {
                injectDelayNodeBeforePurchasedTrigger(fromNode, newNode, selectedEdge, newStepPopup.selectedHandleId, addNodes, addEdges);
                update();
                return;
            }
        }
        // во всех других случаях просто добавляем ноду
        injectRegularNode(triggerType, fromNode, newNode, selectedEdge, newStepPopup.selectedHandleId, addEdges);
        update();
    };
};
const injectDefaultDelay = (triggerType, fromNode, newNode, selectedHandleId, addNodes, addEdges) => {
    let fromNodeId = fromNode.id;
    if (fromNode.type === 'trigger') {
        const loopNode = createLoopNode({
            repeat: TRIGGER_DEFAULT_REPEAT_MAP[triggerType],
        });
        const fromTriggerToLoopNode = createEdge({
            source: fromNode.id,
            target: loopNode.id,
        });
        fromNodeId = loopNode.id;
        addNodes.push(loopNode);
        addEdges.push(fromTriggerToLoopNode);
    }
    const delayNode = createDelayNode({
        offsetSign: -1,
        offsetUnit: 'day',
        offsetValue: DEFAULT_DELAY_FOR_BIRTHDAY_AND_BONUS_EXPIRING_IN_DAYS,
    });
    const fromFromNodeToDelayNode = createEdge({
        source: fromNodeId,
        sourceHandle: selectedHandleId || undefined,
        target: delayNode.id,
        data: { isInteractive: true },
    });
    const fromDelayNodeToNewNode = createEdge({
        source: delayNode.id,
        target: newNode.id,
        data: { isInteractive: true },
    });
    addNodes.push(delayNode);
    addEdges.push(fromFromNodeToDelayNode, fromDelayNodeToNewNode);
};
const injectLoopNode = (triggerType, fromNode, newNode, addNodes, addEdges) => {
    // 1) создаем loop ноду
    const loopNode = createLoopNode({
        repeat: TRIGGER_DEFAULT_REPEAT_MAP[triggerType],
    });
    // 2) создаем ребро от триггера к loop ноде
    const fromTriggerToLoopNode = createEdge({
        source: fromNode.id,
        target: loopNode.id,
    });
    // 3) создаем ребро от loop ноды к новой ноде
    const fromLoopNodeToNewNode = createEdge({
        source: loopNode.id,
        target: newNode.id,
        data: { isInteractive: !TRIGGER_FILTERS_REQUIRED_MAP[triggerType] },
    });
    addNodes.push(loopNode);
    addEdges.push(fromTriggerToLoopNode, fromLoopNodeToNewNode);
};
const injectDelayNodeBeforePurchasedTrigger = (fromNode, newNode, selectedEdge, selectedHandleId, addNodes, addEdges) => {
    var _a;
    // 1) создаем ноду ожидания
    const newDelayNode = createDelayNode({
        offsetSign: 1,
        offsetUnit: 'minute',
        offsetValue: DEFAULT_DELAY_FOR_PURCHASE_IN_MINUTES,
    });
    // 2) создаем ребро от начальной ноды к ноде ожидания
    const fromFromNodeToDelayNode = createEdge({
        id: selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.id,
        source: fromNode.id,
        sourceHandle: selectedHandleId || (selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.sourceHandle) || undefined,
        target: newDelayNode.id,
        data: { isInteractive: true },
    });
    // 3) создаем ребро от ноды ожидания к новой ноде
    const fromDelayNodeToNewNode = createEdge({
        source: newDelayNode.id,
        target: newNode.id,
        data: { isInteractive: true },
    });
    // 4) если нода создается на ребре (между двумя другими нодами),
    //    то создаем ребро от новой ноды к следующей ноде в ветке
    const fromNewNodeToNextNode = selectedEdge
        ? createEdge({
            source: newNode.id,
            sourceHandle: 'no-0',
            target: selectedEdge.target,
            data: {
                isInteractive: (_a = selectedEdge.data) === null || _a === void 0 ? void 0 : _a.isInteractive,
                isHovered: false,
            },
        })
        : undefined;
    addNodes.push(newDelayNode);
    addEdges.push(fromFromNodeToDelayNode, fromDelayNodeToNewNode, fromNewNodeToNextNode);
};
const injectRegularNode = (triggerType, fromNode, newNode, selectedEdge, selectedHandleId, addEdges) => {
    var _a, _b, _c;
    // 1) создаем ребро от начальной ноды к новой ноде
    const fromFromNodeToNewNode = createEdge({
        id: selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.id,
        source: fromNode.id,
        sourceHandle: selectedHandleId || (selectedEdge === null || selectedEdge === void 0 ? void 0 : selectedEdge.sourceHandle) || undefined,
        target: newNode.id,
        data: {
            isInteractive: !((fromNode.type === 'trigger' || fromNode.type === 'loop') &&
                TRIGGER_FILTERS_REQUIRED_MAP[triggerType]),
        },
    });
    // 2) если нода создается на ребре (между двумя другими нодами),
    //    то создаем ребро от новой ноды к следующей ноде в ветке
    if (selectedEdge) {
        let newNodeHandle = '';
        if (newNode.type === 'conditions') {
            const newNodeData = newNode.data;
            newNodeHandle = ((_a = newNodeData.configuration) === null || _a === void 0 ? void 0 : _a.length)
                ? ((_b = newNodeData.configuration[0]) === null || _b === void 0 ? void 0 : _b.code) + '-0'
                : 'add';
        }
        if (newNode.type === 'email-received' ||
            newNode.type === 'channel-received')
            newNodeHandle = 'no-0';
        const fromNewNodeToNextNode = createEdge({
            source: newNode.id,
            sourceHandle: newNodeHandle,
            target: selectedEdge.target,
            data: {
                isInteractive: (_c = selectedEdge.data) === null || _c === void 0 ? void 0 : _c.isInteractive,
                isHovered: false,
            },
        });
        addEdges.push(fromNewNodeToNextNode);
    }
    addEdges.push(fromFromNodeToNewNode);
};
