import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements, } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DragPreview } from '~/src/components/arbitration/ArbitrationGroup/dnd/DragPreview';
import { EmptyGroupPlaceholder } from '~/src/components/arbitration/ArbitrationGroup/helpers/EmptyGroupPlaceholder';
import stylesArbitrationGroup from '~/src/components/arbitration/ArbitrationGroup/styles.module.scss';
import { Drag } from '~/src/icons/Drag';
import styles from './styles.module.scss';
const idle = { type: 'idle' };
export const CurrentGroupSlot = ({ offerTitle, newOffer, offerInsideList, isGroupsExist, setOfferInsideList, setOffersList, }) => {
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();
    const [draggableState, setDraggableState] = useState(idle);
    const [overPlaceholder, setOverPlaceholder] = useState(false);
    const currentGroupElement = useRef(null);
    const slotRef = useRef(null);
    const arbitrationPriorityAfterError = watch('arbitrationPriorityAfterError');
    useEffect(() => {
        if (!currentGroupElement.current)
            return;
        const draggableCleanup = draggable({
            element: currentGroupElement.current,
            getInitialData() {
                return newOffer;
            },
            onDrop() {
                setDraggableState(idle);
            },
            onGenerateDragPreview({ nativeSetDragImage }) {
                disableNativeDragPreview({ nativeSetDragImage });
            },
            onDrag(args) {
                const { location } = args;
                setDraggableState({
                    type: 'is-dragging',
                    x: location.current.input.pageX,
                    y: location.current.input.pageY,
                });
            },
        });
        const slotElement = slotRef.current;
        let dropTargetCleanup = () => { };
        if (slotElement && isGroupsExist)
            dropTargetCleanup = dropTargetForElements({
                element: slotElement,
                onDragEnter: () => {
                    setOverPlaceholder(true);
                },
                onDragLeave: () => {
                    setOverPlaceholder(false);
                },
                onDrop() {
                    setOffersList((prevList) => {
                        const newOfferList = [...prevList].filter((i) => i.guid !== newOffer.guid);
                        return newOfferList;
                    });
                    setOfferInsideList(false);
                    setOverPlaceholder(false);
                    setValue('newOfferItemOffsetTop', undefined);
                    setValue('arbitrationPriorityAfter', undefined);
                    setValue('arbitrationPriorityAfterError', true);
                },
            });
        return combine(draggableCleanup, dropTargetCleanup);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.currentGroupSlot }, { children: [_jsx("div", Object.assign({ ref: slotRef }, { children: _jsx(EmptyGroupPlaceholder, { title: t('Акция в группе ↓'), className: styles.emptyGroupPlaceholderWrapper, placeholderClassName: classNames(styles.emptyGroup, {
                                [styles.emptyGroupPlaceholder]: overPlaceholder,
                                [styles.showPlaceholder]: !isGroupsExist ||
                                    draggableState.type === 'is-dragging' ||
                                    offerInsideList,
                            }), levelGap: -24 }) })), isGroupsExist && (_jsxs("div", Object.assign({ ref: currentGroupElement, style: {
                            display: draggableState.type === 'is-dragging' || offerInsideList
                                ? 'none'
                                : undefined,
                        }, className: classNames(stylesArbitrationGroup.item, stylesArbitrationGroup.itemGroupWrapper, styles.newOfferItem) }, { children: [_jsx(Drag, {}), _jsx("span", { children: offerTitle })] }))), _jsx("span", Object.assign({ className: classNames(styles.currentGroupSlotDescription, {
                            [styles.error]: arbitrationPriorityAfterError,
                        }), id: 'arbitrationPriorityAfter' }, { children: t('Перетяните акцию вниз, чтобы определить её приоритет в списке') }))] })), draggableState.type === 'is-dragging'
                ? createPortal(_jsx(DragPreview, Object.assign({ className: styles.offerItemPreview, x: draggableState.x, y: draggableState.y }, { children: offerTitle })), document.body)
                : null] }));
};
