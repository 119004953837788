import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { ViberFormButton } from './ViberFormButton';
import { ViberFormImage } from './ViberFormImage';
import { ViberFormTest } from './ViberFormTest';
import { ViberFormText } from './ViberFormText';
import { ViberFormUrl } from './ViberFormUrl';
export const ViberForm = () => {
    var _a;
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const [viberText, setViberText] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _a === void 0 ? void 0 : _a.viberText) || '');
    return (_jsxs(_Fragment, { children: [_jsx(ViberFormText, { viberText: viberText, onChangeViberText: setViberText }), _jsx(ViberFormImage, {}), _jsx(ViberFormButton, {}), _jsx(ViberFormUrl, {}), _jsx(ViberFormTest, { viberText: viberText })] }));
};
