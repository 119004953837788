import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './styles.module.scss';
import { YesNoButton } from './YesNoButton';
export const YesNoInput = ({ value = [], onChange, canDeleteYes, canDeleteNo, isDisabled, }) => {
    const handleAddItem = (item) => {
        onChange === null || onChange === void 0 ? void 0 : onChange([...value, item]);
    };
    const handleDeleteItem = (i) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(value.filter((item, idx) => idx !== i));
    };
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [value.map((item, i) => (_jsx(YesNoButton, { value: item, onReset: handleDeleteItem.bind(null, i), isResetDisabled: (item === 'yes' && !canDeleteYes) || (item === 'no' && !canDeleteNo), isDisabled: isDisabled }, `${item}-${i}`))), (!value.includes('yes') || !value.includes('no')) && (_jsx(YesNoButton, { onChange: handleAddItem, hasYesOption: !value.includes('yes'), hasNoOption: !value.includes('no'), isDisabled: isDisabled }))] })));
};
