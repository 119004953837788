import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Purchase } from '@/icons/Purchase';
import { money } from '@/utils/numbers';
import { useCurrentUser } from '~/src/app/useCurrentUser';
import { GearsAlt } from '~/src/icons/GearsAlt';
import { Shop } from '~/src/icons/Shop';
import styles from '../styles.module.scss';
const MailingContent = ({ shopName, totalAmount, }) => {
    const { currency } = useCurrentUser();
    return (_jsx("div", { children: _jsx("section", Object.assign({ className: styles.expandedMailingContent }, { children: _jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.contentRow }, { children: ["\u0421\u0443\u043C\u043C\u0430:\u00A0", _jsx("span", Object.assign({ className: styles.contentValue }, { children: money({
                                    input: totalAmount,
                                    currency,
                                }) }))] })), _jsxs("div", Object.assign({ className: styles.totalAmountRow }, { children: [_jsx(Shop, { size: 14 }), shopName] }))] }) })) }));
};
export const purchaseTitleRenderer = ({ event, translate, entityHandlers, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { handlePurchase, handleOrder, handleReturn, handleEntityLink } = entityHandlers;
    // Изменение счетчика
    if (event.eventType === 'changed') {
        const link = handleEntityLink('offer', (_a = event.params) === null || _a === void 0 ? void 0 : _a.offerId);
        const changeValue = +((_b = event.params) === null || _b === void 0 ? void 0 : _b.incrementedCounter) +
            +((_c = event.params) === null || _c === void 0 ? void 0 : _c.incrementedAndUsedCounter) -
            +((_d = event.params) === null || _d === void 0 ? void 0 : _d.usedCounter);
        const formattedChangeValue = changeValue >= 0 ? '+' + changeValue : changeValue;
        const changeInCounter = changeValue === 0
            ? translate('Изменение в счетчике')
            : translate('в счетчике');
        const rewards = +((_e = event.params) === null || _e === void 0 ? void 0 : _e.incrementedCoupon) +
            +((_f = event.params) === null || _f === void 0 ? void 0 : _f.incrementedAndUsedCoupon) -
            +((_g = event.params) === null || _g === void 0 ? void 0 : _g.usedCoupon);
        const formattedRewards = rewards >= 0 ? '+' + rewards : rewards;
        const rewardsInCounter = rewards !== 0
            ? translate('ADD_REWARDS', {
                count: rewards,
                rewards: formattedRewards,
            })
            : null;
        return {
            color: changeValue > 0 ? 'green' : 'red',
            Icon: _jsx(GearsAlt, { fill: 'currentColor' }),
            title: (_jsxs(_Fragment, { children: [`${formattedChangeValue} ${changeInCounter}`, "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.offerName }))) : (_jsx(_Fragment, { children: event.params.offerName ? (_jsx("span", { children: `"${event.params.offerName}"` })) : (_jsx("span", { children: translate('акции') })) })), `. ${rewardsInCounter}`] })),
        };
    }
    // Уменьшился счетчик
    if (event.eventType === 'expired') {
        const link = handleEntityLink('offer', (_h = event.params) === null || _h === void 0 ? void 0 : _h.offerId);
        return {
            color: 'gray',
            Icon: _jsx(GearsAlt, {}),
            title: (_jsxs(_Fragment, { children: [translate('Истек срок счетчика'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.offerName }))) : (_jsx(_Fragment, { children: event.params.offerName ? (_jsx("span", { children: `"${event.params.offerName}"` })) : (_jsx("span", { children: translate('акции') })) }))] })),
        };
    }
    // Сделал покупку
    if (event.eventType === 'purchase')
        return {
            Icon: _jsx(Purchase, {}),
            content: (event.params.shopName || event.params.totalAmount) && (_jsx(MailingContent, { shopName: event.params.shopName, totalAmount: event.params.totalAmount })),
            title: (_jsxs(_Fragment, { children: [translate('Сделал'), "\u00A0", _jsx("span", Object.assign({ className: ((_j = event.params) === null || _j === void 0 ? void 0 : _j.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупку') }))] })),
        };
    // Сделал возврат
    if (event.eventType === 'purchase_return')
        return {
            color: 'red',
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Сделал'), "\u00A0", _jsx("span", Object.assign({ className: ((_k = event.params) === null || _k === void 0 ? void 0 : _k.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handleReturn((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('возврат') }))] })),
        };
    // Отмена (удаление покупки)
    if (event.eventType === 'purchase_revert')
        return {
            color: 'red',
            Icon: _jsx(Purchase, {}),
            content: (event.params.shopName || event.params.totalAmount) && (_jsx(MailingContent, { shopName: event.params.shopName, totalAmount: event.params.totalAmount })),
            title: (_jsxs(_Fragment, { children: [translate('Отменена (или удалена)'), "\u00A0", _jsx("span", Object.assign({ className: ((_l = event.params) === null || _l === void 0 ? void 0 : _l.purchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.purchaseId); } }, { children: translate('покупка') }))] })),
        };
    // Друг сделал покупку
    if (event.eventType === 'friend_purchase')
        return {
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Друг совершил'), "\u00A0", _jsx("span", Object.assign({ className: ((_m = event.params) === null || _m === void 0 ? void 0 : _m.referralPurchaseId) ? styles.underline : '', onClick: () => { var _a; return handlePurchase((_a = event.params) === null || _a === void 0 ? void 0 : _a.referralPurchaseId); } }, { children: translate('покупку') }))] })),
        };
    // Сделал заказ
    if (event.eventType === 'order')
        return {
            content: (event.params.shopName || event.params.totalAmount) && (_jsx(MailingContent, { shopName: event.params.shopName, totalAmount: event.params.totalAmount })),
            Icon: _jsx(Purchase, {}),
            title: (_jsxs(_Fragment, { children: [translate('Сделал'), "\u00A0", _jsx("span", Object.assign({ className: event.refId ? styles.underline : '', onClick: () => handleOrder(event.refId) }, { children: ((_o = event.params) === null || _o === void 0 ? void 0 : _o.txid)
                            ? translate('ORDER_NUMBER', {
                                number: (_p = event.params) === null || _p === void 0 ? void 0 : _p.txid,
                            })
                            : translate('заказ') }))] })),
        };
    return {};
};
