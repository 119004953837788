import { getIncomers, getOutgoers, } from '@xyflow/react';
import { defined } from '@/utils/defined';
import { createEdge } from './createEdge';
export const findNode = (flow, predicate) => {
    const nodes = flow.getNodes();
    const node = nodes.find(predicate);
    return node;
};
export const findNodeById = (flow, nodeId) => {
    return findNode(flow, (node) => node.id === nodeId);
};
export const findNodeByUUID = (flow, nodeUUID) => {
    const node = findNode(flow, (node) => {
        const nodeWithUUID = node;
        return nodeWithUUID.uuid === nodeUUID;
    });
    return node;
};
export const findNodeByType = (flow, type) => {
    return findNode(flow, (node) => node.type === type);
};
export const findEdgeById = (flow, edgeId) => {
    if (!edgeId)
        return undefined;
    return flow.getEdge(edgeId);
};
export const findEdgesToNode = (flow, node) => {
    const edges = flow.getEdges();
    const nodeId = typeof node === 'string' ? node : node === null || node === void 0 ? void 0 : node.id;
    return edges.filter((e) => e.target === nodeId);
};
export const findFirstEdgeToNode = (flow, node) => {
    const inEdges = findEdgesToNode(flow, node);
    return inEdges[0];
};
export const findEdgesFromNode = (flow, node) => {
    const edges = flow.getEdges();
    const nodeId = typeof node === 'string' ? node : node === null || node === void 0 ? void 0 : node.id;
    return edges.filter((e) => e.source === nodeId);
};
export const nodeHasOutputEdges = (flow, node) => {
    const outputEdges = findEdgesFromNode(flow, node);
    return outputEdges.length > 0;
};
export const replaceNodeEdges = (flow, oldNode, newNode, hideForLayout = true) => {
    const toOldNode = findEdgesToNode(flow, oldNode);
    const fromOldNode = findEdgesFromNode(flow, oldNode);
    const toNewNode = toOldNode.map((e) => {
        return createEdge({
            id: e.id,
            source: e.source,
            sourceHandle: e.sourceHandle,
            target: newNode.id,
            data: e.data,
        }, hideForLayout);
    });
    const fromNewNode = fromOldNode.map((e) => {
        return createEdge({
            id: e.id,
            source: newNode.id,
            sourceHandle: e.sourceHandle,
            target: e.target,
            data: e.data,
        }, hideForLayout);
    });
    return {
        addEdges: [...toNewNode, ...fromNewNode],
        removeEdges: [...toOldNode, ...fromOldNode],
    };
};
const getNodeIncomers = (flow, node) => {
    const nodes = flow.getNodes();
    const edges = flow.getEdges();
    if (node)
        return getIncomers(node, nodes, edges);
    return [];
};
const getNodeOutgoers = (flow, node) => {
    const nodes = flow.getNodes();
    const edges = flow.getEdges();
    if (node)
        return getOutgoers(node, nodes, edges);
    return [];
};
const findNodeInBranch = (flow, startNode, predicate, stopPredicate, type = 'incomer') => {
    const nodes = type === 'incomer'
        ? getNodeIncomers(flow, startNode)
        : getNodeOutgoers(flow, startNode);
    for (let i = 0; i < nodes.length; i += 1) {
        const incomer = nodes[i];
        if (predicate(incomer))
            return incomer;
        if (stopPredicate === null || stopPredicate === void 0 ? void 0 : stopPredicate(incomer))
            return null;
        else
            return findNodeInBranch(flow, incomer, predicate, stopPredicate, type);
    }
    return null;
};
export const branchHasNode = ({ flow, type = 'incomer', toNode, predicate, checkInitialNode = true, stopPredicate, stopOnInitialNode = true, }) => {
    if (checkInitialNode && predicate(toNode))
        return true;
    if (stopOnInitialNode && (stopPredicate === null || stopPredicate === void 0 ? void 0 : stopPredicate(toNode)))
        return false;
    const node = findNodeInBranch(flow, toNode, predicate, stopPredicate, type);
    return !!node;
};
export const updateFlow = (flow, { addNodes = [], removeNodes = [], changeNodes = [], addEdges = [], removeEdges = [], }) => {
    const nodes = flow.getNodes();
    const edges = flow.getEdges();
    const removeNodeIds = removeNodes.map((n) => n === null || n === void 0 ? void 0 : n.id).filter(defined);
    const changeNodeIds = changeNodes.map((n) => n === null || n === void 0 ? void 0 : n.id).filter(defined);
    const removeEdgeIds = removeEdges.map((e) => e === null || e === void 0 ? void 0 : e.id).filter(defined);
    flow.setNodes([
        ...nodes.filter((n) => !removeNodeIds.includes(n.id) && !changeNodeIds.includes(n.id)),
        ...changeNodes.filter(defined),
        ...addNodes.filter(defined),
    ]);
    flow.setEdges([
        ...edges.filter((e) => !removeEdgeIds.includes(e.id)),
        ...addEdges.filter(defined),
    ]);
};
export const flowHasNewStepNode = (flow) => {
    const nodes = flow.getNodes();
    const newStepNodes = nodes.filter((node) => node.type === 'new-step');
    return newStepNodes.length > 0;
};
