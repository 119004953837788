import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { AccentLink } from '@/components/AccentText/AccentLink';
import { AccentText } from '@/components/AccentText/AccentText';
import { SegmentList } from '@/components/SegmentList/SegmentList';
import { Female } from '@/icons/Female';
import { Male } from '@/icons/Male';
import { MobilePhone } from '@/icons/MobilePhone';
import { money, numberRound } from '@/utils/numbers';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import { MailInContainer } from '~/src/icons/MailInContainer';
import { Messengers } from '~/src/icons/Messengers';
import styles from './styles.module.scss';
export const ClientOverview = memo(({ client, currency, purchaseUrl, onPurchasesClick, onPurchaseReturnsClick, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasPurchasesManage = userHasAccessTo([
        'CLIENTS_PURCHASES_LIST',
        'CLIENTS_PURCHASES_MANAGE',
    ]);
    const clientFullName = [
        (_a = client.name) === null || _a === void 0 ? void 0 : _a.trim(),
        (_b = client.patronymic) === null || _b === void 0 ? void 0 : _b.trim(),
        (_c = client.surname) === null || _c === void 0 ? void 0 : _c.trim(),
    ]
        .filter(Boolean)
        .join(' ');
    let clientGender = '';
    let clientGenderIcon;
    if (client.gender === 1) {
        clientGender = t('Мужской');
        clientGenderIcon = _jsx(Male, { width: 20, height: 20 });
    }
    if (client.gender === 2) {
        clientGender = t('Женский');
        clientGenderIcon = _jsx(Female, { width: 20, height: 20 });
    }
    const clientIsBlocked = client.status === 3 || client.status === 4;
    const clientPurchases = client.purchases > 0
        ? money({ input: client.totalSpent, currency }) +
            ' / ' +
            t('CLIENT_PURCHASES_COUNT', {
                amount: numberRound(client.purchases || 0),
                count: client.purchases || 0,
            })
        : t('нет покупок');
    const clientReturns = client.purchaseReturns > 0
        ? money({ input: client.totalReturned, currency }) +
            ' / ' +
            t('CLIENT_RETURNS_COUNT', {
                amount: numberRound(client.purchaseReturns || 0),
                count: client.purchaseReturns || 0,
            })
        : t('нет возвратов');
    const handlePurchasesClick = useCallback((e) => {
        e.preventDefault();
        if (onPurchasesClick)
            onPurchasesClick();
    }, [onPurchasesClick]);
    const handlePurchaseReturnsClick = useCallback((e) => {
        e.preventDefault();
        if (onPurchaseReturnsClick)
            onPurchaseReturnsClick();
    }, [onPurchaseReturnsClick]);
    return (_jsx("table", Object.assign({ className: styles.table }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("th", { children: t('Имя') }), _jsx("td", { children: clientFullName })] }), _jsxs("tr", { children: [_jsx("th", { children: t('Телефон') }), _jsx("td", { children: formatPhoneNumber(client.phoneNumber) })] }), _jsxs("tr", { children: [_jsx("th", { children: t('Карта') }), _jsx("td", { children: client.card })] }), _jsxs("tr", { children: [_jsx("th", { children: t('Email') }), _jsx("td", { children: client.email })] }), _jsxs("tr", { children: [_jsx("th", { children: t('День рождения') }), _jsx("td", { children: client.birthdate
                                ? moment(client.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY')
                                : '' })] }), _jsxs("tr", { children: [_jsx("th", { children: t('Пол') }), _jsx("td", { children: _jsxs("span", { children: [_jsx("span", Object.assign({ className: styles.genderIcon }, { children: clientGenderIcon })), clientGender.toLowerCase()] }) })] }), _jsxs("tr", { children: [_jsx("th", { children: t('Рассылки') }), _jsx("td", { children: _jsxs("div", Object.assign({ className: styles.mailings }, { children: [client.isPhoneSubscribed && _jsx(MobilePhone, {}), client.isEmailSubscribed && _jsx(MailInContainer, { fill: '#3a3a3a' }), client.isChatSubscribed && _jsx(Messengers, { fill: '#3a3a3a' }), !!(client.isPhoneSubscribed || client.isEmailDisabled) &&
                                        !clientIsBlocked && _jsx("span", { children: t('согласен') }), clientIsBlocked && (_jsx("span", Object.assign({ className: styles.blocked }, { children: t('Приостановлены').toLowerCase() })))] })) })] }), _jsxs("tr", { children: [_jsx("th", { children: t('Покупок') }), _jsxs("td", { children: [_jsx("div", Object.assign({ className: styles.purchases }, { children: userHasPurchasesManage &&
                                        client.purchases > 0 &&
                                        (client.phoneNumber || client.card) ? (_jsx(AccentLink, Object.assign({ accent: 'success', href: purchaseUrl, onClick: handlePurchasesClick }, { children: clientPurchases }))) : (_jsx(AccentText, Object.assign({ accent: !client.purchases ? 'empty' : 'success' }, { children: clientPurchases }))) })), _jsx("div", { children: userHasPurchasesManage &&
                                        client.purchaseReturns > 0 &&
                                        (client.phoneNumber || client.card) ? (_jsx(AccentLink, Object.assign({ accent: 'danger', href: purchaseUrl, onClick: handlePurchaseReturnsClick }, { children: clientReturns }))) : (_jsx(AccentText, Object.assign({ accent: !client.purchaseReturns ? 'empty' : 'danger' }, { children: clientReturns }))) })] })] }), _jsxs("tr", { children: [_jsx("th", { children: t('Сегменты') }), _jsx("td", Object.assign({ className: styles.segments }, { children: _jsx(SegmentList, { segments: client.segments }) }))] })] }) })));
});
