import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { RepeatInfinity } from '@/icons/flow/RepeatInfinity';
import { RepeatOneTime } from '@/icons/flow/RepeatOneTime';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { NodePlusButton } from '../helpers/NodePlusButton/NodePlusButton';
import styles from './styles.module.scss';
export const LoopNode = ({ id, data, }) => {
    const flow = useReactFlow();
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const { repeat = 'one-time' } = data;
    const thisNode = flow.getNode(id);
    const handleClick = useCallback(() => {
        if (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid)
            selectedNode.selectUUID(thisNode.uuid);
    }, [thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid, selectedNode.selectUUID]);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ className: styles.tooltip, title: repeat === 'one-time'
                    ? t('Ветка сработает только один раз на клиента')
                    : t('Ветка сработает при каждой активации триггера'), PopperProps: { disablePortal: true } }, { children: _jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles.interactive, { [styles.selected]: selectedNode.uuid === (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid) }, 'nodrag', 'nopan'), onClick: handleClick }, { children: [repeat === 'one-time' ? (_jsx(RepeatOneTime, { className: styles.repeatIcon })) : (_jsx(RepeatInfinity, { className: styles.repeatIcon })), _jsx("span", Object.assign({ className: styles.content }, { children: repeat === 'one-time' ? t('1 раз') : t('каждый раз') }))] })) })), _jsx(NodePlusButton, { nodeId: id }), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', 'flow-handle-target-delay'), type: 'target', position: Position.Left }), _jsx(Handle, { className: 'flow-handle', type: 'source', position: Position.Right })] }));
};
