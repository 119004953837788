import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { ChannelCircles } from '@/components/ChannelCircles/ChannelCircles';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { Draft } from '@/icons/status/Draft';
import { Pause } from '@/icons/status/Pause';
import { cr } from '@/utils/cr';
import { numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import styles from './styles.module.scss';
export const convertFlowToRowTable = ({ flow, shouldDisplayProfit, currency, purchaseUrl, openPurchaseFilter, actions, }) => {
    var _a;
    const channels = Object.assign({}, flow.channels);
    (_a = flow.publishedConfig) === null || _a === void 0 ? void 0 : _a.nodes.forEach((node) => {
        var _a, _b, _c, _d, _e;
        if (node.type === 'channel') {
            const data = node.data;
            if ((_a = data.channels) === null || _a === void 0 ? void 0 : _a.includes('push'))
                channels.push = true;
            if ((_b = data.channels) === null || _b === void 0 ? void 0 : _b.includes('viber'))
                channels.viber = true;
            if ((_c = data.channels) === null || _c === void 0 ? void 0 : _c.includes('sms'))
                channels.sms = true;
            if ((_d = data.channels) === null || _d === void 0 ? void 0 : _d.includes('email'))
                channels.email = true;
            if ((_e = data.channels) === null || _e === void 0 ? void 0 : _e.includes('telegram'))
                channels.telegram = true;
        }
    });
    return {
        id: flow.id,
        cells: [
            _jsxs(_Fragment, { children: [flow.status === 'draft' && (_jsx("span", Object.assign({ className: styles.statusIcon }, { children: _jsx(Draft, {}) }))), flow.status === 'pause' && (_jsx("span", Object.assign({ className: styles.statusIcon }, { children: _jsx(Pause, {}) }))), flow.title] }),
            _jsx(ChannelCircles, { channels: channels, cascade: false }, 'channels'),
            numberRound(roi(shouldDisplayProfit ? flow.profit : flow.revenue, flow.expenses)) + '%',
            numberRound(cr(flow.goals, flow.sendings), 2) + '%',
            _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: Number(flow.profit), revenue: Number(flow.revenue), currency: currency, href: purchaseUrl, onClick: () => openPurchaseFilter(flow.mailings) }, 'profitOrRevenue'),
            _jsx(Expenses, { expenses: Number(flow.expenses), currency: currency }, 'expenses'),
            moment(flow.createdAt).toDate(),
        ],
        actions,
    };
};
