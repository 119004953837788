var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { fixGuids, getDraftGuids, } from '@/components/arbitration/ArbitrationEditor/utils';
import { convertPriorityToSavePriorities } from './convertPriorityToSavePriorities';
export const useArbitrationState = ({ initialList, saveArbitrationList, isLoading = false, }) => {
    const [priority, setPriority] = useState(initialList.priority);
    const [unordered, setUnordered] = useState(initialList.unordered);
    const [lastSavedPriority, setLastSavedPriority] = useState(initialList.priority);
    const [lastSavedUnordered, setLastSavedUnordered] = useState(initialList.unordered);
    const draftGuids = getDraftGuids(priority, lastSavedPriority, unordered, lastSavedUnordered);
    useEffect(() => {
        if (!isLoading) {
            setPriority(initialList.priority);
            setUnordered(initialList.unordered);
            setLastSavedPriority(initialList.priority);
            setLastSavedUnordered(initialList.unordered);
        }
    }, [isLoading]);
    const handleChangeArbitrationList = useCallback((priority, unordered) => {
        setPriority(priority);
        setUnordered(unordered);
    }, []);
    const handleApplyChanges = useCallback((priority) => __awaiter(void 0, void 0, void 0, function* () {
        const fixedPriority = fixGuids(priority, (guid) => {
            if (guid.startsWith('new-'))
                return '';
            return guid;
        });
        const priorities = convertPriorityToSavePriorities(fixedPriority);
        yield saveArbitrationList(priorities);
    }), []);
    return {
        priority,
        unordered,
        lastSavedPriority,
        lastSavedUnordered,
        draftGuids,
        handleChangeArbitrationList,
        handleApplyChanges,
    };
};
