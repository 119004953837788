import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const SMSFormSender = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const { brand } = useCurrentUser();
    const smsSenders = ((_b = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.mailing) === null || _b === void 0 ? void 0 : _b.smsSenders) || [];
    const shouldDisplay = smsSenders.length > 1;
    const handleUpdateSender = (sender) => {
        selectedNode.update({
            sms: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms), { smsSenderId: sender === null || sender === void 0 ? void 0 : sender.id }),
        });
    };
    if (!shouldDisplay)
        return null;
    return (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(formStyles.label, formStyles.movedUp), htmlFor: 'channel-form-sms-sender' }, { children: t('От кого') })), _jsx(Autocomplete, { id: 'channel-form-sms-sender', value: smsSenders.find((item) => { var _a; return item.id === ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms) === null || _a === void 0 ? void 0 : _a.smsSenderId); }) || null, onChange: handleUpdateSender, options: smsSenders, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasResetButton: false, asSelect: true, disabled: !isEditMode })] }));
};
