import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { Input } from '@/components/Input/Input';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
import { useIssuerShopOptions } from './useIssuerShopOptions';
export const ImportFormSettings = ({ importData, importPreview, giftCards = [], promocodes = [], onChangeImportData, onChangeSheet, currency, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const isClients = importData.type === 0;
    const isPurchases = importData.type === 2;
    const isGiftCards = importData.type === 3;
    const isPromocode = importData.type === 4;
    const isBonuses = importData.type === 5;
    const hasSheets = importPreview.sheets.length > 1;
    const importDataGiftCard = giftCards.find((card) => { var _a; return card.id === ((_a = importData.defaultValues) === null || _a === void 0 ? void 0 : _a.giftCardId); }) || null;
    const importDataPromocode = promocodes.find((code) => { var _a; return code.id === ((_a = importData.defaultValues) === null || _a === void 0 ? void 0 : _a.promocodeId); }) || null;
    const handleChangeData = (name, value) => {
        const newImportData = Object.assign(Object.assign({}, importData), { defaultValues: Object.assign({}, importData.defaultValues) });
        if (name === 'autoLevel')
            newImportData.defaultValues.autoLevel = Boolean(value);
        if (name === 'giftCardId')
            newImportData.defaultValues.giftCardId = Number(value);
        if (name === 'promocodeId')
            newImportData.defaultValues.promocodeId = Number(value);
        if (name === 'isReplace')
            newImportData.isReplace = Boolean(value);
        if (name === 'sheet')
            onChangeSheet === null || onChangeSheet === void 0 ? void 0 : onChangeSheet(Number(value));
        if (name === 'skipHeader') {
            newImportData.skipHeader = Number(value);
            if (newImportData.skipHeader < 0)
                newImportData.skipHeader = 0;
            if (newImportData.skipHeader > importPreview.previewRows.length - 1)
                newImportData.skipHeader = importPreview.previewRows.length - 1;
        }
        if (name === 'clientIssuerShop')
            newImportData.defaultValues.issuerShopId = Number(value);
        if (name === 'clientStatus')
            newImportData.defaultValues.status = Number(value);
        onChangeImportData === null || onChangeImportData === void 0 ? void 0 : onChangeImportData(newImportData);
    };
    const updateLevelsCheckbox = (_jsx("div", Object.assign({ className: styles.control }, { children: _jsx(Checkbox, Object.assign({ className: styles.checkbox, checked: (_a = importData.defaultValues) === null || _a === void 0 ? void 0 : _a.autoLevel, align: 'center', onChange: (e) => {
                handleChangeData('autoLevel', e.target.checked);
            } }, { children: t('Обновить уровни лояльности на основе суммы покупок') })) })));
    const giftCardsSelect = (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ htmlFor: 'giftCardId', className: styles.controlLabel }, { children: t('Номинал подарочной карты') })), _jsx("div", Object.assign({ className: styles.giftCardInput }, { children: _jsx(Autocomplete, { id: 'giftCardId', name: 'giftCardId', options: giftCards, getOptionKey: (card) => card.id, getOptionLabel: (card) => `${card.name} (${money({ input: card.amount, currency })})`, value: importDataGiftCard, onChange: (card) => {
                        if (card)
                            handleChangeData('giftCardId', card === null || card === void 0 ? void 0 : card.id);
                    }, asSelect: true, hasResetButton: false }) }))] })));
    const promocodesSelect = (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ htmlFor: 'promocodeId', className: styles.controlLabel }, { children: t('Промокод') })), _jsx("div", Object.assign({ className: styles.promcodeInput }, { children: _jsx(Autocomplete, { id: 'promocodeId', name: 'promocodeId', options: promocodes, getOptionKey: (code) => code.id, getOptionLabel: (code) => code.code, value: importDataPromocode, onChange: (code) => {
                        if (code)
                            handleChangeData('promocodeId', code === null || code === void 0 ? void 0 : code.id);
                    }, asSelect: true, hasResetButton: false }) }))] })));
    const replaceCheckbox = (_jsx("div", Object.assign({ className: styles.control }, { children: _jsx(Checkbox, Object.assign({ className: styles.checkbox, checked: importData.isReplace, align: 'center', onChange: (e) => {
                handleChangeData('isReplace', e.target.checked);
            } }, { children: t('Заменять существующие данные') })) })));
    const bonusesRadio = (_jsx("div", Object.assign({ className: styles.control }, { children: _jsx(RadioGroup, { className: styles.radio, name: 'radio-replace', value: importData.isReplace ? 1 : 0, items: [
                {
                    value: 0,
                    label: t('Увеличить/уменьшить количество бонусов на указанное значение'),
                },
                {
                    value: 1,
                    label: t('Задать новое количество бонусов'),
                },
            ], align: 'vertical', onChange: (value) => {
                handleChangeData('isReplace', value);
            } }) })));
    const sheetsSelect = (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ htmlFor: 'sheet', className: styles.controlLabel }, { children: t('Лист') })), _jsx("div", Object.assign({ className: styles.sheetInput }, { children: _jsx(Autocomplete, { id: 'sheet', name: 'sheet', options: importPreview.sheets, getOptionKey: (sheet) => sheet, getOptionLabel: (sheet) => sheet, value: importPreview.sheets[importData.sheet], onChange: (sheet) => {
                        const index = importPreview.sheets.findIndex((s) => s === sheet);
                        if (index >= 0)
                            handleChangeData('sheet', index);
                    }, asSelect: true, hasResetButton: false }) }))] })));
    const skipHeaderInput = (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ htmlFor: 'skipHeader', className: styles.controlLabel }, { children: t('Пропустить заголовок таблицы из файла') })), _jsx("div", Object.assign({ className: styles.skipHeaderInput }, { children: _jsx(Input, { id: 'skipHeader', name: 'skipHeader', type: 'number', inputMode: 'numeric', value: (_b = importData.skipHeader) !== null && _b !== void 0 ? _b : 1, step: 'any', preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, rightText: t('LINES', { count: (_c = importData.skipHeader) !== null && _c !== void 0 ? _c : 1 }), onChange: (e) => {
                        handleChangeData('skipHeader', e.target.value);
                    } }) }))] })));
    const { issuerShopOptions, isLoading: isLoadingShopOptions } = useIssuerShopOptions();
    const clientIssuerShopSelect = (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ htmlFor: 'clientIssuerShop', className: styles.controlLabel }, { children: t('Место вступления') })), _jsx("div", Object.assign({ className: styles.clientIssuerShopInput }, { children: _jsx(Autocomplete, { id: 'clientIssuerShop', name: 'clientIssuerShop', options: issuerShopOptions, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, isOptionGroup: (option) => option.isGroup, isOptionGroupItem: (option) => option.isGroup === false, value: issuerShopOptions.find((opt) => { var _a; return opt.id === ((_a = importData.defaultValues) === null || _a === void 0 ? void 0 : _a.issuerShopId); }) || null, onChange: (clientIssuerShop) => {
                        if (clientIssuerShop)
                            handleChangeData('clientIssuerShop', clientIssuerShop.id);
                    } }, isLoadingShopOptions.toString()) }))] })));
    const clientStatusOptions = [
        { id: 1, label: t('Участник ПЛ') },
        { id: 2, label: t('Подписчик') },
        { id: 3, label: t('Заблокирован') },
    ];
    const clientStatusSelect = (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsx("label", Object.assign({ htmlFor: 'clientStatus', className: styles.controlLabel }, { children: t('Статус участия') })), _jsx("div", Object.assign({ className: styles.clientStatusInput }, { children: _jsx(Autocomplete, { id: 'clientStatus', name: 'clientStatus', options: clientStatusOptions, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, value: clientStatusOptions.find((opt) => { var _a; return opt.id === ((_a = importData.defaultValues) === null || _a === void 0 ? void 0 : _a.status); }) || clientStatusOptions[0], onChange: (clientStatus) => {
                        if (clientStatus)
                            handleChangeData('clientStatus', clientStatus.id);
                    }, asSelect: true, hasResetButton: false }) }))] })));
    return (_jsxs("div", Object.assign({ className: styles.settings }, { children: [skipHeaderInput, isClients ? clientIssuerShopSelect : null, isClients ? clientStatusSelect : null, isClients ? updateLevelsCheckbox : null, isGiftCards ? giftCardsSelect : null, isPromocode ? promocodesSelect : null, !isPurchases && !isBonuses ? replaceCheckbox : null, isBonuses ? bonusesRadio : null, hasSheets ? sheetsSelect : null] })));
};
