import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import moment from 'moment';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetLastBreadcrumb } from '@/app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteDadataAddress } from '@/components/Autocomplete/instances/AutocompleteDadataAddress/AutocompleteDadataAddress';
import { BASIC_END_YEAR, BASIC_START_YEAR, DateInput, } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { TextArea } from '@/components/Input/TextArea';
import { useDebounce } from '@/hooks/useDebounce';
import { formatOffset } from '@/utils/numbers';
import { convertCoordinatesToArray } from './convertCoordinatesToArray';
import styles from './styles.module.scss';
const DEFAULT_COORDINATES = [55.753995, 37.614069];
// Ввод широты и долготы через пробел, точку с запятой или просто точку
const REGEXP_COORDINATES = /^\s*-?[0-9]{1,3}\.\d+\s*[;,\s]\s*-?[0-9]{1,3}\.\d+\s*$/;
export const FormGeoPushFields = ({ isShowAddressField, defaultCoordinates, timeZone, onChangeMessage, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation();
    const SHOW_FIELD_LIST = [
        { value: 'always', label: t('всегда') },
        { value: 'specified period', label: t('заданный период') },
    ];
    const [mapCoordinates, setMapCoordinates] = React.useState(defaultCoordinates
        ? defaultCoordinates.split(',').map((i) => Number(i.trim()))
        : DEFAULT_COORDINATES);
    const { control, formState, setValue, watch } = useFormContext();
    const { errors, isSubmitted } = formState;
    const show = watch('show');
    const showFrom = watch('showFrom');
    const showBy = watch('showBy');
    const coordinates = watch('coordinates');
    const title = watch('title');
    useGetLastBreadcrumb(title);
    const tzOffset = timeZone ? moment().tz(timeZone).utcOffset() / 60 : '';
    const tzHelp = timeZone
        ? t('по часовому поясу') + ` ${timeZone} ${formatOffset(tzOffset || 0)}`
        : undefined;
    useDebounce({
        value: coordinates,
        callback: () => {
            setMapCoordinates(
            // Координаты могут иметь разный разделитель
            convertCoordinatesToArray(coordinates) || DEFAULT_COORDINATES);
        },
    });
    // Заполняем координаты по данным адреса
    const handleAddressChange = (value) => {
        var _a, _b, _c, _d, _e, _f;
        setValue('address', value);
        if (((_a = value === null || value === void 0 ? void 0 : value.data) === null || _a === void 0 ? void 0 : _a.geo_lat) && ((_b = value === null || value === void 0 ? void 0 : value.data) === null || _b === void 0 ? void 0 : _b.geo_lon)) {
            setValue('coordinates', `${(_c = value === null || value === void 0 ? void 0 : value.data) === null || _c === void 0 ? void 0 : _c.geo_lat}, ${(_d = value === null || value === void 0 ? void 0 : value.data) === null || _d === void 0 ? void 0 : _d.geo_lon}`, { shouldValidate: true });
            setMapCoordinates([
                +((_e = value === null || value === void 0 ? void 0 : value.data) === null || _e === void 0 ? void 0 : _e.geo_lat.replace(/\s/g, '').replace(',', '.')),
                +((_f = value === null || value === void 0 ? void 0 : value.data) === null || _f === void 0 ? void 0 : _f.geo_lon.replace(/\s/g, '').replace(',', '.')),
            ]);
        }
        else {
            setMapCoordinates(DEFAULT_COORDINATES);
            setValue('coordinates', undefined, { shouldValidate: true });
        }
    };
    const notificationPeriod = show === 'specified period';
    return (_jsxs("div", Object.assign({ className: styles.fieldRowWrapper }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Название'), labelFor: 'title', controlSize: '300', help: (_a = errors.title) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.title, isRequired: true }, { children: _jsx(Controller, { name: 'title', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'title', placeholder: t('введите название точки'), hasError: !!errors.title, autoFocus: true }))) }) })), isShowAddressField && (_jsx(FormFieldRow, Object.assign({ label: t('Адрес'), labelFor: 'address', controlSize: '300', help: (_b = errors.address) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.address }, { children: _jsx(Controller, { name: 'address', control: control, render: ({ field }) => (_jsx(AutocompleteDadataAddress, { id: 'address', value: field.value || null, onChange: handleAddressChange, hasError: !!errors.address, hasResetButton: false, placeholder: t('введите адрес') })) }) }))), _jsx(FormFieldRow, Object.assign({ label: t('Координаты'), labelFor: 'coordinates', controlSize: '300', help: ((_c = errors.coordinates) === null || _c === void 0 ? void 0 : _c.message) || (_jsx("a", Object.assign({ href: 'https://help.maxma.com/p/IOhnMOXQsrXivU/Karty-Wallet#403fb8b1', target: '_blank', rel: 'noreferrer', className: styles.coordinatesMessage }, { children: t('Как найти координаты магазина') }))), hasError: !!(errors === null || errors === void 0 ? void 0 : errors.coordinates), isRequired: true }, { children: _jsx(Controller, { name: 'coordinates', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        pattern: {
                            value: REGEXP_COORDINATES,
                            message: t('Не верный формат. Введите широту и долготу через пробел, запятую или точку с запятой'),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'coordinates', placeholder: t('широта, долгота'), hasError: !!(errors === null || errors === void 0 ? void 0 : errors.coordinates), value: (field === null || field === void 0 ? void 0 : field.value) || '' }))) }) })), _jsx(FormFieldRow, { children: _jsx("div", Object.assign({ className: styles.map }, { children: _jsx(YMaps, { children: _jsx(Map, Object.assign({ options: {
                                copyrightLogoVisible: false,
                                copyrightProvidersVisible: false,
                                copyrightUaVisible: false,
                                suppressMapOpenBlock: true,
                            }, width: '300px', height: '100px', state: {
                                center: mapCoordinates,
                                zoom: 16,
                            } }, { children: _jsx(Placemark, { geometry: mapCoordinates }) })) }) })) }), _jsx(FormFieldRow, Object.assign({ label: t('Сообщение'), labelFor: 'message', controlSize: '300', help: (_d = errors.message) === null || _d === void 0 ? void 0 : _d.message, hasError: !!errors.message, isRequired: true }, { children: _jsx(Controller, { name: 'message', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(TextArea, Object.assign({}, field, { id: 'message', placeholder: t('GEO_PUSH_MESSAGE_PLACEHOLDER'), onChange: (e) => {
                            field.onChange(e);
                            onChangeMessage === null || onChangeMessage === void 0 ? void 0 : onChangeMessage(e.target.value);
                        }, hasError: !!errors.message }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Отображать'), labelFor: 'show', controlSize: '200' }, { children: _jsx(Controller, { name: 'show', defaultValue: 'always', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'show', value: SHOW_FIELD_LIST.find((option) => option.value === field.value) || null, onChange: (field) => {
                            setValue('show', field === null || field === void 0 ? void 0 : field.value);
                        }, options: SHOW_FIELD_LIST, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasError: !!errors.show, asSelect: true, hasResetButton: false }))) }) })), notificationPeriod && (_jsx(FormFieldRow, Object.assign({ label: t('с'), labelFor: 'showFrom', helpClassName: styles.helpText, help: ((_e = errors.showFrom) === null || _e === void 0 ? void 0 : _e.message) || ((_f = errors.showBy) === null || _f === void 0 ? void 0 : _f.message) || tzHelp, hasError: !!(((_g = errors.showFrom) === null || _g === void 0 ? void 0 : _g.message) || ((_h = errors.showBy) === null || _h === void 0 ? void 0 : _h.message)) }, { children: _jsxs("div", Object.assign({ className: styles.dates }, { children: [_jsx("div", Object.assign({ className: styles.date }, { children: _jsx(Controller, { name: 'showFrom', control: control, rules: {
                                    required: {
                                        value: notificationPeriod,
                                        message: t('FIELDS_REQUIRED_ERROR'),
                                    },
                                }, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(DateInput, { id: 'showFrom', placeholderText: t('дд.мм.гггг'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                            setValue('showFrom', date ? moment(date).format() : '', {
                                                shouldValidate: true,
                                            });
                                        }, selectsStart: true, startDate: field.value ? moment(field.value).toDate() : null, endDate: showBy ? moment(showBy).toDate() : null, maxDate: showBy ? moment(showBy).toDate() : null, startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR, portalId: 'form-geopush-dates-portal', hasError: !!((_a = errors.showFrom) === null || _a === void 0 ? void 0 : _a.message) }));
                                } }) })), _jsx("label", Object.assign({ className: styles.datesDivider, htmlFor: 'showBy' }, { children: t('по') })), _jsx("div", Object.assign({ className: styles.date }, { children: _jsx(Controller, { name: 'showBy', control: control, rules: {
                                    required: {
                                        value: notificationPeriod,
                                        message: t('FIELDS_REQUIRED_ERROR'),
                                    },
                                }, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(DateInput, { id: 'showBy', selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                            setValue('showBy', date ? moment(date).format() : '', {
                                                shouldValidate: true,
                                            });
                                        }, selectsEnd: true, startDate: showFrom ? moment(showFrom).toDate() : null, endDate: field.value ? moment(field.value).toDate() : null, minDate: showFrom ? moment(showFrom).toDate() : null, startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR, portalId: 'form-geopush-dates-portal', hasError: !!((_a = errors.showBy) === null || _a === void 0 ? void 0 : _a.message) }));
                                } }) }))] })) }))), _jsx(FormFieldRow, Object.assign({ label: t('на дистанции до'), labelFor: 'distance', controlSize: '200', helpClassName: styles.helpText, help: ((_j = errors.distance) === null || _j === void 0 ? void 0 : _j.message) || t('MAX_ERROR_MESSAGE', { amount: 100 }), hasError: !!errors.distance }, { children: _jsx(Controller, { name: 'distance', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        min: {
                            value: 1,
                            message: t('MIN_ERROR_MESSAGE', { amount: 1 }),
                        },
                        max: {
                            value: 100,
                            message: t('MAX_ERROR_MESSAGE', { amount: 100 }),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'distance', rightText: t('метров'), type: 'number', inputMode: 'numeric', step: 'any', preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.distance, onBlur: (e) => {
                            if (!Number(e.target.value))
                                setValue('distance', '1', {
                                    shouldValidate: isSubmitted,
                                });
                            field.onBlur();
                        } }))) }) }))] })));
};
