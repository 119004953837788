import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
export const ChannelReceivedFormTitle = () => {
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    let header = '';
    switch (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.type) {
        case 'delivered_channel': {
            header = 'Доставлено';
            break;
        }
        case 'purchased_channel': {
            header = 'Сделал покупку';
            break;
        }
    }
    return _jsxs(_Fragment, { children: [t(header), "?"] });
};
