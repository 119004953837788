import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const ChannelFormName = () => {
    const { t } = useTranslation();
    const { selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const handleUpdateName = (e) => {
        if ((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.name) !== e.target.value)
            selectedNode.update({
                name: e.target.value,
            });
    };
    return (_jsxs("div", { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'channel-form-name' }, { children: t('Название рассылки') })), _jsx(Input, { id: 'channel-form-name', placeholder: t('введите название рассылки'), defaultValue: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.name) || '', onBlur: handleUpdateName, hasError: !!(errors === null || errors === void 0 ? void 0 : errors.name), disabled: !isEditMode, autoFocus: true }), !!(errors === null || errors === void 0 ? void 0 : errors.name) && _jsx("div", Object.assign({ className: styles.error }, { children: errors.name }))] }));
};
