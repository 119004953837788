import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { SegmentBadge } from '~/src/components/SegmentBadge/SegmentBadge';
import { User } from '~/src/icons/User';
import styles from '../styles.module.scss';
export const loyaltyTitleRenderer = ({ event, translate, entityHandlers, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { handleEntityLink } = entityHandlers;
    if (event.eventType === 'levelup')
        return {
            Icon: _jsx(User, { size: 20 }),
            title: (_jsxs(_Fragment, { children: [translate('LEVEL_UP'), "\u00A0", (_a = event.params) === null || _a === void 0 ? void 0 : _a.oldLevel, "\u00A0", translate('на'), "\u00A0", (_b = event.params) === null || _b === void 0 ? void 0 : _b.level] })),
        };
    if (event.eventType === 'segment_leave') {
        const link = handleEntityLink('segment', (_d = (_c = event.params) === null || _c === void 0 ? void 0 : _c.segment) === null || _d === void 0 ? void 0 : _d.id);
        const state = {
            segmentId: (_f = (_e = event.params) === null || _e === void 0 ? void 0 : _e.segment) === null || _f === void 0 ? void 0 : _f.id,
            conditions: (_h = (_g = event.params) === null || _g === void 0 ? void 0 : _g.segment) === null || _h === void 0 ? void 0 : _h.conditions,
        };
        return {
            Icon: _jsx(User, { size: 20 }),
            title: (_jsxs(_Fragment, { children: [translate('Вышел из'), link ? (_jsx(Link, Object.assign({ to: link, state: state }, { children: _jsx(SegmentBadge, Object.assign({ className: styles.segmentBadge, segment: { color: event.params.segment.color } }, { children: event.params.segment.name })) }))) : (_jsx(SegmentBadge, Object.assign({ className: styles.segmentBadge, segment: { color: event.params.segment.color } }, { children: event.params.segment.name })))] })),
        };
    }
    if (event.eventType === 'segment_join') {
        const link = handleEntityLink('segment', (_k = (_j = event.params) === null || _j === void 0 ? void 0 : _j.segment) === null || _k === void 0 ? void 0 : _k.id);
        const state = {
            segmentId: (_m = (_l = event.params) === null || _l === void 0 ? void 0 : _l.segment) === null || _m === void 0 ? void 0 : _m.id,
            conditions: (_p = (_o = event.params) === null || _o === void 0 ? void 0 : _o.segment) === null || _p === void 0 ? void 0 : _p.conditions,
        };
        return {
            Icon: _jsx(User, { size: 20 }),
            title: (_jsxs(_Fragment, { children: [translate('Попал в'), link ? (_jsx(Link, Object.assign({ to: link, state: state }, { children: _jsx(SegmentBadge, Object.assign({ className: styles.segmentBadge, segment: { color: event.params.segment.color } }, { children: event.params.segment.name })) }))) : (_jsx(SegmentBadge, Object.assign({ className: styles.segmentBadge, segment: { color: event.params.segment.color } }, { children: event.params.segment.name })))] })),
        };
    }
    return {};
};
