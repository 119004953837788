var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import { Input } from '@/components/Input/Input';
import { TextAlignLeft } from '@/icons/TextAlignLeft';
import { LogoLarge } from '@/icons/wallet-card/apple/LogoLarge';
import { scaleImage } from '@/utils/images/scaleImage';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { ImageHelp } from '../../helpers/ImageHelp';
import { SmartInput } from '../../helpers/SmartInput';
import styles from '../../styles.module.scss';
export const HeadApple = () => {
    const { t } = useTranslation();
    const { setActiveCardType, setAppleCardIsFlipped, isElementClick, setIsElementClick, markReadyToSave, uploadImage, appleHead, setAppleHead, } = useWalletCardForm();
    const handleFocus = () => {
        setActiveCardType('apple');
        setAppleCardIsFlipped(false);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (head) => {
        setAppleHead((appleHead) => (Object.assign(Object.assign({}, appleHead), head)));
    };
    const handleApplyLogo = (logo) => __awaiter(void 0, void 0, void 0, function* () {
        handleChange({ logo, logoLoading: !!logo, logoRejectReason: '' });
        if (!logo) {
            markReadyToSave();
            return;
        }
        try {
            const logoSmall = yield scaleImage(logo);
            const [logoUploadResponse, logoSmallUploadResponse] = yield Promise.all([
                uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(logo),
                uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(logoSmall),
            ]);
            handleChange({ logoUploadResponse, logoSmallUploadResponse });
        }
        catch (e) {
            // do nothing
        }
        finally {
            handleChange({ logoLoading: false });
            markReadyToSave();
        }
    });
    return (_jsxs("div", Object.assign({ className: classNames(styles.headWrapper, styles.apple) }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.control, styles.logo) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'apple-head-logo' }, { children: t('логотип') })), _jsxs("div", Object.assign({ className: styles.uploadWrapper }, { children: [_jsx(ImageCropInput, { id: 'apple-head-logo', value: appleHead.logo, onChange: handleApplyLogo, onReject: (reason) => {
                                    handleChange({ logo: null, logoRejectReason: reason || '' });
                                }, previewWidth: 130, previewHeight: 48, maintainPreviewAspectRatio: true, placeholder: 'image', constraints: {
                                    mimeType: 'image/png',
                                    extensions: ['.png'],
                                    maxSize: 10000000,
                                    dimensions: { width: [150, 450], height: 150 },
                                }, outputMimeType: 'png', hasError: !!appleHead.logoRejectReason, onFocus: handleFocus, cropModalIcon: _jsx(LogoLarge, {}), cropModalTitle: t('Логотип в шапку для Apple') }), _jsx(ImageHelp, { dimensions: '150-450\u00D7150', help: t('от') + ' 450×150', rejectReason: appleHead.logoRejectReason, isLoading: appleHead.logoLoading, isFloating: true })] }))] })), _jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'apple-head-title' }, { children: [_jsx(TextAlignLeft, {}), t('заголовок карты')] })), _jsx(Input, { id: 'apple-head-title', value: appleHead.title, onChange: (e) => {
                            handleChange({ title: e.target.value });
                        }, onFocus: handleFocus, onBlur: markReadyToSave, autoFocus: !isElementClick })] })), _jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'apple-field-1' }, { children: [_jsx(TextAlignLeft, {}), t('поле') + ' 1'] })), _jsx(Input, { id: 'apple-field-1', value: appleHead.field_1_Title, onChange: (e) => {
                            handleChange({ field_1_Title: e.target.value });
                        }, onFocus: handleFocus, onBlur: markReadyToSave }), _jsx(SmartInput, { value: appleHead.field_1_Value, vars: appleHead.field_1_Vars, onChange: (value, vars) => {
                            handleChange({
                                field_1_Value: value,
                                field_1_Vars: vars,
                            });
                        }, onFocus: handleFocus, onBlur: markReadyToSave })] })), _jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'apple-field-2' }, { children: [_jsx(TextAlignLeft, {}), t('поле') + ' 2'] })), _jsx(Input, { id: 'apple-field-2', value: appleHead.field_2_Title, onChange: (e) => {
                            handleChange({ field_2_Title: e.target.value });
                        }, onFocus: handleFocus, onBlur: markReadyToSave }), _jsx(SmartInput, { value: appleHead.field_2_Value, vars: appleHead.field_2_Vars, onChange: (value, vars) => {
                            handleChange({
                                field_2_Value: value,
                                field_2_Vars: vars,
                            });
                        }, onFocus: handleFocus, onBlur: markReadyToSave })] }))] })));
};
