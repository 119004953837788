import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { ChannelEmailAlt } from '~/src/icons/ChannelEmailAlt';
import { ChannelPushAlt } from '~/src/icons/ChannelPushAlt';
import { ChannelSMSAlt } from '~/src/icons/ChannelSMSAlt';
import { ChannelViberAlt } from '~/src/icons/ChannelViberAlt';
import { CheckCircle } from '~/src/icons/CheckCircle';
import { EmailNotOpened } from '~/src/icons/flow/EmailNotOpened';
import { Cancelled } from '~/src/icons/status/Cancelled';
import { Done } from '~/src/icons/status/Done';
import { ErrorTriangle } from '~/src/icons/status/ErrorTriangle';
import { Planned } from '~/src/icons/status/Planned';
import { MessageBubble } from '~/src/icons/wallet-card/notify/MessageBubble';
import { MAILING_DELIVERED, MAILING_MARKED_SPAM, MAILING_NOT_DELIVERED, MAILING_OPENED, MAILING_STATUS_DONE, MAILING_STATUS_ERROR, MAILING_STATUS_PLANNED, MAILING_UNSUBSCRIBED, TITLES, } from './constants';
export const mailingEventDataByStatus = (status, finishedAt) => {
    if (status === MAILING_STATUS_PLANNED)
        return { icon: _jsx(Planned, {}), title: 'Запланирована рассылка' };
    if (status === MAILING_STATUS_DONE && finishedAt)
        return { icon: _jsx(Done, {}), title: 'Рассылка' };
    if (status === MAILING_STATUS_ERROR)
        return {
            icon: _jsx(ErrorTriangle, {}),
            title: 'Ошибка отправки',
            color: 'red',
        };
    return { icon: _jsx(Done, {}), title: 'Рассылка' };
};
export const sendingDataByStatus = ({ status, type, deliveredAt, bouncedAt, finishedAt, openedAt, markedSpamAt, unsubscribedAt, }) => {
    if (markedSpamAt)
        return {
            color: 'red',
            title: TITLES[type][MAILING_MARKED_SPAM],
            date: moment(markedSpamAt).format('DD.MM.YY, HH:mm'),
        };
    if (unsubscribedAt)
        return {
            color: 'red',
            title: TITLES[type][MAILING_UNSUBSCRIBED],
            date: moment(unsubscribedAt).format('DD.MM.YY, HH:mm'),
        };
    if (openedAt)
        return {
            icon: type === 'sending_email' ? (_jsx(EmailNotOpened, { size: 20 })) : (_jsx(MessageBubble, {})),
            title: TITLES[type][MAILING_OPENED],
            date: moment(openedAt).format('DD.MM.YY, HH:mm'),
        };
    if (bouncedAt)
        return {
            icon: _jsx(Cancelled, {}),
            title: TITLES[type][MAILING_NOT_DELIVERED],
            date: moment(bouncedAt).format('DD.MM.YY, HH:mm'),
            color: 'red',
        };
    if (deliveredAt)
        return {
            icon: _jsx(CheckCircle, {}),
            title: TITLES[type][MAILING_DELIVERED],
            date: moment(deliveredAt).format('DD.MM.YY, HH:mm'),
        };
    if (status === MAILING_STATUS_ERROR)
        return {
            icon: _jsx(ErrorTriangle, {}),
            title: TITLES[type][MAILING_STATUS_ERROR],
            color: 'red',
            date: moment(finishedAt).format('DD.MM.YY, HH:mm'),
        };
    if (status === MAILING_STATUS_DONE)
        return {
            title: TITLES[type][MAILING_STATUS_DONE],
        };
    return {
        icon: _jsx(Planned, {}),
        title: TITLES[type][MAILING_STATUS_PLANNED],
    };
};
export const getStaticByChannel = (channel) => {
    const channelStatic = {
        sending_push: {
            ColumnIcon: ChannelPushAlt,
            bodyField: 'pushBody',
            type: 'push',
        },
        sending_webpush: {
            ColumnIcon: ChannelPushAlt,
            bodyField: 'webpushTitle',
            type: 'push',
        },
        sending_sms: {
            ColumnIcon: ChannelSMSAlt,
            bodyField: 'smsBody',
            type: 'sms',
        },
        sending_viber: {
            ColumnIcon: ChannelViberAlt,
            bodyField: 'viberBody',
            type: 'viber',
        },
        sending_email: {
            ColumnIcon: ChannelEmailAlt,
            bodyField: 'emailBody',
            type: 'email',
        },
    };
    return channelStatic[channel] || null;
};
