import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { convertToInputValue } from '@/components/MailingRewardsPills/utils';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
import styles from './styles.module.scss';
export const EmailFormSubject = () => {
    var _a;
    const { t } = useTranslation();
    const { brand, currency } = useCurrentUser();
    const { triggerType, selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const [emailSubject, setEmailSubject] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.emailSubject) || '');
    const handleUpdateEmailSubject = () => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.emailSubject) !== emailSubject)
            selectedNode.update({
                email: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email), { emailSubject }),
            });
    };
    const editorContext = {
        channel: 'email',
        triggerType: triggerType,
        rewards: convertToInputValue((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards) || []),
        hasWalletFeature: brandHasWalletFeature(brand === null || brand === void 0 ? void 0 : brand.brand),
    };
    return (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(formStyles.label, formStyles.movedUp), htmlFor: 'channel-form-email-subject' }, { children: t('Тема письма') })), _jsx(MessageEditor, { id: 'channel-form-email-subject', className: styles.emailSubjectEditor, initialValue: emailSubject, onChange: setEmailSubject, onBlur: handleUpdateEmailSubject, placeholder: t('введите тему письма'), context: editorContext, currency: currency, hasError: !!(errors === null || errors === void 0 ? void 0 : errors.emailSubject), isDisabled: !isEditMode }), !!(errors === null || errors === void 0 ? void 0 : errors.emailSubject) && (_jsx("div", Object.assign({ className: formStyles.error }, { children: errors.emailSubject })))] }));
};
