import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { Input } from '@/components/Input/Input';
import { Popup } from '@/components/Popup/Popup';
import { Chevron } from '@/icons/Chevron';
import { Goal } from '@/icons/Goal';
import { compileFilters } from '@/services/filters/compileFilters';
import { useMailingGoalFilters } from '@/services/filters/data/useMailingGoalFilters';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
const GOAL_TYPES = {
    SUBSCRIPTION: 1,
    PURCHASE: 3,
    WALLET_CARD: 6,
    LINK_CLICK: 7,
    DISCARD: 0,
};
export const ChannelFormGoal = () => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const filtersTree = useMailingGoalFilters();
    const popupTrigger = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => setPopupIsOpen(!popupIsOpen);
    const goalTypeOptions = [
        {
            value: GOAL_TYPES.SUBSCRIPTION,
            label: t('подтверждение подписки (Email)'),
        },
        {
            value: GOAL_TYPES.PURCHASE,
            label: t('совершение покупки'),
        },
        {
            value: GOAL_TYPES.WALLET_CARD,
            label: t('установку карты Wallet'),
            buttonTitle: t('установка карты Wallet'),
        },
        {
            value: GOAL_TYPES.LINK_CLICK,
            label: t('переход по ссылке (Email)'),
        },
        {
            value: GOAL_TYPES.DISCARD,
            label: t('не считать'),
        },
    ];
    const selectedGoalType = goalTypeOptions.find((item) => { var _a; return item.value === ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _a === void 0 ? void 0 : _a.goalType); });
    const handleUpdateGoalType = (value) => {
        if (value)
            selectedNode.update({
                goal: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal), { goalType: value.value }),
            });
    };
    const handleUpdateGoalDaysTo = (e) => {
        var _a;
        if (Number(e.target.value) < 1)
            e.target.value = '1';
        if (Number(e.target.value) > 999)
            e.target.value = '999';
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _a === void 0 ? void 0 : _a.goalDaysTo) !== e.target.value)
            selectedNode.update({
                goal: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal), { goalDaysTo: e.target.value }),
            });
    };
    const handleUpdateGoalConditions = (configuration) => {
        selectedNode.update({
            goal: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal), { goalConditions: {
                    conditions: compileFilters(configuration),
                    configuration,
                } }),
        });
    };
    const goalTypeSelect = (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(styles.label, styles.light, styles.movedUp), htmlFor: 'channel-form-goal-type' }, { children: t('Считать достижением цели') })), _jsx("div", Object.assign({ className: styles.goalTypeInput }, { children: _jsx(Autocomplete, { id: 'channel-form-goal-type', value: goalTypeOptions.find((item) => { var _a; return item.value === ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _a === void 0 ? void 0 : _a.goalType); }) || null, onChange: handleUpdateGoalType, options: goalTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }) }))] }));
    const goalDaysToInput = (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(styles.label, styles.light, styles.movedUp), htmlFor: 'channel-form-goal-days-to' }, { children: t('в течение') })), _jsxs("div", Object.assign({ className: styles.goalDaysToInputWrapper }, { children: [_jsx("div", Object.assign({ className: styles.goalDaysToInput }, { children: _jsx(Input, { id: 'channel-form-goal-days-to', defaultValue: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _a === void 0 ? void 0 : _a.goalDaysTo) || '', onBlur: handleUpdateGoalDaysTo, type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['-', '+', ',', '.', 'e'], preventNegativeNumbers: true, shouldHideSpinButtons: true, rightText: t('MAILING_GOAL_DAYS_TO_SHORT', {
                                count: Number(((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _b === void 0 ? void 0 : _b.goalDaysTo) || 0),
                            }) }) })), _jsx("div", Object.assign({ className: styles.description }, { children: t('с момента рассылки') }))] }))] }));
    const goalConditionsInput = (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(styles.label, styles.light, styles.movedUp), htmlFor: 'channel-form-goal-conditions' }, { children: t('если') })), _jsx(FiltersInput, { filtersTree: filtersTree, value: ((_d = (_c = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _c === void 0 ? void 0 : _c.goalConditions) === null || _d === void 0 ? void 0 : _d.configuration) || [], onUpdate: handleUpdateGoalConditions, badgeClassName: styles.badge, disablePortal: true })] }));
    return (_jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ ref: popupTrigger, className: styles.goalButton, type: 'button', onClick: togglePopup, disabled: !isEditMode }, { children: [_jsx(Goal, { fill: 'currentColor' }), _jsxs("span", Object.assign({ className: styles.goalButtonContent }, { children: [t('Цель:'), ' ', (selectedGoalType === null || selectedGoalType === void 0 ? void 0 : selectedGoalType.buttonTitle) || (selectedGoalType === null || selectedGoalType === void 0 ? void 0 : selectedGoalType.label), _jsx(Chevron, { className: classNames(styles.chevron, {
                                    [styles.flipped]: popupIsOpen,
                                }), fill: 'currentColor' })] }))] })), _jsx(Popup, Object.assign({ isOpen: popupIsOpen, onClose: togglePopup, anchorEl: popupTrigger.current, placement: 'top', isLargeRounded: true, disablePortal: true }, { children: _jsxs("div", Object.assign({ className: styles.goalForm }, { children: [goalTypeSelect, ((_e = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _e === void 0 ? void 0 : _e.goalType) !== GOAL_TYPES.DISCARD
                            ? goalDaysToInput
                            : null, ((_f = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.goal) === null || _f === void 0 ? void 0 : _f.goalType) === GOAL_TYPES.PURCHASE
                            ? goalConditionsInput
                            : null] })) }))] }));
};
