import { useLocation } from 'react-router-dom';
import { useGetFlowCandidatesListQuery } from '@/api/query/flows';
import { composeMailings } from '@/components/flow/InteractiveFlow/composeMailings';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useComposedConfig = (enabled, providedMailings) => {
    var _a;
    const location = useLocation();
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const mailingIds = (_a = location.state) === null || _a === void 0 ? void 0 : _a.mailingIds;
    const { data: allMailings = [], isLoading: isLoadingComposedConfig = false } = useGetFlowCandidatesListQuery({ periodStart, periodEnd }, { skip: !enabled || !!(providedMailings === null || providedMailings === void 0 ? void 0 : providedMailings.length) });
    const mailings = providedMailings || collectMailingsForDraft(allMailings, mailingIds || []);
    const { nodes, edges } = composeMailings(mailings);
    if (!enabled)
        return {
            composed: null,
            isLoadingComposedConfig: false,
        };
    return {
        composed: { nodes, edges },
        isLoadingComposedConfig: isLoadingComposedConfig,
    };
};
const collectMailingsForDraft = (allMailings, mailingIds) => {
    const result = [];
    for (let i = 0; i < allMailings.length; i += 1) {
        const mailing = allMailings[i];
        if (mailingIds.includes(mailing.id)) {
            const followedMailings = allMailings.filter((m) => m.followedMailingBrandId === mailing.id);
            result.push(mailing, ...followedMailings);
        }
    }
    return result;
};
