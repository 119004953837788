import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { ClickHand } from '~/src/icons/ClickHand';
import { Goal } from '~/src/icons/Goal';
import { Send } from '~/src/icons/Send';
import { MAILING_STATUS_DONE } from '~/src/types/mailingStatus';
import styles from '../../styles.module.scss';
import { MailingContent } from './MailingContent';
import { getStaticByChannel, mailingEventDataByStatus, sendingDataByStatus, } from './utils';
export const mailingTitleRenderer = ({ event, translate, entityHandlers, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { handleEntityLink } = entityHandlers;
    // Клик по ссылке в письме
    if (event.eventType === 'sending_click') {
        const link = handleEntityLink('mailing', (_a = event.params) === null || _a === void 0 ? void 0 : _a.mailingBrandId, (_b = event.params) === null || _b === void 0 ? void 0 : _b.mailingBrandType);
        return {
            Icon: _jsx(ClickHand, {}),
            title: (_jsxs(_Fragment, { children: [translate('Кликнул по'), "\u00A0", event.params.linkHash ? (_jsx(Link, Object.assign({ className: styles.underline, to: event.params.linkHash, target: '_blank' }, { children: translate('ссылке') }))) : (_jsx("span", { children: translate('ссылке') })), "\u00A0", translate('в email'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_c = event.params) === null || _c === void 0 ? void 0 : _c.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Событие рассылки
    if (event.eventType === 'mailing_event') {
        const link = handleEntityLink('mailing', (_d = event.params) === null || _d === void 0 ? void 0 : _d.mailingBrandId, (_e = event.params) === null || _e === void 0 ? void 0 : _e.mailingBrandType);
        const { icon, title, color } = mailingEventDataByStatus(event.params.status, event.params.finishedAt);
        return {
            color,
            content: (event.params.scheduledAt || event.params.finishedAt) && (_jsx(MailingContent, { scheduledAt: event.params.scheduledAt, finishedAt: event.params.finishedAt, translate: translate })),
            Icon: _jsx(Send, { size: 20 }),
            title: (_jsxs("span", Object.assign({ className: styles.mailingTitle }, { children: [icon, translate(title), link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_f = event.params) === null || _f === void 0 ? void 0 : _f.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` }))), event.params.status === MAILING_STATUS_DONE &&
                        translate('завершена')] }))),
        };
    }
    // Готовится к отправке
    if (event.eventType === 'mailing_sending') {
        const link = handleEntityLink('mailing', (_g = event.params) === null || _g === void 0 ? void 0 : _g.mailingBrandId, (_h = event.params) === null || _h === void 0 ? void 0 : _h.mailingBrandType);
        return {
            content: event.params.goalReachedAt && (_jsx(MailingContent, { translate: translate, goalReachedAt: event.params.goalReachedAt })),
            Icon: event.params.succeeded ? _jsx(Goal, { size: 20 }) : _jsx(Send, { size: 20 }),
            title: (_jsxs(_Fragment, { children: [event.params.succeeded
                        ? translate('Достиг цели в')
                        : translate('Готовится к отправке'), "\u00A0", link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_j = event.params) === null || _j === void 0 ? void 0 : _j.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` })))] })),
        };
    }
    // Рассылки по каналам: email, sms, push, webpush и viber
    const link = handleEntityLink('mailing', (_k = event.params) === null || _k === void 0 ? void 0 : _k.mailingBrandId, (_l = event.params) === null || _l === void 0 ? void 0 : _l.mailingBrandType);
    const { ColumnIcon, bodyField } = getStaticByChannel(event.eventType);
    const { icon, title, date, color } = sendingDataByStatus({
        status: event.params.status,
        type: event.eventType,
        deliveredAt: event.params.deliveredAt,
        bouncedAt: event.params.bouncedAt,
        finishedAt: event.params.finishedAt,
        openedAt: event.params.openedAt,
        markedSpamAt: event.params.markedSpamAt,
        unsubscribedAt: event.params.unsubscribedAt,
    });
    const body = event.params[bodyField];
    return {
        Icon: _jsx(ColumnIcon, { fill: color === 'red' ? '#da5845' : undefined }),
        color,
        content: (body ||
            event.params.bouncedAt ||
            event.params.markedSpamAt ||
            event.params.unsubscribedAt ||
            event.params.deliveredAt ||
            event.params.openedAt ||
            event.params.finishedAt) && (_jsx(MailingContent, { openedAt: event.params.openedAt, deliveredAt: event.params.deliveredAt, finishedAt: event.params.finishedAt, bouncedAt: event.params.bouncedAt, markedSpamAt: event.params.markedSpamAt, unsubscribedAt: event.params.unsubscribedAt, body: body })),
        title: (_jsxs("span", Object.assign({ className: styles.mailingTitle }, { children: [icon, translate(title), link ? (_jsx(Link, Object.assign({ className: styles.underline, to: link, target: '_blank' }, { children: event.params.mailingBrandName }))) : (((_m = event.params) === null || _m === void 0 ? void 0 : _m.mailingBrandName) && (_jsx("span", { children: `"${event.params.mailingBrandName}"` }))), _jsx("span", Object.assign({ className: styles.sendingDate }, { children: date }))] }))),
    };
    return {};
};
