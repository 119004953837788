var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from './';
export const mailingsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getManualMailingList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/manual/list',
                body,
            }),
            providesTags: ['ManualMailingList'],
        }),
        getAutoMailingList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/list',
                body,
            }),
            providesTags: ['AutoMailingList'],
        }),
        getMailing: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/mailing/get/${id}`,
            }),
            providesTags: ['Mailing'],
        }),
        deleteMailing: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/mailing/delete/${id}`,
            }),
            invalidatesTags: [
                'ManualMailingList',
                'AutoMailingList',
                'FlowCandidatesList',
                'Flow',
                'EmailTemplates',
            ],
        }),
        getMailingStats: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/stats',
                body,
            }),
            providesTags: ['MailingStats'],
        }),
        getMailingStatsManual: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/stats',
                body,
            }),
        }),
        switchMailingChannel: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/switch-channel',
                body,
            }),
            invalidatesTags: ['ManualMailingList', 'AutoMailingList'],
        }),
        createMailing: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/add',
                body,
            }),
            invalidatesTags: [
                'ManualMailingList',
                'AutoMailingList',
                'FlowCandidatesList',
                'Flow',
                'EmailTemplates',
            ],
        }),
        updateMailing: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/set',
                body,
            }),
            invalidatesTags: [
                'ManualMailingList',
                'AutoMailingList',
                'Mailing',
                'FlowCandidatesList',
                'Flow',
            ],
        }),
        calculateMailing: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/calculate',
                body,
            }),
        }),
        sendTestMessage: build.mutation({
            query: (_a) => {
                var { type } = _a, body = __rest(_a, ["type"]);
                return ({
                    method: 'POST',
                    url: `/mailing/send-test-${type}`,
                    body,
                });
            },
        }),
        createEmailScreenshot: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/create-email-screenshot',
                body,
            }),
        }),
        setMailingActive: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/set-active',
                body,
            }),
            invalidatesTags: ['ManualMailingList', 'AutoMailingList', 'Mailing'],
        }),
        getMailingExportStats: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/mailing/export-stats',
                body,
            }),
        }),
    }),
});
export const { useGetManualMailingListQuery, useGetAutoMailingListQuery, useGetMailingQuery, useDeleteMailingMutation, useGetMailingStatsQuery, useLazyGetMailingStatsQuery, useGetMailingStatsManualMutation, useSwitchMailingChannelMutation, useCreateMailingMutation, useUpdateMailingMutation, useCalculateMailingMutation, useSendTestMessageMutation, useCreateEmailScreenshotMutation, useSetMailingActiveMutation, useGetMailingExportStatsMutation, } = mailingsApi;
