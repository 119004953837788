import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LexicalComposer, } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import classNames from 'classnames';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFocusPlugin } from '../plugins/AutoFocusPlugin/AutoFocusPlugin';
import { ClipboardPlugin } from '../plugins/ClipboardPlugin/ClipboardPlugin';
import { EditorToolbar } from '../plugins/EditorToolbar/EditorToolbar';
import { EmojiPlugin } from '../plugins/EmojiPlugin/EmojiPlugin';
import { FocusPlugin } from '../plugins/FocusPlugin/FocusPlugin';
import { LinkNode } from '../plugins/LinkPlugin/LinkNode';
import { ParagraphPlugin, paragraphPluginStyles, } from '../plugins/ParagraphPlugin/ParagraphPlugin';
import { SpaceNode, SpacePlugin } from '../plugins/SpacePlugin/SpacePlugin';
import { VariableNode, VariablePlugin, } from '../plugins/VariablePlugin/VariablePlugin';
import { VariableTypeaheadPlugin } from '../plugins/VariableTypeaheadPlugin/VariableTypeaheadPlugin';
import { escapedTextToJSON } from '../utils/escapedTextToJSON';
import { jsonToEscapedText } from '../utils/jsonToEscapedText';
import { jsonToText } from '../utils/jsonToText';
import { textToJSON } from '../utils/textToJSON';
import styles from './styles.module.scss';
export const Editor = memo(({ id, className, initialValue, initialVars, onChange, groups = [], variables = [], allVariables = variables, placeholder, varsPopupType, varsType = 'simple', roundedToolbar, hasError = false, hasAutoFocus = false, asTextInput = false, hasLinkSupport = false, hasEmojiSupport = true, hasSmallVarsPopupIcon = false, currency, isDisabled = false, onFocus, onBlur, }) => {
    const { i18n } = useTranslation();
    const [typeaheadResults, setTypeaheadResults] = useState(0);
    const initialEditorState = JSON.stringify(initialVars
        ? escapedTextToJSON(initialValue, variables, initialVars)
        : textToJSON(initialValue, allVariables));
    const initialEditorConfig = {
        namespace: 'Editor',
        editorState: initialEditorState,
        nodes: [SpaceNode, VariableNode, LinkNode],
        theme: {
            paragraph: paragraphPluginStyles.paragraph,
        },
        onError: (error) => {
            throw error;
        },
        editable: !isDisabled,
    };
    const handleChange = (editorState) => {
        const json = editorState.toJSON();
        const { text, variables } = jsonToText(json);
        const escapedText = varsType === 'with-parameters' ? jsonToEscapedText(json).text : '';
        const vars = variables.map((v) => ({
            var: v.payload.key || '',
            params: v.payload.params || {},
        }));
        onChange(text, vars, escapedText);
    };
    return (_jsxs(LexicalComposer, Object.assign({ initialConfig: initialEditorConfig }, { children: [_jsx("div", Object.assign({ className: styles.placeholderContainer }, { children: _jsx(PlainTextPlugin, { contentEditable: _jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
                            [styles.error]: hasError,
                            [styles.disabled]: isDisabled,
                        }) }, { children: [_jsx(ContentEditable, { id: id, className: classNames(styles.editable, {
                                    [styles.textInput]: asTextInput,
                                }, className, 'lexical-editor') }), !isDisabled && (_jsx(EditorToolbar, { varsPopupType: varsPopupType, hasSmallVarsPopupIcon: hasSmallVarsPopupIcon, variables: groups, hasLinkSupport: hasLinkSupport, hasEmojiSupport: hasEmojiSupport, isRounded: roundedToolbar, currency: currency }))] })), placeholder: _jsx("div", Object.assign({ className: styles.placeholder }, { children: placeholder })), ErrorBoundary: LexicalErrorBoundary }) })), _jsx(OnChangePlugin, { onChange: handleChange }), _jsx(HistoryPlugin, {}), _jsx(AutoFocusPlugin, { isEnabled: hasAutoFocus }), _jsx(FocusPlugin, { onFocus: onFocus, onBlur: onBlur }), _jsx(ClipboardPlugin, { variables: allVariables }), _jsx(EmojiPlugin, {}), _jsx(ParagraphPlugin, { isEnabled: !typeaheadResults }), _jsx(SpacePlugin, {}), _jsx(VariablePlugin, { variables: allVariables, method: varsType === 'simple' ? 'transform' : 'command' }), _jsx(VariableTypeaheadPlugin, { variables: variables, onChangeResultsCount: setTypeaheadResults, isEnabled: varsType === 'simple' })] }), i18n.language));
});
