import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Edit } from '~/src/icons/Edit';
import { User } from '~/src/icons/User';
import styles from '../styles.module.scss';
const ClientUpdateContent = ({ list = [] }) => (_jsx("ul", Object.assign({ className: styles.expandedMailingContent }, { children: list === null || list === void 0 ? void 0 : list.map(({ field, new: newValue, old }) => {
        return (_jsxs("li", Object.assign({ className: styles.accordionContentItem }, { children: [_jsx("span", { children: field }), _jsx("div", { className: styles.expandedContentItemLine }), _jsxs("div", Object.assign({ className: styles.values }, { children: [_jsx("span", Object.assign({ className: styles.accordionContentItemNew }, { children: newValue })), old && (_jsx("span", Object.assign({ className: styles.accordionContentItemOld }, { children: old })))] }))] }), field));
    }) })));
export const clientUpdateTitleRenderer = (event, translate) => {
    var _a, _b, _c, _d, _e, _f;
    // Создание
    if (event.eventType === '0')
        return {
            title: event.params.operatorName
                ? translate('ADDED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : translate('Добавлен сотрудником'),
            Icon: _jsx(User, { size: 20 }),
            content: ((_a = event === null || event === void 0 ? void 0 : event.params) === null || _a === void 0 ? void 0 : _a.changes) && (_jsx("div", { children: _jsx(ClientUpdateContent, { list: (_b = event === null || event === void 0 ? void 0 : event.params) === null || _b === void 0 ? void 0 : _b.changes }) })),
        };
    // Изменены данные
    if (event.eventType === '1')
        return {
            title: event.params.operatorName
                ? translate('UPDATED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : translate('Изменены данные сотрудником'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            content: ((_c = event === null || event === void 0 ? void 0 : event.params) === null || _c === void 0 ? void 0 : _c.changes) && (_jsx("div", { children: _jsx(ClientUpdateContent, { list: (_d = event === null || event === void 0 ? void 0 : event.params) === null || _d === void 0 ? void 0 : _d.changes }) })),
        };
    // Изменены данные (клиентом)
    if (event.eventType === '2')
        return {
            title: translate('API_DATA_CHANGED'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            content: ((_e = event === null || event === void 0 ? void 0 : event.params) === null || _e === void 0 ? void 0 : _e.changes) && (_jsx("div", { children: _jsx(ClientUpdateContent, { list: (_f = event === null || event === void 0 ? void 0 : event.params) === null || _f === void 0 ? void 0 : _f.changes }) })),
        };
    // Удаление
    if (event.eventType === '3')
        return {
            color: 'red',
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            title: event.params.operatorName
                ? translate('DELETED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
        };
    // Заблокирован
    if (event.eventType === '4')
        return {
            color: 'red',
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            title: event.params.operatorName
                ? translate('BLOCKED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
            content: (_jsx("div", { children: _jsx("section", Object.assign({ className: styles.expandedMailingContent }, { children: _jsx("span", Object.assign({ className: styles.accordionContentItem }, { children: _jsxs("div", Object.assign({ className: styles.values }, { children: [_jsxs("span", Object.assign({ className: styles.contentTitleComment }, { children: [translate('Комментарий'), _jsx("div", { className: styles.expandedContentItemLine })] })), _jsx("span", Object.assign({ className: styles.contentValue }, { children: event.params.comment }))] })) })) })) })),
        };
    // Разблокирован
    if (event.eventType === '5')
        return {
            color: 'red',
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
            title: event.params.operatorName
                ? translate('UNBLOCKED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : undefined,
        };
    // Создание (клиентом)
    if (event.eventType === '6')
        return {
            title: translate('Добавлен по API'),
            tooltipTitle: event.params.operatorName
                ? translate('ADDED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : translate('Добавлен сотрудником'),
            Icon: _jsx(User, { size: 20 }),
        };
    // Удаление (клиентом)
    if (event.eventType === '7')
        return {
            title: translate('Удалён по API'),
            tooltipTitle: event.params.operatorName
                ? translate('DELETED_BY_EMPLOYEE', {
                    operatorName: event.params.operatorName,
                })
                : translate('Удален сотрудником'),
            Icon: _jsx(Edit, { fill: '#3A3A3A' }),
        };
    return {};
};
