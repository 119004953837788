import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArbitrationGroup } from '@/components/arbitration/ArbitrationGroup/ArbitrationGroup';
import { useDroppableUnordered } from '@/components/arbitration/ArbitrationGroup/dnd/useDroppableUnordered';
import { ArbitrationGroupPanel } from '@/components/arbitration/ArbitrationGroupPanel/ArbitrationGroupPanel';
import { sortUnordered } from './utils';
export const UnorderedGroup = ({ unordered, draftGuids, isExpanded, onToggle, }) => {
    const { t } = useTranslation();
    const { ref } = useDroppableUnordered();
    const unorderedSorted = useMemo(() => {
        return sortUnordered(unordered);
    }, [unordered]);
    const unorderedGroup = useMemo(() => {
        return {
            guid: 'unordered',
            mode: 'row',
            type: 'sum',
            name: '',
            children: unorderedSorted,
            maxDiscountEnabled: false,
            maxDiscountType: 0,
            maxDiscountAmount: '',
        };
    }, [t, unorderedSorted]);
    return (_jsx(ArbitrationGroupPanel, { children: _jsx(ArbitrationGroup, { ref: ref, group: unorderedGroup, draftGuids: draftGuids, isReadonly: true, isExpanded: isExpanded, onToggle: onToggle }) }));
};
