import { useTranslation } from 'react-i18next';
export const useOptions = (type, clientExtraFields) => {
    const { t } = useTranslation();
    // Клиенты -------------------------------------------------------------------
    const clientFields = [
        { id: 'client-fields', title: t('Клиент'), isGroup: true },
        { id: 'phone_number', title: t('Номер телефона') },
        { id: 'full_name', title: t('ФИО одним полем') },
        { id: 'name', title: t('Имя') },
        { id: 'surname', title: t('Фамилия') },
        { id: 'patronymic', title: t('Отчество') },
        { id: 'birthdate', title: t('Дата рождения') },
        { id: 'email', title: t('Email') },
        { id: 'gender', title: t('Пол') },
    ];
    const programFields = [
        { id: 'program-fields', title: t('Программа лояльности'), isGroup: true },
        { id: 'card', title: t('Номер карты') },
        { id: 'external_id', title: t('Внешний идентификатор') },
        { id: 'created_at', title: t('Дата регистрации') },
        { id: 'purchased_at', title: t('Дата последней покупки') },
        { id: 'purchases', title: t('Кол-во покупок') },
        { id: 'total_spent', title: t('Сумма покупок') },
        { id: 'bonuses', title: t('Кол-во бонусов') },
        { id: 'level', title: t('Уровень клиента') },
        { id: 'issuer_shop_code', title: t('Место вступления (код)') },
    ];
    const mailingFields = [
        { id: 'mailing-fields', title: t('Рассылки'), isGroup: true },
        { id: 'subscribed_all', title: t('Согласие на любые рассылки') },
        { id: 'subscribed_sms', title: t('Согласие на SMS') },
        { id: 'subscribed_email', title: t('Согласие на Email') },
    ];
    const extraFields = clientExtraFields.length
        ? [
            { id: 'extra-fields', title: t('Дополнительные поля'), isGroup: true },
            ...clientExtraFields.map((cef) => ({
                id: 'x_' + cef.name,
                title: cef.title,
            })),
        ]
        : [];
    const clientOptions = [
        ...clientFields,
        ...programFields,
        ...mailingFields,
        ...extraFields,
    ];
    // Дети ----------------------------------------------------------------------
    const parentFields = [
        { id: 'parent-fields', title: t('Клиент'), isGroup: true },
        { id: 'phone_number', title: t('Номер телефона') },
        { id: 'card', title: t('Номер карты') },
        { id: 'external_id', title: t('Внешний идентификатор') },
    ];
    const childrenFields = new Array(10).fill(null).map((_, i) => {
        const n = i + 1;
        return [
            {
                id: `child-fields-${n}`,
                title: t('Ребенок') + ' ' + n,
                isGroup: true,
            },
            {
                id: `child_name_${n}`,
                title: t('Имя ребенка') + ' ' + n,
            },
            {
                id: `child_gender_${n}`,
                title: t('Пол ребенка') + ' ' + n,
            },
            {
                id: `child_birthdate_${n}`,
                title: t('Дата рождения ребенка') + ' ' + n,
            },
        ];
    });
    const childrenOptions = [...parentFields, ...childrenFields.flat()];
    // Покупки -------------------------------------------------------------------
    const buyerFields = [
        { id: 'buyer-fields', title: t('Покупатель'), isGroup: true },
        { id: 'phone_number', title: t('Номер телефона') },
        { id: 'card', title: t('Номер карты') },
        { id: 'external_id', title: t('Внешний идентификатор') },
    ];
    const purchaseFields = [
        { id: 'purchase-fields', title: t('Продажа'), isGroup: true },
        { id: 'txid', title: t('Уникальный ID') },
        { id: 'shop_code', title: t('Код торговой точки') },
        { id: 'executed_at', title: t('Дата продажи') },
        { id: 'cashier_name', title: t('Имя кассира') },
        { id: 'cashier_id', title: t('Код кассира/табельный номер') },
        { id: 'session_id', title: t('Номер смены') },
        { id: 'receipt_id', title: t('Номер чека') },
        { id: 'total_amount', title: t('Полная сумма чека') },
        { id: 'discount', title: t('Полная скидка') },
        { id: 'paid_amount', title: t('Оплаченная сумма') },
        { id: 'bonuses_applied', title: t('Бонусов списано на чек') },
        { id: 'bonuses_collected', title: t('Бонусов начислено за чек') },
    ];
    const receiptFields = [
        { id: 'receipt-fields', title: t('Строка чека'), isGroup: true },
        { id: 'item_external_id', title: t('Идентификатор/код') },
        { id: 'item_sku', title: t('Артикул') },
        { id: 'item_title', title: t('Наименование') },
        {
            id: 'item_category_external_id',
            title: t('Идентификатор/код категории'),
        },
        { id: 'item_category', title: t('Наименование категории') },
        { id: 'item_count', title: t('Количество') },
        { id: 'item_price', title: t('Цена за единицу') },
        { id: 'item_discount', title: t('Скидка') },
        { id: 'item_total_amount', title: t('Оплаченная сумма') },
        { id: 'item_buying_price', title: t('Закупочная цена за единицу') },
        { id: 'item_bonuses_applied', title: t('Бонусов списано на строку') },
        { id: 'item_bonuses_collected', title: t('Бонусов начислено за строку') },
    ];
    const purchaseOptions = [...buyerFields, ...purchaseFields, ...receiptFields];
    // Подарочные карты ----------------------------------------------------------
    const giftCardFields = [
        { id: 'gift-card-fields', title: t('Карта'), isGroup: true },
        { id: 'code', title: t('Уникальный код карты') },
        { id: 'number', title: t('Порядковый номер карты') },
        { id: 'amount', title: t('Оставшаяся сумма на карте') },
        { id: 'initAmount', title: t('Начальная сумма на карте') },
        { id: 'appliedCount', title: t('Кол-во применений') },
    ];
    const activatedFields = [
        { id: 'activated-fields', title: t('Активация'), isGroup: true },
        { id: 'isActivated', title: t('Активирована?') },
        { id: 'activatedAt', title: t('Дата активации') },
    ];
    const expiredFields = [
        { id: 'expired-fields', title: t('Сгорание'), isGroup: true },
        { id: 'isExpired', title: t('Сгорела?') },
        { id: 'expiredAt', title: t('Дата сгорания') },
    ];
    const blockedFields = [
        { id: 'blocked-fields', title: t('Блокировка'), isGroup: true },
        { id: 'isBlocked', title: t('Заблокирована?') },
        { id: 'blockedAt', title: t('Дата блокировки') },
    ];
    const giftCardOptions = [
        ...giftCardFields,
        ...activatedFields,
        ...expiredFields,
        ...blockedFields,
    ];
    // Промокоды -----------------------------------------------------------------
    const promocodeFields = [
        { id: 'promocode-fields', title: t('Промокод'), isGroup: true },
        { id: 'code', title: t('Уникальный код') },
    ];
    const promocodeClientFields = [
        { id: 'promocode-client-fields', title: t('Клиент'), isGroup: true },
        { id: 'phone_number', title: t('Номер телефона') },
        { id: 'card', title: t('Номер карты') },
        { id: 'external_id', title: t('Внешний идентификатор') },
    ];
    const promocodeOptions = [...promocodeFields, ...promocodeClientFields];
    // Бонусы --------------------------------------------------------------------
    const bonusesClientFields = [
        { id: 'bonuses-client-fields', title: t('Клиент'), isGroup: true },
        { id: 'phone_number', title: t('Номер телефона') },
        { id: 'card', title: t('Номер карты') },
        { id: 'external_id', title: t('Внешний идентификатор') },
    ];
    const bonusesFields = [
        { id: 'bonuses-fields', title: t('Бонусы'), isGroup: true },
        { id: 'amount', title: t('Кол-во бонусов') },
        { id: 'expiration_period', title: t('Срок действия в днях') },
        { id: 'comment', title: t('Комментарий') },
        { id: 'notify', title: t('Уведомить клиента?') },
    ];
    const bonusesOptions = [...bonusesClientFields, ...bonusesFields];
    // Result --------------------------------------------------------------------
    const nop = { id: 'nop', title: t('Не загружать') };
    let result;
    switch (type) {
        case 0: {
            result = clientOptions;
            break;
        }
        case 1: {
            result = childrenOptions;
            break;
        }
        case 2: {
            result = purchaseOptions;
            break;
        }
        case 3: {
            result = giftCardOptions;
            break;
        }
        case 4: {
            result = promocodeOptions;
            break;
        }
        case 5: {
            result = bonusesOptions;
            break;
        }
        default: {
            result = [];
        }
    }
    result.unshift(nop);
    return result;
};
