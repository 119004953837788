import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useGetLastBreadcrumb } from '@/app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Button } from '@/components/Button2/Button';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { BASIC_END_YEAR, BASIC_START_YEAR, DateInput, } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { Input } from '@/components/Input/Input';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import { useNotificateForUnsavedForm } from '@/hooks/useNotificateForUnsavedForm';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
const barcodeTypes = [
    {
        id: 'ean13',
        label: 'EAN-13',
    },
    {
        id: 'code128',
        label: 'Code128 / QR-code',
    },
];
export const FormGiftCard = memo(({ onSubmit, error, isSubmitting = false, defaultValues = { type: 'print', barcodeType: 'code128' }, currency, isEdit, onDelete, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors, dirtyFields, isSubmitting: isSubmittingForm }, setValue, watch, } = useForm({
        defaultValues: Object.assign(Object.assign({}, defaultValues), { barcodePrefix: defaultValues.barcodePrefix || '' }),
    });
    const userHasAccessTo = useUserPermissions();
    const userHasGiftCardsManage = userHasAccessTo([
        'GIFTCARDS_MANAGE',
        'GIFTCARDS_INSTANCES_MANAGE',
    ]);
    useNotificateForUnsavedForm(!!Object.keys(dirtyFields).length && !isSubmittingForm);
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const type = watch('type');
    const name = watch('name');
    const isActivationRequired = watch('isActivationRequired');
    const availableFrom = watch('availableFrom');
    const availableTo = watch('availableTo');
    useGetLastBreadcrumb(name);
    const labelWidth = 32.52;
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(FormFieldset, Object.assign({ title: t('Подарочная карта'), titleOffset: labelWidth }, { children: [_jsx(FormFieldRow, Object.assign({ className: styles.typeRow, label: t('Тип карты'), labelFor: 'type', labelWidth: labelWidth, controlSize: '360' }, { children: _jsx(Controller, { name: 'type', control: control, render: ({ field }) => (_jsx(RadioGroup, Object.assign({}, field, { id: 'type', value: field.value, onChange: (value) => setValue('type', value, {
                                    shouldDirty: true,
                                }), items: [
                                    { value: 'print', label: t('Печатная') },
                                    { value: 'digital', label: t('Электронная') },
                                ], align: 'vertical', gap: 'small' }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Название'), labelFor: 'name', labelWidth: labelWidth, controlSize: '300', help: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.name, isRequired: true }, { children: _jsx(Controller, { name: 'name', control: control, rules: {
                                validate: validateTrimValue,
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('введите название карты'), hasError: !!errors.name, autoFocus: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Идентификатор'), labelFor: 'code', labelWidth: labelWidth, controlSize: '300', tooltip: t('Уникальный идентификатор подарочной карты для интеграции с программой лояльности') }, { children: _jsx(Controller, { name: 'code', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'code', placeholder: t('введите идентификатор') }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Сумма номинала'), labelFor: 'amount', labelWidth: labelWidth, controlSize: '150', help: (_b = errors.amount) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.amount, isRequired: true }, { children: _jsx(Controller, { name: 'amount', control: control, rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                min: {
                                    value: 1,
                                    message: t('MIN_ERROR_MESSAGE', {
                                        amount: money({ input: 1, currency }),
                                    }),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'amount', placeholder: t('введите сумму'), rightText: currency, type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.amount, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true }))) }) })), _jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, asCheckbox: true }, { children: _jsx(Controller, { name: 'isBurnable', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                                    setValue('isBurnable', e.target.checked, {
                                        shouldDirty: true,
                                    });
                                }, tooltip: t('Неиспользованный остаток сгорает после первой совершённой покупки') }, { children: t('сгораемый остаток') }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Действует в даты c'), labelFor: 'availableFrom', labelWidth: labelWidth }, { children: _jsxs("div", Object.assign({ className: styles.dates }, { children: [_jsx("div", Object.assign({ className: styles.date }, { children: _jsx(Controller, { name: 'availableFrom', control: control, render: ({ field }) => (_jsx(DateInput, { id: 'availableFrom', placeholderText: t('не ограничено'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                                setValue('availableFrom', date ? moment(date).format() : '', {
                                                    shouldDirty: true,
                                                });
                                            }, selectsStart: true, startDate: field.value ? moment(field.value).toDate() : null, endDate: availableTo ? moment(availableTo).toDate() : null, maxDate: availableTo ? moment(availableTo).toDate() : null, startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR })) }) })), _jsx("label", Object.assign({ className: styles.datesDivider, htmlFor: 'availableTo' }, { children: t('по') })), _jsx("div", Object.assign({ className: styles.date }, { children: _jsx(Controller, { name: 'availableTo', control: control, render: ({ field }) => (_jsx(DateInput, { id: 'availableTo', placeholderText: t('не ограничено'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                                setValue('availableTo', date ? moment(date).format() : '', {
                                                    shouldDirty: true,
                                                });
                                            }, selectsEnd: true, startDate: availableFrom ? moment(availableFrom).toDate() : null, endDate: field.value ? moment(field.value).toDate() : null, minDate: availableFrom ? moment(availableFrom).toDate() : null, startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR })) }) }))] })) })), type === 'print' && (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, asCheckbox: true }, { children: _jsx(Controller, { name: 'isActivationRequired', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                                    setValue('isActivationRequired', e.target.checked, {
                                        shouldDirty: true,
                                    });
                                }, tooltip: t('Карта активируется при передаче клиенту и только после этого становится доступной для использования. Активация должна поддерживаться вашим кассовым ПО, уточните у менеджера, доступна ли эта опция для вас.') }, { children: t('требуется активация карты') }))) }) }))), (isActivationRequired || type === 'digital') && (_jsx(FormFieldRow, Object.assign({ label: t('Срок действия с момента активации'), labelFor: 'validityTime', labelWidth: labelWidth, controlSize: '300', help: (_c = errors.validityTime) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.validityTime }, { children: _jsx(Controller, { name: 'validityTime', control: control, rules: {
                                validate: (value) => {
                                    if (!value)
                                        return true;
                                    if (+value <= 0)
                                        return t('MIN_DAYS_ERROR_MESSAGE', {
                                            amount: 1,
                                            days: 1,
                                        });
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'validityTime', placeholder: t('введите срок действия'), rightText: t('дней'), type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.validityTime, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true }))) }) })))] })), type === 'print' && (_jsxs(FormFieldset, Object.assign({ title: t('Для печатной карты'), titleOffset: labelWidth, tooltip: _jsxs(Trans, Object.assign({ i18nKey: 'PRINTABLE_CARD_HELP' }, { children: [_jsx("a", Object.assign({ href: 'https://help.maxma.com/p/it8iBQCzL5OHBu/Podarochnye-karty', target: '_blank', rel: 'noreferrer' }, { children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u043E \u043F\u043E\u043B\u044F\u0445 \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438" })), _jsx("span", { children: "\u043F\u0435\u0447\u0430\u0442\u043D\u043E\u0439 \u043A\u0430\u0440\u0442\u044B" })] })) }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Товарный код'), labelFor: 'productCode', labelWidth: labelWidth, controlSize: '300', help: ((_d = errors.productCode) === null || _d === void 0 ? void 0 : _d.message) || t('в формате EAN-13'), hasError: !!errors.productCode, isRequired: true }, { children: _jsx(Controller, { name: 'productCode', control: control, rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'productCode', placeholder: t('введите товарный код'), hasError: !!errors.productCode }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Артикул карты'), labelFor: 'sku', labelWidth: labelWidth, controlSize: '300', help: (_e = errors.sku) === null || _e === void 0 ? void 0 : _e.message, hasError: !!errors.sku, isRequired: true }, { children: _jsx(Controller, { name: 'sku', control: control, rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'sku', placeholder: t('введите артикул'), hasError: !!errors.sku }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Тип уникального кода'), labelFor: 'barcodeType', labelWidth: labelWidth, controlSize: '300' }, { children: _jsx(Controller, { name: 'barcodeType', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'barcodeType', value: barcodeTypes.find((item) => item.id === field.value) ||
                                    null, onChange: (barcodeType) => {
                                    setValue('barcodeType', barcodeType === null || barcodeType === void 0 ? void 0 : barcodeType.id, {
                                        shouldDirty: true,
                                    });
                                }, options: barcodeTypes, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Префикс уникального кода'), labelFor: 'barcodePrefix', labelWidth: labelWidth, controlSize: '300', help: ((_f = errors.barcodePrefix) === null || _f === void 0 ? void 0 : _f.message) || t('2-10 цифр'), hasError: !!errors.barcodePrefix }, { children: _jsx(Controller, { name: 'barcodePrefix', control: control, rules: {
                                minLength: {
                                    value: 2,
                                    message: t('FIELD_MIN_LENGTH_ERROR', {
                                        amount: 2,
                                        count: 2,
                                    }),
                                },
                                maxLength: {
                                    value: 10,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: 10,
                                        count: 10,
                                    }),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'barcodePrefix', placeholder: t('введите префикс'), type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.barcodePrefix }))) }) }))] }))), error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), labelWidth: labelWidth, asErrorMessage: true }, { children: error }))), _jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsx("div", Object.assign({ className: styles.toolbar }, { children: isEdit && (_jsx(TrashButton, { disabled: !userHasGiftCardsManage, tooltip: t('Удалить карту'), onClick: onDelete })) })), _jsx(Tooltip, Object.assign({ title: !userHasGiftCardsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: !userHasGiftCardsManage || isSubmitting, size: 'large' }, { children: isEdit ? t('Сохранить изменения') : t('Создать карту') })) }))] }))] })));
});
