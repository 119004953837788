var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useGetLastBreadcrumb } from '@/app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Button } from '@/components/Button2/Button';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { BASIC_END_YEAR, BASIC_START_YEAR, DateInput, } from '@/components/DateInput/DateInput';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Input } from '@/components/Input/Input';
import { Promocode } from '@/components/Promocode/Promocode';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { Switch } from '@/components/Switch/Switch';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import { useNotificateForUnsavedForm } from '@/hooks/useNotificateForUnsavedForm';
import { useProductFilters } from '@/services/filters/data/useProductFilters';
import { usePromocodeConditionsFilters } from '@/services/filters/data/usePromocodeConditionsFilters';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const FormPromocode = memo(({ onSubmit, error, isSubmitting = false, defaultValues, currency, isEdit, onDelete, isFriend = false, bonusesPendingInterval = 0, setActive, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const [isToggleDisabled, setIsToggleDisabled] = useState(false);
    const conditionsFiltersTree = usePromocodeConditionsFilters();
    const allowedCategoriesFiltersTree = useProductFilters();
    const userHasAccessTo = useUserPermissions();
    const userHasPromocodesManage = userHasAccessTo('PROMOCODES_MANAGE');
    const { control, handleSubmit, formState: { errors, isSubmitted, dirtyFields, isSubmitting: isSubmittingForm, }, setValue, trigger, watch, } = useForm({
        defaultValues: Object.assign({ codeType: isFriend ? 'friend' : 'shared', onPurchase: isFriend ? 1 : 0, discountType: 0, conditions: [], allowedCategories: [], rewardType: 0, rewardOn: 0, rewardNotification: true, isActive: true }, defaultValues),
    });
    useNotificateForUnsavedForm(!!Object.keys(dirtyFields).length && !isSubmittingForm);
    const codeType = watch('codeType');
    const availableFrom = watch('availableFrom');
    const availableTo = watch('availableTo');
    const discountType = watch('discountType');
    const rewardType = watch('rewardType');
    const rewardAmount = watch('rewardAmount');
    const isActive = watch('isActive');
    const code = watch('code');
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const labelWidth = 32.52;
    const onPurchaseOptions = isFriend
        ? [
            { value: 1, label: t('Первую покупку').toLowerCase() },
            { value: 2, label: t('Одну покупку').toLowerCase() },
        ]
        : [
            { value: 0, label: t('Любую покупку').toLowerCase() },
            { value: 1, label: t('Первую покупку').toLowerCase() },
            { value: 2, label: t('Одну покупку').toLowerCase() },
        ];
    const discountTypeOptions = [
        { value: 0, label: currency },
        { value: 1, label: '%' },
    ];
    const rewardTypeOptions = [
        { value: 0, label: t('BONUSES_LABEL', { count: rewardAmount || 0 }) },
        { value: 1, label: t('% от покупки друга в бонусах') },
    ];
    const rewardOnOptions = [
        { value: 0, label: t('Все покупки друга').toLowerCase() },
        { value: 1, label: t('Одну покупку друга').toLowerCase() },
    ];
    useEffect(() => {
        if (isSubmitted)
            trigger('discountAmount');
    }, [isSubmitted, discountType]);
    useEffect(() => {
        if (isSubmitted)
            trigger('rewardAmount');
    }, [isSubmitted, rewardType]);
    useGetLastBreadcrumb(code);
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(FormFieldset, Object.assign({ title: t('Промокод'), titleOffset: labelWidth, description: isFriend ? t('Выдается покупателю, который передает его другу') : '' }, { children: [!isFriend && (_jsx(FormFieldRow, Object.assign({ className: styles.codeTypeRow, label: t('Тип кода'), labelFor: 'codeType', labelWidth: labelWidth, controlSize: '200', help: t('Для передачи кода в рассылке или другими способами лично'), helpSize: '200', helpClassName: styles.codeTypeHelp, asCheckbox: true }, { children: _jsx(Controller, { name: 'codeType', control: control, render: ({ field }) => (_jsx(RadioGroup, Object.assign({}, field, { id: 'codeType', value: field.value, onChange: (value) => setValue('codeType', value, {
                                    shouldDirty: true,
                                }), items: [
                                    { value: 'shared', label: t('SHARED_PROMOCODE_TYPE') },
                                    {
                                        value: 'personal',
                                        label: t('PERSONAL_PROMOCODE_TYPE'),
                                    },
                                ], align: 'vertical', gap: 'small' }))) }) }))), _jsx(FormFieldRow, Object.assign({ className: codeType !== 'shared' ? styles.prefixRow : undefined, label: codeType !== 'shared' ? t('Префикс кода') : t('Написание кода'), labelFor: 'code', labelWidth: labelWidth, help: ((_a = errors.code) === null || _a === void 0 ? void 0 : _a.message) ||
                            (codeType !== 'shared'
                                ? t('Генерируемая часть кода добавляется к префиксу')
                                : ''), helpSize: '200', hasError: !!errors.code, isRequired: true }, { children: _jsx(Controller, { name: 'code', control: control, rules: {
                                validate: validateTrimValue,
                                maxLength: {
                                    value: 50,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(50),
                                        count: 50,
                                    }),
                                },
                            }, render: ({ field }) => (_jsxs("div", Object.assign({ className: styles.codeControl }, { children: [_jsx("div", Object.assign({ className: styles.codeInput }, { children: _jsx(Input, Object.assign({}, field, { id: 'code', placeholder: codeType !== 'shared'
                                                ? t('введите префикс')
                                                : t('введите код'), onChange: (e) => setValue('code', e.target.value.toUpperCase(), {
                                                shouldValidate: isSubmitted,
                                                shouldDirty: true,
                                            }), hasError: !!errors.code, autoFocus: true })) })), _jsx(Promocode, { code: field.value || '...', isPersonal: codeType !== 'shared', truncateCodeAt: codeType !== 'shared' ? 15 : 23, isDark: true })] }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Период действия с'), labelFor: 'availableFrom', labelWidth: labelWidth, controlSize: '150' }, { children: _jsx(Controller, { name: 'availableFrom', control: control, render: ({ field }) => (_jsx(DateInput, { id: 'availableFrom', placeholderText: t('не ограничен'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                    setValue('availableFrom', date ? moment(date).format() : '', {
                                        shouldDirty: true,
                                    });
                                }, selectsStart: true, startDate: field.value ? moment(field.value).toDate() : null, endDate: availableTo ? moment(availableTo).toDate() : null, maxDate: availableTo ? moment(availableTo).toDate() : null, startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR })) }) })), _jsx(FormFieldRow, Object.assign({ label: t('по'), labelFor: 'availableTo', labelWidth: labelWidth, controlSize: '150' }, { children: _jsx(Controller, { name: 'availableTo', control: control, render: ({ field }) => (_jsx(DateInput, { id: 'availableTo', placeholderText: t('не ограничен'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                    setValue('availableTo', date ? moment(date).format() : '', {
                                        shouldDirty: true,
                                    });
                                }, selectsEnd: true, startDate: availableFrom ? moment(availableFrom).toDate() : null, endDate: field.value ? moment(field.value).toDate() : null, minDate: availableFrom ? moment(availableFrom).toDate() : null, startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR })) }) }))] })), _jsxs(FormFieldset, Object.assign({ title: isFriend ? t('Друг применяет промокод') : t('Условия применения'), titleOffset: labelWidth }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Только на'), labelFor: 'onPurchase', labelWidth: labelWidth, controlSize: '150' }, { children: _jsx(Controller, { name: 'onPurchase', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'onPurchase', value: onPurchaseOptions.find((item) => item.value === field.value) || null, onChange: (onPurchaseType) => {
                                    setValue('onPurchase', onPurchaseType === null || onPurchaseType === void 0 ? void 0 : onPurchaseType.value, {
                                        shouldDirty: true,
                                    });
                                }, options: onPurchaseOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('при сумме покупки от'), labelFor: 'minPurchaseAmount', labelWidth: labelWidth, controlSize: '150' }, { children: _jsx(Controller, { name: 'minPurchaseAmount', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'minPurchaseAmount', rightText: currency, type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('в месте продажи'), labelWidth: labelWidth }, { children: _jsx(Controller, { name: 'conditions', control: control, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: conditionsFiltersTree, value: field.value, onUpdate: (value) => setValue('conditions', value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                }), hasHangingOperator: false, hasResetButton: false })) }) }))] })), _jsxs(FormFieldset, Object.assign({ title: (isFriend ? t('Вознаграждение другу') : t('Вознаграждение')) + '*', titleOffset: labelWidth }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Скидка'), labelFor: 'discountAmount', labelWidth: labelWidth, help: (_b = errors.discountAmount) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.discountAmount }, { children: _jsx(Controller, { name: 'discountAmount', control: control, rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                max: discountType === 1
                                    ? {
                                        value: 100,
                                        message: t('MAX_ERROR_MESSAGE', { amount: 100 }),
                                    }
                                    : undefined,
                            }, render: ({ field }) => (_jsxs("div", Object.assign({ className: styles.amountControl }, { children: [_jsx("div", Object.assign({ className: styles.amountInput }, { children: _jsx(Input, Object.assign({}, field, { id: 'discountAmount', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.discountAmount, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true })) })), _jsx(Autocomplete, { className: styles.amountTypeInput, value: discountTypeOptions.find((item) => item.value === discountType) || null, onChange: (discountType) => {
                                            setValue('discountType', discountType === null || discountType === void 0 ? void 0 : discountType.value, {
                                                shouldDirty: true,
                                            });
                                        }, options: discountTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true })] }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('на товар'), labelWidth: labelWidth }, { children: _jsx(Controller, { name: 'allowedCategories', control: control, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: allowedCategoriesFiltersTree, value: field.value, onUpdate: (value) => setValue('allowedCategories', value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                }), hasHangingOperator: false })) }) }))] })), isFriend && (_jsxs(FormFieldset, Object.assign({ title: t('Вознаграждение рекомендателю') + '*', titleOffset: labelWidth, description: bonusesPendingInterval > 0 ? (_jsxs(Trans, Object.assign({ i18nKey: 'PROMOCODE_REFERRER_REWARDS_INFO', count: bonusesPendingInterval }, { children: ["\u0411\u043E\u043D\u0443\u0441\u044B \u043D\u0430\u0447\u0438\u0441\u043B\u044F\u044E\u0442\u0441\u044F \u0447\u0435\u0440\u0435\u0437 ", _jsx("b", { children: bonusesPendingInterval }), " \u0434\u043D\u0435\u0439 \u043F\u043E\u0441\u043B\u0435 \u043F\u043E\u043A\u0443\u043F\u043A\u0438 \u0434\u0440\u0443\u0433\u0430"] }))) : undefined }, { children: [_jsx(FormFieldRow, Object.assign({ labelFor: 'rewardAmount', labelWidth: labelWidth, help: (_c = errors.rewardAmount) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.rewardAmount }, { children: _jsx(Controller, { name: 'rewardAmount', control: control, rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                max: rewardType === 1
                                    ? {
                                        value: 100,
                                        message: t('MAX_ERROR_MESSAGE', { amount: 100 }),
                                    }
                                    : undefined,
                            }, render: ({ field }) => (_jsxs("div", Object.assign({ className: styles.amountControl }, { children: [_jsx("div", Object.assign({ className: styles.amountInput }, { children: _jsx(Input, Object.assign({}, field, { id: 'rewardAmount', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.rewardAmount, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true })) })), _jsx(Autocomplete, { className: styles.amountTypeInput, value: rewardTypeOptions.find((item) => item.value === rewardType) || null, onChange: (rewardType) => {
                                            setValue('rewardType', rewardType === null || rewardType === void 0 ? void 0 : rewardType.value, {
                                                shouldDirty: true,
                                            });
                                        }, options: rewardTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, minListWidth: 245, asSelect: true })] }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('начислять за'), labelFor: 'rewardOn', labelWidth: labelWidth, controlSize: '200' }, { children: _jsx(Controller, { name: 'rewardOn', control: control, render: ({ field }) => (_jsx(Autocomplete, { id: 'rewardOn', value: rewardOnOptions.find((item) => item.value === field.value) || null, onChange: (rewardOn) => {
                                    setValue('rewardOn', rewardOn === null || rewardOn === void 0 ? void 0 : rewardOn.value, {
                                        shouldDirty: true,
                                    });
                                }, options: rewardOnOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true })) }) })), _jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, asCheckbox: true }, { children: _jsx(Controller, { name: 'rewardNotification', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                                    setValue('rewardNotification', e.target.checked, {
                                        shouldDirty: true,
                                    });
                                } }, { children: _jsx("div", Object.assign({ className: styles.checkboxLabel }, { children: _jsxs(Trans, Object.assign({ i18nKey: 'REWARD_NOTIFY_PHONE_MESSAGE' }, { children: ["\u0423\u0432\u0435\u0434\u043E\u043C\u0438\u0442\u044C \u043E \u043D\u0430\u0447\u0438\u0441\u043B\u0435\u043D\u0438\u0438 \u0431\u043E\u043D\u0443\u0441\u043E\u0432", _jsx(HelpTooltip, { tooltipClassName: styles.tooltipContent, title: t('Формат сообщения: «Имя, вам начислено N бонусов (всего на счету Y) за покупку друга по вашему промокоду: Префикс кода. Хорошего дня!»'), placement: 'top', isSmall: true }), ' ', "\u0437\u0430 \u043F\u043E\u043A\u0443\u043F\u043A\u0438 \u0434\u0440\u0443\u0433\u0430 (SMS)"] })) })) }))) }) }))] }))), error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), labelWidth: labelWidth, asErrorMessage: true }, { children: error }))), _jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsxs("div", Object.assign({ className: styles.toolbar }, { children: [isEdit && (_jsx(TrashButton, { disabled: !userHasPromocodesManage, tooltip: userHasPromocodesManage
                                    ? t('Удалить промокод')
                                    : t('Недоступно для вашей роли'), onClick: onDelete })), _jsx(Tooltip, Object.assign({ title: !userHasPromocodesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx("div", { children: _jsx(Switch, { label: isActive ? t('Включен') : t('Остановлен'), checked: isActive, onChange: (checked) => __awaiter(void 0, void 0, void 0, function* () {
                                            let ok = true;
                                            if (setActive) {
                                                setIsToggleDisabled(true);
                                                ok = yield setActive(checked);
                                                setIsToggleDisabled(false);
                                            }
                                            if (ok)
                                                setValue('isActive', checked);
                                        }), disabled: !userHasPromocodesManage || isToggleDisabled }) }) }))] })), _jsx(Tooltip, Object.assign({ title: !userHasPromocodesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: !userHasPromocodesManage || isSubmitting, size: 'large' }, { children: isEdit ? t('Сохранить изменения') : t('Создать промокод') })) }))] }))] })));
});
