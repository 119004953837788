import { useCallback, useState } from 'react';
export const useCollapsedGuids = () => {
    const [collapsedGuids, setCollapsedGuids] = useState([]);
    const isGroupExpanded = useCallback((group) => {
        return !collapsedGuids.includes(group.guid);
    }, [collapsedGuids]);
    const toggleGroup = useCallback((group) => {
        setCollapsedGuids((guids) => {
            if (guids.includes(group.guid))
                return guids.filter((guid) => guid !== group.guid);
            return [...guids, group.guid];
        });
    }, []);
    return { isGroupExpanded, toggleGroup };
};
