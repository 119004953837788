import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MailingRewardsList } from '@/components/MailingRewardsList/MailingRewardsList';
import { MailingRewardsMenu } from '@/components/MailingRewardsMenu/MailingRewardsMenu';
import { MailingRewardsPopup } from '@/components/MailingRewardsPopup/MailingRewardsPopup';
import { PROMOCODE_TYPE_FRIEND } from '@/const/promocode';
import { defined } from '@/utils/defined';
import { BonusesAmountForm } from './forms/BonusesAmountForm';
import { OffersForm } from './forms/OffersForm';
import { onlyOne } from './utils';
export const MailingRewardsPillsPopup = ({ isOpen, onClose, anchorEl, type = 'add', activeMenuItem: defaultActiveMenuItem = '', selectedItemIdx, value, onChange, offers = [], promocodes: allPromocodes = [], giftCards = [], currency, }) => {
    const { t } = useTranslation();
    const [activeMenuItem, setActiveMenuItem] = useState(defaultActiveMenuItem);
    useEffect(() => {
        setActiveMenuItem(defaultActiveMenuItem);
    }, [defaultActiveMenuItem]);
    const promocodes = allPromocodes.filter((i) => i.codeType !== PROMOCODE_TYPE_FRIEND);
    const friends = allPromocodes.filter((i) => i.codeType === PROMOCODE_TYPE_FRIEND);
    const handleClose = () => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
        setActiveMenuItem('');
    };
    const selectedItem = typeof selectedItemIdx !== 'undefined' ? value[selectedItemIdx] : undefined;
    const promocodeInValue = value.find((i) => i.type === 'promocode');
    const promocode = allPromocodes.find((p) => p.id === (promocodeInValue === null || promocodeInValue === void 0 ? void 0 : promocodeInValue.promocodeId));
    return (_jsx(MailingRewardsPopup, { isOpen: isOpen, onClose: handleClose, anchorEl: anchorEl, menu: _jsx(MailingRewardsMenu, { activeItem: activeMenuItem, onChangeActiveItem: setActiveMenuItem, offersCount: offers.length, promocodesCount: promocodes.length, friendPromocodesCount: friends.length, giftCardsCount: giftCards.length, hasBonusesApplied: !!value.find((item) => item.type === 'bonuses'), hasPromocodeApplied: !!promocode && promocode.codeType !== PROMOCODE_TYPE_FRIEND, hasFriendPromocodeApplied: !!promocode && promocode.codeType === PROMOCODE_TYPE_FRIEND, hasGiftCardApplied: !!value.find((item) => item.type === 'gift-card') }), list: _jsxs(_Fragment, { children: [activeMenuItem === 'bonuses' && (_jsx(BonusesAmountForm, { type: type, bonusesAmount: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === 'bonuses'
                        ? selectedItem.bonusesAmount
                        : undefined, bonusesLifetime: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === 'bonuses'
                        ? selectedItem.bonusesLifetime
                        : undefined, onChange: (bonusesAmount, bonusesLifetime) => {
                        const newValue = [...value];
                        const newBonusesItem = {
                            type: 'bonuses',
                            bonusesAmount,
                            bonusesLifetime,
                        };
                        if (selectedItemIdx !== undefined)
                            newValue[selectedItemIdx] = newBonusesItem;
                        else
                            newValue.push(newBonusesItem);
                        onChange(onlyOne(newValue, selectedItemIdx));
                        handleClose();
                    } })), activeMenuItem === 'offers' && (_jsx(OffersForm, { type: type, offerId: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === 'offer' ? selectedItem.id : undefined, expireInDays: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === 'offer'
                        ? selectedItem.expireInDays
                        : undefined, offers: offers, onChange: (offerId, expireInDays) => {
                        const newValue = [...value];
                        const newOfferItem = {
                            type: 'offer',
                            id: offerId,
                            expireInDays,
                        };
                        if (selectedItemIdx !== undefined)
                            newValue[selectedItemIdx] = newOfferItem;
                        else
                            newValue.push(newOfferItem);
                        onChange(onlyOne(newValue, selectedItemIdx));
                        handleClose();
                    }, disabledItemIds: value
                        .map((item) => (item.type === 'offer' ? item.id : undefined))
                        .filter(defined) })), (activeMenuItem === 'promocodes' ||
                    activeMenuItem === 'friendPromocodes') && (_jsx(MailingRewardsList, { title: t('Выберите промокод'), items: activeMenuItem === 'promocodes' ? promocodes : friends, onItemClick: (promocodeId) => {
                        const newValue = [...value];
                        const newPromocodeItem = {
                            type: 'promocode',
                            promocodeId,
                        };
                        if (selectedItemIdx !== undefined)
                            newValue[selectedItemIdx] = newPromocodeItem;
                        else
                            newValue.push(newPromocodeItem);
                        onChange(onlyOne(newValue, selectedItemIdx));
                        handleClose();
                    }, selectedItemId: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === 'promocode'
                        ? selectedItem.promocodeId
                        : undefined, disabledItemIds: value
                        .map((item) => item.type === 'promocode' ? item.promocodeId : undefined)
                        .filter(defined) })), activeMenuItem === 'giftCards' && (_jsx(MailingRewardsList, { title: t('Выберите карту'), items: giftCards, onItemClick: (giftCardId) => {
                        const newValue = [...value];
                        const newGiftCardItem = {
                            type: 'gift-card',
                            giftCardId,
                        };
                        if (selectedItemIdx !== undefined)
                            newValue[selectedItemIdx] = newGiftCardItem;
                        else
                            newValue.push(newGiftCardItem);
                        onChange(onlyOne(newValue, selectedItemIdx));
                        handleClose();
                    }, selectedItemId: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.type) === 'gift-card'
                        ? selectedItem.giftCardId
                        : undefined, disabledItemIds: value
                        .map((item) => item.type === 'gift-card' ? item.giftCardId : undefined)
                        .filter(defined), currency: currency })), !activeMenuItem && null] }) }));
};
