import { notNull } from '@/utils/notNull';
import { numberRound } from '@/utils/numbers';
import { createEdge } from '../createEdge';
import { createNode } from '../createNode';
export const createChannelNode = (data, id) => {
    return createNode({
        id,
        type: 'channel',
        data,
        position: data.position || { x: 0, y: 0 },
        style: !data.position ? { opacity: 0 } : undefined,
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const validateChannelNodeData = (data, t) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
    const errors = {};
    const nameMaxLength = 200;
    // 1) name
    if (!((_a = data.name) === null || _a === void 0 ? void 0 : _a.trim()))
        errors.name = t('FIELD_REQUIRED_ERROR');
    else if (data.name.length > nameMaxLength)
        errors.name = t('FIELD_MAX_LENGTH_ERROR', {
            amount: numberRound(nameMaxLength),
            count: nameMaxLength,
        });
    if ((_b = data.channels) === null || _b === void 0 ? void 0 : _b.includes('push')) {
        // 2) push text
        if (!((_d = (_c = data.push) === null || _c === void 0 ? void 0 : _c.pushText) === null || _d === void 0 ? void 0 : _d.trim()))
            errors.pushText = t('FIELD_REQUIRED_ERROR');
        // 3) push image expire (days)
        if ((_e = data.push) === null || _e === void 0 ? void 0 : _e.walletImageId)
            if (!data.push.walletPushExpireInDays)
                errors.walletPushExpireInDays = t('FIELD_REQUIRED_ERROR_SHORT');
            else {
                const n = Number(data.push.walletPushExpireInDays);
                const min = 1;
                const max = 30;
                if (n < min)
                    errors.walletPushExpireInDays = t('MIN_ERROR_MESSAGE', {
                        amount: min,
                    });
                else if (n > max)
                    errors.walletPushExpireInDays = t('MAX_ERROR_MESSAGE', {
                        amount: max,
                    });
            }
    }
    if ((_f = data.channels) === null || _f === void 0 ? void 0 : _f.includes('viber')) {
        // 4) viber text
        if (!((_h = (_g = data.viber) === null || _g === void 0 ? void 0 : _g.viberText) === null || _h === void 0 ? void 0 : _h.trim()))
            errors.viberText = t('FIELD_REQUIRED_ERROR');
        const isRequiredButton = !!((_j = data.viber) === null || _j === void 0 ? void 0 : _j.viberImageId) || !!((_k = data.viber) === null || _k === void 0 ? void 0 : _k.viberUrl);
        // 5) viber button
        if (isRequiredButton && !((_m = (_l = data.viber) === null || _l === void 0 ? void 0 : _l.viberButton) === null || _m === void 0 ? void 0 : _m.trim()))
            errors.viberButton = t('FIELD_REQUIRED_ERROR');
        const isRequiredUrl = !!((_o = data.viber) === null || _o === void 0 ? void 0 : _o.viberImageId) || !!((_p = data.viber) === null || _p === void 0 ? void 0 : _p.viberButton);
        // 6) viber url
        if (isRequiredUrl && !((_r = (_q = data.viber) === null || _q === void 0 ? void 0 : _q.viberUrl) === null || _r === void 0 ? void 0 : _r.trim()))
            errors.viberUrl = t('FIELD_REQUIRED_ERROR');
    }
    // 7) sms text
    if ((_s = data.channels) === null || _s === void 0 ? void 0 : _s.includes('sms'))
        if (!((_u = (_t = data.sms) === null || _t === void 0 ? void 0 : _t.smsText) === null || _u === void 0 ? void 0 : _u.trim()))
            errors.smsText = t('FIELD_REQUIRED_ERROR');
    if ((_v = data.channels) === null || _v === void 0 ? void 0 : _v.includes('telegram')) {
        // 8) telegram text
        if (!((_x = (_w = data.telegram) === null || _w === void 0 ? void 0 : _w.telegramText) === null || _x === void 0 ? void 0 : _x.trim()))
            errors.telegramText = t('FIELD_REQUIRED_ERROR');
        const isRequiredButton = !!((_y = data.telegram) === null || _y === void 0 ? void 0 : _y.telegramUrl);
        // 9) telegram button
        if (isRequiredButton && !((_0 = (_z = data.telegram) === null || _z === void 0 ? void 0 : _z.telegramButton) === null || _0 === void 0 ? void 0 : _0.trim()))
            errors.telegramButton = t('FIELD_REQUIRED_ERROR');
        const isRequiredUrl = !!((_1 = data.telegram) === null || _1 === void 0 ? void 0 : _1.telegramButton);
        // 10) telegram url
        if (isRequiredUrl && !((_3 = (_2 = data.telegram) === null || _2 === void 0 ? void 0 : _2.telegramUrl) === null || _3 === void 0 ? void 0 : _3.trim()))
            errors.telegramUrl = t('FIELD_REQUIRED_ERROR');
    }
    if ((_4 = data.channels) === null || _4 === void 0 ? void 0 : _4.includes('email')) {
        // 11) email subject
        if (!((_6 = (_5 = data.email) === null || _5 === void 0 ? void 0 : _5.emailSubject) === null || _6 === void 0 ? void 0 : _6.trim()))
            errors.emailSubject = t('FIELD_REQUIRED_ERROR');
        // 12) email body
        if (!((_8 = (_7 = data.email) === null || _7 === void 0 ? void 0 : _7.emailBody) === null || _8 === void 0 ? void 0 : _8.trim()))
            errors.emailBody = t('FIELD_REQUIRED_ERROR');
        else {
            const body = data.email.emailBody;
            const link = /{{\s*brand\.settings\.landingBase\s*}}\/m\/unsub\/{{\s*msgid\s*}}/;
            const variable = '!' + t('Отписаться');
            if (!link.test(body) && body.indexOf(variable) === -1)
                errors.emailBody = t('MAILING.CREATE.MISSING_UNSUB_LINK');
        }
    }
    // 13) cascade body
    if (data.hasCascadeEnabled && !((_9 = data.channels) === null || _9 === void 0 ? void 0 : _9.length))
        errors.channels = t('добавьте хотя бы один канал');
    return errors;
};
export const createChannelNodeForMailing = (mailing, resetUUID) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const channels = [
        mailing.channels.push ? 'push' : null,
        mailing.channels.viber ? 'viber' : null,
        mailing.channels.sms ? 'sms' : null,
        mailing.channels.email ? 'email' : null,
        mailing.channels.telegram ? 'telegram' : null,
    ].filter(notNull);
    const cascadeChannels = channels.filter((ch) => ch !== 'email' && ch !== 'telegram');
    const hasCascadeEnabled = cascadeChannels.length > 1;
    const cascadeActiveChannelTab = cascadeChannels[0];
    const rewards = [];
    if (mailing.bonuses && mailing.bonuses > 0 && mailing.expireInDays)
        rewards.push({
            type: 'bonuses',
            bonusesAmount: mailing.bonuses.toString(),
            bonusesLifetime: mailing.expireInDays.toString(),
        });
    if ((_a = mailing.promocode) === null || _a === void 0 ? void 0 : _a.id)
        rewards.push({
            type: 'promocode',
            promocodeId: mailing.promocode.id,
        });
    if ((_b = mailing.giftCard) === null || _b === void 0 ? void 0 : _b.id)
        rewards.push({
            type: 'gift-card',
            giftCardId: mailing.giftCard.id,
        });
    if ((_c = mailing.offers) === null || _c === void 0 ? void 0 : _c.length) {
        const items = mailing.offers.map((offer) => ({
            type: 'offer',
            id: offer.offer.id,
            expireInDays: offer.expireInDays,
        }));
        rewards.push(...items);
    }
    return createChannelNode({
        status: mailing.isActive ? 'active' : 'pause',
        name: mailing.name,
        channels,
        push: {
            messagingAppId: (_d = mailing.messagingApp) === null || _d === void 0 ? void 0 : _d.id,
            pushText: mailing.rawPushBody || '',
            pushTextLength: undefined,
            walletImage: mailing.walletImage,
            walletImageId: (_e = mailing.walletImage) === null || _e === void 0 ? void 0 : _e.id,
            walletImageFile: undefined,
            walletPushExpireInDays: mailing.walletPushExpireInDays,
        },
        viber: {
            viberText: mailing.rawViberBody || '',
            viberTextLength: undefined,
            viberImage: mailing.viberImage,
            viberImageId: (_f = mailing.viberImage) === null || _f === void 0 ? void 0 : _f.id,
            viberImageFile: undefined,
            viberButton: mailing.viberButton,
            viberUrl: mailing.viberUrl,
        },
        sms: {
            smsSenderId: (_g = mailing.smsSender) === null || _g === void 0 ? void 0 : _g.id,
            smsText: mailing.rawSmsBody || '',
            smsTextLength: undefined,
            smsMessagesCount: undefined,
        },
        telegram: {
            telegramText: mailing.rawTelegramBody || '',
            telegramTextLength: undefined,
            telegramImage: mailing.telegramImage,
            telegramImageId: (_h = mailing.telegramImage) === null || _h === void 0 ? void 0 : _h.id,
            telegramImageFile: undefined,
            telegramButton: mailing.telegramButton,
            telegramUrl: mailing.telegramUrl,
        },
        email: {
            emailSenderId: (_j = mailing.emailSender) === null || _j === void 0 ? void 0 : _j.id,
            emailSubject: mailing.emailSubject,
            emailBody: mailing.emailBody,
            emailDesign: mailing.emailDesign,
            emailPreviewImageUrl: mailing.emailPreviewImageUrl,
            includeRecommendations: !!mailing.recommendationType,
            recommendationType: mailing.recommendationType,
            recommendationCount: mailing.recommendationCount,
        },
        rewards,
        goal: {
            goalType: mailing.goalType,
            goalDaysTo: ((_k = mailing.goalDaysTo) === null || _k === void 0 ? void 0 : _k.toString()) || '',
            goalConditions: mailing.goalConditions,
        },
        hasCascadeEnabled,
        cascadeActiveChannelTab,
    }, resetUUID ? '' : mailing.guid);
};
const createCascadeNodeForMailing = (mailing) => {
    const channels = [
        mailing.channels.push ? 'push' : null,
        mailing.channels.viber ? 'viber' : null,
        mailing.channels.sms ? 'sms' : null,
        mailing.channels.email ? 'email' : null,
        mailing.channels.telegram ? 'telegram' : null,
    ].filter(notNull);
    const cascadeChannels = channels.filter((ch) => ch !== 'email' && ch !== 'telegram');
    if (cascadeChannels.length)
        return createChannelNodeForMailing(Object.assign(Object.assign({}, mailing), { channels: {
                push: mailing.channels.push,
                viber: mailing.channels.viber,
                sms: mailing.channels.sms,
                email: false,
                telegram: false,
            } }));
    return null;
};
const createEmailNodeForMailing = (mailing) => {
    if (mailing.channels.email)
        return createChannelNodeForMailing(Object.assign(Object.assign({}, mailing), { channels: {
                push: false,
                viber: false,
                sms: false,
                email: true,
                telegram: false,
            } }), true);
    return null;
};
const createTelegramNodeForMailing = (mailing) => {
    if (mailing.channels.telegram)
        return createChannelNodeForMailing(Object.assign(Object.assign({}, mailing), { channels: {
                push: false,
                viber: false,
                sms: false,
                email: false,
                telegram: true,
            } }), true);
    return null;
};
export const createChannelNodesFromMailings = (branches) => {
    const channelNodes = [];
    const channelEdges = [];
    const uuidMap = {
        cascade: {},
        email: {},
        telegram: {},
    };
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const source = fromNodeId;
                const sourceHandle = fromHandleId !== 'no-handle' ? fromHandleId : undefined;
                const telegramNode = createTelegramNodeForMailing(mailing);
                if (telegramNode) {
                    uuidMap.telegram[mailing.id] = telegramNode.uuid;
                    channelNodes.push(telegramNode);
                    channelEdges.push(createEdge({
                        source,
                        sourceHandle,
                        target: telegramNode.id,
                        data: { isInteractive: true },
                    }));
                }
                const emailNode = createEmailNodeForMailing(mailing);
                if (emailNode) {
                    uuidMap.email[mailing.id] = emailNode.uuid;
                    channelNodes.push(emailNode);
                    channelEdges.push(createEdge({
                        source,
                        sourceHandle,
                        target: emailNode.id,
                        data: { isInteractive: true },
                    }));
                }
                const cascadeNode = createCascadeNodeForMailing(mailing);
                if (cascadeNode) {
                    uuidMap.cascade[mailing.id] = cascadeNode.uuid;
                    channelNodes.push(cascadeNode);
                    channelEdges.push(createEdge({
                        source,
                        sourceHandle,
                        target: cascadeNode.id,
                        data: { isInteractive: true },
                    }));
                }
            }
        }
    }
    return { channelNodes, channelEdges, uuidMap };
};
