import { useNodesInitialized, useReactFlow } from '@xyflow/react';
import { cancelable } from 'cancelable-promise';
import { useEffect, useRef } from 'react';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { layoutFlow } from './elkLayout';
let lastPromise;
export const useLayoutFlow = () => {
    const flow = useReactFlow();
    const initialized = useNodesInitialized();
    const { layout } = useInteractiveFlow();
    const isFirstLayout = useRef(true);
    useEffect(() => {
        if (initialized || layout.isLayoutUpdateRequested) {
            layout.resetLayoutRequest();
            const nodes = flow.getNodes();
            const edges = flow.getEdges();
            if (lastPromise)
                lastPromise.cancel();
            lastPromise = cancelable(layoutFlow(nodes, edges));
            lastPromise.then((layout) => {
                if (layout) {
                    flow.setNodes(layout.nodes);
                    flow.setEdges(layout.edges);
                    const fitView = layout.nodes.length <= 3;
                    if (fitView || isFirstLayout.current)
                        window.requestAnimationFrame(() => {
                            flow.fitView({ maxZoom: 1 });
                        });
                    isFirstLayout.current = false;
                }
            });
        }
    }, [initialized, layout.isLayoutUpdateRequested]);
    return true;
};
