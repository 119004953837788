import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Gears } from '@/icons/Gears';
import { isItemActive } from './isItemActive';
import styles from './styles.module.scss';
export const ArbitrationButton = ({ item, currentRoute, onClick, }) => {
    const { t } = useTranslation();
    const handleClick = () => {
        if (item === null || item === void 0 ? void 0 : item.route)
            onClick(item.route);
    };
    if (!(item === null || item === void 0 ? void 0 : item.shouldDisplay))
        return null;
    if (item.route && !item.displayAsBlocked)
        return (_jsx(Tooltip, Object.assign({ title: t('Арбитраж'), placement: 'right' }, { children: _jsx("a", Object.assign({ className: classNames(styles.arbitrationButton, {
                    [styles.active]: isItemActive(item, currentRoute),
                }), href: item.href, onClick: handleClick }, { children: _jsx(Gears, { size: 16, fill: 'currentColor' }) })) })));
    if (item.tooltip)
        return (_jsx(Tooltip, Object.assign({ className: styles.tooltip, title: t(item.tooltip), placement: 'right' }, { children: _jsx("div", Object.assign({ className: styles.arbitrationButton }, { children: _jsx(Gears, { size: 16, fill: 'currentColor' }) })) })));
    return null;
};
