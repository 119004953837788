import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxSelect } from '@/components/CheckboxSelect/CheckboxSelect';
import { DateInput } from '@/components/DateInput/DateInput';
import { DaysSelect } from '@/components/DaysSelect/DaysSelect';
import { DAYS } from '@/components/DaysSelect/DaysSelect';
import { parseDaysNumber } from '@/components/DaysSelect/parseDaysNumber';
import { Wrapper } from '@/components/FilterForm/controls/Wrapper';
import { TimeInput } from '@/components/TimeInput/TimeInput';
import { formatDay } from '@/utils/dates';
import styles from './styles.module.scss';
export const ScheduleFilterForm = ({ value, onChange, scheduleType: defaultScheduleType = '', }) => {
    const { t } = useTranslation();
    const [data, setData] = useState((value === null || value === void 0 ? void 0 : value.rawConditions.schedule) || {
        scheduleType: defaultScheduleType || '',
        scheduleDaysOfWeek: [],
        scheduleDaysOfMonth: [],
        scheduleDate: '',
        scheduleTime: '',
    });
    const dayOfMonthOptions = new Array(31).fill(null).map((_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
    }));
    dayOfMonthOptions.push({
        value: 32,
        label: t('последний день месяца'),
    });
    const isApplyButtonDisabled = () => {
        if (!data.scheduleTime || data.scheduleTime.includes('_'))
            return true;
        if (data.scheduleType === 'weekly')
            return !data.scheduleDaysOfWeek.length;
        if (data.scheduleType === 'monthly')
            return !data.scheduleDaysOfMonth.length;
        if (data.scheduleType === 'yearly')
            return !data.scheduleDate;
        return false;
    };
    const handleUpdateData = (partialData) => {
        setData((data) => (Object.assign(Object.assign({}, data), partialData)));
    };
    const handleApply = () => {
        if (!isApplyButtonDisabled())
            onChange === null || onChange === void 0 ? void 0 : onChange(createScheduleSavedFilter(data));
    };
    return (_jsx(Wrapper, Object.assign({ label: '', isHeaderVisible: false, isApplyButtonDisabled: isApplyButtonDisabled(), onApplyClick: handleApply }, { children: _jsxs("div", Object.assign({ className: styles.scheduleRow }, { children: [data.scheduleType === 'weekly' && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.scheduleDivider }, { children: t('SEND_ON_DAYS') })), _jsx("div", Object.assign({ className: styles.scheduleDaysOfWeekInput }, { children: _jsx(DaysSelect, { value: data.scheduleDaysOfWeek, onChange: (daysOfWeek) => {
                                    handleUpdateData({ scheduleDaysOfWeek: daysOfWeek });
                                }, hasReset: false }) }))] })), data.scheduleType === 'monthly' && (_jsx("div", Object.assign({ className: styles.scheduleDaysOfMonthInput }, { children: _jsx(CheckboxSelect, { text: dayOfMonthOptions
                            .filter((i) => data.scheduleDaysOfMonth.includes(i.value))
                            .map((i) => i.label)
                            .join(', ') || '', options: dayOfMonthOptions, value: data.scheduleDaysOfMonth, onChange: (daysOfMonth) => {
                            handleUpdateData({ scheduleDaysOfMonth: daysOfMonth });
                        }, hasResetButton: false, listHeight: 250, listWidth: 150 }) }))), data.scheduleType === 'yearly' && (_jsx("div", Object.assign({ className: styles.scheduleDateInput }, { children: _jsx(DateInput, { selected: data.scheduleDate ? moment(data.scheduleDate).toDate() : null, onChange: (date) => {
                            handleUpdateData({
                                scheduleDate: date ? moment(date).format() : '',
                            });
                        }, portalId: 'schedule-modal-dates-portal', dateFormat: 'd MMMM', placeholderText: '', minDate: moment()
                            .startOf('day')
                            .set('month', 0)
                            .set('date', 1)
                            .toDate(), maxDate: moment()
                            .endOf('day')
                            .set('month', 11)
                            .set('date', 31)
                            .toDate(), hasDayNamesHidden: true, hasYearDropdownHidden: true, hasConstraints: true }) }))), _jsx("div", Object.assign({ className: styles.scheduleDivider }, { children: data.scheduleType === 'monthly' ? t('числа в') : t('SEND_AT') })), _jsx("div", Object.assign({ className: styles.scheduleTimeInput }, { children: _jsx(TimeInput, { value: data.scheduleTime, onAccept: (time) => {
                            handleUpdateData({ scheduleTime: time });
                        }, onClear: () => {
                            handleUpdateData({ scheduleTime: '' });
                        }, onKeyDown: (e) => {
                            if (e.code === 'Enter')
                                handleApply();
                        } }) }))] })) })));
};
const DAYS_MAP = {
    [DAYS.WEEKDAY_MON]: 1,
    [DAYS.WEEKDAY_TUE]: 2,
    [DAYS.WEEKDAY_WED]: 3,
    [DAYS.WEEKDAY_THU]: 4,
    [DAYS.WEEKDAY_FRI]: 5,
    [DAYS.WEEKDAY_SAT]: 6,
    [DAYS.WEEKDAY_SUN]: 7,
};
export const createScheduleSavedFilter = (data) => {
    return {
        id: data.scheduleType,
        code: data.scheduleType,
        text: createScheduleCondition(data.scheduleType, data.scheduleTime, data.scheduleDaysOfWeek.reduce((sum, w) => sum + w, 0), data.scheduleDaysOfMonth, data.scheduleDate),
        rawConditions: {
            op: 'include',
            schedule: data,
        },
    };
};
export const createScheduleCondition = (scheduleType, scheduleTime, scheduleWeekdays, scheduleDays, scheduleDate) => {
    switch (scheduleType) {
        case 'daily': {
            return `в ${scheduleTime}`;
        }
        case 'weekly': {
            const days = parseDaysNumber(scheduleWeekdays);
            const dayLabels = days.map((d) => formatDay(DAYS_MAP[d], 'ru', days.length > 1));
            return `${dayLabels.join(', ')} в ${scheduleTime}`;
        }
        case 'monthly': {
            const scheduleDayNames = scheduleDays.map((day) => {
                if (day === 32)
                    return 'последнего';
                return day.toString();
            });
            return `${scheduleDayNames.join(', ')} числа в ${scheduleTime}`;
        }
        case 'yearly': {
            const date = moment(scheduleDate).locale('ru').format('D MMMM');
            return `${date} в ${scheduleTime}`;
        }
        default: {
            return '';
        }
    }
};
