import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './EmptyGroupPlaceholder.module.scss';
export const EmptyGroupPlaceholder = ({ className, title, placeholderClassName, levelGap = 0, }) => {
    const { t } = useTranslation();
    const EMPTY_GROUP_INITIAL_GAP = 24;
    const paddingLeft = EMPTY_GROUP_INITIAL_GAP + levelGap;
    return (_jsx("div", Object.assign({ className: classNames(className, styles.wrapper), style: { paddingLeft } }, { children: _jsx("div", Object.assign({ className: classNames(placeholderClassName, styles.emptyGroupPlaceholder) }, { children: title || t('Пустая группа') })) })));
};
