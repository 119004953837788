var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGetMailingStatsManualMutation } from '@/api/query/mailings';
import { useAppPeriod } from '@/hooks/useAppPeriod';
import { useModalLoading } from '@/hooks/useModalLoading';
export const useMailingStatsModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [mailingStats, setMailingStats] = useState(null);
    const { appPeriod } = useAppPeriod();
    const [periodStart, setPeriodStart] = useState(appPeriod.start.toDate());
    const [periodEnd, setPeriodEnd] = useState(appPeriod.end.toDate());
    useEffect(() => {
        setPeriodStart(appPeriod.start.toDate());
        setPeriodEnd(appPeriod.end.toDate());
    }, [isOpen]);
    const [fetchStats, { isLoading }] = useGetMailingStatsManualMutation();
    useModalLoading(!mailingStats && isLoading);
    const openModal = (mailingId) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield fetchStats({
            id: mailingId,
            periodStart: moment(periodStart).format(),
            periodEnd: moment(periodEnd).format(),
        });
        if (!('error' in result)) {
            setIsOpen(true);
            setMailingStats(result.data);
        }
    });
    const closeModal = () => {
        setIsOpen(false);
        setMailingStats(null);
    };
    const changePeriod = (mailingId) => (start, end) => __awaiter(void 0, void 0, void 0, function* () {
        if (start)
            setPeriodStart(start);
        if (end)
            setPeriodEnd(end);
        const result = yield fetchStats({
            id: mailingId,
            periodStart: moment(start || periodStart).format(),
            periodEnd: moment(end || periodEnd).format(),
        });
        if (!('error' in result))
            setMailingStats(result.data);
    });
    return {
        isOpen,
        openModal,
        closeModal,
        mailingStats,
        periodStart,
        periodEnd,
        changePeriod,
        isLoading,
    };
};
