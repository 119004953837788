import { useReactFlow } from '@xyflow/react';
import * as triggers from '@/const/mailing-triggers';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { createEdge } from '../nodes/createEdge';
import { findEdgesFromNode, findEdgesToNode, findFirstEdgeToNode, findNodeById, findNodeByType, updateFlow, } from '../nodes/utils';
import { useCreateInitialNodes } from './useInitialNodes';
export const useDeleteNode = () => {
    const flow = useReactFlow();
    const { triggerType, layout, onChange } = useInteractiveFlow();
    const createInitialNodes = useCreateInitialNodes();
    return (nodeId) => {
        const node = flow.getNode(nodeId);
        if (!node)
            return;
        const inEdge = findFirstEdgeToNode(flow, node);
        const prevNode = findNodeById(flow, inEdge === null || inEdge === void 0 ? void 0 : inEdge.source);
        if (!inEdge || !prevNode)
            return;
        const addEdges = [];
        // удаляем ноду N
        //           x
        // [Np] --> [N] --> [No1]
        //              --> [No2]
        //              --> [No3]
        const removeNodes = [node];
        // удаляем ребро, которое входит в удаляемую ноду N
        //       x
        // [Np] --> [N] --> [No1]
        //              --> [No2]
        //              --> [No3]
        const removeEdges = [inEdge];
        // удаляем все ребра, которые выходят из удаляемой ноды N
        //               x
        // [Np] --> [N] --> [No1]
        //              --> [No2]
        //              --> [No3]
        const outEdges = findEdgesFromNode(flow, node);
        removeEdges.push(...outEdges);
        // создаем новые ребра, которые будут выходить из ноды Np,
        // которая предшествует удаляемой ноде N,
        // и будут замыкаться на следующие после удаляемой [No1], [No2] и тд
        // [Np] --> [No1]
        //      --> [No2]
        //      --> [No3]
        if (outEdges.length > 0) {
            const newEdges = outEdges.map((e) => {
                const data = e.data;
                return createEdge({
                    id: e.id,
                    source: inEdge.source,
                    sourceHandle: inEdge.sourceHandle,
                    target: e.target,
                    data: { isInteractive: data === null || data === void 0 ? void 0 : data.isInteractive },
                });
            });
            addEdges.push(...newEdges);
        }
        const isLastUserCreatedNode = triggerType === triggers.TRIGGER_TYPE_SCHEDULE
            ? prevNode.type === 'trigger'
            : prevNode.type === 'loop';
        // если удаляется последняя созданная пользователем нода,
        // необходимо сбросить полотно на изначальное состояние,
        // как если бы пользователь только приступил к созданию цепочки
        if (!outEdges.length && isLastUserCreatedNode) {
            const prevNodeOutEdges = findEdgesFromNode(flow, prevNode);
            if (prevNodeOutEdges.length === 1) {
                const triggerNode = findNodeByType(flow, 'trigger');
                const triggerNodeOutEdges = findEdgesFromNode(flow, triggerNode);
                if (triggerNode && triggerNodeOutEdges.length === 1) {
                    const initialNodes = createInitialNodes();
                    flow.setNodes(initialNodes.addNodes);
                    flow.setEdges(initialNodes.addEdges);
                    onChange === null || onChange === void 0 ? void 0 : onChange(true);
                    return;
                }
                if (triggerNode && triggerNodeOutEdges.length > 1) {
                    const prevNodeInEdges = findEdgesToNode(flow, prevNode);
                    removeNodes.push(prevNode);
                    removeEdges.push(...prevNodeOutEdges, ...prevNodeInEdges);
                }
            }
        }
        updateFlow(flow, {
            removeNodes,
            addEdges,
            removeEdges,
        });
        onChange === null || onChange === void 0 ? void 0 : onChange();
        window.requestAnimationFrame(() => {
            layout.forceLayout();
        });
    };
};
