import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { cloneElement, memo, useCallback, useRef, useState } from 'react';
import { NewBadge } from '@/components/NewBadge/NewBadge';
import { SoonBadge } from '@/components/SoonBadge/SoonBadge';
import { Tooltip as CustomTooltip } from '@/components/Tooltip/Tooltip';
import { DropdownChevron } from './DropdownChevron';
import styles from './styles.module.scss';
export const Dropdown = memo(({ items, title, offset, onOpen, onClose, className, triggerClassName, titleClassName, chevronClassName, disablePortal = false, listMaxHeight, placement = 'bottom-end', size = 'normal', isDanger = false, children, highlightSelectedItem = false, onFocus, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedItemElement = useRef(null);
    const handleOpen = useCallback(() => {
        setIsOpen(true);
        if (listMaxHeight)
            setTimeout(() => {
                var _a;
                (_a = selectedItemElement.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'nearest' });
            });
        if (onOpen)
            onOpen();
    }, [onOpen]);
    const handleClose = useCallback(() => {
        setIsOpen(false);
        if (onClose)
            onClose();
    }, [onClose]);
    const handleClick = useCallback(() => {
        isOpen ? handleClose() : handleOpen();
    }, [isOpen, handleOpen, handleClose]);
    const stopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const popperModifiers = [
        {
            name: 'offset',
            options: { offset },
        },
    ];
    const list = (_jsx("ul", Object.assign({ className: classNames(styles.list, styles[size]), style: listMaxHeight
            ? {
                '--max-height': `${listMaxHeight}px`,
            }
            : undefined }, { children: items
            .filter((item) => item.shouldDisplay !== false)
            .map((item) => (_jsx(CustomTooltip, Object.assign({ title: item.tooltip, placement: item.tooltipPlacement || 'top' }, { children: _jsxs("li", Object.assign({ ref: item.isSelected ? selectedItemElement : undefined, className: classNames(styles.item, {
                    [styles.danger]: item.isDanger,
                    [styles.divider]: item.isDivider,
                    [styles.multi]: item.isMultiLine,
                    [styles.selected]: highlightSelectedItem && item.isSelected,
                    [styles.disabled]: item.isDisabled,
                    [styles.soonWrapper]: item.isSoon,
                    [styles.newWrapper]: item.isNew,
                }), onClick: !item.isDisabled ? item.onClick : stopPropagation }, { children: [item.label, !!item.isSoon && _jsx(SoonBadge, {}), !!item.isNew && _jsx(NewBadge, {})] })) }), item.id))) })));
    const titleElement = titleClassName ? (_jsx("span", Object.assign({ className: titleClassName }, { children: title }))) : (title);
    return (_jsx(ClickAwayListener, Object.assign({ onClickAway: handleClose }, { children: _jsx(Tooltip, Object.assign({ open: isOpen, PopperProps: {
                className: classNames(styles.popup, className, {
                    [styles.hasMaxHeight]: !!listMaxHeight,
                }),
                modifiers: popperModifiers,
                disablePortal,
                onTouchEnd: (e) => e.stopPropagation(),
            }, placement: placement, title: list, disableFocusListener: true, disableHoverListener: true, onOpen: handleOpen, onClose: handleClose }, { children: children ? (cloneElement(children, { onClick: handleClick })) : (_jsxs("button", Object.assign({ className: classNames(styles.trigger, {
                    [styles.hasTitle]: !!title,
                    [styles.danger]: isDanger,
                }, styles[size], triggerClassName), onClick: handleClick, onFocus: onFocus }, { children: [titleElement, _jsx(DropdownChevron, { className: chevronClassName, isActive: isOpen })] }))) })) })));
});
