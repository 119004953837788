import { api } from './';
export const arbitrationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getArbitrationList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/arbitration/list',
                body,
            }),
            providesTags: ['ArbitrationList'],
        }),
        saveArbitrationList: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/arbitration/save',
                body,
            }),
            invalidatesTags: ['ArbitrationList'],
        }),
    }),
});
export const { useGetArbitrationListQuery, useSaveArbitrationListMutation } = arbitrationApi;
