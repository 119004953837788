import { useGetShopGroupOptionsQuery } from '@/api/query/shop-group';
export const useIssuerShopOptions = () => {
    const { data: issuerShopGroups = [], isLoading } = useGetShopGroupOptionsQuery(undefined, { refetchOnMountOrArgChange: true });
    const issuerShopOptions = [];
    issuerShopGroups.forEach((group) => {
        if (group.id !== null)
            issuerShopOptions.push({
                id: group.id,
                label: group.name,
                isGroup: true,
            });
        group.shops.forEach((shop) => {
            issuerShopOptions.push({
                id: shop.id,
                label: shop.name,
                isGroup: group.id !== null ? false : undefined,
            });
        });
    });
    return { issuerShopOptions, isLoading };
};
