import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { PriceInput } from '@/components/PriceInput/PriceInput';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const PurchaseItemLockedPriceInput = ({ idx, item, onChangeItemPrice, currency }) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.column, styles.price) }, { children: [item.isFreePrice && (_jsx(PriceInput, { value: !item.price ? '' : item.price.toString(), onChange: (price) => onChangeItemPrice(idx, Number(price)), currency: currency })), !item.isFreePrice && !item.product && '-', !item.isFreePrice &&
                !!item.product &&
                money({
                    input: item.price,
                    withFraction: true,
                    removeFractionZeros: false,
                    currency,
                })] })));
};
