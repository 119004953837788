import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { ACCESS_OPTIONS } from '../constants';
import styles from '../styles.module.scss';
export const ToolsSections = memo(({ isView, longListItems, handleSetValue }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { t } = useTranslation();
    const { control, formState: { errors }, setValue, watch, } = useFormContext();
    const giftCards = watch('GIFTCARDS');
    const isGiftCardsInstanceManageDisabled = giftCards !== ACCESS_OPTIONS.EDITING;
    useEffect(() => {
        if (isGiftCardsInstanceManageDisabled)
            setValue('GIFTCARDS_INSTANCES_MANAGE', false);
    }, [isGiftCardsInstanceManageDisabled]);
    return (_jsx("div", Object.assign({ id: 'tools' }, { children: _jsxs(FormFieldset, Object.assign({ fieldsetClassName: styles.fieldset, titleClassName: styles.fieldsetTitle, title: t('Инструменты') }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Цепочки'), labelFor: 'FLOWS', controlSize: '154', help: (_a = errors.FLOWS) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.FLOWS }, { children: _jsx(Controller, { name: 'FLOWS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'FLOWS', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('FLOWS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Ручн. рассылки'), labelFor: 'MANUAL_MAILINGS', controlSize: '154', help: (_b = errors.MANUAL_MAILINGS) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.MANUAL_MAILINGS }, { children: _jsx(Controller, { name: 'MANUAL_MAILINGS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'MANUAL_MAILINGS', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('MANUAL_MAILINGS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Авт. рассылки'), labelFor: 'AUTO_MAILINGS', help: (_c = errors.AUTO_MAILINGS) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.MANUAL_MAILINGS, controlSize: '154' }, { children: _jsx(Controller, { name: 'AUTO_MAILINGS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'AUTO_MAILINGS', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('AUTO_MAILINGS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Карты Wallet'), labelFor: 'WALLET_CARDS', help: (_d = errors.WALLET_CARDS) === null || _d === void 0 ? void 0 : _d.message, hasError: !!errors.WALLET_CARDS, controlSize: '154' }, { children: _jsx(Controller, { name: 'WALLET_CARDS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'WALLET_CARDS', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('WALLET_CARDS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Акции'), labelFor: 'OFFERS', help: (_e = errors.OFFERS) === null || _e === void 0 ? void 0 : _e.message, hasError: !!errors.OFFERS, controlSize: '154' }, { children: _jsx(Controller, { name: 'OFFERS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'OFFERS', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('OFFERS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Промокоды и приведи друга'), labelFor: 'PROMOCODES', labelClassName: styles.labelPreLine, help: (_f = errors.PROMOCODES) === null || _f === void 0 ? void 0 : _f.message, hasError: !!errors.PROMOCODES, controlSize: '154' }, { children: _jsx(Controller, { name: 'PROMOCODES', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'PROMOCODES', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('PROMOCODES', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Арбитраж'), labelFor: 'ARBITRATION', labelClassName: styles.labelPreLine, tooltip: t('Доступ к приоритизации всех акций бренда, вне ограничений по точкам продаж'), tooltipPlacement: 'right', help: (_g = errors.ARBITRATION) === null || _g === void 0 ? void 0 : _g.message, hasError: !!errors.ARBITRATION, controlSize: '154' }, { children: _jsx(Controller, { name: 'ARBITRATION', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'ARBITRATION', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('ARBITRATION', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Подарочные карты'), labelFor: 'GIFTCARDS', help: (_h = errors.GIFTCARDS) === null || _h === void 0 ? void 0 : _h.message, hasError: !!errors.GIFTCARDS, controlSize: '154' }, { children: _jsx(Controller, { name: 'GIFTCARDS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'giftCards', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('GIFTCARDS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ controlSize: '360' }, { children: _jsx(Controller, { name: 'GIFTCARDS_INSTANCES_MANAGE', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, disabled: isView || giftCards !== ACCESS_OPTIONS.EDITING, onChange: (e) => {
                                setValue('GIFTCARDS_INSTANCES_MANAGE', e.target.checked, {
                                    shouldDirty: true,
                                });
                            } }, { children: t('Активация и блокировка') }))) }) }))] })) })));
});
