import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const ImportFormDescription = ({ importData, }) => {
    const { t } = useTranslation();
    const isClients = importData.type === 0;
    const isChildren = importData.type === 1;
    const isPurchases = importData.type === 2;
    const isGiftCards = importData.type === 3;
    const isPromocode = importData.type === 4;
    const isBonuses = importData.type === 5;
    const title = (_jsx("h2", Object.assign({ className: styles.descriptionTitle }, { children: t('Настройте соответствие полей') })));
    const noLoadHelp = (_jsx("li", { children: t('Чтобы не загружать столбец из файла, выберите пункт «не загружать»') }));
    const clientsHelp = (_jsxs("ul", Object.assign({ className: styles.descriptionList }, { children: [noLoadHelp, _jsx("li", { children: t('Определите хотя бы одно из обязательных полей: номер телефона, номер карты или внешний идентификатор') })] })));
    const childrenHelp = (_jsxs("ul", Object.assign({ className: styles.descriptionList }, { children: [noLoadHelp, _jsx("li", { children: t('Определите хотя бы одно из обязательных полей для родителя (номер телефона, номер карты или внешний идентификатор) и для каждого ребенка (имя или день рождения)') })] })));
    const bonusesHelp = clientsHelp;
    const giftCardsHelp = (_jsxs("ul", Object.assign({ className: styles.descriptionList }, { children: [noLoadHelp, _jsx("li", { children: t('Определите обязательное поле: уникальный код карты') })] })));
    const promocodeHelp = (_jsxs("ul", Object.assign({ className: styles.descriptionList }, { children: [noLoadHelp, _jsx("li", { children: t('Определите обязательное поле: уникальный код') })] })));
    const purchasesHelp = (_jsxs("ul", Object.assign({ className: styles.descriptionList }, { children: [noLoadHelp, _jsx("li", { children: t('Определите обязательное поле: уникальный ID, код торговой точки, дата продажи') }), _jsx("li", { children: t('Определите хотя бы одно из обязательных полей: цена за единицу, сумма строки или сумма чека') })] })));
    let help = null;
    if (isClients)
        help = clientsHelp;
    if (isChildren)
        help = childrenHelp;
    if (isPurchases)
        help = purchasesHelp;
    if (isGiftCards)
        help = giftCardsHelp;
    if (isPromocode)
        help = promocodeHelp;
    if (isBonuses)
        help = bonusesHelp;
    return help ? (_jsxs("div", Object.assign({ className: styles.description }, { children: [title, help] }))) : null;
};
