var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApplyBonusesInput } from '@/components/ApplyBonusesInput/ApplyBonusesInput';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteClients } from '@/components/Autocomplete/instances/AutocompleteClients';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { PromocodeInput } from '@/components/PromocodeInput/PromocodeInput';
import { PurchaseItemInput } from '@/components/PurchaseItemInput/PurchaseItemInput';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { useDebounce } from '@/hooks/useDebounce';
import { buildClientFullName } from '@/utils/getClientFullName';
import { addToLS, getFromLS } from '@/utils/LS';
import { money } from '@/utils/numbers';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import { FormPurchaseModalInfo } from './FormPurchaseModalInfo';
import styles from './styles.module.scss';
export const FormPurchase = memo(({ id, shops = [], txid, currency, calculation, setCalculation, setHasFormChanged, onChangeSubmitting, onChangeClient, onToggleItems, onAdded, onUpdated, getClients = () => __awaiter(void 0, void 0, void 0, function* () { return []; }), getProducts = () => __awaiter(void 0, void 0, void 0, function* () { return []; }), setPurchase = () => __awaiter(void 0, void 0, void 0, function* () { return undefined; }), confirmPurchaseTicket = () => __awaiter(void 0, void 0, void 0, function* () { return ''; }), updatePurchase = () => __awaiter(void 0, void 0, void 0, function* () { return ''; }), selectedPurchase = null, selectedClient = null, onError, handleDiscardPurchaseTicket, hasPriceInputLocked = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { t } = useTranslation();
    const isEdit = !!selectedPurchase;
    const shopIdFromLS = getFromLS({ key: 'lastPurchaseShopId' });
    const shopFromLS = shops.find((shop) => shop.id == shopIdFromLS);
    const { control, handleSubmit, formState: { errors, isSubmitted, dirtyFields, isSubmitting }, setValue, getValues, watch, } = useForm({
        defaultValues: {
            client: selectedPurchase ? selectedPurchase.client : selectedClient,
            executedAt: selectedPurchase
                ? moment(selectedPurchase.executedAt).format('DD.MM.YYYY')
                : moment().format('DD.MM.YYYY'),
            shopId: selectedPurchase
                ? selectedPurchase.shop.id
                : (shopFromLS === null || shopFromLS === void 0 ? void 0 : shopFromLS.id) || ((_a = shops[0]) === null || _a === void 0 ? void 0 : _a.id),
        },
    });
    const [calculationLoading, setCalculationLoading] = useState(false);
    const [showItems, setShowItems] = useState(false);
    const [items, setItems] = useState([{ qty: 1, price: 0 }]);
    const [promocodeError, setPromocodeError] = useState('');
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmitting &&
                (!!Object.keys(dirtyFields).length || items.length > 1));
    }, [dirtyFields, isSubmitting, items, setHasFormChanged]);
    const bonusesError = (_d = (_c = (_b = calculation === null || calculation === void 0 ? void 0 : calculation.calculationResult) === null || _b === void 0 ? void 0 : _b.bonuses) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.description;
    const maxToApplyBonuses = (_f = (_e = calculation === null || calculation === void 0 ? void 0 : calculation.calculationResult) === null || _e === void 0 ? void 0 : _e.bonuses) === null || _f === void 0 ? void 0 : _f.maxToApply;
    const maxBonuses = maxToApplyBonuses || 0;
    const [discountAmount, setDiscountAmount] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [bonusesCollected, setBonusesCollected] = useState(0);
    const client = watch('client');
    const amount = watch('amount');
    const shopId = watch('shopId');
    const executedAt = watch('executedAt');
    const resetApplyBonuses = () => {
        setValue('apply', 0);
        setValue('applyBonuses', undefined);
    };
    const getTotalAmount = () => {
        const values = getValues();
        let totalAmount = 0;
        if (showItems)
            for (let i = 0; i < items.length; i++) {
                if (!items[i].product)
                    continue;
                totalAmount += items[i].qty * items[i].price;
            }
        else
            totalAmount = values.amount || 0;
        return totalAmount < 0 ? 0 : totalAmount;
    };
    const buildSetPurchasePayload = () => {
        const values = getValues();
        if (!values.client)
            return;
        return {
            clientId: values.client.id,
            shopId: values.shopId || 0,
            txid,
            executedAt: values.executedAt,
            amount: getTotalAmount(),
            applyBonuses: values.apply ? values.applyBonuses || maxBonuses : 0,
            promocode: values.promocode || undefined,
            rows: showItems
                ? items
                    .filter((i) => i.product && i.qty > 0)
                    .map((i) => {
                    var _a;
                    return ({
                        productId: ((_a = i.product) === null || _a === void 0 ? void 0 : _a.id) || 0,
                        qty: i.qty,
                        price: hasPriceInputLocked && !i.isFreePrice ? undefined : i.price,
                    });
                })
                : [],
        };
    };
    const calculatePurchase = () => __awaiter(void 0, void 0, void 0, function* () {
        var _l, _m, _o, _p, _q, _r;
        if (selectedPurchase)
            return;
        const payload = buildSetPurchasePayload();
        if (!payload)
            return;
        setCalculationLoading(true);
        const result = yield setPurchase(payload);
        setCalculation(result);
        setCalculationLoading(false);
        if (!result)
            return;
        if ((_m = (_l = result.calculationResult) === null || _l === void 0 ? void 0 : _l.promocode) === null || _m === void 0 ? void 0 : _m.error) {
            setValue('promocode', '');
            setPromocodeError(result.calculationResult.promocode.error.description || '');
        }
        if (result.processingException) {
            onError(result.processingException.descriptionRus);
            return;
        }
        const discountAmount = ((_p = (_o = result.calculationResult) === null || _o === void 0 ? void 0 : _o.summary) === null || _p === void 0 ? void 0 : _p.totalDiscount) || 0;
        setDiscountAmount(discountAmount);
        setRemainingAmount(getTotalAmount() - discountAmount);
        setBonusesCollected(((_r = (_q = result.calculationResult) === null || _q === void 0 ? void 0 : _q.bonuses) === null || _r === void 0 ? void 0 : _r.collected) || 0);
    });
    useEffect(() => {
        if (onChangeSubmitting)
            onChangeSubmitting(calculationLoading);
    }, [calculationLoading]);
    useEffect(() => {
        if (onChangeClient)
            onChangeClient(client);
        resetApplyBonuses();
        calculatePurchase();
    }, [client === null || client === void 0 ? void 0 : client.id, shopId, executedAt]);
    useEffect(() => {
        resetApplyBonuses();
        calculatePurchase();
        if (onToggleItems)
            onToggleItems(showItems);
    }, [showItems]);
    useEffect(() => {
        if (showItems) {
            resetApplyBonuses();
            calculatePurchase();
        }
    }, [JSON.stringify(items)]);
    useDebounce({
        value: amount,
        callback: () => {
            resetApplyBonuses();
            calculatePurchase();
        },
    });
    const handleAddPurchase = () => __awaiter(void 0, void 0, void 0, function* () {
        if (calculation === null || calculation === void 0 ? void 0 : calculation.ticket) {
            onChangeSubmitting === null || onChangeSubmitting === void 0 ? void 0 : onChangeSubmitting(true);
            const error = yield confirmPurchaseTicket({
                ticket: calculation.ticket,
                txid,
            });
            onChangeSubmitting === null || onChangeSubmitting === void 0 ? void 0 : onChangeSubmitting(false);
            if (error)
                onError(error);
            else {
                const values = getValues();
                addToLS({ key: 'lastPurchaseShopId', value: values.shopId });
                onAdded === null || onAdded === void 0 ? void 0 : onAdded();
                handleDiscardPurchaseTicket();
            }
        }
    });
    const handleUpdatePurchase = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedPurchase) {
            const values = getValues();
            onChangeSubmitting === null || onChangeSubmitting === void 0 ? void 0 : onChangeSubmitting(true);
            const error = yield updatePurchase({
                purchaseId: selectedPurchase.id,
                executedAt: values.executedAt,
                shopId: values.shopId || 0,
            });
            onChangeSubmitting === null || onChangeSubmitting === void 0 ? void 0 : onChangeSubmitting(false);
            if (error)
                onError(error);
            else {
                const values = getValues();
                addToLS({ key: 'lastPurchaseShopId', value: values.shopId });
                onUpdated === null || onUpdated === void 0 ? void 0 : onUpdated();
            }
        }
    });
    const labelWidth = showItems ? 25.35 : 27.28;
    return (_jsxs("form", Object.assign({ id: id, onSubmit: handleSubmit(() => {
            if (!isEdit)
                handleAddPurchase();
            else
                handleUpdatePurchase();
        }) }, { children: [_jsxs(FormFieldRow, Object.assign({ label: t('Для клиента'), labelFor: 'client', labelWidth: labelWidth, controlSize: !isEdit ? '400' : undefined, alignItems: isEdit ? 'center' : undefined, help: (_g = errors.client) === null || _g === void 0 ? void 0 : _g.message, hasError: !!errors.client, isRequired: true }, { children: [!isEdit && (_jsx(Controller, { name: 'client', control: control, rules: {
                            required: {
                                value: true,
                                message: t('FIELD_REQUIRED_ERROR'),
                            },
                        }, render: ({ field }) => (_jsx(AutocompleteClients, { id: 'client', value: field.value, onChange: (value) => setValue('client', value, {
                                shouldValidate: isSubmitted,
                                shouldDirty: true,
                            }), getClients: getClients, hasError: !!errors.client, shouldLoadInitialData: true })) })), isEdit && client && (_jsxs("span", Object.assign({ className: styles.text }, { children: [buildClientFullName(client), client.phoneNumber
                                ? `, ${formatPhoneNumber(client.phoneNumber)}`
                                : ''] })))] })), _jsx(FormFieldRow, Object.assign({ label: t('Место покупки'), labelFor: 'shopId', labelWidth: labelWidth, controlSize: '400', help: (_h = errors.shopId) === null || _h === void 0 ? void 0 : _h.message, hasError: !!errors.shopId, isRequired: true }, { children: _jsx(Controller, { name: 'shopId', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'shopId', placeholder: t('выберите место покупки'), value: shops.find((shop) => shop.id === field.value) || null, onChange: (shop) => {
                            setValue('shopId', (shop === null || shop === void 0 ? void 0 : shop.id) || null, {
                                shouldValidate: isSubmitted,
                                shouldDirty: true,
                            });
                        }, options: shops, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasError: !!errors.shopId, isLarge: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Дата покупки'), labelFor: 'executedAt', labelWidth: labelWidth, controlSize: '150', help: (_j = errors.executedAt) === null || _j === void 0 ? void 0 : _j.message, hasError: !!errors.executedAt, isRequired: true }, { children: _jsx(Controller, { name: 'executedAt', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(DateInput, { id: 'executedAt', selected: field.value
                            ? moment(field.value, 'DD.MM.YYYY').toDate()
                            : null, onChange: (date) => {
                            setValue('executedAt', date ? moment(date).format('DD.MM.YYYY') : '', { shouldValidate: isSubmitted, shouldDirty: true });
                        }, hasError: !!errors.executedAt, endYear: 2040 })) }) })), !isEdit && (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, controlSize: '360', alignItems: 'center' }, { children: _jsx(RadioGroup, { name: 'showItems', value: showItems ? 1 : 0, onChange: (value) => setShowItems(!!value), items: [
                        { value: 0, label: t('Указать сумму') },
                        { value: 1, label: t('Указать состав покупки') },
                    ] }) }))), !isEdit && !showItems && (_jsx(FormFieldRow, Object.assign({ label: t('Сумма'), labelFor: 'amount', labelWidth: labelWidth, controlSize: '200', help: (_k = errors.amount) === null || _k === void 0 ? void 0 : _k.message, hasError: !!errors.amount, isRequired: true }, { children: _jsx(Controller, { name: 'amount', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        min: {
                            value: 0.01,
                            message: t('MIN_ERROR_MESSAGE', {
                                amount: money({
                                    input: 0.01,
                                    withFraction: true,
                                    currency,
                                }),
                            }),
                        },
                        max: {
                            value: 99999999,
                            message: t('MAX_ERROR_MESSAGE', {
                                amount: money({
                                    input: 99999999,
                                    currency,
                                }),
                            }),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'amount', placeholder: t('введите сумму'), rightText: currency, type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.amount, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true }))) }) }))), !isEdit && showItems && (_jsx(FormFieldRow, Object.assign({ className: styles.purchaseItemsRow, controlClassName: styles.purchaseItemsRowControl, labelWidth: labelWidth }, { children: _jsx(PurchaseItemInput, { items: items, onChangeItems: setItems, getProducts: (searchText) => getProducts({
                        shopId: shopId || 0,
                        searchText,
                        limit: 10,
                        offset: 0,
                    }), hasPriceInputLocked: hasPriceInputLocked, currency: currency }) }))), !isEdit && (_jsx(FormFieldRow, Object.assign({ label: t('Промокод'), labelFor: 'promocode', labelWidth: labelWidth, controlSize: '250', help: t(promocodeError), hasError: !!promocodeError }, { children: _jsx(Controller, { name: 'promocode', control: control, render: ({ field }) => (_jsx(PromocodeInput, { id: 'promocode', promocode: field.value, onChangePromocode: (promocode) => {
                            setValue('promocode', promocode, { shouldDirty: true });
                            setPromocodeError('');
                            resetApplyBonuses();
                            calculatePurchase();
                        }, onCancel: () => {
                            setValue('promocode', '');
                            setPromocodeError('');
                            resetApplyBonuses();
                            calculatePurchase();
                        }, isDisabled: !client || calculationLoading, hasError: !!promocodeError })) }) }))), !isEdit && (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, alignItems: 'center', help: bonusesError, hasError: !!bonusesError, asCheckbox: true }, { children: _jsx(Controller, { name: 'apply', control: control, render: ({ field: applyField }) => (_jsx(Controller, { name: 'applyBonuses', control: control, render: ({ field: applyBonusesField }) => (_jsx(ApplyBonusesInput, { isChecked: applyField.value === 1, onChange: (checked) => {
                                setValue('apply', checked ? 1 : 0, {
                                    shouldDirty: true,
                                });
                                calculatePurchase();
                            }, bonuses: applyBonusesField.value || 0, onChangeBonuses: (bonuses) => {
                                setValue('applyBonuses', bonuses, { shouldDirty: true });
                                calculatePurchase();
                            }, maxBonuses: maxBonuses, availableBonuses: (client === null || client === void 0 ? void 0 : client.bonuses) || 0, isDisabled: maxBonuses <= 0, hasError: !!bonusesError })) })) }) }))), _jsx("div", { className: styles.divider }), _jsx(FormPurchaseModalInfo, { selectedPurchase: selectedPurchase, currency: currency, showItems: showItems, calculationLoading: calculationLoading, totalAmount: getTotalAmount(), discountAmount: discountAmount, remainingAmount: remainingAmount, bonusesCollected: bonusesCollected, labelWidth: labelWidth })] })));
});
