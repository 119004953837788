import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '~/src/components/HelpTooltip2/HelpTooltip';
import { ChooseOfferPosition } from './ChooseOfferPosition';
import { CurrentGroupSlot } from './CurrentGroupSlot';
import styles from './styles.module.scss';
const NEW_OFFER_GUID = 'new-offer-guid';
export const FormOfferPriorityTab = ({ currency, offerTitle, offersList, priority, currentGroup, arbitrationPriorityGuid, offerInsideList, options, setOfferInsideList, setOffersList, setCurrentGroup, }) => {
    const { t } = useTranslation();
    const isGroupsExist = options.length > 0;
    const newOffer = {
        offerTitle,
        newOffer: true,
        guid: arbitrationPriorityGuid || NEW_OFFER_GUID,
        kind: 'offer',
        priority: 0,
        offerId: 0,
        offerDiscountType: 1,
        offerHasCounter: false,
    };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: styles.priorityTabContent }, { children: [_jsx(HelpTooltip, Object.assign({ placement: 'right', html: t('TOOLTIP_ARBITRATION_OFFER_HINT') }, { children: _jsx("span", Object.assign({ className: styles.priorytyTabTitle }, { children: t('Приоритет акций среди других') })) })), _jsx(CurrentGroupSlot, { newOffer: newOffer, offerTitle: offerTitle, arbitrationPriorityGuid: arbitrationPriorityGuid, offerInsideList: offerInsideList, isGroupsExist: isGroupsExist, setOfferInsideList: setOfferInsideList, setOffersList: setOffersList }), _jsx(ChooseOfferPosition, { newOffer: newOffer, currency: currency, isGroupsExist: isGroupsExist, offerTitle: offerTitle, offersList: offersList, priority: priority, currentGroup: currentGroup, arbitrationPriorityGuid: arbitrationPriorityGuid, offerInsideList: offerInsideList, options: options, setOfferInsideList: setOfferInsideList, setOffersList: setOffersList, setCurrentGroup: setCurrentGroup })] })) }));
};
