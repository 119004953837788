import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Promocode } from '@/components/Promocode/Promocode';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_SHARED } from '@/const/promocode';
import { Archive } from '@/icons/Archive';
import { Drag } from '@/icons/Drag';
import { ExternalLink } from '@/icons/nav/ExternalLink';
import { Pause } from '@/icons/status/Pause';
import { Plus } from '../ArbitrationOperators/Plus';
import { ArbitrationGroupItemIcons } from './ArbitrationGroupItemIcons';
import { DragPreview } from './dnd/DragPreview';
import { useDraggableTreeItem } from './dnd/useDraggableTreeItem';
import { Line } from './helpers/Line';
import { Operator } from './helpers/Operator';
import styles from './styles.module.scss';
export const ArbitrationGroupItem = ({ className, serialNumber, priority, parent, renderGroup, level = 0, levelGap = 0, isLast = false, isTopLevel = false, currency, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const isUnordered = (parent === null || parent === void 0 ? void 0 : parent.guid) === 'unordered';
    const ITEM_INITIAL_GAP = isUnordered ? 55 : 73;
    const ITEM_TOP_LEVEL_INITIAL_GAP = 40;
    const paddingLeft = isTopLevel
        ? ITEM_TOP_LEVEL_INITIAL_GAP
        : ITEM_INITIAL_GAP + levelGap;
    const levelGapVariable = { '--level-gap': levelGap + 'px' };
    const { ref, draggableState } = useDraggableTreeItem(priority, parent);
    const lineAndOperator = !isLast && (_jsxs(_Fragment, { children: [_jsx(Line, { initialGap: isUnordered ? 40 : undefined, levelGap: levelGap }), !isUnordered && (_jsx(Operator, { operator: (parent === null || parent === void 0 ? void 0 : parent.type) || 'sum', levelGap: levelGap }))] }));
    const archiveIcon = (_jsx(Tooltip, Object.assign({ title: t('В архиве'), placement: 'top' }, { children: _jsx("span", Object.assign({ className: styles.statusIcon }, { children: _jsx(Archive, {}) })) })));
    const pauseIcon = (_jsx(Tooltip, Object.assign({ title: priority.kind === 'promocode' ? t('Остановлен') : t('Отключена'), placement: 'top' }, { children: _jsx("span", Object.assign({ className: classNames(styles.statusIcon, styles.pauseIcon) }, { children: _jsx(Pause, {}) })) })));
    let link = undefined;
    let draggableContent = null;
    let draggableId = '';
    let previewContent = null;
    if (priority.kind === 'promocode' &&
        priority.promocodeId !== undefined &&
        priority.promocodeCode !== undefined) {
        draggableId = 'promocode:' + priority.promocodeId;
        draggableContent = (_jsxs("div", Object.assign({ className: classNames('drag-preview-content', styles.promocodeWrapper, {
                [styles.muted]: priority.isArchived,
            }) }, { children: [serialNumber !== undefined ? serialNumber + '. ' : null, _jsx(Promocode, { code: priority.promocodeCode, isPersonal: priority.promocodeType !== PROMOCODE_TYPE_SHARED }), priority.isActive === false && pauseIcon, !!priority.isArchived && archiveIcon] })));
        link =
            priority.promocodeType === PROMOCODE_TYPE_FRIEND
                ? generatePath(ROUTES.PROMOCODES_FRIEND_EDIT, {
                    promocodeId: priority.promocodeId,
                })
                : generatePath(ROUTES.PROMOCODES_EDIT, {
                    promocodeId: priority.promocodeId,
                });
        if (!userHasAccessTo('PROMOCODES_MANAGE'))
            link = '';
    }
    if (priority.kind === 'offer' &&
        priority.offerId !== undefined &&
        priority.offerTitle !== undefined) {
        draggableId = 'offer:' + priority.offerId;
        draggableContent = (_jsxs("div", Object.assign({ className: classNames('drag-preview-content', styles.offerWrapper, {
                [styles.muted]: priority.isArchived,
            }) }, { children: [_jsx(ArbitrationGroupItemIcons, { priority: priority, currency: currency }), _jsxs("div", { children: [serialNumber !== undefined ? serialNumber + '. ' : null, priority.offerTitle] }), priority.isActive === false && pauseIcon, !!priority.isArchived && archiveIcon] })));
        link = generatePath(ROUTES.OFFERS_EDIT, { offerId: priority.offerId });
        if (!userHasAccessTo('OFFERS_MANAGE'))
            link = '';
    }
    if (priority.kind === 'group' && priority.arbitrationGroup !== undefined) {
        draggableId = 'group:' + priority.arbitrationGroup.guid;
        draggableContent = renderGroup(priority.arbitrationGroup, draggableState.type === 'is-dragging');
        previewContent = priority.arbitrationGroup.name;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ ref: ref, "data-priority-id": draggableId, "data-priority-level": level, "data-priority-top-level": isTopLevel, "data-priority-last": isLast, className: classNames(className, {
                    [styles.item]: priority.kind === 'offer' || priority.kind === 'promocode',
                    [styles.itemGroupWrapper]: priority.kind === 'group',
                    [styles.dragging]: draggableState.type === 'is-dragging',
                    [styles.topLevel]: isTopLevel,
                }), style: priority.kind === 'offer' || priority.kind === 'promocode'
                    ? Object.assign({ paddingLeft }, levelGapVariable) : Object.assign({}, levelGapVariable) }, { children: [(priority.kind === 'offer' || priority.kind === 'promocode') && (_jsx("div", Object.assign({ className: classNames(styles.dragHandle, {
                            [styles.sum]: (parent === null || parent === void 0 ? void 0 : parent.type) === 'sum',
                            [styles.max]: (parent === null || parent === void 0 ? void 0 : parent.type) === 'max',
                            [styles.priority]: (parent === null || parent === void 0 ? void 0 : parent.type) === 'priority',
                            [styles.unordered]: isUnordered,
                        }) }, { children: _jsx(Drag, {}) }))), draggableContent, !!link && (_jsx(Link, Object.assign({ className: styles.itemLink, to: link, target: '_blank', onClick: (e) => e.stopPropagation() }, { children: _jsx(ExternalLink, {}) }))), !isTopLevel ? (lineAndOperator) : !isLast ? (_jsx(Plus, { className: styles.topLevelOperator })) : null] })), draggableState.type === 'is-dragging'
                ? createPortal(_jsx(DragPreview, Object.assign({ className: styles.dragPreview, x: draggableState.x, y: draggableState.y }, { children: previewContent || draggableContent })), document.body)
                : null] }));
};
