import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getRFMSegmentOptionsSelector, getSegmentOptionsSelector, } from '@/store/selectors/filters';
import { getRFMSegmentOptionsThunk, getSegmentOptionsThunk, } from '@/store/thunk/filters';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
export const useEventsFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { brand } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const segments = useAppSelector(getSegmentOptionsSelector);
    const rfmSegments = useAppSelector(getRFMSegmentOptionsSelector);
    const defaultItems = [
        {
            type: 'branch',
            id: 'segments',
            label: t('Сегменты'),
            items: [
                {
                    type: 'leaf',
                    id: 'clientSegments',
                    label: t('REGULAR_SEGMENTS'),
                    filterTitle: t('Сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getSegmentOptionsThunk()),
                    options: segments,
                    useBothOperations: true,
                },
                {
                    type: 'leaf',
                    id: 'clientRfmSegments',
                    label: 'RFM',
                    filterTitle: t('RFM сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
                    options: rfmSegments,
                    useBothOperations: true,
                    locked: !isRFMEnabled,
                },
            ],
        },
    ];
    return defaultItems;
};
