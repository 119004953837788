var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { DatePeriodSelect } from '@/components/DatePeriodSelect/DatePeriodSelect';
import { goalDescriptions } from '@/components/MailingStatsTable/goalDescriptions';
import { MailingStatsTable, } from '@/components/MailingStatsTable/MailingStatsTable';
import { triggerDescriptions } from '@/components/MailingStatsTable/triggerDescriptions';
import { Modal } from '@/components/Modal/Modal';
import { Download } from '@/icons/Download';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './styles.module.scss';
import { useExportMailingStats } from './useExportMailingStats';
export const MailingStatsModal = memo((_a) => {
    var { isOpen, onClose, periodStart, periodEnd, onChangePeriod } = _a, props = __rest(_a, ["isOpen", "onClose", "periodStart", "periodEnd", "onChangePeriod"]);
    const { mailing } = props;
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasExportManage = userHasAccessTo('EXPORTS_MANAGE');
    const handleChangePeriod = useCallback((start, end) => {
        onChangePeriod(start && moment(start).startOf('day').toDate(), end && moment(end).endOf('day').toDate());
    }, [onChangePeriod]);
    const exportReport = useExportMailingStats({
        id: mailing.id,
        periodStart: moment(periodStart).format(),
        periodEnd: moment(periodEnd).format(),
    });
    return (_jsx(Modal, Object.assign({ isOnMountFocus: true, className: styles.modal, isOpen: isOpen, onClose: onClose, titleClassName: styles.title, title: t('MAILING_MODAL_HEADER', { name: mailing.name }), titleTooltip: _jsxs("ul", Object.assign({ className: styles.help }, { children: [_jsxs("li", { children: [_jsx("span", { children: t('Триггер:') }), ' ', t(triggerDescriptions[mailing.triggerType])] }), _jsxs("li", { children: [_jsx("span", { children: t('Цель:') }), " ", t(goalDescriptions[mailing.goalType])] })] })), titleRightElement: _jsxs("div", Object.assign({ className: styles.titleRightElement }, { children: [_jsx(DatePeriodSelect, { className: styles.period, start: periodStart, end: periodEnd, onChange: handleChangePeriod }), _jsx(Tooltip, Object.assign({ title: !userHasExportManage && t('Недоступно для вашей роли') }, { children: _jsxs(Button, Object.assign({ disabled: !userHasExportManage, onClick: exportReport, className: styles.downloadButton }, { children: ["XLS ", _jsx(Download, {})] })) }))] })), bodyClassName: styles.body, hasNoPadding: true }, { children: _jsx(MailingStatsTable, Object.assign({}, props, { mailingGoalType: mailing.goalType })) })));
});
