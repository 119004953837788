import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
export const EmailReceivedFormTitle = () => {
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    let header = '';
    switch (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.type) {
        case 'opened': {
            header = 'Открыл письмо';
            break;
        }
        case 'clicked': {
            header = 'Перешел по ссылке';
            break;
        }
        case 'purchased': {
            header = 'Сделал покупку';
            break;
        }
    }
    return _jsxs(_Fragment, { children: [t(header), "?"] });
};
