import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { Lock } from '@/icons/Lock';
import { RFMValueTable } from '../RFMValueTable/RFMValueTable';
import styles from './styles.module.scss';
export const RFMThresholdPanel = ({ recency, frequency, monetary, isLocked, currency, }) => {
    const { t } = useTranslation();
    const icon = isLocked ? _jsx(Lock, { className: styles.lock }) : null;
    return (_jsx(StatsPanel, Object.assign({ className: styles.wrapper, titleClassName: styles.title, title: _jsxs(_Fragment, { children: [icon, _jsx(HelpTooltip, Object.assign({ tooltipClassName: styles.tooltip, title: _jsxs(Trans, Object.assign({ i18nKey: 'RFM_THRESHOLDS_TOOLTIP' }, { children: ["\u0410\u0432\u0442\u043E\u043F\u043E\u0440\u043E\u0433\u0438 \u043E\u0431\u043D\u043E\u0432\u043B\u044F\u044E\u0442\u0441\u044F \u0440\u0430\u0437 \u0432 \u043C\u0435\u0441\u044F\u0446.", ' ', _jsx("a", Object.assign({ href: 'https://help.maxma.com/p/I_0Fd1WUZ6_6cf/smart-RFM', target: '_blank', rel: 'noreferrer' }, { children: "\u041F\u0440\u043E \u0440\u0430\u0441\u0447\u0435\u0442 \u043F\u043E\u0440\u043E\u0433\u043E\u0432 \u0432 \u0431\u0430\u0437\u0435 \u0437\u043D\u0430\u043D\u0438\u0439" }))] })), placement: 'right', size: 'medium', hasLightIcon: true }, { children: t('Автопороги') }))] }) }, { children: _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsx("div", Object.assign({ className: styles.table }, { children: _jsx(RFMValueTable, { title: t('R, дней назад'), groups: recency.map((item) => (Object.assign(Object.assign({}, item), { scale: item.scale || 'days' }))), currency: currency, isLocked: isLocked }) })), _jsx("div", Object.assign({ className: styles.table }, { children: _jsx(RFMValueTable, { title: t('F, покупки'), groups: frequency.map((item) => (Object.assign(Object.assign({}, item), { scale: item.scale || 'purchases' }))), currency: currency, isLocked: isLocked }) })), _jsx("div", Object.assign({ className: styles.table }, { children: _jsx(RFMValueTable, { title: t('M, сумма'), groups: monetary.map((item) => (Object.assign(Object.assign({}, item), { scale: item.scale || 'money' }))), currency: currency, isLocked: isLocked }) }))] })) })));
};
