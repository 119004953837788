import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { ImageCropInput, } from '@/components/ImageCropInput/ImageCropInput';
import { useUploadImage } from '@/hooks/useUploadImage';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const PushFormImage = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const uploadImage = useUploadImage();
    const [error, setError] = useState('');
    const resetImage = () => {
        selectedNode.update({
            push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { walletImage: undefined, walletImageId: undefined, walletImageFile: undefined }),
        });
    };
    const saveImage = (file, uuid, data) => {
        uploadImage(file).then((response) => {
            selectedNode.update({
                push: Object.assign(Object.assign({}, data), { walletImage: response, walletImageId: response === null || response === void 0 ? void 0 : response.id, walletImageFile: file }),
            }, uuid);
        });
    };
    const handleUpdatePushImage = (file) => {
        setError('');
        if (!file) {
            resetImage();
            return;
        }
        saveImage(file, selectedNode.uuid, Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push));
    };
    const handleResetPushImage = (reason) => {
        setError('');
        const message = rejectUploadReason(reason, '', '1125×433', true);
        if (message)
            setError(t(message));
        resetImage();
    };
    return (_jsxs("div", { children: [_jsx("label", Object.assign({ className: formStyles.label, htmlFor: 'channel-form-wallet-image' }, { children: t('Картинка на Wallet-карте') })), _jsx(ImageCropInput, { id: 'channel-form-wallet-image', value: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.walletImageFile) ||
                    ((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _b === void 0 ? void 0 : _b.walletImage) ||
                    null, onChange: handleUpdatePushImage, onReject: handleResetPushImage, previewWidth: 326, previewHeight: 122, description: 'PNG, ' + t('от') + ' ' + t('1125×433, до 10мб'), constraints: {
                    mimeType: 'image/png',
                    extensions: ['.png'],
                    maxSize: 10000000,
                    dimensions: { width: 1125, height: 433 },
                }, outputMimeType: 'png', hasError: !!error, cropModalTitle: t('Картинка на Wallet-карте'), theme: 'outlined', isDisabled: !isEditMode }), _jsx("div", Object.assign({ className: formStyles.error }, { children: error }))] }));
};
