import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { FormGiftCardGenerateModal } from '@/forms/FormGiftCardGenerate/FormGiftCardGenerateModal';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
import { useLastListState } from '~/src/hooks/useLastListState';
import { ROUTES } from '../routes';
import { GiftCardsPanel } from './GiftCardsPanel';
import { useData } from './useData';
import { useDeleteGiftCard } from './useDeleteGiftCard';
import { useGenerateGiftCards } from './useGenerateGiftCards';
export const GiftCards = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const limit = 100;
    const [offset, setOffset] = useState(0);
    const [selectedToExportCard, setSelectedToExportCard] = useState(null);
    const [searchText, setSearchText] = useState('');
    const { handleCleanData } = useLastListState();
    const handleOpenModal = useCallback((card) => {
        setSelectedToExportCard(card);
    }, []);
    const handleCloseModal = useCallback(() => {
        setSelectedToExportCard(null);
    }, []);
    const handleApplySearch = useCallback((searchText) => {
        setOffset(0);
        setSearchText(searchText);
    }, []);
    const { brand } = useCurrentUser();
    const { count, data, isLoading, isFetching } = useData({
        searchText,
        periodStart,
        periodEnd,
        limit,
        offset,
    });
    const deleteCard = useDeleteGiftCard({
        onSuccess: () => setOffset(0),
    });
    const { generateGiftCards, isSubmitting, error } = useGenerateGiftCards({
        onSuccess: () => navigate(ROUTES.EXPORTS),
    });
    if (!brand)
        return null;
    const currency = brand.brand.currency.symbol || brand.brand.currency.unitShort;
    return (_jsxs(Container, Object.assign({ notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, { leftElement: _jsx(SearchPanel, { name: 'searchText', placeholder: t('Название подарочной карты'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch }) }) }, { children: [_jsx(GiftCardsPanel, { count: count || 0, items: (data === null || data === void 0 ? void 0 : data.cards) || [], total: data === null || data === void 0 ? void 0 : data.total, isLoading: isLoading, isFetching: isFetching, limit: limit, offset: offset, onChangeOffset: (offset) => {
                    setOffset(offset);
                    handleCleanData();
                }, currency: currency, isProfitVisible: brand.brand.showProfit, onDeleteCard: (card) => deleteCard(card.id, card.name), onExportCard: handleOpenModal, onGoToCreateForm: () => navigate(ROUTES.GIFT_CARDS_CREATE), onGoToEditForm: (giftCardId) => {
                    const path = generatePath(ROUTES.GIFT_CARDS_EDIT, {
                        giftCardId: giftCardId.toString(),
                    });
                    navigate(path);
                }, onGoToInstances: (giftCardId) => {
                    const path = generatePath(ROUTES.GIFT_CARDS_INSTANCES, {
                        giftCardId: giftCardId.toString(),
                    });
                    navigate(path);
                } }), !!selectedToExportCard && (_jsx(FormGiftCardGenerateModal, { isOpen: !!selectedToExportCard, onClose: handleCloseModal, card: selectedToExportCard, onSubmit: (payload) => generateGiftCards(Object.assign({ id: selectedToExportCard.id }, payload)), isSubmitting: isSubmitting, error: error, currency: currency }))] })));
};
