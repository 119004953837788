export const COLOR_MAP = {
    max: '#5083C1',
    sum: '#8641B0',
    priority: '#499E5C',
};
export const BACKGROUND_COLOR_MAP = {
    max: 'rgb(230, 239, 249)',
    sum: 'rgb(241, 232, 244)',
    priority: 'rgb(226, 243, 229)',
};
