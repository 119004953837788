import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { Gears } from '@/icons/Gears';
import styles from './GroupSettingsButton.module.scss';
export const GroupSettingsButton = forwardRef(({ className, isActive, isVisible, onClick }, ref) => {
    return (_jsx("button", Object.assign({ ref: ref, type: 'button', className: classNames(className, styles.groupSettingsButton, {
            [styles.active]: isActive,
            [styles.visible]: isVisible,
        }), onClick: onClick }, { children: _jsx(Gears, { size: 17, fill: 'currentColor' }) })));
});
