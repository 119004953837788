import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { APPLY_ON_PURCHASE, APPLY_ON_SOME_UNITS, DISCOUNT_TYPE_FIXED, } from './constants';
import { FormOfferApplyOn } from './controls/FormOfferApplyOn';
import { FormOfferAvailableDates } from './controls/FormOfferAvailableDates';
import { FormOfferBans } from './controls/FormOfferBans';
import { FormOfferBonusCheckboxes } from './controls/FormOfferBonusCheckboxes/FormOfferBonusCheckboxes';
import { FormOfferCheapestOpted } from './controls/FormOfferCheapestOpted';
import { FormOfferConditions } from './controls/FormOfferConditions';
import { FormOfferConditionsProduct } from './controls/FormOfferConditionsProduct';
import { FormOfferEachN } from './controls/FormOfferEachN';
import { FormOfferGrowingDiscount } from './controls/FormOfferGrowingDiscount';
import { FormOfferItemConditions } from './controls/FormOfferItemConditions';
import { FormOfferLimit } from './controls/FormOfferLimit';
import { FormOfferNotMoreN } from './controls/FormOfferNotMoreN';
import { FormOfferRecipients } from './controls/FormOfferRecipients';
import { FormOfferSpreadDiscountOpted } from './controls/FormOfferSpreadDiscountOpted';
import { FormOfferTitle } from './controls/FormOfferTitle';
import { FormOfferType } from './controls/FormOfferType';
import { FormOfferWeekdays } from './controls/FormOfferWeekdays';
const labelWidth = 27.395;
export const FormOfferGeneralTab = memo(({ currency, hasDiscountOfferFeature = false, clientExtraFields = [], counters = [], mailingOffers = [], getMailingLink, segments = [], }) => {
    const { t } = useTranslation();
    const { setValue, getValues } = useFormContext();
    const handleOfferTypeChange = (offerType) => {
        const { applyOn } = getValues();
        if (offerType === 'fixed_price')
            setValue('discountType', DISCOUNT_TYPE_FIXED);
        if (offerType === 'fixed_price' && applyOn === APPLY_ON_PURCHASE)
            setValue('applyOn', APPLY_ON_SOME_UNITS);
        if (offerType === 'growing_discount')
            setValue('applyOn', APPLY_ON_SOME_UNITS);
        if (offerType === 'free_product')
            setValue('applyOn', APPLY_ON_SOME_UNITS);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormFieldset, Object.assign({ title: t('Общее'), titleOffset: labelWidth }, { children: _jsx(FormOfferTitle, { labelWidth: labelWidth }) })), _jsx(FormFieldset, Object.assign({ title: t('Участники') + '*', titleOffset: labelWidth }, { children: _jsx(FormOfferRecipients, { extraFields: clientExtraFields, counters: counters, mailingOffers: mailingOffers, segments: segments, getMailingLink: getMailingLink, labelWidth: labelWidth }) })), _jsxs(FormFieldset, Object.assign({ title: t('Условия акции'), titleOffset: labelWidth }, { children: [_jsx(FormOfferConditionsProduct, { labelWidth: labelWidth }), _jsx(FormOfferConditions, { labelWidth: labelWidth }), _jsx(FormOfferWeekdays, { labelWidth: labelWidth })] })), _jsxs(FormFieldset, Object.assign({ title: t('Вознаграждение'), titleOffset: labelWidth }, { children: [_jsx(FormOfferType, { labelWidth: labelWidth, hasDiscountOfferFeature: hasDiscountOfferFeature, currency: currency, onOfferTypeChange: handleOfferTypeChange }), _jsx(FormOfferGrowingDiscount, { labelWidth: labelWidth, currency: currency }), _jsx(FormOfferBonusCheckboxes, { labelWidth: labelWidth }), _jsx(FormOfferApplyOn, { labelWidth: labelWidth }), _jsx(FormOfferItemConditions, { labelWidth: labelWidth }), _jsx(FormOfferEachN, { labelWidth: labelWidth }), _jsx(FormOfferNotMoreN, { labelWidth: labelWidth }), _jsx(FormOfferCheapestOpted, { labelWidth: labelWidth }), _jsx(FormOfferSpreadDiscountOpted, { labelWidth: labelWidth })] })), _jsxs(FormFieldset, Object.assign({ title: t('Ограничения акции'), titleOffset: labelWidth }, { children: [_jsx(FormOfferAvailableDates, { labelWidth: labelWidth }), _jsx(FormOfferLimit, { labelWidth: labelWidth }), _jsx(FormOfferBans, { currency: currency, labelWidth: labelWidth })] }))] }));
});
