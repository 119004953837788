import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const TableRowCell = ({ className, column, row, children, needAdaptive, isSubCell = false, needWrapper = true, colspan, onClick, }) => {
    const cellContent = (_jsxs(_Fragment, { children: [renderCellContent(children), needAdaptive && _jsx("div", { className: styles.gradientLeft })] }));
    return (_jsx("td", Object.assign({ className: classNames(className, {
            [styles.center]: (column === null || column === void 0 ? void 0 : column.align) === 'center',
            [styles.right]: (column === null || column === void 0 ? void 0 : column.align) === 'right',
            [styles.interactive]: !!onClick && !(row === null || row === void 0 ? void 0 : row.isTotal),
            [styles.total]: row === null || row === void 0 ? void 0 : row.isTotal,
            [styles.subCell]: isSubCell,
        }), onClick: onClick, colSpan: colspan }, { children: isSubCell && needWrapper ? (_jsx("div", { children: _jsx("div", { children: cellContent }) })) : (cellContent) })));
};
const renderCellContent = (cellContent) => {
    if (typeof cellContent === 'number')
        return numberRound(cellContent);
    if (cellContent instanceof Date)
        return moment(cellContent).format('DD.MM.YYYY HH:mm');
    return cellContent;
};
