import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterConditionsLarge } from '@/icons/flow/FilterConditionsLarge';
import { RepeatClock } from '@/icons/flow/RepeatClock';
import { StartAltLarge } from '@/icons/flow/StartAltLarge';
import { Timer } from '@/icons/flow/Timer';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { ChannelForm } from '../ChannelForm/ChannelForm';
import { ChannelFormGoal } from '../ChannelForm/ChannelFormGoal';
import { ChannelFormIcon } from '../ChannelForm/ChannelFormIcon';
import { ChannelFromStats } from '../ChannelForm/ChannelFormStats';
import { ChannelFormStatus } from '../ChannelForm/ChannelFormStatus';
import { ChannelFormTitle } from '../ChannelForm/ChannelFormTitle';
import { useChannelFormState } from '../ChannelForm/useChannelFormState';
import { ChannelReceivedForm } from '../ChannelReceivedForm/ChannelReceivedForm';
import { ChannelReceivedFormIcon } from '../ChannelReceivedForm/ChannelReceivedFormIcon';
import { ChannelReceivedFormTitle } from '../ChannelReceivedForm/ChannelReceivedFormTitle';
import { ClientFiltersForm } from '../ClientFiltersForm/ClientFiltersForm';
import { DelayForm } from '../DelayForm/DelayForm';
import { EmailReceivedForm } from '../EmailReceivedForm/EmailReceivedForm';
import { EmailReceivedFormIcon } from '../EmailReceivedForm/EmailReceivedFormIcon';
import { EmailReceivedFormTitle } from '../EmailReceivedForm/EmailReceivedFormTitle';
import { LoopForm } from '../LoopForm/LoopForm';
import { ScheduleForm } from '../ScheduleForm/ScheduleForm';
import { TriggerFiltersForm } from '../TriggerFiltersForm/TriggerFiltersForm';
export const useDrawerContent = () => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const isOpen = !!selectedNode.uuid;
    const close = useCallback((e) => {
        if ((e === null || e === void 0 ? void 0 : e.target) instanceof HTMLElement &&
            (e.target.closest('.react-flow__node') ||
                e.target.closest('.emoji-picker') ||
                e.target.closest('[data-confirm-dialog="true"]')))
            return;
        selectedNode.reset();
    }, [selectedNode.reset]);
    const closeOnEsc = useCallback((e) => {
        if (e.key === 'Escape')
            close();
    }, []);
    let icon;
    let title;
    let titleRightElement;
    let children;
    let bottomContent;
    let footer;
    let isLarge = false;
    let isWide = false;
    const isChannelForm = selectedNode.getType() === 'channel';
    const { isCascade } = useChannelFormState();
    const isChannelReceivedForm = selectedNode.getType() === 'channel-received';
    const isClientFiltersForm = selectedNode.getType() === 'conditions' &&
        ((_a = selectedNode.getData()) === null || _a === void 0 ? void 0 : _a.type) === 'filter';
    const isDelayForm = selectedNode.getType() === 'delay';
    const isEmailReceivedForm = selectedNode.getType() === 'email-received';
    const isLoopForm = selectedNode.getType() === 'loop';
    const isScheduleForm = selectedNode.getType() === 'conditions' &&
        ((_b = selectedNode.getData()) === null || _b === void 0 ? void 0 : _b.type) === 'schedule';
    const isTriggerFiltersForm = selectedNode.getType() === 'conditions' &&
        ((_c = selectedNode.getData()) === null || _c === void 0 ? void 0 : _c.type) === 'trigger';
    if (isChannelForm) {
        icon = _jsx(ChannelFormIcon, {});
        title = _jsx(ChannelFormTitle, {});
        titleRightElement = _jsx(ChannelFormStatus, {});
        children = _jsx(ChannelForm, {});
        bottomContent = !isEditMode ? _jsx(ChannelFromStats, {}) : undefined;
        footer = _jsx(ChannelFormGoal, {});
        isLarge = true;
        isWide = isCascade;
    }
    if (isChannelReceivedForm) {
        icon = _jsx(ChannelReceivedFormIcon, {});
        title = _jsx(ChannelReceivedFormTitle, {});
        children = _jsx(ChannelReceivedForm, {});
        isLarge = true;
    }
    if (isClientFiltersForm) {
        icon = _jsx(FilterConditionsLarge, {});
        title = t('Фильтр');
        children = _jsx(ClientFiltersForm, {});
        isLarge = true;
    }
    if (isDelayForm) {
        icon = _jsx(Timer, {});
        title = t('Ожидание');
        children = _jsx(DelayForm, {});
    }
    if (isEmailReceivedForm) {
        icon = _jsx(EmailReceivedFormIcon, {});
        title = _jsx(EmailReceivedFormTitle, {});
        children = _jsx(EmailReceivedForm, {});
        isLarge = true;
    }
    if (isLoopForm) {
        icon = _jsx(RepeatClock, {});
        title = t('Количество запусков');
        children = _jsx(LoopForm, {});
    }
    if (isScheduleForm) {
        icon = _jsx(StartAltLarge, {});
        title = t('Условие старта');
        children = _jsx(ScheduleForm, {});
        isLarge = true;
    }
    if (isTriggerFiltersForm) {
        icon = _jsx(StartAltLarge, {});
        title = t('Условие старта');
        children = _jsx(TriggerFiltersForm, {});
        isLarge = true;
    }
    return {
        selectedNode,
        isOpen,
        close,
        closeOnEsc,
        icon,
        title,
        titleRightElement,
        children,
        bottomContent,
        footer,
        isLarge,
        isWide,
    };
};
