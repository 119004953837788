import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useRFMSegments } from '../RFMData/useRFMSegments';
export const RFMRecommendationsItemText = ({ id }) => {
    const { t } = useTranslation();
    const segments = useRFMSegments();
    const segment = segments[id];
    const additionallyList = (_jsxs("ul", { children: [_jsxs("li", { children: [t('настройте автоматическую рассылку на день рождения клиента'), ";"] }), _jsx("li", { children: t('используйте ручные рекламные и информационные рассылки (новая коллекция, распродажи, информация о бренде и др.)') })] }));
    const additionallyBlock = (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('Дополнительно'), ":"] }), additionallyList] }));
    const excludeSegmentBlock = (_jsxs("p", { children: [t('Исключите сегмент из ручных рекламных и информационных рассылок'), "."] }));
    const rewardMessage = (rewardType, count) => (_jsx("p", { children: t('USE_REWARD_MESSAGE', {
            rewardType: t(rewardType),
            count,
        }) }));
    if (id === 'VIP')
        return additionallyList;
    if (id === 'potentialVIP')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 2 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('IN_THE_SEGMENT_WITH_REPEAT', {
                                    segmentName: segment.label,
                                    count: 30,
                                    countRepeat: 30,
                                }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('среднее', 7), additionallyBlock] }));
    if (id === 'loyal')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 2 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('MOVE_TO_THE_SEGMENT', { segmentName: segment.label }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('минимальное или среднее', 7), additionallyBlock] }));
    if (id === 'newcomers')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('Настройте welcome-рассылку'), ":"] }), _jsx("ul", { children: _jsxs("li", { children: [t('при регистрации клиента с приветствием от бренда и подарочным начислением'), "."] }) }), _jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 2 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('IN_THE_SEGMENT_WITH_REPEAT', {
                                    segmentName: segment.label,
                                    count: 30,
                                    countRepeat: 30,
                                }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('минимальное', 7), additionallyBlock] }));
    if (id === 'leavingVIP')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 3 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('MOVE_TO_THE_SEGMENT', { segmentName: segment.label }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT_WITH_REPEAT', {
                                    segmentName: segment.label,
                                    count: 30,
                                    countRepeat: 30,
                                }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('крупное', 7), additionallyBlock] }));
    if (id === 'potentialLoyal')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 3 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('MOVE_TO_THE_SEGMENT', { segmentName: segment.label }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT_WITH_REPEAT', {
                                    segmentName: segment.label,
                                    count: 30,
                                    countRepeat: 30,
                                }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('среднее или крупное', 7), additionallyBlock] }));
    if (id === 'outflowPossible')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 4 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('MOVE_TO_THE_SEGMENT', { segmentName: segment.label }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT', { segmentName: segment.label, count: 30 }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT', { segmentName: segment.label, count: 60 }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('среднее', 14), additionallyBlock] }));
    if (id === 'outflowVIP')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 4 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('MOVE_TO_THE_SEGMENT', { segmentName: segment.label }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT', { segmentName: segment.label, count: 30 }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT', { segmentName: segment.label, count: 60 }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('крупное', 14), excludeSegmentBlock] }));
    if (id === 'outflow')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 4 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('MOVE_TO_THE_SEGMENT', { segmentName: segment.label }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT', { segmentName: segment.label, count: 30 }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT', { segmentName: segment.label, count: 60 }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('среднее или крупное', 14), excludeSegmentBlock] }));
    if (id === 'oneTimeClients')
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [t('SET_UP_AUTO_MAILINGS', { count: 3 }), ":"] }), _jsxs("ol", { children: [_jsxs("li", { children: [t('MOVE_TO_THE_SEGMENT', { segmentName: segment.label }), ";"] }), _jsxs("li", { children: [t('IN_THE_SEGMENT', { segmentName: segment.label, count: 30 }), ";"] }), _jsxs("li", { children: [t('BURNING_REWARD_REMINDER', { count: 3 }), "."] })] }), rewardMessage('минимальное', 14), excludeSegmentBlock] }));
    if (id === 'ultraflow')
        return (_jsxs("ul", { children: [_jsxs("li", { children: [t('Исключение сегмента из автоматических рассылок'), ";"] }), _jsxs("li", { children: [t('Периодическая реактивация через массовые ручные рассылки с контролем их рентабельности'), "."] })] }));
    if (id === 'ultraLargeSales')
        return (_jsxs("ul", { children: [_jsxs("li", { children: [t('Механики для увеличения частоты покупок (акции с предложением, ограниченным по времени)'), ";"] }), _jsxs("li", { children: [t('Информационные рассылки'), ";"] }), _jsxs("li", { children: [t('Стандартные автоматические рассылки: день рождения и праздники'), ";"] }), _jsxs("li", { children: [t('Рекомендации в соответствии с присвоенным маркером RFM'), "."] })] }));
    if (id === 'ultraActiveCustomers')
        return (_jsxs("ul", { children: [_jsxs("li", { children: [t('Механики для увеличения среднего чека (предложение акций 1+1, комплементарных предложений и т.д.)'), ";"] }), _jsxs("li", { children: [t('Информационные рассылки'), ";"] }), _jsxs("li", { children: [t('Стандартные автоматические рассылки: день рождения и праздники'), "."] })] }));
    if (id === 'possibleFraud')
        return (_jsxs("ul", { children: [_jsxs("li", { children: [t('Блокировка пользователей, исключение из всех рассылок и передача на проверку в СБ'), ";"] }), _jsxs("li", { children: [t('При ошибке — возвращение в сегмент VIP'), "."] })] }));
    if (id === 'wholesalers')
        return (_jsxs("ul", { children: [_jsxs("li", { children: [t('Изучение сегмента, разработка персональных акций'), ";"] }), _jsxs("li", { children: [t('Стандартные рекламные рассылки: с выгодным предложением / акцией / новостью о новом товаре или интересной товарной группе'), ";"] }), _jsxs("li", { children: [t('Стандартные автоматические рассылки: день рождения и праздники'), "."] })] }));
    return null;
};
