import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { PushFormExpireInDays } from './PushFormExpireInDays';
import { PushFormImage } from './PushFormImage';
import { PushFormMessagingApp } from './PushFormMessagingApp';
import { PushFormTest } from './PushFormTest';
import { PushFormText } from './PushFormText';
export const PushForm = () => {
    var _a;
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const [pushText, setPushText] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.pushText) || '');
    return (_jsxs(_Fragment, { children: [_jsx(PushFormMessagingApp, {}), _jsx(PushFormText, { pushText: pushText, onChangePushText: setPushText }), _jsx(PushFormImage, {}), _jsx(PushFormExpireInDays, {}), _jsx(PushFormTest, { pushText: pushText })] }));
};
