import { createEdge } from '../createEdge';
import { createNode } from '../createNode';
export const createEmailReceivedNode = (data) => {
    return createNode({
        type: 'email-received',
        data,
        position: data.position || { x: 0, y: 0 },
        style: !data.position ? { opacity: 0 } : undefined,
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const validateEmailReceivedNodeData = (data, t) => {
    const errors = {};
    if (!data.conditions.length)
        errors.conditions = t('Добавьте хотя бы одно условие');
    return errors;
};
export const createEmailReceivedNodeForMailing = (mailing) => {
    var _a;
    const { ifMailing } = ((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions) || {};
    return createEmailReceivedNode({
        type: ifMailing.replace('not_', ''),
        conditions: [ifMailing.startsWith('not_') ? 'no' : 'yes'],
    });
};
export const createEmailReceivedNodesFromMailings = (branches) => {
    var _a;
    const emailReceivedNodes = [];
    const emailReceivedEdges = [];
    const emailReceivedBranches = {};
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const nodeMap = {};
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const { ifMailing } = ((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions) || {};
                let node = nodeMap[ifMailing];
                if (!node) {
                    node = createEmailReceivedNodeForMailing(mailing);
                    nodeMap[ifMailing] = node;
                    emailReceivedNodes.push(node);
                    emailReceivedEdges.push(createEdge({
                        source: fromNodeId,
                        sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                        target: node.id,
                        data: { isInteractive: true },
                    }));
                }
                const conditionHandle = node.data.conditions[0] + '-0';
                if (!emailReceivedBranches[node.id])
                    emailReceivedBranches[node.id] = {
                        [conditionHandle]: [],
                    };
                emailReceivedBranches[node.id][conditionHandle].push(mailing);
            }
        }
    }
    return { emailReceivedNodes, emailReceivedEdges, emailReceivedBranches };
};
