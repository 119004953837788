import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { useDebounce } from '@/hooks/useDebounce';
import { CloseCircle } from '@/icons/CloseCircle';
import { Search } from '@/icons/Search';
import styles from './styles.module.scss';
export const SearchInput = memo(({ id, className, name, value: defaultValue, onChange, onKeyUp, onReset, debounceDelay, placeholder, hasAutoFocus, autoComplete = 'on', }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    useDebounce({
        value,
        callback: onChange,
        delay: debounceDelay,
    });
    const handleChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);
    const handleResetSearch = useCallback(() => {
        setValue('');
        if (onReset)
            onReset();
    }, [onReset]);
    useEffect(() => {
        var _a;
        if (hasAutoFocus)
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: [_jsx("span", Object.assign({ className: styles.icon }, { children: _jsx(Search, {}) })), _jsx("input", { autoComplete: autoComplete, id: id, ref: inputRef, className: styles.input, value: value, onChange: handleChange, onKeyUp: onKeyUp, placeholder: placeholder, name: name }), !!value && (_jsx(Tooltip, Object.assign({ title: t('Сбросить поиск'), placement: 'top', PopperProps: {
                    disablePortal: true,
                } }, { children: _jsx("button", Object.assign({ className: styles.reset, type: 'button', onClick: handleResetSearch }, { children: _jsx(CloseCircle, {}) })) })))] })));
});
