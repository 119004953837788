import { useReactFlow } from '@xyflow/react';
import { useCurrentUser } from '@/app/useCurrentUser';
import { brandHasTelegramFeature } from '@/utils/brandHasTelegramFeature';
import { brandHasViberFeature } from '@/utils/brandHasViberFeature';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { TRIGGER_FILTERS_REQUIRED_MAP } from '../nodes/TriggerNode/utils';
import { branchHasNode } from '../nodes/utils';
export const useAvailableChannels = () => {
    var _a, _b;
    const { user, brand: brandData } = useCurrentUser();
    const brand = brandData === null || brandData === void 0 ? void 0 : brandData.brand;
    const brandHasWalletCardSettings = typeof (brand === null || brand === void 0 ? void 0 : brand.walletCardSettings) === 'object'
        ? Object.keys(brand.walletCardSettings).length > 0
        : !!(brand === null || brand === void 0 ? void 0 : brand.walletCardSettings);
    const channelsEnabled = [
        brandHasWalletCardSettings ||
            brandHasWalletFeature(brand) ||
            ((_a = brand === null || brand === void 0 ? void 0 : brand.features) === null || _a === void 0 ? void 0 : _a.mobilePush)
            ? 'push'
            : '',
        brandHasViberFeature(brand) ? 'viber' : '',
        'sms',
        (user === null || user === void 0 ? void 0 : user.isSuperAdmin) || ((_b = brand === null || brand === void 0 ? void 0 : brand.features) === null || _b === void 0 ? void 0 : _b.emailEditor) ? 'email' : '',
        brandHasTelegramFeature(brand) ? 'telegram' : '',
    ].filter(Boolean);
    const cascadeChannels = [
        channelsEnabled.includes('push') ? 'push' : null,
        channelsEnabled.includes('viber') ? 'viber' : null,
        channelsEnabled.includes('sms') ? 'sms' : null,
    ].filter(notNull);
    const steps = [
        channelsEnabled.includes('push') ? 'push' : null,
        channelsEnabled.includes('viber') ? 'viber' : null,
        channelsEnabled.includes('sms') ? 'sms' : null,
        channelsEnabled.includes('email') ? 'email' : null,
        channelsEnabled.includes('telegram') ? 'telegram' : null,
        cascadeChannels.length > 1 ? 'cascade' : null,
    ];
    return steps.filter(notNull);
};
const nodeIsTriggerFilter = (node) => {
    const data = node.data;
    return (node.type === 'conditions' &&
        (data.type === 'trigger' || data.type === 'schedule'));
};
const nodeIsEmailChannel = (node) => {
    const data = node.data;
    const { channels = [] } = data;
    return (node.type === 'channel' &&
        channels.length === 1 &&
        channels.includes('email'));
};
const nodeIsEmailReceivedTrigger = (node) => {
    return node.type === 'email-received';
};
const nodeIsChannelOrEmailReceivedTrigger = (node) => {
    const data = node.data;
    const { channels = [] } = data;
    return ((node.type === 'channel' && !channels.includes('email')) ||
        node.type === 'email-received');
};
const nodeIsChannel = (node) => {
    var _a;
    const channels = ((_a = node === null || node === void 0 ? void 0 : node.data) === null || _a === void 0 ? void 0 : _a.channels) || [];
    return (node.type === 'channel' &&
        (channels.includes('push') ||
            channels.includes('sms') ||
            channels.includes('telegram') ||
            channels.includes('viber')));
};
const nodeIsChannelReceivedTrigger = (node) => {
    return node.type === 'channel-received';
};
const nodeIsEmailChannelOrChannelReceivedTrigger = (node) => {
    const data = node.data;
    const { channels = [] } = data;
    return ((node.type === 'channel' &&
        channels.length === 1 &&
        channels.includes('email')) ||
        node.type === 'channel-received');
};
const nodeIsDelay = (node) => {
    return node.type === 'delay';
};
const isUsedTriggerFilter = (filterCode) => (node) => {
    const data = node.data;
    const configuration = data.configuration || [];
    if (node.type !== 'conditions')
        return false;
    if (data.type !== 'trigger')
        return false;
    return configuration.some((filter) => filter.code === filterCode);
};
const discardUsedTriggerFilters = (flow, toNode, filters, fromNode) => {
    const branchHasDelayBackward = branchHasNode({
        flow,
        toNode,
        predicate: nodeIsDelay,
        stopPredicate: (node) => node.type === 'channel',
    });
    if (branchHasDelayBackward)
        return [];
    return filters.filter((filter) => {
        const options = {
            flow,
            toNode,
            predicate: isUsedTriggerFilter(filter.id),
        };
        if (branchHasNode(Object.assign(Object.assign({}, options), { type: 'incomer' })))
            return false;
        if (fromNode &&
            branchHasNode(Object.assign(Object.assign({}, options), { toNode: fromNode, type: 'outgoer' })))
            return false;
        return true;
    });
};
const isTriggerFiltersEnabled = (flow, toNode, fromNode) => {
    const branchHasTriggerFiltersBackward = branchHasNode({
        flow,
        toNode,
        predicate: nodeIsTriggerFilter,
    });
    const branchHasTriggerFiltersForward = fromNode
        ? branchHasNode({
            flow,
            type: 'outgoer',
            toNode: fromNode,
            predicate: nodeIsTriggerFilter,
        })
        : false;
    return !branchHasTriggerFiltersBackward && !branchHasTriggerFiltersForward;
};
const isEmailReceivedTriggersEnabled = (flow, toNode, fromNode) => {
    const branchHasEmailChannelBackward = branchHasNode({
        flow,
        toNode,
        predicate: nodeIsEmailChannel,
        stopPredicate: nodeIsChannelOrEmailReceivedTrigger,
    });
    const branchHasEmailReceivedTriggerForward = fromNode
        ? branchHasNode({
            flow,
            type: 'outgoer',
            toNode: fromNode,
            predicate: nodeIsEmailReceivedTrigger,
        })
        : false;
    return branchHasEmailChannelBackward && !branchHasEmailReceivedTriggerForward;
};
const isChannelReceivedTriggersEnabled = (flow, toNode, fromNode) => {
    const branchHasChannelBackward = branchHasNode({
        flow,
        toNode,
        predicate: nodeIsChannel,
        stopPredicate: nodeIsEmailChannelOrChannelReceivedTrigger,
    });
    const branchHasChannelReceivedTriggerForward = fromNode
        ? branchHasNode({
            flow,
            type: 'outgoer',
            toNode: fromNode,
            predicate: nodeIsChannelReceivedTrigger,
        })
        : false;
    return branchHasChannelBackward && !branchHasChannelReceivedTriggerForward;
};
export const useAvailableTriggers = (toNode, fromNode) => {
    const flow = useReactFlow();
    const { triggerType, triggerFilters = [], emailReceivedTriggers = [], channelReceivedTriggers = [], } = useInteractiveFlow();
    if (!toNode)
        return {
            availableTriggerFilters: [],
        };
    const branchHasChannelBackward = branchHasNode({
        flow,
        toNode,
        predicate: nodeIsChannel,
    });
    const availableTriggerFilters = branchHasChannelBackward
        ? []
        : !TRIGGER_FILTERS_REQUIRED_MAP[triggerType] ||
            isTriggerFiltersEnabled(flow, toNode, fromNode)
            ? discardUsedTriggerFilters(flow, toNode, triggerFilters, fromNode)
            : [];
    const availableEmailReceivedTriggers = isEmailReceivedTriggersEnabled(flow, toNode, fromNode)
        ? emailReceivedTriggers
        : [];
    const availableСhannelReceivedTriggers = isChannelReceivedTriggersEnabled(flow, toNode, fromNode)
        ? channelReceivedTriggers
        : [];
    if (availableСhannelReceivedTriggers.length)
        return {
            availableTriggerFilters: availableСhannelReceivedTriggers,
            isChannelTriggers: true,
        };
    if (availableEmailReceivedTriggers.length)
        return {
            availableTriggerFilters: availableEmailReceivedTriggers,
            isEmailTriggers: true,
        };
    return {
        availableTriggerFilters,
    };
};
export const useAvailableSteps = (toNode, fromNode) => {
    const flow = useReactFlow();
    const { triggerType } = useInteractiveFlow();
    const availableChannels = useAvailableChannels();
    if (!toNode)
        return [];
    const branchHasTriggerFilters = !isTriggerFiltersEnabled(flow, toNode, fromNode);
    if (TRIGGER_FILTERS_REQUIRED_MAP[triggerType] && !branchHasTriggerFilters)
        return [];
    let availableSteps = [
        'filter-conditions',
        'delay',
        ...availableChannels,
    ];
    const branchHasChannelReceivedBackward = branchHasNode({
        flow,
        toNode,
        predicate: (node) => node.type === 'channel-received' || node.type === 'email-received',
        stopPredicate: (node) => node.type === 'channel',
    });
    const branchHasDelayBackward = branchHasNode({
        flow,
        toNode,
        predicate: nodeIsDelay,
        stopPredicate: (node) => node.type === 'channel',
    });
    const branchHasDelayForward = fromNode
        ? branchHasNode({
            flow,
            type: 'outgoer',
            toNode: fromNode,
            predicate: nodeIsDelay,
            stopPredicate: (node) => node.type === 'channel',
        })
        : false;
    const branchHasFilterConditionsForward = fromNode
        ? branchHasNode({
            flow,
            type: 'outgoer',
            toNode: fromNode,
            predicate: (node) => node.type === 'conditions',
            stopPredicate: (node) => node.type === 'channel' ||
                node.type === 'channel-received' ||
                node.type === 'email-received',
        })
        : false;
    if (branchHasDelayBackward)
        availableSteps = availableSteps.filter((step) => step !== 'filter-conditions');
    if (branchHasDelayBackward ||
        branchHasDelayForward ||
        branchHasFilterConditionsForward ||
        branchHasChannelReceivedBackward)
        availableSteps = availableSteps.filter((step) => step !== 'delay');
    return availableSteps;
};
