import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { useLastListState } from '~/src/hooks/useLastListState';
import { ROUTES } from '../routes';
import { OffersPanel } from './OffersPanel';
import { useData } from './useData';
import { useDeleteOffer } from './useDeleteOffer';
import { useOpenPurchaseFilter } from './useOpenPurchaseFilter';
export const Offers = () => {
    const { t } = useTranslation();
    const { brand } = useCurrentUser();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const limit = 100;
    const [offset, setOffset] = useState(0);
    const { currentTab, handleCleanData, handleCurrentTab } = useLastListState();
    const [activeTabId, setActiveTabId] = useState(currentTab || 'active');
    const { count, data, isLoading, isFetching } = useData({
        searchText,
        limit,
        offset,
        archived: activeTabId === 'archived',
    });
    const deleteOffer = useDeleteOffer({
        onSuccess: () => setOffset(0),
    });
    const handleChangeActiveTab = useCallback((tabId) => {
        setActiveTabId(tabId);
        handleCurrentTab(tabId);
        setOffset(0);
    }, []);
    const handleApplySearch = useCallback((searchText) => {
        setOffset(0);
        setSearchText(searchText);
    }, []);
    const { purchaseUrl, openPurchaseFilter } = useOpenPurchaseFilter();
    if (!brand)
        return null;
    return (_jsx(Container, Object.assign({ notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, { leftElement: _jsx(SearchPanel, { name: 'searchText', placeholder: t('Название акции'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch }) }) }, { children: _jsx(OffersPanel, { activeCount: count === null || count === void 0 ? void 0 : count.active, archivedCount: count === null || count === void 0 ? void 0 : count.archived, offers: data === null || data === void 0 ? void 0 : data.offers, total: data === null || data === void 0 ? void 0 : data.total, isLoading: isLoading, isFetching: isFetching, activeTabId: activeTabId, onChangeActiveTab: handleChangeActiveTab, count: activeTabId === 'active' ? count === null || count === void 0 ? void 0 : count.active : count === null || count === void 0 ? void 0 : count.archived, limit: limit, offset: offset, onChangeOffset: (offset) => {
                setOffset(offset);
                handleCleanData();
            }, onDeleteOffer: deleteOffer, purchaseUrl: purchaseUrl, onOpenPurchaseFilter: openPurchaseFilter, shouldDisplayProfit: brand.brand.showProfit, currency: brand.brand.currency.symbol || brand.brand.currency.unitShort, timeZone: brand.brand.defaultTimezone, onGoToCreateForm: () => navigate(ROUTES.OFFERS_CREATE), onGoToEditForm: (offerId) => {
                const path = generatePath(ROUTES.OFFERS_EDIT, {
                    offerId: offerId.toString(),
                });
                navigate(path);
            } }) })));
};
