import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { InteractiveFlowForm } from './interactive-form/InteractiveFlowForm';
import { useGlobalUI } from './useGlobalUI';
import { useSelectedFlow } from './useSelectedFlow';
export const FlowsForm = () => {
    useGlobalUI();
    const { brand } = useCurrentUser();
    const { selectedFlow, isLoadingSelectedFlow, isFetchingSelectedFlow } = useSelectedFlow();
    if (!brand)
        return null;
    if (isLoadingSelectedFlow || isFetchingSelectedFlow)
        return null;
    return _jsx(InteractiveFlowForm, { selectedFlow: selectedFlow });
};
