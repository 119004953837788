import * as triggers from '@/const/mailing-triggers';
import { createChannelNodesFromMailings } from './nodes/ChannelNode/utils';
import { createRecipientFiltersNodesFromMailings, createScheduleNodesFromMailings, createTriggerConditionNodesFromMailings, } from './nodes/ConditionsNode/utils';
import { createDelayNodesFromMailings } from './nodes/DelayNode/utils';
import { createEmailReceivedNodesFromMailings } from './nodes/EmailReceivedNode/utils';
import { createLoopNodesFromMailings } from './nodes/LoopNode/utils';
import { createTriggerNode } from './nodes/TriggerNode/utils';
export const composeMailings = (allMailings) => {
    const nodes = [];
    const edges = [];
    const mailings = allMailings.filter((m) => Number(m.triggerType) !== triggers.TRIGGER_TYPE_EMAIL_DELIVERED);
    const emailMailings = allMailings.filter((m) => m.channels.email);
    const emailReceivedMailings = allMailings.filter((m) => Number(m.triggerType) === triggers.TRIGGER_TYPE_EMAIL_DELIVERED);
    if (!mailings.length)
        return { nodes, edges };
    // 1) Create Trigger Node
    const triggerType = Number(mailings[0].triggerType);
    const triggerNode = createTriggerNode({ triggerType });
    nodes.push(triggerNode);
    const triggerBranches = { [triggerNode.id]: { 'no-handle': mailings } };
    // 2) Create Loop Nodes
    const { loopNodes, loopEdges, loopBranches } = createLoopNodesFromMailings(triggerBranches, triggerType);
    nodes.push(...loopNodes);
    edges.push(...loopEdges);
    // 3) Create "Before" Delay Nodes
    const { delayNodes: beforeDelayNodes, delayEdges: beforeDelayEdges, delayBranches: beforeDelayBranches, } = createDelayNodesFromMailings(loopBranches, true);
    nodes.push(...beforeDelayNodes);
    edges.push(...beforeDelayEdges);
    // 4) Create Trigger Condition Nodes
    const { triggerConditionNodes, triggerConditionEdges, triggerConditionBranches, } = triggerType === triggers.TRIGGER_TYPE_SCHEDULE
        ? createScheduleNodesFromMailings(beforeDelayBranches)
        : createTriggerConditionNodesFromMailings(beforeDelayBranches);
    nodes.push(...triggerConditionNodes);
    edges.push(...triggerConditionEdges);
    // 5) Create "Non-Before" Delay Nodes
    const { delayNodes: nonBeforeDelayNodes, delayEdges: nonBeforeDelayEdges, delayBranches: nonBeforeDelayBranches, } = createDelayNodesFromMailings(triggerConditionBranches);
    nodes.push(...nonBeforeDelayNodes);
    edges.push(...nonBeforeDelayEdges);
    // 6) Create Recipient Filters Nodes
    const { recipientFiltersNodes, recipientFiltersEdges, recipientFiltersBranches, } = createRecipientFiltersNodesFromMailings(nonBeforeDelayBranches);
    nodes.push(...recipientFiltersNodes);
    edges.push(...recipientFiltersEdges);
    // 7) Create Channel Nodes
    const { channelNodes, channelEdges, uuidMap } = createChannelNodesFromMailings(recipientFiltersBranches);
    nodes.push(...channelNodes);
    edges.push(...channelEdges);
    // 8) Create Sub-Graphs for Email Received Mailings
    for (let i = 0; i < emailMailings.length; i += 1) {
        const emailMailing = emailMailings[i];
        const mailings = emailReceivedMailings.filter((m) => { var _a; return ((_a = m.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions.mailingBrandEq) === emailMailing.id; });
        if (!mailings.length)
            continue;
        // 8.1) Create Delay Nodes
        const { delayNodes, delayEdges, delayBranches } = createDelayNodesFromMailings({
            [uuidMap.email[emailMailing.id]]: { 'no-handle': mailings },
        });
        nodes.push(...delayNodes);
        edges.push(...delayEdges);
        // 8.2) Create Email Received Condition Nodes
        const { emailReceivedNodes, emailReceivedEdges, emailReceivedBranches } = createEmailReceivedNodesFromMailings(delayBranches);
        nodes.push(...emailReceivedNodes);
        edges.push(...emailReceivedEdges);
        // 8.3) Create Recipient Filters Nodes
        const { recipientFiltersNodes, recipientFiltersEdges, recipientFiltersBranches, } = createRecipientFiltersNodesFromMailings(emailReceivedBranches);
        nodes.push(...recipientFiltersNodes);
        edges.push(...recipientFiltersEdges);
        // 8.4) Create Channel Nodes
        const { channelNodes, channelEdges } = createChannelNodesFromMailings(recipientFiltersBranches);
        nodes.push(...channelNodes);
        edges.push(...channelEdges);
    }
    return { nodes, edges };
};
