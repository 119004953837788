var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Pause } from '@/icons/Pause';
import { useInvalidateFlow } from './useInvalidateFlow';
import { usePauseFlow } from './usePauseFlow';
export const PauseButton = ({ flow }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    const { pauseFlow, isPausingFlow } = usePauseFlow();
    const { invalidateFlow, isInvalidatingFlow } = useInvalidateFlow(flow.id);
    const isDisabled = !userHasFlowsManage || isPausingFlow || isInvalidatingFlow;
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const success = yield pauseFlow(flow.id);
        if (success)
            invalidateFlow();
    });
    return (_jsx(Tooltip, Object.assign({ title: !userHasFlowsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Pause, {}), onClick: handleClick, disabled: isDisabled }, { children: t('Остановить') })) })));
};
