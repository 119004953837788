import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { useAvailableChannels } from '../../../hooks/useAvailableSteps';
import { CascadeFormTab } from './CascadeFormTab';
import styles from './styles.module.scss';
export const CascadeFormTabs = ({ push, viber, sms, }) => {
    const availableChannels = useAvailableChannels();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const [activeTab, setActiveTab] = useState((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.cascadeActiveChannelTab) || 'push');
    const handleUpdateActiveTab = (tab) => {
        if (isEditMode && (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.cascadeActiveChannelTab) !== tab)
            selectedNode.update({ cascadeActiveChannelTab: tab });
        setActiveTab(tab);
    };
    const handleToggleChannel = (channel) => {
        const channels = (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) || [];
        selectedNode.update({
            channels: channels.includes(channel)
                ? channels.filter((c) => c !== channel)
                : [...channels, channel],
        });
    };
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.tabsWrapper }, { children: [availableChannels.includes('push') && (_jsx(CascadeFormTab, { channel: 'push', activeTab: activeTab, channels: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) || [], onClick: handleUpdateActiveTab, onToggle: handleToggleChannel, isDisabled: !isEditMode })), availableChannels.includes('viber') && (_jsx(CascadeFormTab, { channel: 'viber', activeTab: activeTab, channels: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) || [], onClick: handleUpdateActiveTab, onToggle: handleToggleChannel, isDisabled: !isEditMode })), availableChannels.includes('sms') && (_jsx(CascadeFormTab, { channel: 'sms', activeTab: activeTab, channels: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) || [], onClick: handleUpdateActiveTab, onToggle: handleToggleChannel, isDisabled: !isEditMode }))] })), _jsxs("div", { children: [activeTab === 'push' && push, activeTab === 'viber' && viber, activeTab === 'sms' && sms] })] }));
};
