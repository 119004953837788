import { MAILING_STATUS_DONE, MAILING_STATUS_ERROR, MAILING_STATUS_PLANNED, } from '~/src/types/mailingStatus';
export { MAILING_STATUS_DONE, MAILING_STATUS_ERROR, MAILING_STATUS_PLANNED };
export const MAILING_NOT_DELIVERED = 'MAILING_NOT_DELIVERED';
export const MAILING_DELIVERED = 'MAILING_DELIVERED';
export const MAILING_OPENED = 'MAILING_OPENED';
export const MAILING_UNSUBSCRIBED = 'MAILING_UNSUBSCRIBED';
export const MAILING_MARKED_SPAM = 'MAILING_MARKED_SPAM';
export const TITLES = {
    sending_email: {
        [MAILING_STATUS_PLANNED]: 'Ожидает отправки',
        [MAILING_STATUS_DONE]: 'Отправлен email',
        [MAILING_STATUS_ERROR]: 'Ошибка отправки',
        [MAILING_NOT_DELIVERED]: 'Не доставлено',
        [MAILING_DELIVERED]: 'Доставлен email',
        [MAILING_OPENED]: 'Открыл email',
        [MAILING_UNSUBSCRIBED]: 'Отписался от Email в',
        [MAILING_MARKED_SPAM]: 'Отправил в спам',
    },
    sending_viber: {
        [MAILING_STATUS_PLANNED]: 'Ожидает отправки Viber',
        [MAILING_STATUS_DONE]: 'Отправлен Viber',
        [MAILING_STATUS_ERROR]: 'Ошибка отправки Viber',
        [MAILING_NOT_DELIVERED]: 'Не доставлено',
        [MAILING_DELIVERED]: 'Доставлен Viber',
        [MAILING_OPENED]: 'Открыл Viber',
        [MAILING_UNSUBSCRIBED]: '',
        [MAILING_MARKED_SPAM]: '',
    },
    sending_webpush: {
        [MAILING_STATUS_PLANNED]: 'Ожидает отправки',
        [MAILING_STATUS_DONE]: 'Отправлен Push',
        [MAILING_STATUS_ERROR]: 'Ошибка отправки Push',
        [MAILING_NOT_DELIVERED]: 'Не доставлено',
        [MAILING_DELIVERED]: 'Доставлен Push',
        [MAILING_OPENED]: '',
        [MAILING_UNSUBSCRIBED]: '',
        [MAILING_MARKED_SPAM]: '',
    },
    sending_push: {
        [MAILING_STATUS_PLANNED]: 'Ожидает отправки',
        [MAILING_STATUS_DONE]: 'Отправлен Push',
        [MAILING_STATUS_ERROR]: 'Ошибка отправки Push',
        [MAILING_NOT_DELIVERED]: 'Не доставлено',
        [MAILING_DELIVERED]: 'Доставлен Push',
        [MAILING_OPENED]: '',
        [MAILING_UNSUBSCRIBED]: '',
        [MAILING_MARKED_SPAM]: '',
    },
    sending_sms: {
        [MAILING_STATUS_PLANNED]: 'Ожидает отправки',
        [MAILING_STATUS_DONE]: 'Отправлен SMS',
        [MAILING_STATUS_ERROR]: 'Ошибка отправки SMS',
        [MAILING_NOT_DELIVERED]: 'Не доставлено',
        [MAILING_DELIVERED]: 'Доставлен SMS',
        [MAILING_OPENED]: '',
        [MAILING_UNSUBSCRIBED]: '',
        [MAILING_MARKED_SPAM]: '',
    },
};
