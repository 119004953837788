import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { ACCESS_OPTIONS } from '../constants';
import styles from '../styles.module.scss';
export const ClientsSection = memo(({ isView, longListItems, handleSetValue }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { control, formState: { errors }, setValue, watch, } = useFormContext();
    const clientList = watch('clientList');
    const clientListOwn = watch('clientListOwn');
    const clientSegments = watch('CLIENTS_SEGMENTS');
    const clientPurchases = watch('CLIENTS_PURCHASES');
    const isClientSectionAccess = clientList !== ACCESS_OPTIONS.NO_ACCESS ||
        clientSegments !== ACCESS_OPTIONS.NO_ACCESS ||
        clientPurchases !== ACCESS_OPTIONS.NO_ACCESS;
    useEffect(() => {
        if (clientList !== ACCESS_OPTIONS.EDITING)
            setValue('CLIENTS_BONUSES_MANAGE', false);
    }, [clientList]);
    useEffect(() => {
        if (!isClientSectionAccess)
            setValue('CLIENTS_PERSONAL_DATA_VIEW', false);
    }, [isClientSectionAccess]);
    const allDataAccessListItems = [
        {
            label: t('Всех клиентов'),
            value: ACCESS_OPTIONS.ALL_CLIENTS,
        },
        {
            label: t('Только своих'),
            value: ACCESS_OPTIONS.OWN_CLIENTS,
        },
    ];
    useEffect(() => {
        if (clientList && !clientListOwn)
            setValue('clientListOwn', allDataAccessListItems[0].value);
    }, [clientList]);
    const clientListOwnHelper = (_jsx(HelpTooltip, { tooltipClassName: styles.helpTooltip, title: t('только тех, кого пользователь добавил в систему сам'), placement: 'right', hasLightIcon: true }));
    // const personalDataViewHelperContent = (
    //   <>
    //     <span>{t('Скрывает:')}</span>
    //     <ul>
    //       <li>{t('Email, кроме домена email')}</li>
    //       <li>{t('Телефон, кроме последних цифр')}</li>
    //       <li>{t('Фамилию')}</li>
    //       <li>{t('Карту, весь номер кроме последних цифр')}</li>
    //     </ul>
    //   </>
    // )
    return (_jsx("div", Object.assign({ id: 'clients' }, { children: _jsxs(FormFieldset, Object.assign({ fieldsetClassName: styles.fieldset, titleClassName: styles.fieldsetTitle, title: t('Клиенты') }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Список клиентов'), labelFor: 'clientList', controlSize: '320', help: (_a = errors.clientList) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.clientList }, { children: _jsxs("div", Object.assign({ className: styles.clientList }, { children: [_jsx(Controller, { name: 'clientList', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView, className: styles.autocompleteWrapper }, field, { id: 'clientList', value: longListItems.find((item) => item.value === field.value) ||
                                        longListItems[0], onChange: (item) => {
                                        handleSetValue('clientList', item);
                                    }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }), clientList && (_jsx(Controller, { name: 'clientListOwn', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ helperTooltip: clientListOwn === ACCESS_OPTIONS.OWN_CLIENTS &&
                                        clientListOwnHelper, disabled: isView }, field, { id: 'clientListOwn', wrapperClassName: classNames(styles.clientsVariant, styles.ownAccess, {
                                        [styles.ownFieldDisabled]: isView,
                                    }), value: allDataAccessListItems.find((item) => item.value === field.value) || allDataAccessListItems[0], onChange: (item) => {
                                        handleSetValue('clientListOwn', item);
                                    }, options: allDataAccessListItems, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, isInline: true }))) }))] })) })), _jsx(FormFieldRow, { children: _jsx(Controller, { name: 'CLIENTS_BONUSES_MANAGE', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { disabled: isView || clientList !== ACCESS_OPTIONS.EDITING, value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                                setValue('CLIENTS_BONUSES_MANAGE', e.target.checked, {
                                    shouldDirty: true,
                                });
                            } }, { children: t('Начисление бонусов') }))) }) }), _jsx(FormFieldRow, Object.assign({ label: t('Сегменты'), labelFor: '', help: (_b = errors.CLIENTS_SEGMENTS) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.CLIENTS_SEGMENTS, controlSize: '154' }, { children: _jsx(Controller, { name: 'CLIENTS_SEGMENTS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'CLIENTS_SEGMENTS', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('CLIENTS_SEGMENTS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Покупки'), labelFor: 'CLIENTS_PURCHASES', help: (_c = errors.CLIENTS_PURCHASES) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.CLIENTS_PURCHASES, controlSize: '154' }, { children: _jsx(Controller, { name: 'CLIENTS_PURCHASES', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'CLIENTS_PURCHASES', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('CLIENTS_PURCHASES', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) }))] })) })));
});
