import { parseDaysNumber } from '@/components/DaysSelect/parseDaysNumber';
import { createScheduleSavedFilter } from '@/components/FilterListInput/ScheduleFilterForm';
import * as triggers from '@/const/mailing-triggers';
import { compileFilters } from '@/services/filters/compileFilters';
import { createEdge } from '../createEdge';
import { createNode } from '../createNode';
import { getScheduleTypeLabel } from './schedule';
export const createConditionNode = (data) => {
    return createNode({
        type: 'conditions',
        data,
        position: data.position || { x: 0, y: 0 },
        style: !data.position ? { opacity: 0 } : undefined,
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const validateConditionsNodeData = (data, t) => {
    var _a;
    const errors = {};
    if (!((_a = data.configuration) === null || _a === void 0 ? void 0 : _a.length))
        errors.configuration = t('Добавьте хотя бы одно условие');
    return errors;
};
export const createConditionNodeForMailing = (type, filter, mailing) => {
    var _a, _b, _c;
    let configuration = [];
    let subheader = (filter === null || filter === void 0 ? void 0 : filter.text.split(':')[0].trim()) || '';
    let selectedFilterCode = (filter === null || filter === void 0 ? void 0 : filter.code) || (filter === null || filter === void 0 ? void 0 : filter.id) || '';
    let conditions = [];
    if (type === 'trigger') {
        configuration = ((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.configuration) || [];
        configuration = configuration === null || configuration === void 0 ? void 0 : configuration.filter((f) => f.code === (filter === null || filter === void 0 ? void 0 : filter.code));
        conditions = configuration === null || configuration === void 0 ? void 0 : configuration.map((savedFilter) => compileFilters([savedFilter]));
    }
    if (type === 'filter') {
        configuration = ((_c = (_b = mailing.recipients) === null || _b === void 0 ? void 0 : _b.filters) === null || _c === void 0 ? void 0 : _c.configuration) || [];
        configuration = configuration === null || configuration === void 0 ? void 0 : configuration.filter((f) => f.code === (filter === null || filter === void 0 ? void 0 : filter.code));
        conditions = configuration === null || configuration === void 0 ? void 0 : configuration.map((savedFilter) => compileFilters([savedFilter]));
    }
    if (type === 'schedule') {
        const options = getScheduleOptionsFromMailing(mailing);
        subheader = getScheduleTypeLabel(options.scheduleType) || '';
        selectedFilterCode = options.scheduleType;
        configuration = [createScheduleSavedFilter(options)];
        conditions = [configuration[0].rawConditions.schedule];
    }
    return createConditionNode({
        type,
        subheader,
        selectedFilterCode,
        configuration,
        conditions,
    });
};
const getScheduleOptionsFromMailing = (mailing) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const scheduleType = (_b = (_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions) === null || _b === void 0 ? void 0 : _b.scheduleType;
    const scheduleTime = (_d = (_c = mailing.triggerConditions) === null || _c === void 0 ? void 0 : _c.conditions) === null || _d === void 0 ? void 0 : _d.scheduleTime;
    const scheduleDaysOfMonth = (_f = (_e = mailing.triggerConditions) === null || _e === void 0 ? void 0 : _e.conditions) === null || _f === void 0 ? void 0 : _f.scheduleDays;
    const scheduleDate = (_h = (_g = mailing.triggerConditions) === null || _g === void 0 ? void 0 : _g.conditions) === null || _h === void 0 ? void 0 : _h.scheduleDate;
    const days = (_k = (_j = mailing.triggerConditions) === null || _j === void 0 ? void 0 : _j.conditions) === null || _k === void 0 ? void 0 : _k.scheduleWeekdays;
    const scheduleDaysOfWeek = parseDaysNumber(days);
    return {
        scheduleType,
        scheduleDaysOfWeek,
        scheduleDaysOfMonth,
        scheduleDate,
        scheduleTime,
    };
};
// -----------------------------------------------------------------------------
export const sortFilters = (mailings, filters, getFilters, sort = 'asc') => {
    const varsMap = {};
    for (let i = 0; i < mailings.length; i += 1) {
        const mailing = mailings[i];
        const filters = getFilters(mailing);
        for (let j = 0; j < filters.length; j += 1) {
            const { code, text } = filters[j];
            if (!varsMap[code])
                varsMap[code] = [];
            if (!varsMap[code].includes(text))
                varsMap[code].push(text);
            if (!varsMap[code].includes(mailing.id))
                varsMap[code].push(mailing.id);
        }
    }
    const sortedFilters = [...filters];
    // 1) sort alphabetically by code
    sortedFilters.sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 0));
    // 2) sort by mailing count
    sortedFilters.sort((a, b) => sort === 'asc'
        ? varsMap[a.code].length - varsMap[b.code].length
        : varsMap[b.code].length - varsMap[a.code].length);
    return sortedFilters;
};
export const getFilterPath = (filters, idx, splitFilterVariantsToDifferentNodes = true) => {
    const filter = filters[idx];
    const filterKey = (filter) => filter.code + JSON.stringify(filter.rawConditions);
    return (filters.slice(0, idx).reduce((path, f) => path + filterKey(f), '') +
        (splitFilterVariantsToDifferentNodes ? filterKey(filter) : filter.code));
};
export const createFilterNodesFromMailings = (branches, getFilters, createNode, sort = 'asc') => {
    var _a, _b, _c, _d;
    const filterNodes = [];
    const filterEdges = [];
    const filterBranches = {};
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const nodeMap = {};
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const initialFilters = getFilters(mailing);
                if (!initialFilters.length) {
                    if (!filterBranches[fromNodeId])
                        filterBranches[fromNodeId] = {};
                    if (!filterBranches[fromNodeId][fromHandleId])
                        filterBranches[fromNodeId][fromHandleId] = [];
                    filterBranches[fromNodeId][fromHandleId].push(mailing);
                    continue;
                }
                const sortedFilters = sortFilters(mailings, initialFilters, getFilters, sort);
                for (let j = 0; j < sortedFilters.length; j += 1) {
                    const filter = sortedFilters[j];
                    const currentPath = getFilterPath(sortedFilters, j);
                    if (!nodeMap[currentPath]) {
                        const node = createNode(filter, mailing);
                        nodeMap[currentPath] = node;
                        filterNodes.push(node);
                        if (j === 0)
                            filterEdges.push(createEdge({
                                source: fromNodeId,
                                sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                                target: node.id,
                                data: { isInteractive: true },
                            }));
                        else {
                            const prevPath = getFilterPath(sortedFilters, j - 1);
                            const fromNode = nodeMap[prevPath];
                            const source = fromNode.id;
                            const sourceHandle = sortedFilters[j - 1].code +
                                '-' +
                                ((fromNode.data.configuration || []).length - 1);
                            filterEdges.push(createEdge({
                                source,
                                sourceHandle,
                                target: node.id,
                                data: { isInteractive: true },
                            }));
                        }
                    }
                    const node = nodeMap[currentPath];
                    const handle = filter.code + '-' + (((_a = (node.data.configuration || [])) === null || _a === void 0 ? void 0 : _a.length) - 1);
                    if (!((_c = (_b = node.data.configuration) === null || _b === void 0 ? void 0 : _b.map((f) => f.text)) === null || _c === void 0 ? void 0 : _c.includes(filter.text)))
                        (_d = node.data.conditions) === null || _d === void 0 ? void 0 : _d.push(compileFilters([filter]));
                    if (j === sortedFilters.length - 1) {
                        if (!filterBranches[node.id])
                            filterBranches[node.id] = {};
                        if (!filterBranches[node.id][handle])
                            filterBranches[node.id][handle] = [];
                        filterBranches[node.id][handle].push(mailing);
                    }
                }
            }
        }
    }
    return {
        filterNodes,
        filterEdges,
        filterBranches,
    };
};
// Trigger Conditions ----------------------------------------------------------
export const getTriggerConditionFilters = (mailing) => {
    var _a;
    const configuration = ((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.configuration) || [];
    const filters = configuration;
    return filters;
};
export const createTriggerConditionNodesFromMailings = (branches) => {
    const resultAsc = createFilterNodesFromMailings(branches, getTriggerConditionFilters, createConditionNodeForMailing.bind(null, 'trigger'), 'asc');
    const resultDesc = createFilterNodesFromMailings(branches, getTriggerConditionFilters, createConditionNodeForMailing.bind(null, 'trigger'), 'desc');
    const result = resultAsc.filterNodes.length < resultDesc.filterNodes.length
        ? resultAsc
        : resultDesc;
    return {
        triggerConditionNodes: result.filterNodes,
        triggerConditionEdges: result.filterEdges,
        triggerConditionBranches: result.filterBranches,
    };
};
// Recipient Filters -----------------------------------------------------------
export const getRecipientFilters = (mailing) => {
    var _a, _b;
    const configuration = ((_b = (_a = mailing.recipients) === null || _a === void 0 ? void 0 : _a.filters) === null || _b === void 0 ? void 0 : _b.configuration) || [];
    const filters = configuration;
    return filters;
};
export const createRecipientFiltersNodesFromMailings = (branches) => {
    const resultAsc = createFilterNodesFromMailings(branches, getRecipientFilters, createConditionNodeForMailing.bind(null, 'filter'), 'asc');
    const resultDesc = createFilterNodesFromMailings(branches, getRecipientFilters, createConditionNodeForMailing.bind(null, 'filter'), 'desc');
    const result = resultAsc.filterNodes.length < resultDesc.filterNodes.length
        ? resultAsc
        : resultDesc;
    return {
        recipientFiltersNodes: result.filterNodes,
        recipientFiltersEdges: result.filterEdges,
        recipientFiltersBranches: result.filterBranches,
    };
};
// Schedule Conditions ---------------------------------------------------------
export const createScheduleNodesFromMailings = (branches) => {
    var _a, _b, _c;
    const triggerConditionNodes = [];
    const triggerConditionEdges = [];
    const triggerConditionBranches = {};
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const nodeMap = {};
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const options = getScheduleOptionsFromMailing(mailing);
                const type = options.scheduleType;
                if (!nodeMap[type]) {
                    const node = createConditionNodeForMailing('schedule', null, mailing);
                    nodeMap[type] = node;
                    triggerConditionNodes.push(node);
                    triggerConditionEdges.push(createEdge({
                        source: fromNodeId,
                        sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                        target: node.id,
                    }));
                }
                const node = nodeMap[type];
                const savedFilter = createScheduleSavedFilter(options);
                const condition = savedFilter.rawConditions.schedule;
                const handle = options.scheduleType +
                    '-' +
                    (((_a = (node.data.configuration || [])) === null || _a === void 0 ? void 0 : _a.length) - 1);
                if (!((_b = node.data.configuration) === null || _b === void 0 ? void 0 : _b.map((f) => f.text).includes(savedFilter.text)))
                    (_c = node.data.conditions) === null || _c === void 0 ? void 0 : _c.push(condition);
                if (!triggerConditionBranches[node.id])
                    triggerConditionBranches[node.id] = {};
                if (!triggerConditionBranches[node.id][handle])
                    triggerConditionBranches[node.id][handle] = [];
                triggerConditionBranches[node.id][handle].push(mailing);
            }
        }
    }
    return {
        triggerConditionNodes,
        triggerConditionEdges,
        triggerConditionBranches,
    };
};
// All Conditions --------------------------------------------------------------
export const createConditionNodesFromMailings = (triggerType, branches) => {
    let conditionNodes = [];
    let conditionEdges = [];
    let conditionBranches = {};
    // 1) Trigger First
    const triggerFirst_Step1 = triggerType === triggers.TRIGGER_TYPE_SCHEDULE
        ? createScheduleNodesFromMailings(branches)
        : createTriggerConditionNodesFromMailings(branches);
    const triggerFirst_Step2 = createRecipientFiltersNodesFromMailings(triggerFirst_Step1.triggerConditionBranches);
    const triggerFirst_Nodes = [
        ...triggerFirst_Step1.triggerConditionNodes,
        ...triggerFirst_Step2.recipientFiltersNodes,
    ];
    const triggerFirst_Edges = [
        ...triggerFirst_Step1.triggerConditionEdges,
        ...triggerFirst_Step2.recipientFiltersEdges,
    ];
    const triggerFirst_Branches = triggerFirst_Step2.recipientFiltersBranches;
    // 2) Recipients First
    const recipientsFirst_Step1 = createRecipientFiltersNodesFromMailings(branches);
    const recipientsFirst_Step2 = triggerType === triggers.TRIGGER_TYPE_SCHEDULE
        ? createScheduleNodesFromMailings(recipientsFirst_Step1.recipientFiltersBranches)
        : createTriggerConditionNodesFromMailings(recipientsFirst_Step1.recipientFiltersBranches);
    const recipientsFirst_Nodes = [
        ...recipientsFirst_Step1.recipientFiltersNodes,
        ...recipientsFirst_Step2.triggerConditionNodes,
    ];
    const recipientsFirst_Edges = [
        ...recipientsFirst_Step1.recipientFiltersEdges,
        ...recipientsFirst_Step2.triggerConditionEdges,
    ];
    const recipientsFirst_Branches = recipientsFirst_Step2.triggerConditionBranches;
    if (recipientsFirst_Nodes.length < triggerFirst_Nodes.length) {
        conditionNodes = recipientsFirst_Nodes;
        conditionEdges = recipientsFirst_Edges;
        conditionBranches = recipientsFirst_Branches;
    }
    else {
        conditionNodes = triggerFirst_Nodes;
        conditionEdges = triggerFirst_Edges;
        conditionBranches = triggerFirst_Branches;
    }
    return { conditionNodes, conditionEdges, conditionBranches };
};
