import { api } from '@/api/query';
import { useGetFlowQuery } from '@/api/query/flows';
import { useAppDispatch } from '@/hooks/store';
export const useInvalidateFlow = (id) => {
    const dispatch = useAppDispatch();
    const { isFetching: isInvalidatingFlow } = useGetFlowQuery({ id: id || 0 }, { skip: id === undefined });
    const invalidateFlow = () => {
        dispatch(api.util.invalidateTags(['Flow']));
    };
    return { invalidateFlow, isInvalidatingFlow };
};
