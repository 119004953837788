export const ALL_DATA_TYPES = [
    'purchase',
    'bonus',
    'client_update',
    'loyalty',
    'mailing',
];
export const INIT_ACTIVE_BUTTONS = {
    all: true,
    purchase: false,
    bonus: false,
    loyalty: false,
    client_update: false,
    mailing: false,
};
