function convertPriorityToSavePriority(priority) {
    if (priority.kind === 'offer' && priority.offerId)
        return {
            offerId: priority.offerId,
            promocodeId: null,
            group: null,
        };
    if (priority.kind === 'promocode' && priority.promocodeId)
        return {
            offerId: null,
            promocodeId: priority.promocodeId,
            group: null,
        };
    if (priority.kind === 'group' && priority.arbitrationGroup) {
        const group = priority.arbitrationGroup;
        const saveGroup = {
            guid: group.guid || null,
            type: group.type,
            name: group.name,
            maxDiscountEnabled: group.maxDiscountEnabled,
            maxDiscountAmount: group.maxDiscountAmount || null,
            maxDiscountType: group.maxDiscountType,
            children: [],
        };
        group.children.forEach((child) => {
            const converted = convertPriorityToSavePriority(child);
            if (converted)
                saveGroup.children.push(converted);
        });
        return {
            offerId: null,
            promocodeId: null,
            group: saveGroup,
        };
    }
    return null;
}
export function convertPriorityToSavePriorities(priority) {
    const savePriorities = [];
    for (let i = 0; i < priority.length; i += 1) {
        const converted = convertPriorityToSavePriority(priority[i]);
        if (converted)
            savePriorities.push(converted);
    }
    return savePriorities;
}
