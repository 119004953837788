var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BaseEdge, EdgeLabelRenderer, getSimpleBezierPath, } from '@xyflow/react';
import classNames from 'classnames';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { EdgePlusButton } from '../helpers/EdgePlusButton/EdgePlusButton';
import styles from './styles.module.scss';
export const CustomEdge = (_a) => {
    var { data = {}, id, sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition } = _a, otherProps = __rest(_a, ["data", "id", "sourceX", "sourceY", "sourcePosition", "targetX", "targetY", "targetPosition"]);
    const { isEditMode } = useInteractiveFlow();
    const [path, labelX, labelY] = getSimpleBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const { isHovered = false, isInteractive } = data;
    return (_jsxs(_Fragment, { children: [_jsx(BaseEdge, Object.assign({ className: classNames({
                    [styles.dashed]: !isEditMode,
                }), id: id, path: path }, otherProps)), isInteractive && (_jsx(EdgeLabelRenderer, { children: _jsx(EdgePlusButton, { edgeId: id, buttonIsVisible: isHovered, x: labelX, y: labelY }) }))] }));
};
