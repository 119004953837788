import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Promocode as PromocodeElement } from '@/components/Promocode/Promocode';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_SHARED } from '@/const/promocode';
import { Reset } from '@/icons/Reset';
import { MailingRewardsPillsPopup } from '../MailingRewardsPillsPopup';
import styles from '../styles.module.scss';
export const Promocode = ({ idx, value, onChange, offers = [], promocodes = [], giftCards = [], isDisabled = false, currency, }) => {
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    };
    const handleReset = () => {
        const newValue = [...value];
        newValue.splice(idx, 1);
        onChange(newValue);
    };
    const selectedItem = value[idx];
    if (selectedItem.type !== 'promocode')
        return null;
    const selectedPromocode = promocodes.find((p) => p.id === selectedItem.promocodeId);
    if (!selectedPromocode)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.buttonWrapper }, { children: [_jsx("button", Object.assign({ className: classNames(styles.formButton, styles.promocodeButton, {
                            [styles.nonInteractive]: isDisabled,
                            [styles.active]: popupIsOpen,
                        }), ref: triggerRef, type: 'button', onClick: togglePopup }, { children: _jsx(PromocodeElement, { code: selectedPromocode.code, isPersonal: selectedPromocode.codeType !== PROMOCODE_TYPE_SHARED, isDark: true }) })), _jsx("button", Object.assign({ className: styles.resetButton, type: 'button', disabled: isDisabled, onClick: handleReset }, { children: _jsx(Reset, {}) }))] })), popupIsOpen && (_jsx(MailingRewardsPillsPopup, { type: 'edit', activeMenuItem: selectedPromocode.codeType === PROMOCODE_TYPE_FRIEND
                    ? 'friendPromocodes'
                    : 'promocodes', selectedItemIdx: idx, isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, value: value, onChange: onChange, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency }))] }));
};
