export const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('error', reject);
        image.addEventListener('abort', reject);
        image.addEventListener('load', () => {
            resolve([image, image.width, image.height]);
        });
        image.src = url;
    });
};
