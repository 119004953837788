import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useGetSegmentQuery } from '@/api/query/segment';
import { compileFilters } from '@/services/filters/compileFilters';
import { addToLS, getFromLS } from '@/utils/LS';
import { useBuildRFMConditions } from './useBuildRFMConditions';
export const useFiltersState = ({ currency, localStorageKey = 'wfilters', }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const location = useLocation();
    const buildRFMConditions = useBuildRFMConditions();
    const segmentState = (_a = location.state) === null || _a === void 0 ? void 0 : _a.segmentId;
    const segmentId = typeof segmentState === 'number' ? segmentState : segmentState === null || segmentState === void 0 ? void 0 : segmentState.id;
    const conditions = (_b = location.state) === null || _b === void 0 ? void 0 : _b.conditions;
    const segmentFromLS = getFromLS({ key: 'clientsSegment' });
    const filtersFromLS = getFromLS({ key: localStorageKey });
    const [segment, setSegment] = useState(conditions ? null : (segmentFromLS === null || segmentFromLS === void 0 ? void 0 : segmentFromLS.id) ? segmentFromLS : null);
    const [filters, setFilters] = useState(conditions
        ? buildRFMConditions(conditions, currency)
        : ((_c = filtersFromLS === null || filtersFromLS === void 0 ? void 0 : filtersFromLS.filters) === null || _c === void 0 ? void 0 : _c.length)
            ? filtersFromLS
            : null);
    const compiledFilters = useMemo(() => compileFilters((filters === null || filters === void 0 ? void 0 : filters.filters) || []), [filters]);
    const updateFilters = useCallback((filters) => {
        if (!filters.length)
            reset();
        else {
            const state = { filters, touched: true };
            setFilters(state);
            addToLS({ key: localStorageKey, value: state });
        }
    }, []);
    const reset = useCallback(() => {
        setSegment(null);
        addToLS({ key: 'clientsSegment', value: {} });
        setFilters(null);
        addToLS({ key: localStorageKey, value: { filters: [], touched: false } });
        window.history.replaceState({}, '');
    }, []);
    const untouchLS = useCallback((segment) => {
        if (segment) {
            addToLS({ key: 'clientsSegment', value: segment });
            setSegment(segment);
        }
        if (filters === null || filters === void 0 ? void 0 : filters.filters.length) {
            const state = Object.assign(Object.assign({}, filters), { touched: false });
            setFilters(state);
            addToLS({ key: localStorageKey, value: state });
        }
    }, [filters]);
    const { data: loadedSegment, isFetching: isLoading } = useGetSegmentQuery({ id: segmentId || 0 }, { skip: !segmentId, refetchOnMountOrArgChange: true });
    useEffect(() => {
        if (isLoading || !loadedSegment)
            return;
        if (loadedSegment.type === 'segment') {
            addToLS({ key: 'clientsSegment', value: loadedSegment });
            setSegment(loadedSegment);
            addToLS({
                key: localStorageKey,
                value: { filters: loadedSegment.configuration, touched: false },
            });
            setFilters({ filters: loadedSegment.configuration, touched: false });
        }
        if (loadedSegment.type === 'rfm') {
            addToLS({ key: 'clientsSegment', value: {} });
            setSegment(null);
            const configuration = [
                {
                    code: 'clientRfmSegments',
                    text: `${t('RFM сегменты')}: ${t(loadedSegment.name)}`,
                    rawConditions: {
                        op: 'include',
                        checkboxes: { [loadedSegment.id]: true },
                    },
                },
            ];
            addToLS({
                key: localStorageKey,
                value: { filters: configuration, touched: false },
            });
            setFilters({ filters: configuration, touched: false });
        }
    }, [isLoading]);
    return {
        segment: segment || undefined,
        filters,
        compiledFilters,
        updateFilters,
        reset,
        untouchLS,
    };
};
