import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { createHref, ROUTES } from '@/app/pages/routes';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { FlowArrow } from '@/icons/FlowArrow';
import { brandHasFlowFeature } from '@/utils/brandHasFlowFeature';
import styles from './styles.module.scss';
export const MailingFlowIcon = ({ flowId, }) => {
    const { t } = useTranslation();
    const { brand } = useCurrentUser();
    const path = generatePath(ROUTES.FLOWS_EDIT, { flowId: flowId.toString() });
    const tooltipContent = brandHasFlowFeature(brand === null || brand === void 0 ? void 0 : brand.brand) ? (_jsxs(Trans, Object.assign({ i18nKey: 'MAILING_FLOW_TOOLTIP' }, { children: ["\u0412\u0445\u043E\u0434\u0438\u0442 \u0432 ", _jsx("a", Object.assign({ href: createHref(path) }, { children: "\u0446\u0435\u043F\u043E\u0447\u043A\u0443" }))] }))) : (t('Входит в цепочку'));
    return (_jsx(Tooltip, Object.assign({ title: tooltipContent, placement: 'top' }, { children: _jsx("span", Object.assign({ className: styles.flowIcon }, { children: _jsx(FlowArrow, {}) })) })));
};
