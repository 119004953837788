import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { RepeatInfinity } from '@/icons/flow/RepeatInfinity';
import { RepeatOneTime } from '@/icons/flow/RepeatOneTime';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const LoopForm = () => {
    const { t } = useTranslation();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const handleUpdateRepeat = (value) => {
        selectedNode.update({
            repeat: value,
        });
    };
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t('Запускать ветку') })), _jsx(RadioGroup, { className: styles.group, name: 'branch-repeat', value: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.repeat, onChange: handleUpdateRepeat, items: [
                    {
                        value: 'one-time',
                        label: (_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsxs("div", Object.assign({ className: styles.label }, { children: [_jsx(RepeatOneTime, {}), t('Один раз')] })), _jsx("div", Object.assign({ className: styles.description }, { children: t('Ветка сработает только один раз на клиента') }))] }))),
                    },
                    {
                        value: 'infinity',
                        label: (_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsxs("div", Object.assign({ className: styles.label }, { children: [_jsx(RepeatInfinity, {}), t('Каждый раз')] })), _jsx("div", Object.assign({ className: styles.description }, { children: t('Ветка сработает при каждой активации триггера') }))] }))),
                    },
                ], disabled: !isEditMode })] })));
};
