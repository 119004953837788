import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { SearchInput } from '@/components/SearchInput/SearchInput';
import styles from './styles.module.scss';
export const SearchPanel = memo(({ className, placeholder, hasAutoFocus = false, hasBottomMargin = false, hasMaxWidth = false, name, onApplySearch, }) => {
    const [searchText, setSearchText] = useState('');
    const handleApplySearch = useCallback(() => {
        onApplySearch(searchText);
    }, [onApplySearch, searchText]);
    const handleKeyUp = useCallback((e) => {
        if (e.key === 'Enter')
            handleApplySearch();
    }, [handleApplySearch]);
    const handleResetSearch = useCallback(() => {
        setSearchText('');
    }, [onApplySearch]);
    const handleChangeSearch = useCallback((value) => {
        if (!value)
            onApplySearch('');
        else
            setSearchText(value);
    }, [handleResetSearch]);
    return (_jsx(SearchInput, { className: classNames({
            [styles.bottomMargin]: hasBottomMargin,
            [styles.maxWidth]: hasMaxWidth,
        }, className), name: name, value: searchText, onChange: handleChangeSearch, onKeyUp: handleKeyUp, onReset: handleResetSearch, debounceDelay: 0, placeholder: placeholder, hasAutoFocus: hasAutoFocus }));
});
