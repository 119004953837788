import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { SMSFormSender } from './SMSFormSender';
import { SMSFormTest } from './SMSFormTest';
import { SMSFormText } from './SMSFormText';
export const SMSForm = () => {
    var _a;
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const [smsText, setSmsText] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms) === null || _a === void 0 ? void 0 : _a.smsText) || '');
    return (_jsxs(_Fragment, { children: [_jsx(SMSFormSender, {}), _jsx(SMSFormText, { smsText: smsText, onChangeSmsText: setSmsText }), _jsx(SMSFormTest, { smsText: smsText })] }));
};
