import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Description } from '~/src/components/arbitration/ArbitrationGroup/helpers/Description';
import { ArbitrationGroupPanel } from '~/src/components/arbitration/ArbitrationGroupPanel/ArbitrationGroupPanel';
import { Autocomplete } from '~/src/components/Autocomplete/Autocomplete';
import { getSelectedGroup } from '../FormOfferTabs';
import { OfferItem } from './OfferItem';
import styles from './styles.module.scss';
export const ChooseOfferPosition = ({ currency, newOffer, isGroupsExist, offerTitle, offersList, priority, currentGroup, arbitrationPriorityGuid, offerInsideList, options, setOfferInsideList, setOffersList, setCurrentGroup, }) => {
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();
    const handleChangeList = ({ isFirstOffer, guid, edge, }) => {
        if (isFirstOffer) {
            setOffersList([newOffer]);
            setValue('arbitrationPriorityAfter', null);
            setValue('arbitrationGroupGuid', currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.guid);
            return;
        }
        setOffersList((prevList) => {
            var _a;
            const newListOffer = prevList.filter((offer) => offer.guid !== (newOffer === null || newOffer === void 0 ? void 0 : newOffer.guid));
            const dropItemIndex = newListOffer.findIndex((offer) => offer.guid === guid);
            const insertionIndex = edge === 'top' ? dropItemIndex : dropItemIndex + 1;
            setValue('arbitrationGroupGuid', currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.guid);
            setValue('arbitrationPriorityAfterError', false);
            if (insertionIndex !== -1)
                newListOffer.splice(insertionIndex, 0, newOffer);
            const newOfferPrevIndex = newListOffer.findIndex((offer) => offer.guid === (newOffer === null || newOffer === void 0 ? void 0 : newOffer.guid)) - 1;
            setValue('arbitrationPriorityAfter', ((_a = newListOffer[newOfferPrevIndex]) === null || _a === void 0 ? void 0 : _a.guid) || null);
            return newListOffer;
        });
        setOfferInsideList(true);
    };
    const handleSelectGroup = (value) => {
        const { currentGroup, offersList } = getSelectedGroup(priority, value === null || value === void 0 ? void 0 : value.id);
        if (arbitrationPriorityGuid && !offerInsideList)
            setOffersList(offersList.filter((i) => i.guid !== arbitrationPriorityGuid));
        else
            setOffersList(offersList);
        setCurrentGroup(currentGroup);
        setOfferInsideList(false);
        setValue('arbitrationPriorityAfter', undefined, {
            shouldValidate: false,
            shouldDirty: true,
        });
    };
    const [overEmptyList, setOverEmptyList] = useState(false);
    const listRef = useRef(null);
    const newOfferItemOffsetTop = watch('newOfferItemOffsetTop');
    useEffect(() => {
        const listElement = listRef.current;
        listElement.scrollTop = newOfferItemOffsetTop - listElement.offsetTop - 96;
        if (!listElement)
            return;
        let dropTargetCleanup = () => { };
        if ((offersList === null || offersList === void 0 ? void 0 : offersList.length) === 0)
            dropTargetCleanup = dropTargetForElements({
                element: listElement,
                onDrag: () => {
                    setOverEmptyList(true);
                },
                onDragLeave: () => {
                    setOverEmptyList(false);
                },
                onDrop() {
                    setOverEmptyList(false);
                    setValue('newOfferItemOffsetTop', 0);
                    handleChangeList({
                        isFirstOffer: true,
                    });
                    setOfferInsideList(true);
                },
            });
        const autoScrollCleanup = autoScrollForElements({
            element: listElement,
            getConfiguration() {
                return {
                    maxScrollSpeed: 'fast',
                };
            },
        });
        return combine(autoScrollCleanup, dropTargetCleanup);
    }, [newOfferItemOffsetTop, offersList]);
    return (_jsxs(ArbitrationGroupPanel, { children: [_jsxs("div", Object.assign({ className: styles.chooseGroupSelect }, { children: [_jsx("span", Object.assign({ className: styles.chooseGroupSelectTitle }, { children: t('Выберите группу') })), _jsxs("div", Object.assign({ className: styles.chooseGroupSelectContainer }, { children: [isGroupsExist && (_jsx(Autocomplete, { className: styles.chooseGroupSelectInput, value: options.find((i) => i.id === (currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.guid)) || options[0], onChange: handleSelectGroup, options: options, getOptionKey: (option) => option === null || option === void 0 ? void 0 : option.id, getOptionLabel: (option) => option === null || option === void 0 ? void 0 : option.title, asSelect: true })), currentGroup && (_jsx(Description, { showArrow: false, group: currentGroup, isExpanded: true }))] }))] })), _jsx("ul", Object.assign({ ref: listRef, className: classNames(styles.offerList, {
                    [styles.emptyGroupPlaceholder]: !(offersList === null || offersList === void 0 ? void 0 : offersList.length) && overEmptyList,
                }) }, { children: offersList === null || offersList === void 0 ? void 0 : offersList.map((group, index) => (_jsx(OfferItem, { arbitrationPriorityGuid: arbitrationPriorityGuid, offerTitle: offerTitle, currency: currency, parentType: currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.type, group: group, index: index, handleChangeList: handleChangeList }, group.guid))) }))] }));
};
