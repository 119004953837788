import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useEffect, useRef } from 'react';
export const useDroppableTopLevelBottom = () => {
    const ref = useRef(null);
    useEffect(() => {
        const element = ref.current;
        if (!element)
            return;
        const dropTargetCleanup = dropTargetForElements({
            element,
            canDrop({ source }) {
                return 'kind' in source.data && source.data.kind !== 'promocode';
            },
            getData() {
                return { isTopLevelBottom: true };
            },
            onDrag({ self }) {
                self.element.classList.add('dnd-dragging-over');
            },
            onDragLeave({ self }) {
                self.element.classList.remove('dnd-dragging-over');
            },
            onDrop({ self }) {
                self.element.classList.remove('dnd-dragging-over');
            },
            getIsSticky() {
                return false;
            },
        });
        return dropTargetCleanup;
    }, []);
    return { ref };
};
