var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getErrorMessage } from '@/api/query';
import { useStartFlowMutation } from '@/api/query/flows';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useStartFlow = () => {
    const addToast = useToastContext();
    const [start, { isLoading }] = useStartFlowMutation();
    const startFlow = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield start({ id });
        if ('error' in result) {
            const { status } = result.error;
            if (!status)
                addToast({ type: 'error', message: getErrorMessage(result.error) });
            return false;
        }
        return true;
    });
    return {
        startFlow,
        isStartingFlow: isLoading,
    };
};
