import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { BonusMovementSmallAlt } from '@/icons/BonusMovementSmallAlt';
import { PercentAlt } from '@/icons/PercentAlt';
import styles from './styles.module.scss';
export const FormArbitrationGroupOperatorHelp = ({ operator, currency }) => {
    const { t } = useTranslation();
    const iconsHelp = (_jsxs("div", Object.assign({ className: styles.helpIconsWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.helpIconsRow }, { children: [_jsx("div", Object.assign({ className: styles.currencyIcon }, { children: currency })), _jsx("div", { children: _jsx(PercentAlt, {}) }), _jsx("div", { children: _jsx(BonusMovementSmallAlt, {}) })] })), _jsx("div", Object.assign({ className: styles.helpIconsText }, { children: t('Бонусная и скидочная акции срабатывают вместе') }))] })));
    if (operator === 'sum')
        return (_jsx("div", Object.assign({ className: styles.help }, { children: _jsxs(Trans, Object.assign({ i18nKey: 'ARBITRATION_OPERATOR_HELP_SUM' }, { children: ["\u041F\u043E\u0440\u044F\u0434\u043E\u043A \u0432\u043D\u0443\u0442\u0440\u0438 \u0433\u0440\u0443\u043F\u043F\u044B", ' ', _jsx("span", Object.assign({ className: classNames(styles.bold, styles.red) }, { children: "\u0432\u0430\u0436\u0435\u043D" })), ": \u043A\u0430\u0436\u0434\u0430\u044F \u0441\u043B\u0435\u0434\u0443\u044E\u0449\u0430\u044F \u043F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u0441\u044F \u043F\u043E\u0432\u0435\u0440\u0445 \u043F\u0440\u0435\u0434\u044B\u0434\u0443\u0449\u0435\u0439"] })) })));
    if (operator === 'max')
        return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.help }, { children: _jsxs(Trans, Object.assign({ i18nKey: 'ARBITRATION_OPERATOR_HELP_MAX' }, { children: ["\u041F\u043E\u0440\u044F\u0434\u043E\u043A \u0432\u043D\u0443\u0442\u0440\u0438 \u0433\u0440\u0443\u043F\u043F\u044B", ' ', _jsx("span", Object.assign({ className: classNames(styles.bold, styles.green) }, { children: "\u043D\u0435 \u0432\u0430\u0436\u0435\u043D" })), ": \u0441\u0440\u0435\u0434\u0438 \u0441\u043A\u0438\u0434\u043E\u0447\u043D\u044B\u0445 \u0430\u043A\u0446\u0438\u0439 \u043F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u0441\u044F \u043E\u0434\u043D\u0430, \u043A\u043E\u0442\u043E\u0440\u0430\u044F \u0434\u0430\u0441\u0442 \u0431\u00F3\u043B\u044C\u0448\u0443\u044E \u0441\u043A\u0438\u0434\u043A\u0443; \u0441\u0440\u0435\u0434\u0438 \u0431\u043E\u043D\u0443\u0441\u043D\u044B\u0445 \u2014 \u043E\u0434\u043D\u0430, \u043A\u043E\u0442\u043E\u0440\u0430\u044F \u0434\u0430\u0441\u0442 \u0431\u043E\u043B\u044C\u0448\u0435 \u0431\u043E\u043D\u0443\u0441\u043E\u0432"] })) })), iconsHelp] }));
    if (operator === 'priority')
        return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.help }, { children: _jsxs(Trans, Object.assign({ i18nKey: 'ARBITRATION_OPERATOR_HELP_PRIORITY' }, { children: ["\u041F\u043E\u0440\u044F\u0434\u043E\u043A \u0432\u043D\u0443\u0442\u0440\u0438 \u0433\u0440\u0443\u043F\u043F\u044B", ' ', _jsx("span", Object.assign({ className: classNames(styles.bold, styles.red) }, { children: "\u0432\u0430\u0436\u0435\u043D" })), ": \u043F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u0441\u044F \u0442\u0430, \u0447\u0442\u043E \u0440\u0430\u0441\u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0430 \u0432\u044B\u0448\u0435"] })) })), iconsHelp] }));
    return null;
};
