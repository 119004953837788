import { useReactFlow } from '@xyflow/react';
import { useState } from 'react';
import { createChannelNode } from '../../nodes/ChannelNode/utils';
import { createChannelReceivedNode } from '../../nodes/ChannelReceivedNode/utils';
import { createConditionNode } from '../../nodes/ConditionsNode/utils';
import { createDelayNode } from '../../nodes/DelayNode/utils';
import { createEmailReceivedNode } from '../../nodes/EmailReceivedNode/utils';
import { createLoopNode } from '../../nodes/LoopNode/utils';
import { findNodeByUUID, replaceNodeEdges, updateFlow, } from '../../nodes/utils';
export const useSelectedNode = (onChange) => {
    const flow = useReactFlow();
    const [selectedNodeUUID, setSelectedNodeUUID] = useState('');
    const [selectedNodeHandleIdx, setSelectedNodeHandleIdx] = useState(null);
    const selectUUID = (nodeUUID, handleIdx = null) => {
        const clickedNode = findNodeByUUID(flow, nodeUUID);
        if (clickedNode) {
            setSelectedNodeUUID(clickedNode.uuid);
            setSelectedNodeHandleIdx(handleIdx);
        }
    };
    const reset = () => {
        setSelectedNodeUUID('');
    };
    const update = (data, uuid) => {
        var _a, _b, _c;
        const clickedNode = findNodeByUUID(flow, uuid || selectedNodeUUID);
        const clickedNodeData = clickedNode.data;
        if (clickedNode && clickedNodeData) {
            let newNode = undefined;
            if (clickedNode.type === 'loop')
                newNode = createLoopNode(Object.assign(Object.assign(Object.assign({}, clickedNodeData), data), { position: clickedNode.position }));
            if (clickedNode.type === 'delay')
                newNode = createDelayNode(Object.assign(Object.assign(Object.assign({}, clickedNodeData), data), { position: clickedNode.position }));
            if (clickedNode.type === 'channel')
                newNode = createChannelNode(Object.assign(Object.assign(Object.assign({}, clickedNodeData), data), { position: clickedNode.position }));
            if (clickedNode.type === 'conditions')
                newNode = createConditionNode(Object.assign(Object.assign(Object.assign({}, clickedNodeData), data), { position: clickedNode.position }));
            if (clickedNode.type === 'channel-received')
                newNode = createChannelReceivedNode(Object.assign(Object.assign(Object.assign({}, clickedNodeData), data), { position: clickedNode.position }));
            if (clickedNode.type === 'email-received')
                newNode = createEmailReceivedNode(Object.assign(Object.assign(Object.assign({}, clickedNodeData), data), { position: clickedNode.position }));
            if (!newNode)
                return;
            newNode.uuid = uuid || selectedNodeUUID;
            const { addEdges, removeEdges } = replaceNodeEdges(flow, clickedNode, newNode, false);
            if (newNode.type === 'conditions') {
                const edgeFromAddButton = addEdges.find((e) => e.source === (newNode === null || newNode === void 0 ? void 0 : newNode.id) && e.sourceHandle === 'add');
                const newData = newNode.data;
                if (edgeFromAddButton && ((_a = newData.configuration) === null || _a === void 0 ? void 0 : _a.length))
                    edgeFromAddButton.sourceHandle =
                        ((_c = (_b = newData.configuration) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.code) + '-0';
            }
            if (newNode.type === 'channel-received' ||
                newNode.type === 'email-received') {
                const edgeFromAddButton = addEdges.find((e) => e.source === (newNode === null || newNode === void 0 ? void 0 : newNode.id) && e.sourceHandle === 'add');
                const newData = newNode.data;
                if (edgeFromAddButton && newData.conditions.length)
                    edgeFromAddButton.sourceHandle = newData.conditions[0] + '-0';
            }
            updateFlow(flow, {
                addNodes: [newNode],
                removeNodes: [clickedNode],
                addEdges,
                removeEdges,
            });
            onChange();
        }
    };
    const getData = () => {
        const clickedNode = findNodeByUUID(flow, selectedNodeUUID);
        if (!clickedNode)
            return null;
        return clickedNode.data;
    };
    const getType = () => {
        const clickedNode = findNodeByUUID(flow, selectedNodeUUID);
        if (!clickedNode)
            return undefined;
        return clickedNode.type;
    };
    return {
        uuid: selectedNodeUUID,
        getType,
        getData,
        handleIdx: selectedNodeHandleIdx,
        selectUUID,
        reset,
        update,
    };
};
