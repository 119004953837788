import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { useMailingStatsModal } from '@/components/flow/InteractiveFlow/forms/ChannelForm/useMailingStatsModal';
import { MailingRevenueProfitHelp } from '@/components/MailingRevenueProfitHelp/MailingRevenueProfitHelp';
import { MailingStatsModal } from '@/components/MailingStatsModal/MailingStatsModal';
import { Table } from '@/components/Table/Table';
import { notNull } from '@/utils/notNull';
import { convertFlowsToTable } from './convertFlowsToTable';
import { FlowsTableEmpty } from './FlowsTableEmpty';
import styles from './styles.module.scss';
import { useOpenPurchaseFilter } from './useOpenPurchaseFilter';
export const FlowsTable = ({ flows, isLoading, onCreateFlow, onComposeFlow, onEditFlow, onDeleteFlow, }) => {
    const { t } = useTranslation();
    const { currency, shouldDisplayProfit } = useCurrentUser();
    const { purchaseUrl, openPurchaseFilter } = useOpenPurchaseFilter();
    const mailingStatsModal = useMailingStatsModal();
    const [selectedMailing, setSelectedMailing] = useState(null);
    const userHasAccessTo = useUserPermissions();
    const userHasFlowManage = userHasAccessTo('FLOWS_MANAGE');
    const columns = [
        {
            id: 'title',
            title: t('Название'),
        },
        {
            id: 'channels',
            title: t('Каналы'),
        },
        {
            id: 'roi',
            title: t('ROI'),
            tooltip: t(`TOOLTIP_ROI_${shouldDisplayProfit ? 'PROFIT' : 'REVENUE'}`),
            align: 'right',
        },
        {
            id: 'cr',
            title: t('CR'),
            tooltip: t('TOOLTIP_MAILING_CR'),
            align: 'right',
        },
        {
            id: 'profitOrRevenue',
            title: t(shouldDisplayProfit ? 'Прибыль' : 'Выручка'),
            tooltip: _jsx(MailingRevenueProfitHelp, {}),
            align: 'right',
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            tooltip: t('Сумма затрат на рассылку за выбранный период. Учитываются списания бонусов, скидки и затраты на рассылки по всем каналам'),
            align: 'right',
        },
        {
            id: 'date',
            title: t('Дата'),
            align: 'right',
        },
        { id: 'actions' },
    ];
    const rows = convertFlowsToTable({
        flows,
        shouldDisplayProfit,
        currency,
        purchaseUrl,
        openPurchaseFilter,
        openMailingModal: (mailing) => {
            setSelectedMailing(mailing);
            mailingStatsModal.openModal(mailing.id);
        },
        actions: (flow) => [
            !flow.config
                ? {
                    id: 'view',
                    label: t('Просмотр'),
                    onClick: () => onEditFlow(flow),
                }
                : null,
            flow.config
                ? {
                    id: 'edit',
                    tooltip: !userHasFlowManage && t('Недоступно для вашей роли'),
                    label: t('Редактировать'),
                    onClick: () => onEditFlow(flow),
                    isDisabled: !userHasFlowManage,
                }
                : null,
            {
                id: 'delete',
                tooltip: !userHasFlowManage && t('Недоступно для вашей роли'),
                label: flow.config ? t('Удалить') : t('Распустить'),
                onClick: () => onDeleteFlow(flow),
                isDanger: true,
                isDisabled: !userHasFlowManage,
            },
        ].filter(notNull),
    });
    const onRowClick = (row) => {
        const clicked = flows.find((flow) => flow.id === row.id);
        if (clicked)
            onEditFlow(clicked);
    };
    if (!flows.length)
        return (_jsx(FlowsTableEmpty, { onCreateFlow: onCreateFlow, onComposeFlow: onComposeFlow }));
    return (_jsxs(_Fragment, { children: [_jsx(Table, { className: styles.table, cellClassNames: {
                    1: styles.channelColumn,
                    7: styles.modalButtonColumn,
                }, isLoading: isLoading, columns: columns, rows: rows, onRowClick: onRowClick }), !!mailingStatsModal.mailingStats && !!selectedMailing && (_jsx(MailingStatsModal, { isOpen: mailingStatsModal.isOpen, onClose: () => {
                    setSelectedMailing(null);
                    mailingStatsModal.closeModal();
                }, periodStart: mailingStatsModal.periodStart, periodEnd: mailingStatsModal.periodEnd, onChangePeriod: mailingStatsModal.changePeriod(selectedMailing.id), mailing: selectedMailing, stats: mailingStatsModal.mailingStats, isManual: false, currency: currency, isLoading: mailingStatsModal.isLoading }))] }));
};
