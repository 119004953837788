export const getEdgeId = (edge) => {
    const source = edge.source || '';
    const target = edge.target || '';
    const ms = new Date().getTime();
    const id = edge.id || `${source}--${target}--${ms}`;
    return id;
};
export const createEdge = (edge, hidden = true) => {
    var _a;
    let sourceConditionsIndex = undefined;
    if (edge.sourceHandle) {
        const index = edge.sourceHandle.split('-')[1];
        sourceConditionsIndex = index !== undefined ? Number(index) : undefined;
    }
    return Object.assign(Object.assign({}, edge), { id: getEdgeId(edge), type: edge.type || 'custom', source: edge.source || '', target: edge.target || '', style: Object.assign(Object.assign({}, edge.style), { opacity: hidden ? 0 : 1 }), selectable: !!((_a = edge.data) === null || _a === void 0 ? void 0 : _a.isInteractive), focusable: false, deletable: false, sourceConditionsIndex });
};
