import { ROUTES } from '@/app/pages/routes';
export const BREADCRUMBS_MAP = {
    [ROUTES.DASHBOARD]: { root: 'Аналитика', name: 'Сводка' },
    [ROUTES.RFM]: { root: 'Аналитика', name: 'Smart RFM' },
    [ROUTES.REPORTS]: { root: 'Аналитика', name: 'Отчеты' },
    [ROUTES.EXPORTS]: { root: 'Аналитика', name: 'Выгрузки' },
    [ROUTES.CLIENTS]: { root: 'Клиенты', name: 'Список клиентов' },
    [ROUTES.SEGMENTS]: { root: 'Клиенты', name: 'Сегменты' },
    [ROUTES.PURCHASES]: { root: 'Клиенты', name: 'Покупки клиентов' },
    [ROUTES.EVENTS]: { root: 'Клиенты', name: 'Все события' },
    [ROUTES.MAILINGS_MANUAL]: {
        root: 'Рассылки',
        name: 'Ручные',
    },
    [ROUTES.IMPORTS]: {
        root: 'Импорт данных',
    },
    // для консистентности, у импортов нет роута без id
    '/app/import/create': {
        root: 'Новый импорт',
    },
    [ROUTES.MAILINGS_MANUAL_CREATE]: {
        root: 'Рассылки',
        name: 'Ручные',
        prevStepLink: ROUTES.MAILINGS_MANUAL,
    },
    [ROUTES.MAILINGS_MANUAL_EDIT]: {
        root: 'Рассылки',
        name: 'Ручные',
        prevStepLink: ROUTES.MAILINGS_MANUAL,
    },
    [ROUTES.MAILINGS_AUTOMATIC]: {
        root: 'Рассылки',
        name: 'Автоматические',
    },
    [ROUTES.MAILINGS_AUTOMATIC_CREATE]: {
        root: 'Рассылки',
        name: 'Автоматические',
        prevStepLink: ROUTES.MAILINGS_AUTOMATIC,
    },
    [ROUTES.MAILINGS_AUTOMATIC_EDIT]: {
        root: 'Рассылки',
        name: 'Автоматические',
        prevStepLink: ROUTES.MAILINGS_AUTOMATIC,
    },
    [ROUTES.FLOWS]: { root: 'Цепочки' },
    [ROUTES.FLOWS_CREATE]: { root: 'Цепочки' },
    [ROUTES.FLOWS_EDIT]: { root: 'Цепочки' },
    [ROUTES.WALLET_CARDS]: {
        root: 'Инструменты',
        name: 'Карты Wallet',
        prevStepLink: ROUTES.WALLET_CARDS,
    },
    [ROUTES.WALLET_CARDS_CREATE]: {
        root: 'Инструменты',
        name: 'Карты Wallet',
        prevStepLink: ROUTES.WALLET_CARDS,
    },
    [ROUTES.WALLET_CARDS_EDIT]: {
        root: 'Инструменты',
        name: 'Карты Wallet',
        prevStepLink: ROUTES.WALLET_CARDS,
    },
    [ROUTES.GEO_PUSH_CREATE]: {
        root: 'Инструменты',
        name: 'Карты Wallet',
        prevStepLink: ROUTES.WALLET_CARDS,
    },
    [ROUTES.GEO_PUSH_EDIT]: {
        root: 'Инструменты',
        name: 'Карты Wallet',
        prevStepLink: ROUTES.WALLET_CARDS,
    },
    [ROUTES.OFFERS]: { root: 'Инструменты', name: 'Акции' },
    [ROUTES.OFFERS_CREATE]: {
        root: 'Инструменты',
        name: 'Акции',
        prevStepLink: ROUTES.OFFERS,
    },
    [ROUTES.OFFERS_EDIT]: {
        root: 'Инструменты',
        name: 'Акции',
        prevStepLink: ROUTES.OFFERS,
    },
    [ROUTES.ARBITRATION]: {
        root: 'Инструменты',
        name: 'Арбитраж',
    },
    [ROUTES.PROMOCODES]: { root: 'Инструменты', name: 'Промокоды' },
    [ROUTES.PROMOCODES_CREATE]: {
        root: 'Инструменты',
        name: 'Промокоды',
        prevStepLink: ROUTES.PROMOCODES,
    },
    [ROUTES.PROMOCODES_EDIT]: {
        root: 'Инструменты',
        name: 'Промокоды',
        prevStepLink: ROUTES.PROMOCODES,
    },
    [ROUTES.PROMOCODES_FRIEND]: {
        root: 'Инструменты',
        name: 'Приведи друга',
    },
    [ROUTES.PROMOCODES_FRIEND_CREATE]: {
        root: 'Инструменты',
        name: 'Приведи друга',
        prevStepLink: ROUTES.PROMOCODES_FRIEND,
    },
    [ROUTES.PROMOCODES_FRIEND_EDIT]: {
        root: 'Инструменты',
        name: 'Приведи друга',
        prevStepLink: ROUTES.PROMOCODES_FRIEND,
    },
    [ROUTES.GIFT_CARDS]: {
        root: 'Инструменты',
        name: 'Подарочные карты',
    },
    [ROUTES.GIFT_CARDS_CREATE]: {
        root: 'Инструменты',
        name: 'Подарочные карты',
        prevStepLink: ROUTES.GIFT_CARDS,
    },
    [ROUTES.GIFT_CARDS_EDIT]: {
        root: 'Инструменты',
        name: 'Подарочные карты',
        prevStepLink: ROUTES.GIFT_CARDS,
    },
    [ROUTES.GIFT_CARDS_INSTANCES]: {
        root: 'Инструменты',
        name: 'Подарочные карты',
        prevStepLink: ROUTES.GIFT_CARDS,
    },
    [ROUTES.BILLING]: { root: 'Биллинг', name: 'Детализация' },
    [ROUTES.BILLING_INVOICES]: { root: 'Биллинг', name: 'Счета' },
    [ROUTES.SETTINGS_USERS]: { root: 'Настройка', name: 'Пользователи' },
    [ROUTES.SETTINGS_SHOPS]: { root: 'Настройка', name: 'Точки продаж' },
};
