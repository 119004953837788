import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { TelegramFormButton } from './TelegramFormButton';
import { TelegramFormImage } from './TelegramFormImage';
import { TelegramFormTest } from './TelegramFormTest';
import { TelegramFormText } from './TelegramFormText';
import { TelegramFormUrl } from './TelegramFormUrl';
export const TelegramForm = () => {
    var _a;
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const [telegramText, setTelegramText] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram) === null || _a === void 0 ? void 0 : _a.telegramText) || '');
    return (_jsxs(_Fragment, { children: [_jsx(TelegramFormImage, {}), _jsx(TelegramFormText, { telegramText: telegramText, onChangeTelegramText: setTelegramText }), _jsx(TelegramFormButton, {}), _jsx(TelegramFormUrl, {}), _jsx(TelegramFormTest, { telegramText: telegramText })] }));
};
