import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCounterListQuery } from '@/api/query/counters';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { FormMailing } from '@/forms/FormMailing/FormMailing';
import { useBrandSegments } from '@/hooks/useBrandSegments';
import { useDeleteMailing } from '../Mailings/useDeleteMailing';
import { ROUTES } from '../routes';
import { convertMailingToFormValues } from './convertMailingToFormValues';
import { MailingsFormLoading } from './MailingsFormLoading';
import styles from './styles.module.scss';
import { useCalculate } from './useCalculate';
import { useEmailMailings } from './useEmailMailings';
import { useEmptyFormDefaultValues } from './useEmptyFormDefaultValues';
import { useGiftCards } from './useGiftCards';
import { useLevels } from './useLevels';
import { useMailingData } from './useMailingData';
import { useOffers } from './useOffers';
import { usePromocodes } from './usePromocodes';
import { useRefreshEmailPreview } from './useRefreshEmailPreview';
import { useSaveMailing } from './useSaveMailing';
import { useSendTestMessage } from './useSendTestMessage';
import { useSetMailingActive } from './useSetMailingActive';
import { useTemplatesInfiniteScrollData } from './useTemplatesInfiniteScrollData';
import { useUploadImage } from './useUploadImage';
export const MailingsForm = ({ mailingId: defaultMailingId, flow: defaultFlow, isManual = false, isStandalone = false, onGoBack, }) => {
    var _a, _b, _c, _d;
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { brand, user, timeZone } = useCurrentUser();
    const { data: counters = [] } = useGetCounterListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const type = isManual ? 'manual' : 'auto';
    const isClone = !!((_a = location.state) === null || _a === void 0 ? void 0 : _a.clone);
    const routerMailingId = isClone ? (_b = location.state) === null || _b === void 0 ? void 0 : _b.mailingId : params.mailingId;
    const providedMailingId = routerMailingId
        ? Number(routerMailingId)
        : defaultMailingId;
    const mailingId = !isClone ? providedMailingId : undefined;
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort) || '';
    const onGoToList = onGoBack ||
        (() => {
            const route = isManual
                ? ROUTES.MAILINGS_MANUAL
                : ROUTES.MAILINGS_AUTOMATIC;
            navigate(route);
        });
    const rfmSegment = (_c = location.state) === null || _c === void 0 ? void 0 : _c.segment;
    const segments = useBrandSegments();
    const offers = useOffers();
    const promocodes = usePromocodes();
    const giftCards = useGiftCards();
    const levels = useLevels();
    const emailMailings = useEmailMailings(mailingId);
    const templatesData = useTemplatesInfiniteScrollData();
    const { globalKey } = (brand === null || brand === void 0 ? void 0 : brand.brand) || { globalKey: '' };
    const deleteMailing = useDeleteMailing(globalKey, { onSuccess: onGoToList });
    const refreshEmailPreview = useRefreshEmailPreview();
    const uploadImage = useUploadImage();
    const calculate = useCalculate(type);
    const sendTestMessage = useSendTestMessage();
    const setMailingActive = useSetMailingActive(mailingId);
    const { selectedMailing, isLoading } = useMailingData(providedMailingId);
    const defaultValues = useEmptyFormDefaultValues(rfmSegment);
    const { saveMailing, isSubmitting, error } = useSaveMailing({
        type,
        id: mailingId,
        flowId: ((_d = selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.flow) === null || _d === void 0 ? void 0 : _d.id) || (defaultFlow === null || defaultFlow === void 0 ? void 0 : defaultFlow.id),
        onSuccess: onGoToList,
    });
    const isReadOnly = type === 'manual' &&
        !isClone &&
        (selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.status) &&
        (selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.status) !== 0 &&
        (selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.status) !== 5;
    if (!brand)
        return null;
    const form = (_jsx(FormMailing, { type: type, isClone: isClone, flow: (selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.flow) || defaultFlow, unlayerUrl: '/components/unlayer/embed.1.5.30.js', unlayerProjectId: 4335, onSubmit: (values) => saveMailing(values, false), error: error, isSubmitting: isSubmitting, defaultValues: selectedMailing
            ? convertMailingToFormValues(selectedMailing, isClone, timeZone)
            : defaultValues, currency: currency, isEdit: !!mailingId, onDelete: () => {
            if (mailingId && selectedMailing)
                deleteMailing(selectedMailing);
        }, onSaveDraft: (values) => saveMailing(values, true), channelsInput: isStandalone ? 'buttons' : 'tabs', 
        //
        brand: brand === null || brand === void 0 ? void 0 : brand.brand, user: user, 
        //
        counters: counters, segments: segments, offers: offers, promocodes: promocodes, giftCards: giftCards, levels: levels, emailMailings: emailMailings, calculate: calculate, onSendTestMessage: sendTestMessage, templatesData: templatesData, uploadImage: uploadImage, refreshEmailPreview: refreshEmailPreview, setActive: setMailingActive, isReadOnly: !!isReadOnly, 
        //
        stepsClassName: classNames({ [styles.steps]: isStandalone }), panelClassName: classNames({ [styles.panel]: isStandalone }) }));
    if (isLoading && !isStandalone)
        return null;
    if (isLoading && isStandalone)
        return _jsx(MailingsFormLoading, {});
    return isStandalone ? form : _jsx(Container, { children: form });
};
