var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip as MUITooltip, } from '@mui/material';
import classNames from 'classnames';
import { memo } from 'react';
import styles from './styles.module.scss';
export const Tooltip = memo((_a) => {
    var { className, title, html, textAlign = 'left', theme = 'dark', size = 'normal', popperClassName } = _a, props = __rest(_a, ["className", "title", "html", "textAlign", "theme", "size", "popperClassName"]);
    if (!title && !html)
        return props.children;
    const content = html ? (_jsx("span", { dangerouslySetInnerHTML: { __html: html } })) : (title || '');
    const popperModifiers = [
        {
            name: 'offset',
            options: { offset: [0, -3] },
        },
    ];
    return (_jsx(MUITooltip, Object.assign({ classes: {
            tooltip: classNames(styles.tooltip, styles[textAlign], styles[theme], styles[size], className),
            arrow: styles.arrow,
            popper: classNames(styles.popper, popperClassName),
        }, title: content, PopperProps: {
            modifiers: popperModifiers,
        }, arrow: true }, props)));
});
