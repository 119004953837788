import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { COLOR_MAP } from './COLOR_MAP';
import styles from './Parenthesis.module.scss';
export const Parenthesis = ({ group, levelGap = 0, }) => {
    const PARENTHESIS_INITIAL_GAP = 24;
    const isUnordered = group.guid === 'unordered';
    const color = isUnordered ? '#D9D9D9' : COLOR_MAP[group.type];
    const colorVariable = { '--color': color };
    return (_jsx(_Fragment, { children: _jsx("div", { className: styles.itemsParenthesisCover, style: Object.assign({ left: PARENTHESIS_INITIAL_GAP + levelGap }, colorVariable) }) }));
};
