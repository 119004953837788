import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
export const MailingRevenueProfitHelp = () => {
    const { shouldDisplayProfit } = useCurrentUser();
    const docsLink = 'https://maxma.slite.com/app/docs/pD6D15JhLI6oi8#edd486ad';
    const revenueMessage = (_jsxs(Trans, Object.assign({ i18nKey: 'TOOLTIP_MAILING_REVENUE' }, { children: ["\u0421\u0443\u043C\u043C\u0430 \u0437\u0430\u043A\u0430\u0437\u043E\u0432 \u0438 \u0440\u043E\u0437\u043D\u0438\u0447\u043D\u044B\u0445 \u043F\u0440\u043E\u0434\u0430\u0436, \u0441\u0434\u0435\u043B\u0430\u043D\u043D\u044B\u0445 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0430\u043C\u0438 \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u044B \u043B\u043E\u044F\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u0432 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u043F\u0435\u0440\u0438\u043E\u0434\u0430 \u0430\u0442\u0440\u0438\u0431\u0443\u0446\u0438\u0438 (\u043E", ' ', _jsx("a", Object.assign({ href: docsLink, target: '_blank', rel: 'noreferrer' }, { children: "\u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430\u0445 \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F \u0446\u0435\u043B\u0438" })), ' ', "\u0432 \u0411\u0430\u0437\u0435 \u0437\u043D\u0430\u043D\u0438\u0439). \u041E\u0442\u043C\u0435\u043D\u0435\u043D\u043D\u044B\u0435 \u0437\u0430\u043A\u0430\u0437\u044B \u043D\u0435 \u0443\u0447\u0438\u0442\u044B\u0432\u0430\u044E\u0442\u0441\u044F."] })));
    const profitMessage = (_jsxs(Trans, Object.assign({ i18nKey: 'TOOLTIP_MAILING_PROFIT' }, { children: ["\u0412\u0430\u043B\u043E\u0432\u0430\u044F \u043F\u0440\u0438\u0431\u044B\u043B\u044C \u043E\u0442 \u0437\u0430\u043A\u0430\u0437\u043E\u0432 \u0438 \u0440\u043E\u0437\u043D\u0438\u0447\u043D\u044B\u0445 \u043F\u0440\u043E\u0434\u0430\u0436, \u0441\u0434\u0435\u043B\u0430\u043D\u043D\u044B\u0445 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0430\u043C\u0438 \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u044B \u043B\u043E\u044F\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u0432 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u043F\u0435\u0440\u0438\u043E\u0434\u0430 \u0430\u0442\u0440\u0438\u0431\u0443\u0446\u0438\u0438 (\u043E", ' ', _jsx("a", Object.assign({ href: docsLink, target: '_blank', rel: 'noreferrer' }, { children: "\u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430\u0445 \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F \u0446\u0435\u043B\u0438" })), ' ', "\u0432 \u0411\u0430\u0437\u0435 \u0437\u043D\u0430\u043D\u0438\u0439). \u041E\u0442\u043C\u0435\u043D\u0435\u043D\u043D\u044B\u0435 \u0437\u0430\u043A\u0430\u0437\u044B \u043D\u0435 \u0443\u0447\u0438\u0442\u044B\u0432\u0430\u044E\u0442\u0441\u044F."] })));
    return _jsx(_Fragment, { children: shouldDisplayProfit ? profitMessage : revenueMessage });
};
