import { jsx as _jsx } from "react/jsx-runtime";
import { Greater } from '../../ArbitrationOperators/Greater';
import { Max } from '../../ArbitrationOperators/Max';
import { Plus } from '../../ArbitrationOperators/Plus';
import styles from '../styles.module.scss';
export const Operator = ({ operator, levelGap = 0, }) => {
    const OPERATOR_INITIAL_GAP = 42;
    if (operator === 'max')
        return (_jsx(Max, { className: styles.operator, style: { left: OPERATOR_INITIAL_GAP + levelGap } }));
    if (operator === 'priority')
        return (_jsx(Greater, { className: styles.operator, style: { left: OPERATOR_INITIAL_GAP + levelGap } }));
    if (operator === 'sum')
        return (_jsx(Plus, { className: styles.operator, style: { left: OPERATOR_INITIAL_GAP + levelGap } }));
    return null;
};
