import { api } from './';
export const shopGroupApi = api.injectEndpoints({
    endpoints: (build) => ({
        getShopGroupOptions: build.query({
            query: () => '/shop/group/options-list',
            providesTags: ['ShopGroupOptions'],
        }),
        getShopGroupList: build.query({
            query: () => '/shop/group/list',
            providesTags: ['ShopGroupList'],
        }),
        getShopGroupListManual: build.mutation({
            query: () => '/shop/group/list',
        }),
        addShopGroup: build.mutation({
            query(body) {
                return {
                    method: 'POST',
                    url: '/shop/group/add',
                    body,
                };
            },
            invalidatesTags: ['ShopGroupList', 'ShopGroupOptions'],
        }),
        updateShopGroup: build.mutation({
            query(body) {
                return {
                    method: 'POST',
                    url: '/shop/group/set',
                    body,
                };
            },
            invalidatesTags: ['ShopGroupList', 'ShopGroupOptions'],
        }),
        deleteShopGroup: build.mutation({
            query({ id }) {
                return {
                    method: 'POST',
                    url: `/shop/group/delete/${id}`,
                };
            },
            invalidatesTags: ['ShopGroupList', 'ShopGroupOptions'],
        }),
    }),
});
export const { useGetShopGroupOptionsQuery, useGetShopGroupListQuery, useGetShopGroupListManualMutation, useAddShopGroupMutation, useUpdateShopGroupMutation, useDeleteShopGroupMutation, } = shopGroupApi;
