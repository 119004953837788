import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { ArbitrationEditor } from '@/components/arbitration/ArbitrationEditor/ArbitrationEditor';
import { Container } from '@/components/Container/Container';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { Switch } from '@/components/Switch/Switch';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { useNotificateForUnsavedForm } from '@/hooks/useNotificateForUnsavedForm';
import styles from './styles.module.scss';
import { useArbitrationList } from './useArbitrationList';
import { useArbitrationState } from './useArbitrationState';
import { useSaveArbitrationList } from './useSaveArbitrationList';
export const Arbitration = () => {
    const { t } = useTranslation();
    const { brand, currency } = useCurrentUser();
    const userHasAccessTo = useUserPermissions();
    const { data, isLoading, isFetching } = useArbitrationList();
    const { saveArbitrationList } = useSaveArbitrationList();
    const [searchText, setSearchText] = useState('');
    const [isArchivedVisible, setisArchivedVisisble] = useState(false);
    const { priority, unordered, lastSavedPriority, lastSavedUnordered, draftGuids, handleChangeArbitrationList, handleApplyChanges, } = useArbitrationState({
        initialList: data,
        saveArbitrationList,
        isLoading: isFetching,
    });
    useNotificateForUnsavedForm(draftGuids.length > 0);
    if (!brand)
        return null;
    if (isLoading)
        return (_jsx(Container, { children: _jsx(TabsPanel, { isSkeleton: true }) }));
    return (_jsx(Container, Object.assign({ topElement: _jsxs("div", Object.assign({ className: styles.searchPanelWrapper }, { children: [_jsx(SearchPanel, { className: styles.searchPanel, placeholder: t('Поиск'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: setSearchText }), _jsx(Switch, { label: t('Отображать архивные акции и промокоды'), labelClassName: styles.switchLabel, checked: isArchivedVisible, onChange: setisArchivedVisisble })] })), notMobileRightPadding: true }, { children: _jsx(ArbitrationEditor, { priority: priority, unordered: unordered, onChange: handleChangeArbitrationList, lastSavedPriority: lastSavedPriority, lastSavedUnordered: lastSavedUnordered, onApply: handleApplyChanges, searchText: searchText, isArchivedVisible: isArchivedVisible, currency: currency, isLoading: isFetching, isEditingDisabled: !userHasAccessTo('ARBITRATION_MANAGE') }) })));
};
