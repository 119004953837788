import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
import styles from './styles.module.scss';
export const PushFormExpireInDays = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const handleUpdatePushExpireInDays = (e) => {
        var _a;
        const numberValue = !e.target.value
            ? null
            : Number(e.target.value);
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.walletPushExpireInDays) !== numberValue)
            selectedNode.update({
                push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { walletPushExpireInDays: numberValue }),
            });
    };
    return (_jsxs("div", Object.assign({ className: classNames(styles.pushExpireInDays, {
            [styles.hasError]: !!(errors === null || errors === void 0 ? void 0 : errors.walletPushExpireInDays),
        }) }, { children: [_jsx("label", Object.assign({ className: styles.pushExpireInDaysLabel, htmlFor: 'channel-form-push-expire-in-days' }, { children: t('Показывать картинку') })), _jsxs("div", Object.assign({ className: styles.pushExpireInDaysInput }, { children: [_jsx(Input, { id: 'channel-form-push-expire-in-days', defaultValue: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.walletPushExpireInDays) || '', onBlur: handleUpdatePushExpireInDays, type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['+', ',', '.', '-', 'e'], preventNegativeNumbers: true, shouldHideSpinButtons: true, rightText: t('DAYS_EXPIRING', {
                            count: Number(((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _b === void 0 ? void 0 : _b.walletPushExpireInDays) || 0),
                        }), hasError: !!(errors === null || errors === void 0 ? void 0 : errors.walletPushExpireInDays), disabled: !isEditMode }), !!(errors === null || errors === void 0 ? void 0 : errors.walletPushExpireInDays) && (_jsx("div", Object.assign({ className: classNames(formStyles.error, styles.pushExpireInDaysError) }, { children: errors.walletPushExpireInDays })))] }))] })));
};
