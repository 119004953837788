import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { $generateHtmlFromNodes } from '@lexical/html';
import { LinkNode } from '@lexical/link';
import { LexicalComposer, } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { QuoteNode } from '@lexical/rich-text';
import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../Editor/styles.module.scss';
import { AutoFocusPlugin } from '../plugins/AutoFocusPlugin/AutoFocusPlugin';
import { EmojiPlugin } from '../plugins/EmojiPlugin/EmojiPlugin';
import { FocusPlugin } from '../plugins/FocusPlugin/FocusPlugin';
import linkStyles from '../plugins/LinkPlugin/styles.module.scss';
import { ParagraphPlugin, paragraphPluginStyles, } from '../plugins/ParagraphPlugin/ParagraphPlugin';
import { SpaceNode, SpacePlugin } from '../plugins/SpacePlugin/SpacePlugin';
import { VariableNode, VariablePlugin, } from '../plugins/VariablePlugin/VariablePlugin';
import { EditorInitStatePlugin } from './plugins/EditorInitStatePlugin';
import { LinkPlugin } from './plugins/LinkPlugin/LinkPlugin';
import { useLinkPopup } from './plugins/LinkPlugin/useLinkPopup';
import themeStyles from './themeStyles.module.scss';
import { Toolbar } from './Toolbar/Toolbar';
export const TelegramTextEditor = ({ hasError = false, hasAutoFocus = false, asTextInput = false, hasLinkSupport = false, hasEmojiSupport = true, hasSmallVarsPopupIcon = false, id, currency, className, initialValue, placeholder, varsType = 'simple', groups = [], variables = [], allVariables = variables, varsPopupType, roundedToolbar, isDisabled = false, onChange, onFocus, onBlur, }) => {
    const { i18n } = useTranslation();
    const initialEditorConfig = {
        namespace: 'TelegramTextEditor',
        nodes: [QuoteNode, SpaceNode, VariableNode, LinkNode],
        theme: {
            paragraph: paragraphPluginStyles.paragraph,
            quote: themeStyles.quote,
            code: themeStyles.hidden,
            link: linkStyles.link,
            text: {
                bold: themeStyles.bold,
                code: themeStyles.code,
                italic: themeStyles.italic,
                strikethrough: themeStyles.strikethrough,
                subscript: themeStyles.subscript,
                superscript: themeStyles.superscript,
                underline: themeStyles.underline,
                underlineStrikethrough: themeStyles.underlineStrikethrough,
            },
        },
        onError: (error) => {
            throw error;
        },
        editable: !isDisabled,
    };
    const editorRef = useRef(null);
    const { isLink, isOpenLinkPopup, isCreateEmptyLink, linkHref, linkTitle, handleCloseLinkPopup, onChangeLinkData, handleCreateLink, onOpenLinkPopup, handleDeleteLink, onClickLink, } = useLinkPopup(editorRef.current, allVariables);
    return (_jsxs(LexicalComposer, Object.assign({ initialConfig: initialEditorConfig }, { children: [_jsx("div", Object.assign({ className: styles.placeholderContainer }, { children: _jsx(RichTextPlugin, { contentEditable: _jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
                            [styles.error]: hasError,
                            [styles.disabled]: isDisabled,
                        }) }, { children: [_jsx(ContentEditable, { id: id, className: classNames(styles.editable, {
                                    [styles.textInput]: asTextInput,
                                }, className, 'lexical-editor') }), editorRef !== undefined && (_jsx(EditorRefPlugin, { editorRef: editorRef })), !isDisabled && (_jsx(Toolbar, { isLink: isLink, isCreateEmptyLink: isCreateEmptyLink, handleDeleteLink: handleDeleteLink, onChangeLinkData: onChangeLinkData, handleCreateLink: handleCreateLink, onOpenLinkPopup: onOpenLinkPopup, handleCloseLinkPopup: handleCloseLinkPopup, linkTitle: linkTitle, linkHref: linkHref, isOpenLinkPopup: isOpenLinkPopup, varsPopupType: varsPopupType, hasSmallVarsPopupIcon: hasSmallVarsPopupIcon, variables: groups, hasLinkSupport: hasLinkSupport, hasEmojiSupport: hasEmojiSupport, isRounded: roundedToolbar, currency: currency }))] })), placeholder: _jsx("div", Object.assign({ className: styles.placeholder }, { children: placeholder })), ErrorBoundary: LexicalErrorBoundary }) })), _jsx(AutoFocusPlugin, { isEnabled: hasAutoFocus }), _jsx(FocusPlugin, { onFocus: onFocus, onBlur: onBlur }), _jsx(ParagraphPlugin, {}), _jsx(OnChangePlugin, { ignoreSelectionChange: true, onChange: (editorState, editor) => {
                    editorState.read(() => {
                        // Генерируем строку разметки из DOM
                        const htmlFromNodes = $generateHtmlFromNodes(editor);
                        // Превращаем строку разметки в DOM документ
                        const tempElement = document.createElement('div');
                        tempElement.innerHTML = htmlFromNodes;
                        // Получаем коллекцию тегов и удаляем у них атрибуты
                        const tags = tempElement.querySelectorAll('*');
                        tags.forEach((tag) => {
                            Array.from(tag.attributes).forEach((attr) => {
                                if (attr.name !== 'href')
                                    tag.removeAttribute(attr.name);
                            });
                        });
                        const formattedHtmlFromNodes = tempElement.innerHTML
                            // Превращаем теги <code> в <tg-spoiler> который служит для телеграма скрытым текстом
                            .replaceAll(/<code>/g, '<tg-spoiler>')
                            .replaceAll(/<\/code>/g, '</tg-spoiler>')
                            // Исключаем теги <span> потому что телеграм не понимает их
                            .replace(/<span[^>]*>|<\/span>/g, '')
                            // Удаляем теги <b> и <i> чтобы не дублировать strong и em
                            .replace(/<\/?b>/g, '')
                            .replace(/<\/?i>/g, '')
                            // Удаляем теги <p> и добавляем перенос строки
                            .replaceAll('<p>', '')
                            .replace(/<\/p><br>|<\/p>/g, '\n')
                            .replaceAll(/<\/blockquote>/g, '</blockquote>\n');
                        onChange(formattedHtmlFromNodes);
                    });
                } }), _jsx(HistoryPlugin, {}), _jsx(SpacePlugin, {}), _jsx(LinkPlugin, { onClickLink: onClickLink }), _jsx(EditorInitStatePlugin, { initialValue: initialValue }), _jsx(AutoFocusPlugin, {}), _jsx(EmojiPlugin, {}), _jsx(VariablePlugin, { variables: allVariables, method: varsType === 'simple' ? 'transform' : 'command' })] }), i18n.language));
};
