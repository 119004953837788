import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { ImageCropInput, } from '@/components/ImageCropInput/ImageCropInput';
import { useUploadImage } from '@/hooks/useUploadImage';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const ViberFormImage = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const uploadImage = useUploadImage();
    const [error, setError] = useState('');
    const resetImage = () => {
        selectedNode.update({
            viber: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber), { viberImage: undefined, viberImageId: undefined, viberImageFile: undefined }),
        });
    };
    const saveImage = (file, uuid, data) => {
        uploadImage(file).then((response) => {
            selectedNode.update({
                viber: Object.assign(Object.assign({}, data), { viberImage: response, viberImageId: response === null || response === void 0 ? void 0 : response.id, viberImageFile: file }),
            }, uuid);
        });
    };
    const handleUpdateViberImage = (file) => {
        setError('');
        if (!file) {
            resetImage();
            return;
        }
        saveImage(file, selectedNode.uuid, Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber));
    };
    const handleResetViberImage = (reason) => {
        setError('');
        const message = rejectUploadReason(reason, '', 'square');
        if (message)
            setError(t(message));
        resetImage();
    };
    return (_jsxs("div", { children: [_jsx(ImageCropInput, { value: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _a === void 0 ? void 0 : _a.viberImageFile) ||
                    ((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _b === void 0 ? void 0 : _b.viberImage) ||
                    null, onChange: handleUpdateViberImage, onReject: handleResetViberImage, previewWidth: 160, previewHeight: 160, description: t('Квадратная, до 300кб'), constraints: {
                    mimeType: 'image/*',
                    maxSize: 300000,
                    shape: 'square',
                }, outputMimeType: 'original', cropModalTitle: t('Картинка'), hasError: !!error, theme: 'outlined', isDisabled: !isEditMode }), _jsx("div", Object.assign({ className: formStyles.error }, { children: error }))] }));
};
