import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { ClientBlockAlert } from '@/components/ClientBlockAlert/ClientBlockAlert';
import { ClientBlockButton } from '@/components/ClientBlockButton/ClientBlockButton';
import { ClientBonusHistory } from '@/components/ClientBonusHistory/ClientBonusHistory';
import { ClientBonusPanel } from '@/components/ClientBonusPanel/ClientBonusPanel';
import { ClientOverview } from '@/components/ClientOverview/ClientOverview';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './styles.module.scss';
export const ClientOverviewPanel = memo(({ client, bonusHistory, clientReservedBonuses, currency, purchaseUrl, onPurchasesClick, onPurchaseReturnsClick, onCorrectBonuses, onAddPurchase, onBlock, onUnblock, isBlocking, isUnblocking, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasBonusesManage = userHasAccessTo('CLIENTS_BONUSES_MANAGE');
    const userHasPurchasesManage = userHasAccessTo('CLIENTS_PURCHASES_MANAGE');
    const clientIsBlocked = client.status === 3 || client.status === 4;
    const blockHistoryItem = bonusHistory.find((i) => i.type === 'client' && i.operation === 4);
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [clientIsBlocked && !!blockHistoryItem && (_jsx(ClientBlockAlert, { date: blockHistoryItem.createdAt, comment: blockHistoryItem.comment, onUnblockClick: onUnblock, isLoading: isUnblocking })), _jsxs("div", Object.assign({ className: styles.bonuses }, { children: [_jsxs("div", Object.assign({ className: styles.bonusRow }, { children: [_jsx(ClientBonusPanel, { className: styles.bonus, type: 'account', bonuses: client.bonuses, isMuted: clientIsBlocked }), _jsx(ClientBonusPanel, { className: styles.bonus, type: 'pending', bonuses: client.pendingBonuses, isMuted: clientIsBlocked })] })), _jsxs("div", Object.assign({ className: styles.bonusRow }, { children: [_jsx(ClientBonusPanel, { className: styles.bonus, type: 'applied', bonuses: client.appliedBonuses, isMuted: clientIsBlocked }), _jsx(ClientBonusPanel, { className: styles.bonus, type: 'expired', bonuses: client.expiredBonuses, isMuted: clientIsBlocked })] }))] })), _jsxs("div", Object.assign({ className: classNames(styles.body, {
                    [styles.blocked]: clientIsBlocked,
                }) }, { children: [_jsx("div", Object.assign({ className: styles.overview }, { children: _jsx(ClientOverview, { client: client, currency: currency, purchaseUrl: purchaseUrl, onPurchasesClick: onPurchasesClick, onPurchaseReturnsClick: onPurchaseReturnsClick }) })), _jsx("div", Object.assign({ className: styles.history }, { children: _jsx(ClientBonusHistory, { items: bonusHistory, clientJoinedAt: client.joinedAt, clientReservedBonuses: clientReservedBonuses }) }))] })), !clientIsBlocked && (_jsxs("div", Object.assign({ className: styles.footer }, { children: [_jsx(ClientBlockButton, { onApply: onBlock, isLoading: isBlocking }), _jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsx(Tooltip, Object.assign({ placement: 'top', title: userHasBonusesManage
                                    ? undefined
                                    : t('Недоступно для вашей роли') }, { children: _jsx(Button, Object.assign({ theme: 'default', onClick: () => onCorrectBonuses === null || onCorrectBonuses === void 0 ? void 0 : onCorrectBonuses(client), disabled: !userHasBonusesManage }, { children: t('Начислить, списать бонусы') })) })), _jsx(Tooltip, Object.assign({ placement: 'top', title: userHasPurchasesManage
                                    ? undefined
                                    : t('Недоступно для вашей роли') }, { children: _jsx(Button, Object.assign({ onClick: () => onAddPurchase === null || onAddPurchase === void 0 ? void 0 : onAddPurchase(client), theme: 'default', size: 'extra-large', disabled: !userHasPurchasesManage }, { children: t('Добавить покупку') })) }))] }))] })))] })));
});
