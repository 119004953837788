import { useEffect, useState } from 'react';
import { useDebounce } from '@/hooks/useDebounce';
export const useCalculate = (handler, methods) => {
    const { watch, getValues } = methods;
    const [calculateResult, setCalculateResult] = useState({});
    const channels = watch('channels');
    const recipientAllClients = watch('recipientAllClients');
    const recipientFilters = watch('recipientFilters');
    const triggerFilters = watch('triggerFilters');
    const triggerType = watch('triggerType');
    const pushText = watch('pushText');
    const viberText = watch('viberText');
    const telegramText = watch('telegramText');
    const smsText = watch('smsText');
    const offsetSign = watch('offsetSign');
    const offsetUnit = watch('offsetUnit');
    const offsetValue = watch('offsetValue');
    const triggerScheduleType = watch('triggerScheduleType');
    const triggerScheduleTime = watch('triggerScheduleTime');
    const triggerScheduleDaysOfWeek = watch('triggerScheduleDaysOfWeek');
    const triggerScheduleDaysOfMonth = watch('triggerScheduleDaysOfMonth');
    const triggerScheduleDate = watch('triggerScheduleDate');
    useDebounce({
        value: pushText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: viberText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: smsText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: telegramText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: offsetValue || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: triggerScheduleTime || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: (triggerScheduleDaysOfWeek === null || triggerScheduleDaysOfWeek === void 0 ? void 0 : triggerScheduleDaysOfWeek.join(',')) || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: (triggerScheduleDaysOfMonth === null || triggerScheduleDaysOfMonth === void 0 ? void 0 : triggerScheduleDaysOfMonth.join(',')) || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useEffect(() => {
        handler(getValues()).then(setCalculateResult);
    }, [
        recipientAllClients,
        JSON.stringify(recipientFilters),
        JSON.stringify(triggerFilters),
        triggerType,
        channels === null || channels === void 0 ? void 0 : channels.join(','),
        offsetSign,
        offsetUnit,
        triggerScheduleType,
        triggerScheduleDate,
    ]);
    return calculateResult;
};
