import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { ChannelPush } from '@/icons/ChannelPush';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { FormMailingPushExpireInDays } from './controls/FormMailingPushExpireInDays';
import { FormMailingPushImage } from './controls/FormMailingPushImage';
import { FormMailingPushSender } from './controls/FormMailingPushSender';
import { FormMailingPushTest } from './controls/FormMailingPushTest';
import { FormMailingPushText } from './controls/FormMailingPushText';
import styles from './styles.module.scss';
export const FormMailingStepPush = ({ textLength, onSendTestPush, uploadImage, brand, currency, labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    return (_jsxs(FormFieldset, Object.assign({ title: _jsxs("div", Object.assign({ className: styles.channelTitle }, { children: [_jsx("div", Object.assign({ className: classNames(styles.channel, styles.push) }, { children: _jsx(ChannelPush, {}) })), "PUSH"] })), titleOffset: labelWidth, tooltip: _jsx("a", Object.assign({ href: 'https://help.maxma.com/p/IOhnMOXQsrXivU/Untitled#cfddf1da', target: '_blank', rel: 'noreferrer' }, { children: t('Как выглядит Push') })), tooltipClassName: styles.pushTooltip }, { children: [_jsx(FormMailingPushSender, { brand: brand, labelWidth: labelWidth }), _jsx(FormMailingPushText, { length: textLength, hasWalletFeature: brandHasWalletFeature(brand), currency: currency, labelWidth: labelWidth }), !((_a = brand === null || brand === void 0 ? void 0 : brand.features) === null || _a === void 0 ? void 0 : _a.mobilePush) && (_jsxs(_Fragment, { children: [_jsx(FormMailingPushImage, { uploadImage: uploadImage, labelWidth: labelWidth }), _jsx(FormMailingPushExpireInDays, { labelWidth: labelWidth })] })), _jsx(FormMailingPushTest, { onSendTestPush: onSendTestPush, labelWidth: labelWidth })] })));
};
