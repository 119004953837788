import moment from 'moment-timezone';
export const getAvailabilityRange = (item, tz, placeholder) => {
    var _a, _b;
    const from = ((_a = item.availableFrom) === null || _a === void 0 ? void 0 : _a.includes(':'))
        ? moment(item.availableFrom).tz(tz).format('DD.MM.YYYY')
        : moment(item.availableFrom).format('DD.MM.YYYY');
    const to = ((_b = item.availableTo) === null || _b === void 0 ? void 0 : _b.includes(':'))
        ? moment(item.availableTo).tz(tz).format('DD.MM.YYYY')
        : moment(item.availableTo).format('DD.MM.YYYY');
    return [
        item.availableFrom ? from : placeholder,
        item.availableTo ? to : placeholder,
    ].join(' – ');
};
