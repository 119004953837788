import { OFFSET_SIGN_BEFORE } from '@/forms/FormMailing/controls/FormMailingOffset';
import { createEdge } from '../createEdge';
import { createNode } from '../createNode';
export const createDelayNode = (data) => {
    return createNode({
        type: 'delay',
        data,
        position: data.position || { x: 0, y: 0 },
        style: !data.position ? { opacity: 0 } : undefined,
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const createDelayNodeForMailing = (mailing) => {
    return createDelayNode({
        offsetSign: mailing.offsetSign,
        offsetUnit: mailing.offsetUnit,
        offsetValue: mailing.offsetValue,
    });
};
const findDelayNodeForMailing = (nodes, mailing) => {
    for (let i = 0; i < nodes.length; i += 1) {
        const node = nodes[i];
        if (node.type !== 'delay')
            continue;
        const sameSign = node.data.offsetSign === mailing.offsetSign;
        const sameUnit = node.data.offsetUnit === mailing.offsetUnit;
        const sameValue = node.data.offsetValue === mailing.offsetValue;
        if (sameSign && sameUnit && sameValue)
            return node;
    }
    return null;
};
export const createDelayNodesFromMailings = (branches, isBefore) => {
    const delayNodes = [];
    const delayEdges = [];
    const delayBranches = {};
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const thisBranchNodes = [];
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const shouldSkip = !Number(mailing.offsetSign) ||
                    (isBefore &&
                        Number(mailing.offsetSign) !== Number(OFFSET_SIGN_BEFORE)) ||
                    (!isBefore &&
                        Number(mailing.offsetSign) === Number(OFFSET_SIGN_BEFORE));
                if (shouldSkip) {
                    if (!delayBranches[fromNodeId])
                        delayBranches[fromNodeId] = {};
                    const handle = !fromHandleId ? 'no-handle' : fromHandleId;
                    if (!delayBranches[fromNodeId][handle])
                        delayBranches[fromNodeId][handle] = [];
                    delayBranches[fromNodeId][handle].push(mailing);
                    continue;
                }
                let delayNode = findDelayNodeForMailing(thisBranchNodes, mailing);
                if (!delayNode) {
                    delayNode = createDelayNodeForMailing(mailing);
                    delayNodes.push(delayNode);
                    thisBranchNodes.push(delayNode);
                    delayEdges.push(createEdge({
                        source: fromNodeId,
                        sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                        target: delayNode.id,
                        data: { isInteractive: true },
                    }));
                }
                if (!delayBranches[delayNode.id])
                    delayBranches[delayNode.id] = {};
                if (!delayBranches[delayNode.id]['no-handle'])
                    delayBranches[delayNode.id]['no-handle'] = [];
                delayBranches[delayNode.id]['no-handle'].push(mailing);
            }
        }
    }
    return { delayNodes, delayEdges, delayBranches };
};
