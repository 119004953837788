import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { extractInstruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { useEffect } from 'react';
import { flushSync } from 'react-dom';
import styles from '../styles.module.scss';
import { drop, dropTo } from '../utils';
const FLASH_DROPS = false;
export const useMonitorForElements = (priority, unordered, onChange) => {
    useEffect(() => {
        const autoScrollCleanup = autoScrollForElements({
            element: document.body,
        });
        const monitorCleanup = monitorForElements({
            canMonitor: ({ source }) => {
                return 'kind' in source.data;
            },
            onDrop: ({ location, source }) => {
                const target = location.current.dropTargets[0];
                if (!target)
                    return;
                const sourceData = source.data;
                const targetData = target.data;
                const instruction = extractInstruction(targetData);
                const dropResult = instruction
                    ? drop(priority, unordered, sourceData, targetData, instruction)
                    : target.data.isTopLevelBottom
                        ? dropTo(priority, unordered, sourceData, 'priority')
                        : target.data.isUnordered
                            ? dropTo(priority, unordered, sourceData, 'unordered')
                            : undefined;
                const { newPriority, newUnordered, sourceItemId } = dropResult || {};
                if (sourceItemId && newPriority && newUnordered) {
                    flushSync(() => onChange(newPriority, newUnordered));
                    const element = document.querySelector(`[data-priority-id="${sourceItemId}"]`);
                    if (element instanceof HTMLElement && FLASH_DROPS) {
                        element.classList.add(styles.flash);
                        setTimeout(() => element.classList.remove(styles.flash), 350);
                    }
                }
            },
        });
        return combine(monitorCleanup, autoScrollCleanup);
    }, [priority, unordered]);
};
