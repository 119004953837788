import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { useNotificateForUnsavedForm } from '@/hooks/useNotificateForUnsavedForm';
import { Magic } from '@/icons/Magic';
import { useArbitrationList } from '~/src/app/pages/Arbitration/useArbitrationList';
import { Panel } from '~/src/components/Panel2/Panel';
import { APPLY_ON_ALL_UNITS, DISCOUNT_TYPE_FIXED, DISCOUNT_TYPE_PERCENT, LIMIT_PERIOD_DAY, LIMIT_TYPE_NO_LIMIT, } from './constants';
import { FormOfferFooter } from './FormOfferFooter';
import { FormOfferTabs } from './FormOfferTabs';
import styles from './styles.module.scss';
const labelWidth = 27.395;
export const FormOffer = memo(({ onSubmit, error, isSubmitting = false, isClone = false, defaultValues, currency, isEdit, onDelete, hasDiscountOfferFeature = false, clientExtraFields = [], counters = [], mailingOffers = [], getMailingLink, segments = [], setActive, initialPriority = [], }) => {
    const { t } = useTranslation();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: Object.assign(Object.assign({ allowBonusCollect: !!(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.allowBonusCollect), allowBonusApply: !!(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.allowBonusApply), allowBonusApplyNotMore: !!(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.allowBonusApplyNotMore), bonusApplyMaxType: (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.bonusApplyMaxType) || DISCOUNT_TYPE_FIXED, bonusApplyMaxAmount: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.bonusApplyMaxAmount, arbitrationGroupGuid: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.arbitrationGroupGuid, conditionsProduct: [], arbitrationPriorityAfter: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.arbitrationPriority, arbitrationPriorityAfterError: false, conditions: [], weekdays: [], type: 'bonus', discountType: DISCOUNT_TYPE_PERCENT, growingDiscount: [{ to: 1 }, { to: 0 }], bonusesLifetime: undefined, itemConditions: [], applyOn: APPLY_ON_ALL_UNITS, limitType: LIMIT_TYPE_NO_LIMIT, limitPeriod: LIMIT_PERIOD_DAY, isActive: true }, defaultValues), { eachN: (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.eachN) || 2, constraintType: currency, notMoreNOpted: typeof (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.notMoreNOpted) === 'undefined'
                ? true
                : defaultValues.notMoreNOpted, notMoreN: (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.notMoreN) || 1, title: isClone
                ? `${t('Копия')} ${defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.title}`
                : defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.title }),
    });
    const { data } = useArbitrationList();
    const { watch, trigger, setError, setValue, formState: { dirtyFields, errors, isSubmitting: isSubmittingForm }, } = methods;
    useNotificateForUnsavedForm(!!Object.keys(dirtyFields).length && !isSubmittingForm);
    const [activeTab, setActiveTab] = useState('general');
    const errorFieldsList = Object.keys(errors);
    const scrollToError = (field) => {
        const firstErrorField = field || errorFieldsList[0];
        const element = document.getElementById(firstErrorField);
        if (element)
            element.scrollIntoView({ block: 'center' });
    };
    useEffect(() => {
        if (errorFieldsList.length > 0)
            scrollToError();
    }, [activeTab, error]);
    const title = watch('title') || t('Без названия');
    const arbitrationPriorityAfter = watch('arbitrationPriorityAfter');
    const recipientTags = watch('recipientTags');
    const recipientFilters = watch('recipientFilters');
    const priority = (data === null || data === void 0 ? void 0 : data.priority.length) ? data.priority : initialPriority;
    const isPriority = !!(priority === null || priority === void 0 ? void 0 : priority.length);
    const handleFormSubmit = () => {
        const isValidRecipientValue = (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.all) || (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.noCards) || (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.withCards)
            ? true
            : recipientFilters && recipientFilters.length > 0;
        if (activeTab === 'general') {
            setActiveTab('priority');
            trigger().then(() => {
                if (!isValidRecipientValue)
                    setError('recipientFilters', {
                        type: 'error',
                        message: 'error',
                    });
            });
        }
        if (activeTab === 'priority')
            trigger().then((isValidForm) => {
                if (!isValidRecipientValue)
                    setError('recipientFilters', {
                        type: 'error',
                        message: 'error',
                    });
                if (!isValidForm || !isValidRecipientValue) {
                    setActiveTab('general');
                    return;
                }
                if (arbitrationPriorityAfter === undefined && isPriority) {
                    setValue('arbitrationPriorityAfterError', true);
                    scrollToError('arbitrationPriorityAfter');
                    return;
                }
                if (isValidForm &&
                    isValidRecipientValue &&
                    (arbitrationPriorityAfter !== undefined || !isPriority))
                    methods.handleSubmit(onSubmit)();
            });
    };
    return (_jsx("div", Object.assign({ className: classNames(styles.channelsTabsWrapper) }, { children: _jsxs(FormProvider, Object.assign({}, methods, { children: [_jsxs("ul", Object.assign({ className: styles.channelsTabs }, { children: [_jsx("li", { children: _jsx("button", Object.assign({ className: classNames(styles.tabButton, {
                                    [styles.active]: activeTab === 'general',
                                }), onClick: () => setActiveTab('general') }, { children: t('Общее') })) }), _jsx("li", { children: _jsx("button", Object.assign({ className: classNames(styles.tabButton, {
                                    [styles.active]: activeTab === 'priority',
                                }), onClick: () => setActiveTab('priority') }, { children: t('Приоритет') })) })] })), _jsxs("div", Object.assign({ className: classNames({
                        [styles.channelsTabsPanel]: true,
                    }) }, { children: [_jsx("h1", Object.assign({ className: classNames('page-title', styles.pageTitle) }, { children: title })), _jsx(Panel, Object.assign({ size: '800', isCentered: true }, { children: _jsxs("form", { children: [_jsx(FormOfferTabs, { priority: priority, activeTab: activeTab, currency: currency, title: title, counters: counters, segments: segments, mailingOffers: mailingOffers, clientExtraFields: clientExtraFields, hasDiscountOfferFeature: hasDiscountOfferFeature, getMailingLink: getMailingLink }), _jsx("div", { className: styles.divider }), _jsx("div", Object.assign({ className: styles.warning }, { children: _jsx(FormFieldRow, Object.assign({ labelClassName: styles.warningLabel, label: _jsxs("span", Object.assign({ className: styles.warningIconWrapper }, { children: [_jsx(Magic, {}), t('Рекомендации')] })), labelWidth: labelWidth, controlClassName: styles.warningControl }, { children: t('Не забудьте протестировать акцию на кассе') })) })), error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), labelWidth: labelWidth, asErrorMessage: true }, { children: error }))), _jsx(FormOfferFooter, { isEdit: isEdit, onDelete: onDelete, isSubmitting: isSubmitting, setActive: setActive, isGeneralTab: activeTab === 'general', handleFormSubmit: handleFormSubmit })] }) }))] }))] })) })));
});
