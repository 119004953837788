import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormOfferGeneralTab } from './FormOfferGeneralTab';
import { FormOfferPriorityTab } from './FormOfferPriorityTab/FormOfferPriorityTab';
// проходим по всем группам вложенности
// и собираем их в список где родитель будет над его дочерними. Фиксируем глубину вложенности
export function convertGroupsDataToPriorityWithDepth(elements, depth = 0) {
    let result = [];
    elements === null || elements === void 0 ? void 0 : elements.forEach((element) => {
        if (element.kind === 'group') {
            result.push(Object.assign(Object.assign({}, element), { depth }));
            if (element.arbitrationGroup && element.arbitrationGroup.children)
                result = result.concat(convertGroupsDataToPriorityWithDepth(element.arbitrationGroup.children, depth + 1));
        }
    });
    return result;
}
export const getSelectedGroup = (priority, arbitrationGroupInitGuid) => {
    var _a, _b, _c;
    let currentGroup;
    if (arbitrationGroupInitGuid)
        currentGroup = priority.find((i) => { var _a; return ((_a = i.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.guid) === arbitrationGroupInitGuid; });
    else
        currentGroup = priority[0];
    const selectedGroup = currentGroup &&
        currentGroup.arbitrationGroup && Object.assign(Object.assign({}, currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.arbitrationGroup), { children: currentGroup.arbitrationGroup.children });
    return {
        currentGroup: currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.arbitrationGroup,
        offersList: (_c = ((selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.children) ||
            ((_b = (_a = priority[0]) === null || _a === void 0 ? void 0 : _a.arbitrationGroup) === null || _b === void 0 ? void 0 : _b.children))) === null || _c === void 0 ? void 0 : _c.filter((i) => i.kind === 'offer'),
    };
};
export const FormOfferTabs = memo(({ activeTab, currency, title, counters, segments, mailingOffers, clientExtraFields, hasDiscountOfferFeature, getMailingLink, priority, }) => {
    const { watch } = useFormContext();
    const { t } = useTranslation();
    const arbitrationGroupGuid = watch('arbitrationGroupGuid');
    const arbitrationPriorityGuid = watch('arbitrationPriority');
    const priorityList = useMemo(() => convertGroupsDataToPriorityWithDepth(priority), [priority]);
    const options = useMemo(() => priorityList.map(({ depth, arbitrationGroup }) => ({
        shift: depth,
        id: (arbitrationGroup === null || arbitrationGroup === void 0 ? void 0 : arbitrationGroup.guid) || '',
        title: (arbitrationGroup === null || arbitrationGroup === void 0 ? void 0 : arbitrationGroup.mode) === 'exclusive'
            ? t('Единственная в чеке')
            : (arbitrationGroup === null || arbitrationGroup === void 0 ? void 0 : arbitrationGroup.name) || '',
    })), [priorityList]);
    const [offersList, setOffersList] = useState([]);
    const [currentGroup, setCurrentGroup] = useState();
    const [offerInsideList, setOfferInsideList] = useState(!!arbitrationPriorityGuid);
    useEffect(() => {
        var _a;
        const { offersList, currentGroup } = getSelectedGroup(priorityList, arbitrationGroupGuid);
        setOffersList(offersList);
        setCurrentGroup(currentGroup || ((_a = priority[0]) === null || _a === void 0 ? void 0 : _a.arbitrationGroup));
    }, [priority]);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { display: activeTab === 'general' ? 'block' : 'none' } }, { children: _jsx(FormOfferGeneralTab, { currency: currency, counters: counters, segments: segments, mailingOffers: mailingOffers, clientExtraFields: clientExtraFields, hasDiscountOfferFeature: hasDiscountOfferFeature, getMailingLink: getMailingLink }) })), activeTab === 'priority' && (_jsx("div", { children: _jsx(FormOfferPriorityTab, { activeTab: activeTab, currency: currency, priority: priorityList, offerTitle: title, arbitrationPriorityGuid: arbitrationPriorityGuid, offersList: offersList, offerInsideList: offerInsideList, currentGroup: currentGroup, options: options, setOffersList: setOffersList, setCurrentGroup: setCurrentGroup, setOfferInsideList: setOfferInsideList }) }))] }));
});
