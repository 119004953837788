import * as triggers from '@/const/mailing-triggers';
export const triggerDescriptions = {
    [triggers.TRIGGER_TYPE_SEGMENT_JOIN]: 'Попадание в сегмент',
    [triggers.TRIGGER_TYPE_SEGMENT_LEAVE]: 'Покидание сегмента',
    [triggers.TRIGGER_TYPE_PURCHASE]: 'Совершение покупки',
    [triggers.TRIGGER_TYPE_BONUS_ACTIVATE]: 'Начисление бонусов за покупку',
    [triggers.TRIGGER_TYPE_RETURN]: 'Возврат товара',
    [triggers.TRIGGER_TYPE_ABANDONED_CART]: 'Брошенная корзина',
    [triggers.TRIGGER_TYPE_PRODUCT_VIEW]: 'Просмотр товара или категории',
    [triggers.TRIGGER_TYPE_PRICE_IN_CART_DROPPED]: 'Цена на товар в корзине снижена',
    [triggers.TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED]: 'Цена на товар в избранном снижена',
    [triggers.TRIGGER_TYPE_BIRTHDAY]: 'День рождения',
    [triggers.TRIGGER_TYPE_CHILD_BIRTHDAY]: 'День рождения ребёнка',
    [triggers.TRIGGER_TYPE_BONUS_EXPIRING]: 'Сгорание бонусов',
    [triggers.TRIGGER_TYPE_REGISTER_CLIENT]: 'Клиент зарегистрировался в Программе Лояльности',
    [triggers.TRIGGER_TYPE_REGISTER_WALLET_CARD]: 'Клиент установил карту Wallet',
    [triggers.TRIGGER_TYPE_SUBSCRIBE_EMAIL]: 'Клиент подписался на Email-рассылку',
    [triggers.TRIGGER_TYPE_SCHEDULE]: 'По расписанию',
    [triggers.TRIGGER_TYPE_LEVEL_UP]: 'Клиент перешел на новый уровень',
};
