import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { FilterListInput } from '@/components/FilterListInput/FilterListInput';
import { FiltersTreeSelect } from '@/components/FiltersTreeSelect/FiltersTreeSelect';
import { compileFilters } from '@/services/filters/compileFilters';
import { useTriggerFilters } from '@/services/filters/data/useTriggerFilters';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { findEdgesFromNode, findNodeByUUID } from '../../nodes/utils';
import commonStyles from '../styles.module.scss';
import { FiltersWarning } from '../Warnings/FiltersWarning';
import styles from './styles.module.scss';
const noop = () => undefined;
export const TriggerFiltersForm = () => {
    var _a;
    const flow = useReactFlow();
    const { triggerType, selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const filtersTree = useTriggerFilters(triggerType);
    const handleUpdate = (value) => {
        selectedNode.update({
            configuration: value,
            conditions: value.map((savedFilter) => compileFilters([savedFilter])),
        });
    };
    const thisNode = findNodeByUUID(flow, selectedNode.uuid);
    const edgesFromThisNode = findEdgesFromNode(flow, thisNode);
    const nonDeletableIdx = edgesFromThisNode
        .map((edge) => {
        var _a;
        const sourceHandleIdx = (_a = edge.sourceHandle) === null || _a === void 0 ? void 0 : _a.split('-')[1];
        return sourceHandleIdx ? Number(sourceHandleIdx) : null;
    })
        .filter(notNull);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.selectWrapper }, { children: _jsx(FiltersTreeSelect, { filtersTree: filtersTree, selectedCode: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.selectedFilterCode) || '', onChange: noop, isDisabled: true }) })), _jsx(FilterListInput, { filtersTree: filtersTree, selectedCode: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.selectedFilterCode) || '', value: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.configuration, onChange: handleUpdate, defaultOpenIdx: selectedNode.handleIdx, nonDeletableIdx: nonDeletableIdx, isDisabled: !isEditMode }), (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.configuration) === null || _a === void 0 ? void 0 : _a.length) || 0) > 1 && _jsx(FiltersWarning, {}), !!(errors === null || errors === void 0 ? void 0 : errors.configuration) && (_jsx("div", Object.assign({ className: classNames(commonStyles.error, commonStyles.mt12) }, { children: errors.configuration })))] }));
};
