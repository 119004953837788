import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { cloneElement, memo, useCallback, useEffect, useRef, useState, } from 'react';
import { FilterForm } from '@/components/FilterForm/FilterForm';
import { FiltersTreeView } from '@/components/FiltersTreeView/FiltersTreeView';
import { Popup } from '@/components/Popup/Popup';
import { TwoColumnLayout } from '@/components/TwoColumnLayout/TwoColumnLayout';
import { findLeafByCode } from '@/services/filters/findLeafByCode';
import { getFirstLeafCode } from '@/services/filters/getFirstLeafCode';
import styles from './styles.module.scss';
export const FiltersPopup = memo(({ trigger, filtersTree, selectedCode: defaultSelectedCode = getFirstLeafCode(filtersTree), disabledCodes, hiddenCodes, onApply, onToggle, rightPanelClassName, disablePortal = false, }) => {
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [selectedCode, setSelectedCode] = useState(defaultSelectedCode);
    useEffect(() => {
        setSelectedCode(defaultSelectedCode);
    }, [defaultSelectedCode]);
    const handleTogglePopup = useCallback(() => {
        setPopupIsOpen(!popupIsOpen);
        setSelectedCode(defaultSelectedCode);
        if (onToggle)
            onToggle(!popupIsOpen);
    }, [popupIsOpen, defaultSelectedCode, onToggle]);
    const handleSelectFilter = useCallback((code, item) => {
        if (item.type === 'custom') {
            setPopupIsOpen(false);
            if (onToggle)
                onToggle(false);
            if (item.onClick)
                item.onClick();
            return;
        }
        setSelectedCode(code);
    }, [onToggle]);
    const handleApply = useCallback((item) => {
        onApply(item);
        handleTogglePopup();
    }, [onApply, handleTogglePopup]);
    return (_jsxs(_Fragment, { children: [cloneElement(trigger, {
                ref: triggerRef,
                onClick: handleTogglePopup,
            }), _jsxs(Popup, Object.assign({ isOpen: popupIsOpen, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, onClose: handleTogglePopup, placement: 'bottom-start', offset: [0, 17], isRounded: true, className: classNames(styles.formView, {
                    [styles.treeView]: !selectedCode,
                }), disablePortal: disablePortal, hasTail: true }, { children: [_jsx("div", Object.assign({ className: styles.mobileView }, { children: selectedCode ? (_jsx(FilterForm, { onBack: () => setSelectedCode(''), item: findLeafByCode(filtersTree, selectedCode), onApply: handleApply }, selectedCode)) : (_jsx(FiltersTreeView, { filtersTree: filtersTree, selectedCode: selectedCode, disabledCodes: disabledCodes, hiddenCodes: hiddenCodes, onChange: handleSelectFilter, scrollToSelectedNode: true })) })), _jsx("div", Object.assign({ className: styles.desktopView }, { children: popupIsOpen && (_jsx(TwoColumnLayout, { left: _jsx(FiltersTreeView, { filtersTree: filtersTree, selectedCode: selectedCode, disabledCodes: disabledCodes, hiddenCodes: hiddenCodes, onChange: handleSelectFilter }), right: _jsx(FilterForm, { item: findLeafByCode(filtersTree, selectedCode), onApply: handleApply }, selectedCode), rightClassName: rightPanelClassName })) }))] }))] }));
});
