export const canvasToBlob = (canvas) => {
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                const file = new File([blob], 'image.png');
                resolve(file);
            }
            else
                reject();
        });
    });
};
