var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '@/api/dashboardApi';
import { getBestAggInterval } from '@/utils/getBestAggInterval';
import { createSimpleThunk } from '@/utils/thunks';
export const fetchSparklineDataThunk = createAsyncThunk('reports/sparkline/fetch', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { dashboardId } = payload, body = __rest(payload, ["dashboardId"]);
        const bucketSize = body.bucketSize ||
            getBestAggInterval({
                periodStart: body.periodStart,
                periodEnd: body.periodEnd,
                isSparkline: true,
            });
        const [{ data: singleValue }, { data: chartData }] = yield Promise.all([
            api.getSingleValueApi(body),
            api.getChartDataApi(Object.assign(Object.assign({}, body), { bucketSize })),
        ]);
        return { dashboardId, singleValue, chartData };
    }
    catch (e) {
        thunkApi.rejectWithValue(e);
    }
}));
export const fetchTableRoot = createSimpleThunk('reports/table/fetch/root', api.getTableDataApi);
export const fetchTableNode = createAsyncThunk('reports/table/fetch/node', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { key } = payload, body = __rest(payload, ["key"]);
        const { data } = yield api.getTableDataApi(body);
        return data.data;
    }
    catch (e) {
        thunkApi.rejectWithValue(e);
    }
}));
export const fetchDashboardList = createSimpleThunk('reports/dashboard/list/fetch', api.getDashboardListApi);
export const addDashboard = createSimpleThunk('reports/dashboard/add', api.addDashboardApi);
export const deleteDashboard = createAsyncThunk('reports/dashboard/delete', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { id, isSaved } = payload;
        if (isSaved)
            yield api.deleteDashboardApi(id);
    }
    catch (e) {
        thunkApi.rejectWithValue(e);
    }
}));
export const renameDashboard = createAsyncThunk('reports/dashboard/rename', (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { id, title, isSaved } = payload;
        if (isSaved)
            yield api.renameDashboardApi({ id, title });
    }
    catch (e) {
        thunkApi.rejectWithValue(e);
    }
}));
export const saveDashboard = createSimpleThunk('reports/dashboard/save', api.saveDashboardApi);
export const fetchChartData = createSimpleThunk('reports/chart/fetch', api.getChartDataApi);
