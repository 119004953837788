var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef, useState } from 'react';
import { useGetClientsEventListMutation } from '@/api/query/client';
import { useAppSelector } from '~/src/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '~/src/store/selectors/appPeriod';
import { ALL_DATA_TYPES, INIT_ACTIVE_BUTTONS } from './constants';
const LIMIT_EVENTS = 40;
export const useData = ({ filters }) => {
    const [activeDataTypesFilters, setActiveDataTypesFilters] = useState(INIT_ACTIVE_BUTTONS);
    const dataTypesState = useRef(ALL_DATA_TYPES);
    const [getClientsEventList, { isLoading, data }] = useGetClientsEventListMutation();
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const [nextOffset, setNextOffset] = useState(0);
    const offsetsOfPreviousPages = useRef([0]);
    const clientPhone = useRef(undefined);
    const resetOffset = () => {
        setNextOffset(0);
        offsetsOfPreviousPages.current = [0];
    };
    const refetchClientsEventList = (offset) => __awaiter(void 0, void 0, void 0, function* () {
        const eventListData = yield getClientsEventList({
            dataTypes: dataTypesState.current,
            limit: LIMIT_EVENTS,
            offset: offset,
            filters: Object.assign({ clientPhoneEq: clientPhone.current || undefined }, filters),
            periodStart: periodStart,
            periodEnd: periodEnd,
        });
        if ('data' in eventListData)
            if (!eventListData.data.nextOffset && eventListData.data.nextOffset !== 0)
                setNextOffset(null);
            else
                setNextOffset(eventListData.data.nextOffset);
    });
    const handleNextPage = () => {
        if (nextOffset === null)
            return;
        offsetsOfPreviousPages.current.push(nextOffset || 0);
        refetchClientsEventList(nextOffset);
    };
    const handlePrevPage = () => {
        offsetsOfPreviousPages.current.length > 1 &&
            offsetsOfPreviousPages.current.pop();
        refetchClientsEventList(offsetsOfPreviousPages.current[offsetsOfPreviousPages.current.length - 1] || 0);
    };
    const handleChangeFilters = (filter) => {
        resetOffset();
        // Если выбрано "Все"
        if (filter === 'all') {
            dataTypesState.current = [];
            setActiveDataTypesFilters(INIT_ACTIVE_BUTTONS);
            refetchClientsEventList(0);
            return;
        }
        // Если фильтр уже выбран
        if (activeDataTypesFilters[filter]) {
            dataTypesState.current = dataTypesState.current.filter((type) => type !== filter);
            // Если все фильтры выключены включаем "Все"
            if (dataTypesState.current.length === 0) {
                dataTypesState.current = ALL_DATA_TYPES;
                setActiveDataTypesFilters(INIT_ACTIVE_BUTTONS);
            }
            // Иначе выключаем один фильтр
            else
                setActiveDataTypesFilters((prev) => (Object.assign(Object.assign({}, prev), { [filter]: false })));
            refetchClientsEventList(0);
        }
        else {
            if (activeDataTypesFilters.all)
                dataTypesState.current = [filter];
            else
                dataTypesState.current = [...dataTypesState.current, filter];
            setActiveDataTypesFilters((prev) => (Object.assign(Object.assign({}, prev), { [filter]: true, all: false })));
            refetchClientsEventList(0);
        }
    };
    const handleApplySearch = (searchText) => {
        resetOffset();
        clientPhone.current = searchText;
        refetchClientsEventList(0);
    };
    useEffect(() => {
        refetchClientsEventList(0);
    }, [periodStart, periodEnd, filters]);
    const clientsEventList = Object.entries((data === null || data === void 0 ? void 0 : data.events) || {});
    return {
        isPrevButtonDisabled: offsetsOfPreviousPages.current.length === 1,
        isNextButtonDisabled: nextOffset === null,
        isLoading: isLoading,
        clientsEventList,
        activeDataTypesFilters,
        resetOffset,
        handlePrevPage,
        handleNextPage,
        handleApplySearch,
        handleChangeFilters,
    };
};
