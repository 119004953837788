export const TRIGGER_TYPE_SEGMENT_JOIN = 0;
export const TRIGGER_TYPE_SEGMENT_LEAVE = 1;
export const TRIGGER_TYPE_PURCHASE = 6;
export const TRIGGER_TYPE_BONUS_ACTIVATE = 2;
export const TRIGGER_TYPE_RETURN = 3;
export const TRIGGER_TYPE_ABANDONED_CART = 8;
export const TRIGGER_TYPE_PRODUCT_VIEW = 9;
export const TRIGGER_TYPE_PRICE_IN_CART_DROPPED = 10;
export const TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED = 11;
export const TRIGGER_TYPE_BIRTHDAY = 4;
export const TRIGGER_TYPE_CHILD_BIRTHDAY = 7;
export const TRIGGER_TYPE_BONUS_EXPIRING = 5;
export const TRIGGER_TYPE_REGISTER_CLIENT = 12;
export const TRIGGER_TYPE_REGISTER_WALLET_CARD = 13;
export const TRIGGER_TYPE_SUBSCRIBE_EMAIL = 14;
export const TRIGGER_TYPE_SCHEDULE = 15;
export const TRIGGER_TYPE_LEVEL_UP = 16;
export const TRIGGER_TYPE_EMAIL_DELIVERED = 17;
export const TRIGGER_TYPE_CHANNEL_DELIVERED = 18;
export const TRIGGER_TYPES_MAP = {
    [TRIGGER_TYPE_SEGMENT_JOIN]: 'TRIGGER_TYPE_SEGMENT_JOIN',
    [TRIGGER_TYPE_SEGMENT_LEAVE]: 'TRIGGER_TYPE_SEGMENT_LEAVE',
    [TRIGGER_TYPE_PURCHASE]: 'TRIGGER_TYPE_PURCHASE',
    [TRIGGER_TYPE_BONUS_ACTIVATE]: 'TRIGGER_TYPE_BONUS_ACTIVATE',
    [TRIGGER_TYPE_RETURN]: 'TRIGGER_TYPE_RETURN',
    [TRIGGER_TYPE_ABANDONED_CART]: 'TRIGGER_TYPE_ABANDONED_CART',
    [TRIGGER_TYPE_PRODUCT_VIEW]: 'TRIGGER_TYPE_PRODUCT_VIEW',
    [TRIGGER_TYPE_PRICE_IN_CART_DROPPED]: 'TRIGGER_TYPE_PRICE_IN_CART_DROPPED',
    [TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED]: 'TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED',
    [TRIGGER_TYPE_BIRTHDAY]: 'TRIGGER_TYPE_BIRTHDAY',
    [TRIGGER_TYPE_CHILD_BIRTHDAY]: 'TRIGGER_TYPE_CHILD_BIRTHDAY',
    [TRIGGER_TYPE_BONUS_EXPIRING]: 'TRIGGER_TYPE_BONUS_EXPIRING',
    [TRIGGER_TYPE_REGISTER_CLIENT]: 'TRIGGER_TYPE_REGISTER_CLIENT',
    [TRIGGER_TYPE_REGISTER_WALLET_CARD]: 'TRIGGER_TYPE_REGISTER_WALLET_CARD',
    [TRIGGER_TYPE_SUBSCRIBE_EMAIL]: 'TRIGGER_TYPE_SUBSCRIBE_EMAIL',
    [TRIGGER_TYPE_SCHEDULE]: 'TRIGGER_TYPE_SCHEDULE',
    [TRIGGER_TYPE_LEVEL_UP]: 'TRIGGER_TYPE_LEVEL_UP',
    [TRIGGER_TYPE_EMAIL_DELIVERED]: 'TRIGGER_TYPE_EMAIL_DELIVERED',
    [TRIGGER_TYPE_CHANNEL_DELIVERED]: 'TRIGGER_TYPE_CHANNEL_DELIVERED',
};
