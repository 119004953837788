import cloneDeep from 'lodash.clonedeep';
import { v4 as uuid } from 'uuid';
import { defined } from '@/utils/defined';
const fingNestedGroup = (currentGroup, predicate) => {
    if (predicate(currentGroup))
        return currentGroup;
    for (let i = 0; i < currentGroup.children.length; i += 1) {
        const child = currentGroup.children[i].arbitrationGroup;
        const result = child ? fingNestedGroup(child, predicate) : null;
        if (result !== null)
            return result;
    }
    return null;
};
const findGroupByGuid = (priority, guid) => {
    for (let i = 0; i < priority.length; i += 1) {
        const item = priority[i];
        if (item.arbitrationGroup) {
            const found = fingNestedGroup(item.arbitrationGroup, (g) => g.guid === guid);
            if (found)
                return found;
        }
    }
    return null;
};
const findGroupByChildGuid = (priority, guid) => {
    for (let i = 0; i < priority.length; i += 1) {
        const item = priority[i];
        if (item.arbitrationGroup) {
            const found = fingNestedGroup(item.arbitrationGroup, (g) => g.children.map((i) => { var _a; return (_a = i.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.guid; }).includes(guid));
            if (found)
                return found;
        }
    }
    return null;
};
const findGroupByChild = (priority, test) => {
    for (let i = 0; i < priority.length; i += 1) {
        const item = priority[i];
        if (item.arbitrationGroup) {
            const found = fingNestedGroup(item.arbitrationGroup, (g) => !!g.children.find(test));
            if (found)
                return found;
        }
    }
    return null;
};
export const deepIncludes = (parent, child) => {
    if (parent.kind !== 'group' || parent.arbitrationGroup === undefined)
        return false;
    const group = parent.arbitrationGroup;
    if (group.children.includes(child))
        return true;
    for (let i = 0; i < group.children.length; i += 1) {
        const includes = deepIncludes(group.children[i], child);
        if (includes)
            return true;
    }
    return false;
};
export const changeGroup = (priority, guid, values) => {
    const newPriority = cloneDeep(priority);
    const group = findGroupByGuid(newPriority, guid);
    if (group) {
        group.name = values.title;
        group.type = values.operator;
        group.maxDiscountEnabled = values.hasConstraint;
        group.maxDiscountAmount = values.constraintValue || undefined;
        group.maxDiscountType = values.constraintType === 'currency' ? 0 : 1;
    }
    return newPriority;
};
export const deleteGroup = (priority, guid) => {
    const newPriority = cloneDeep(priority).filter((p) => { var _a; return ((_a = p.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.guid) !== guid; });
    const parent = findGroupByChildGuid(newPriority, guid);
    if (parent)
        parent.children = [...parent.children].filter((i) => { var _a; return ((_a = i.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.guid) !== guid; });
    return newPriority;
};
export const groupHasChildren = (priority, guid) => {
    const group = findGroupByGuid(priority, guid);
    return !!(group === null || group === void 0 ? void 0 : group.children.length);
};
export const addSubGroup = (priority, guid, name) => {
    const newPriority = cloneDeep(priority);
    const parent = findGroupByGuid(newPriority, guid);
    const newGroupGuid = 'new-' + uuid();
    if (parent)
        parent.children.unshift({
            kind: 'group',
            priority: parent.children.length,
            arbitrationGroup: {
                guid: newGroupGuid,
                mode: 'row',
                type: 'sum',
                name,
                maxDiscountEnabled: false,
                maxDiscountType: 1,
                maxDiscountAmount: '',
                children: [],
            },
        });
    return { newGroupGuid, newPriority };
};
export const addTopLevelGroup = (priority, name) => {
    const newPriority = cloneDeep(priority);
    const newGroupGuid = 'new-' + uuid();
    newPriority.push({
        kind: 'group',
        priority: newPriority.length,
        arbitrationGroup: {
            guid: newGroupGuid,
            mode: 'row',
            type: 'sum',
            name,
            maxDiscountEnabled: false,
            maxDiscountType: 1,
            maxDiscountAmount: '',
            children: [],
        },
    });
    return { newGroupGuid, newPriority };
};
export const findGroups = (priority, predicate) => {
    return priority
        .filter((item) => {
        return item.kind === 'group' && predicate(item.arbitrationGroup);
    })
        .filter(defined);
};
export const findPriorityItems = (priority, predicate) => {
    return priority.filter((item) => {
        return predicate(item);
    });
};
export const getDraftGuids = (currentPriority, lastSavedPriority, currentUnordered, lastSavedUnordered) => {
    var _a;
    const draftGuids = [];
    const groupsHasDiff = (one, two) => {
        const itemSequenceOne = one.children
            .map((child) => `${child.kind}:${getPriorityId(child)}`)
            .join('-');
        const itemSequenceTwo = two.children
            .map((child) => `${child.kind}:${getPriorityId(child)}`)
            .join('-');
        return (one.name !== two.name ||
            one.type !== two.type ||
            one.maxDiscountEnabled !== two.maxDiscountEnabled ||
            one.maxDiscountAmount !== two.maxDiscountAmount ||
            one.maxDiscountType !== two.maxDiscountType ||
            one.children.length !== two.children.length ||
            itemSequenceOne !== itemSequenceTwo);
    };
    const walk = (current, lastSaved) => {
        for (let i = 0; i < current.length; i += 1) {
            const item = current[i];
            if (item.kind !== 'group' || !item.arbitrationGroup)
                continue;
            const group = item.arbitrationGroup;
            if (group.guid.startsWith('new-'))
                draftGuids.push(group.guid);
            else {
                const saved = findGroupByGuid(lastSaved, group.guid);
                if (saved && groupsHasDiff(group, saved))
                    draftGuids.push(group.guid);
            }
            walk(group.children, lastSaved);
        }
    };
    walk(currentPriority, lastSavedPriority);
    const currentPrioritySequence = currentPriority
        .map((child) => `${child.kind}:${getPriorityId(child)}`)
        .join('-');
    const lastSavedPrioritySequence = lastSavedPriority
        .map((child) => `${child.kind}:${getPriorityId(child)}`)
        .join('-');
    if (currentPriority.length !== lastSavedPriority.length) {
        const a = currentPrioritySequence.split('-');
        const b = lastSavedPrioritySequence.split('-');
        const diff = a
            .filter((x) => !b.includes(x))
            .concat(b.filter((x) => !a.includes(x)));
        diff.forEach((itemId) => {
            const [kind, id] = itemId.split(':');
            if (kind === 'group')
                draftGuids.push(id);
        });
    }
    else if (currentPrioritySequence !== lastSavedPrioritySequence)
        for (let i = 0; i < currentPriority.length; i += 1) {
            const current = currentPriority[i];
            const currentId = `${current.kind}:${getPriorityId(current)}`;
            const last = lastSavedPriority[i];
            const lastId = `${last.kind}:${getPriorityId(last)}`;
            if (currentId !== lastId && ((_a = current.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.guid))
                draftGuids.push(current.arbitrationGroup.guid);
        }
    if (currentUnordered.length !== lastSavedUnordered.length)
        draftGuids.push('unordered');
    return draftGuids;
};
export const fixGuids = (priority, fix) => {
    const newPriority = cloneDeep(priority);
    const walk = (priority) => {
        for (let i = 0; i < priority.length; i += 1) {
            const item = priority[i];
            if (item.kind !== 'group' || !item.arbitrationGroup)
                continue;
            item.arbitrationGroup.guid = fix(item.arbitrationGroup.guid);
            walk(item.arbitrationGroup.children);
        }
    };
    walk(newPriority);
    return newPriority;
};
export const getPriorityId = (priority) => {
    var _a;
    return priority.kind === 'group'
        ? (_a = priority.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.guid
        : priority.kind === 'offer'
            ? priority.offerId
            : priority.promocodeId;
};
export const drop = (priority, unordered, source, target, instruction) => {
    const newPriority = cloneDeep(priority);
    const newUnordered = cloneDeep(unordered);
    const sourceItemId = `${source.kind}:${getPriorityId(source)}`;
    const findSourceChild = (child) => child.kind === source.kind && getPriorityId(child) === getPriorityId(source);
    const sourceGroup = findGroupByChild(newPriority, findSourceChild);
    let sourceArray = sourceGroup === null || sourceGroup === void 0 ? void 0 : sourceGroup.children;
    if (!sourceArray && newUnordered.find(findSourceChild))
        sourceArray = newUnordered;
    if (!sourceArray && newPriority.find(findSourceChild))
        sourceArray = newPriority;
    const findTargetChild = (child) => child.kind === target.kind && getPriorityId(child) === getPriorityId(target);
    const targetGroup = findGroupByChild(newPriority, findTargetChild);
    let targetArray = targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.children;
    if (instruction.type === 'make-child' &&
        target.arbitrationGroup !== undefined) {
        const targetGroup = findGroupByGuid(newPriority, target.arbitrationGroup.guid);
        targetArray = targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.children;
    }
    if (instruction.type === 'reparent' && targetGroup) {
        const targetGroupParent = findGroupByChildGuid(newPriority, targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.guid);
        targetArray = targetGroupParent === null || targetGroupParent === void 0 ? void 0 : targetGroupParent.children;
    }
    if (!targetArray && newPriority.find(findTargetChild))
        targetArray = newPriority;
    if (sourceArray && targetArray) {
        const sourceItem = sourceArray.find(findSourceChild);
        const targetIndex = instruction.type === 'make-child'
            ? 0
            : targetArray.findIndex(findTargetChild);
        if (sourceItem &&
            targetIndex >= 0 &&
            (instruction.type === 'reorder-above' ||
                instruction.type === 'reorder-below' ||
                instruction.type === 'make-child')) {
            if (instruction.type === 'reorder-above')
                targetArray.splice(targetIndex, 0, Object.assign({}, sourceItem));
            else if (instruction.type === 'reorder-below')
                targetArray.splice(targetIndex + 1, 0, Object.assign({}, sourceItem));
            else if (instruction.type === 'make-child')
                targetArray.unshift(Object.assign({}, sourceItem));
            const sourceIndex = sourceArray.findIndex((child) => child === sourceItem);
            if (sourceIndex >= 0)
                sourceArray.splice(sourceIndex, 1);
            return { newPriority, newUnordered, sourceItemId };
        }
        if (sourceItem && instruction.type === 'reparent') {
            targetArray.push(Object.assign({}, sourceItem));
            const sourceIndex = sourceArray.findIndex((child) => child === sourceItem);
            if (sourceIndex >= 0)
                sourceArray.splice(sourceIndex, 1);
            return { newPriority, newUnordered, sourceItemId };
        }
    }
};
export const dropTo = (priority, unordered, source, to = 'priority') => {
    const newPriority = cloneDeep(priority);
    const newUnordered = cloneDeep(unordered);
    const sourceItemId = `${source.kind}:${getPriorityId(source)}`;
    const findSourceChild = (child) => child.kind === source.kind && getPriorityId(child) === getPriorityId(source);
    const sourceGroup = findGroupByChild(newPriority, findSourceChild);
    let sourceArray = sourceGroup === null || sourceGroup === void 0 ? void 0 : sourceGroup.children;
    if (!sourceArray && newUnordered.find(findSourceChild))
        sourceArray = newUnordered;
    if (!sourceArray && newPriority.find(findSourceChild))
        sourceArray = newPriority;
    if (sourceArray) {
        const sourceItem = sourceArray.find(findSourceChild);
        if (sourceItem) {
            if (to === 'priority')
                newPriority.push(Object.assign({}, sourceItem));
            else
                newUnordered.push(Object.assign({}, sourceItem));
            const sourceIndex = sourceArray.findIndex((child) => child === sourceItem);
            if (sourceIndex >= 0)
                sourceArray.splice(sourceIndex, 1);
            return { newPriority, newUnordered, sourceItemId };
        }
    }
};
export const sortUnordered = (unordered) => {
    const newUnordered = cloneDeep(unordered);
    const getPriorityName = (priority) => {
        var _a;
        if (priority.kind === 'offer')
            return priority.offerTitle || '';
        if (priority.kind === 'promocode')
            return priority.promocodeCode || '';
        if (priority.kind === 'group')
            return ((_a = priority.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.name) || '';
        return '';
    };
    newUnordered.sort((a, b) => {
        const aName = getPriorityName(a);
        const bName = getPriorityName(b);
        return aName.localeCompare(bName);
    });
    return newUnordered;
};
export const filterSearch = (searchText, isArchivedVisible, priority) => {
    var _a;
    if (!searchText.trim() && isArchivedVisible)
        return priority;
    const clone = cloneDeep(priority);
    const result = [];
    const searchTextLower = searchText.toLowerCase();
    const pass = (title, isArchived) => {
        const passTitle = title === null || title === void 0 ? void 0 : title.toLowerCase().includes(searchTextLower);
        const passArchivedProp = !isArchivedVisible ? !isArchived : true;
        return passTitle && passArchivedProp;
    };
    for (let i = 0; i < clone.length; i += 1) {
        const item = clone[i];
        if (item.kind === 'offer' && pass(item.offerTitle, item.isArchived))
            result.push(item);
        if (item.kind === 'promocode' && pass(item.promocodeCode, item.isArchived))
            result.push(item);
        if (item.kind === 'group')
            result.push(item);
        if (item.kind === 'group' && ((_a = item.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.children.length))
            item.arbitrationGroup.children = filterSearch(searchText, isArchivedVisible, item.arbitrationGroup.children);
    }
    return result;
};
