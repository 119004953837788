import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Error } from '@/icons/status/Error';
import { largeNumberRound, numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const ChannelNodeStats = ({ id }) => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { isEditMode, stats } = useInteractiveFlow();
    const { currency, shouldDisplayProfit } = useCurrentUser();
    const thisNode = flow.getNode(id);
    const thisNodeStats = thisNode ? stats === null || stats === void 0 ? void 0 : stats[thisNode.uuid] : undefined;
    if (!thisNodeStats || isEditMode)
        return null;
    const profitOrRevenue = shouldDisplayProfit
        ? thisNodeStats.profit
        : thisNodeStats.revenue;
    const profitOrRevenueFormatted = largeNumberRound({
        value: Number(profitOrRevenue),
        currency,
    });
    const profitOrRevenueLabel = shouldDisplayProfit ? t('Прибыль') : t('Выручка');
    const roiValue = numberRound(roi(profitOrRevenue, thisNodeStats.expenses)) + '%';
    const failed = Number(thisNodeStats.failed || 0);
    const failedFormatted = failed > 0 ? numberRound(failed) : '';
    return (_jsxs("div", Object.assign({ className: styles.stats }, { children: [_jsxs("div", Object.assign({ className: styles.statsItem }, { children: [_jsx("div", Object.assign({ className: styles.statsTitle }, { children: profitOrRevenueLabel })), _jsx("div", Object.assign({ className: styles.statsValue }, { children: profitOrRevenueFormatted }))] })), _jsxs("div", Object.assign({ className: styles.statsItem }, { children: [_jsx("div", Object.assign({ className: styles.statsTitle }, { children: "ROI" })), _jsx("div", Object.assign({ className: styles.statsValue }, { children: roiValue }))] })), _jsx("div", Object.assign({ className: styles.statsItem }, { children: _jsx("div", Object.assign({ className: styles.statsValue }, { children: !!failedFormatted && (_jsxs(_Fragment, { children: [_jsx(Error, { size: 12 }), failedFormatted] })) })) }))] })));
};
