import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import styles from '../../styles.module.scss';
import { PopupWithOptions } from './PopupWithOptions';
export const FormOfferBonusCheckboxes = ({ labelWidth }) => {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isBonusesLifetime, setIsBonusesLifetime] = useState(false);
    const { setValue, watch } = useFormContext();
    const offerType = watch('type');
    const allowBonusCollect = watch('allowBonusCollect');
    const bonusesAvailableInDays = watch('bonusesAvailableInDays');
    const bonusesAvailableAt = watch('bonusesAvailableAt');
    const bonusesExpireInDays = watch('bonusesExpireInDays');
    const bonusesExpireAt = watch('bonusesExpireAt');
    const bonusesLifetime = watch('bonusesLifetime');
    const shouldDisplay = offerType === 'bonus';
    useEffect(() => {
        if (bonusesLifetime)
            return setIsBonusesLifetime(true);
        setIsBonusesLifetime(false);
    }, [bonusesLifetime]);
    const options = [
        { id: 1, label: t('прибавляются'), value: true },
        { id: 2, label: t('начисляются вместо'), value: false },
    ];
    if (shouldDisplay)
        return (_jsxs(_Fragment, { children: [_jsx(PopupWithOptions, { labelWidth: labelWidth, prefix: t('активируются'), postfix: t('после начисления'), neutralFieldText: t('по правилам ПЛ'), fromDateFieldName: 'bonusesAvailableAt', daysNumberFieldName: 'bonusesAvailableInDays', fromDateFieldValue: bonusesAvailableAt, daysNumberFieldValue: bonusesAvailableInDays, endDate: bonusesExpireAt }), _jsx(PopupWithOptions, { labelWidth: labelWidth, prefix: t('сгорают'), postfix: t('после активации'), neutralFieldText: t('по правилам ПЛ'), fromDateFieldName: 'bonusesExpireAt', daysNumberFieldName: 'bonusesExpireInDays', fromDateFieldValue: bonusesExpireAt, daysNumberFieldValue: bonusesExpireInDays, startDate: bonusesAvailableAt }), _jsx(FormFieldRow, Object.assign({ tooltip: t('Теперь эта настройка в разделе\nОграничения акции →\nЗапреты'), tooltipPlacement: 'right', tooltipPosition: 'control', className: styles.fieldRow, alignItems: 'center', labelWidth: labelWidth, controlClassName: styles.controlClassName }, { children: _jsxs("div", Object.assign({ className: styles.isAdditionalAutocompleteWrapper }, { children: [_jsx(Autocomplete, { disabled: true, className: styles.isAdditionalAutocomplete, value: allowBonusCollect ? options[0] : options[1], options: options, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, onChange: (data) => {
                                    setValue('isAddition', data === null || data === void 0 ? void 0 : data.value, {
                                        shouldDirty: true,
                                    });
                                }, hasResetButton: false, isInline: true, asSelect: true, renderOption: () => {
                                    var _a;
                                    return (_jsx("div", Object.assign({ className: styles.renderOption }, { children: _jsx("span", Object.assign({ className: styles.placeholder }, { children: (_a = options[0]) === null || _a === void 0 ? void 0 : _a.label })) })));
                                } }), allowBonusCollect ? t('к бонусам по ПЛ') : t('бонусов ПЛ')] })) }))] }));
    return null;
};
