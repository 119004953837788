import { useTranslation } from 'react-i18next';
export const useTriggersChannelReceived = () => {
    const { t } = useTranslation();
    return [
        {
            id: 'delivered_channel',
            label: t('Доставлено') + '?',
        },
        {
            id: 'purchased_channel',
            label: t('Сделал покупку') + '?',
        },
    ];
};
