import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements, } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { attachInstruction, extractInstruction, } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { useEffect, useRef, useState } from 'react';
import { deepIncludes } from '../../ArbitrationEditor/utils';
const idle = { type: 'idle' };
const setDropTargetStyle = (element, instruction) => {
    var _a, _b, _c;
    if (instruction.type === 'reparent') {
        const newParent = (_c = (_b = (_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.closest('[data-priority-id]')) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.closest('[data-priority-id]');
        if (newParent)
            newParent.classList.add('dnd-dragging-over-' + instruction.type);
    }
    else {
        element.classList.add('dnd-dragging-over');
        element.classList.add('dnd-dragging-over-' + instruction.type);
    }
};
const resetDropTargetStyles = () => {
    const allDropTargetElements = document.querySelectorAll('[data-priority-id]');
    allDropTargetElements.forEach((element) => {
        element.classList.remove('dnd-dragging-over');
        element.classList.remove('dnd-dragging-over-reorder-above');
        element.classList.remove('dnd-dragging-over-reorder-below');
        element.classList.remove('dnd-dragging-over-make-child');
        element.classList.remove('dnd-dragging-over-reparent');
        element.classList.remove('dnd-dragging-over-instruction-blocked');
    });
};
export const useDraggableTreeItem = (priority, parent) => {
    const ref = useRef(null);
    const [state, setState] = useState(idle);
    useEffect(() => {
        const element = ref.current;
        if (!element)
            return;
        const draggableCleanup = draggable({
            element,
            getInitialData() {
                return priority;
            },
            onGenerateDragPreview({ nativeSetDragImage }) {
                disableNativeDragPreview({ nativeSetDragImage });
            },
            onDrag({ location }) {
                resetDropTargetStyles();
                if (location.current.dropTargets[0]) {
                    const instruction = extractInstruction(location.current.dropTargets[0].data);
                    if (instruction)
                        setDropTargetStyle(location.current.dropTargets[0].element, instruction);
                }
                setState({
                    type: 'is-dragging',
                    x: location.current.input.pageX,
                    y: location.current.input.pageY,
                });
            },
            onDrop() {
                resetDropTargetStyles();
                setState(idle);
            },
        });
        const dropTargetCleanup = dropTargetForElements({
            element,
            canDrop({ source }) {
                const sourcePriority = source.data;
                if ((parent === null || parent === void 0 ? void 0 : parent.guid) === 'unordered')
                    return false;
                if (deepIncludes(sourcePriority, priority))
                    return false;
                if ((parent === null || parent === void 0 ? void 0 : parent.mode) === 'exclusive' && sourcePriority.kind === 'group')
                    return false;
                return true;
            },
            getData({ source, input, element }) {
                var _a;
                const data = priority;
                const htmlElement = element instanceof HTMLElement ? element : null;
                const currentLevel = (htmlElement === null || htmlElement === void 0 ? void 0 : htmlElement.dataset.priorityLevel) !== undefined
                    ? Number(htmlElement.dataset.priorityLevel)
                    : 0;
                const isLast = (htmlElement === null || htmlElement === void 0 ? void 0 : htmlElement.dataset.priorityLast) !== undefined
                    ? htmlElement.dataset.priorityLast === 'true'
                    : false;
                const groupElement = element.querySelector('[data-priority-expanded]');
                const isExpanded = groupElement &&
                    groupElement instanceof HTMLElement &&
                    groupElement.dataset.priorityExpanded !== undefined
                    ? groupElement.dataset.priorityExpanded === 'true'
                    : false;
                const block = [];
                if (priority.kind !== 'group' ||
                    (((_a = priority.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.children.length) && isExpanded) ||
                    source.data === priority)
                    block.push('make-child');
                const isTopLevel = (htmlElement === null || htmlElement === void 0 ? void 0 : htmlElement.dataset.priorityTopLevel) === 'true';
                if (isTopLevel &&
                    (source.data.kind === 'promocode' || source.data.kind === 'offer'))
                    block.push('reorder-above', 'reorder-below');
                return attachInstruction(data, {
                    element,
                    input,
                    currentLevel: currentLevel + 1,
                    indentPerLevel: 32,
                    mode: isExpanded ? 'expanded' : isLast ? 'last-in-group' : 'standard',
                    block,
                });
            },
            getIsSticky() {
                return false;
            },
        });
        return combine(draggableCleanup, dropTargetCleanup);
    }, [priority, parent]);
    return { ref, draggableState: state };
};
