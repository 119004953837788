import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { DropdownPills } from '@/components/DropdownPills/DropdownPills';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { TwoAxisLineChart, } from '@/components/TwoAxisLineChart/TwoAxisLineChart';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const DependencePanel = memo(({ selectedScaleId, onChangeScale, selectedLeftSourceId, onChangeLeftSource, leftSourceTotal, selectedRightSourceId, onChangeRightSource, rightSourceTotal, dates, leftAxis, rightAxis, hasLeftAxisGrid = true, hasRightAxisGrid = true, hasXAxisGrid = false, currency, isProfitVisible = false, isLoading = false, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const scales = [
        { id: 'day', label: t('День') },
        { id: 'week', label: t('Неделя') },
        { id: 'month', label: t('Месяц') },
    ];
    const sources = [
        isProfitVisible
            ? { id: 'profit', label: t('Прибыль по ПЛ') }
            : { id: 'revenue', label: t('Выручка по ПЛ') },
        { id: 'cards', label: t('Регистрации') },
        { id: 'purchases', label: t('Покупки с картами') },
        { id: 'expenses', label: t('Расходы на ПЛ') },
    ];
    const leftSourceCurrency = selectedLeftSourceId === 'revenue' || selectedLeftSourceId === 'expenses'
        ? currency
        : '';
    const leftSourceTotalLabel = `${numberRound(leftSourceTotal)} ${leftSourceCurrency}`.trim();
    const rightSourceCurrency = selectedRightSourceId === 'revenue' ||
        selectedRightSourceId === 'expenses'
        ? currency
        : '';
    const rightSourceTotalLabel = `${numberRound(rightSourceTotal)} ${rightSourceCurrency}`.trim();
    const leftAxisLabel = ((_a = sources.find((s) => s.id === selectedLeftSourceId)) === null || _a === void 0 ? void 0 : _a.label) || '';
    const rightAxisLabel = ((_b = sources.find((s) => s.id === selectedRightSourceId)) === null || _b === void 0 ? void 0 : _b.label) || '';
    const leftAxisData = useMemo(() => (Object.assign(Object.assign({}, leftAxis), { label: leftAxisLabel })), [leftAxis]);
    const rightAxisData = useMemo(() => (Object.assign(Object.assign({}, rightAxis), { label: rightAxisLabel })), [rightAxis]);
    const leftSourceDropdown = (_jsxs("div", { children: [_jsx(Dropdown, { triggerClassName: styles.source, title: leftAxisLabel, items: sources.map((source) => (Object.assign(Object.assign({}, source), { onClick: () => onChangeLeftSource(source.id) }))), size: 'medium', placement: 'bottom-start' }), _jsxs("div", Object.assign({ className: styles.leftTotalItem }, { children: [_jsx("div", { className: styles.leftTotalColor }), leftSourceTotalLabel] }))] }));
    const rightSourceDropdown = (_jsxs("div", { children: [_jsx(Dropdown, { triggerClassName: styles.source, title: rightAxisLabel, items: sources.map((source) => (Object.assign(Object.assign({}, source), { onClick: () => onChangeRightSource(source.id) }))), size: 'medium', placement: 'bottom-start' }), _jsxs("div", Object.assign({ className: styles.rightTotalItem }, { children: [_jsx("div", { className: styles.rightTotalColor }), rightSourceTotalLabel] }))] }));
    return (_jsx(StatsPanel, Object.assign({ className: styles.panel, title: _jsxs("div", Object.assign({ className: styles.title }, { children: [_jsxs("div", Object.assign({ className: styles.dropdowns }, { children: [leftSourceDropdown, rightSourceDropdown] })), _jsx(DropdownPills, { items: scales, selectedItemId: selectedScaleId, onChange: onChangeScale })] })), isLoading: isLoading }, { children: _jsx("div", Object.assign({ className: styles.chart }, { children: _jsx(TwoAxisLineChart, { labels: dates, leftAxis: leftAxisData, rightAxis: rightAxisData, hasLeftAxisGrid: hasLeftAxisGrid, hasRightAxisGrid: hasRightAxisGrid, hasXAxisGrid: hasXAxisGrid }) })) })));
});
