import { useReactFlow } from '@xyflow/react';
import { useEffect } from 'react';
import * as triggers from '@/const/mailing-triggers';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { createEdge } from '../nodes/createEdge';
import { createLoopNode } from '../nodes/LoopNode/utils';
import { createNewStepNode } from '../nodes/NewStepNode/utils';
import { createTriggerNode, TRIGGER_DEFAULT_REPEAT_MAP, } from '../nodes/TriggerNode/utils';
export const useCreateInitialNodes = () => {
    const { triggerType, triggerLabel } = useInteractiveFlow();
    const createInitialNodes = () => {
        const triggerNode = createTriggerNode({ triggerType, triggerLabel });
        const newStepNode = createNewStepNode();
        if (triggerType === triggers.TRIGGER_TYPE_SCHEDULE) {
            const fromTriggerToNewStepNode = createEdge({
                source: triggerNode.id,
                target: newStepNode.id,
            });
            return {
                addNodes: [triggerNode, newStepNode],
                addEdges: [fromTriggerToNewStepNode],
            };
        }
        const loopNode = createLoopNode({
            repeat: TRIGGER_DEFAULT_REPEAT_MAP[triggerType],
        });
        const fromTriggerToLoopNode = createEdge({
            source: triggerNode.id,
            target: loopNode.id,
        });
        const fromLoopNodeToNewStepNode = createEdge({
            source: loopNode.id,
            target: newStepNode.id,
        });
        return {
            addNodes: [triggerNode, loopNode, newStepNode],
            addEdges: [fromTriggerToLoopNode, fromLoopNodeToNewStepNode],
        };
    };
    return createInitialNodes;
};
export const useInitialNodes = (enabled = true) => {
    const flow = useReactFlow();
    const createInitialNodes = useCreateInitialNodes();
    useEffect(() => {
        if (!enabled)
            return;
        const { addNodes, addEdges } = createInitialNodes();
        flow.setNodes(addNodes);
        flow.setEdges(addEdges);
    }, []);
};
