import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { YesNoInput } from '@/components/FilterListInput/YesNoInput';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { findEdgesFromNode, findNodeByUUID } from '../../nodes/utils';
import commonStyles from '../styles.module.scss';
export const EmailReceivedForm = () => {
    const flow = useReactFlow();
    const { selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const handleUpdate = (conditions) => {
        selectedNode.update({ conditions });
    };
    const thisNode = findNodeByUUID(flow, selectedNode.uuid);
    const edgesFromThisNode = findEdgesFromNode(flow, thisNode);
    const nonDeletableHandles = edgesFromThisNode
        .map((edge) => edge.sourceHandle || null)
        .filter(notNull);
    return (_jsxs(_Fragment, { children: [_jsx(YesNoInput, { value: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.conditions, onChange: handleUpdate, canDeleteNo: !nonDeletableHandles.some((h) => h.startsWith('no')), canDeleteYes: !nonDeletableHandles.some((h) => h.startsWith('yes')), isDisabled: !isEditMode }), !!(errors === null || errors === void 0 ? void 0 : errors.conditions) && (_jsx("div", Object.assign({ className: classNames(commonStyles.error, commonStyles.mt12) }, { children: errors.conditions })))] }));
};
