import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { RFMChartContainer } from '@/components/rfm/RFMChart/RFMChartContainer';
import { RFMMovementsPanelContainer } from '@/components/rfm/RFMMovementsPanelContainer/RFMMovementsPanelContainer';
import { RFMPageLayout } from '@/components/rfm/RFMPageLayout/RFMPageLayout';
import { RFMRecommendations } from '@/components/rfm/RFMRecommendations/RFMRecommendations';
import { RFMTableContainer } from '@/components/rfm/RFMTable/RFMTableContainer';
import { RFMThresholdPanelContainer } from '@/components/rfm/RFMThresholdPanel/RFMThresholdPanelContainer';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { ROUTES } from '../routes';
import { useFilters } from './useFilters';
import { useOpenedSegments } from './useOpenedSegments';
import { useRecommendationSegments } from './useRecommendationSegments';
export const RFM = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { brand } = useCurrentUser();
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort) || '';
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const shouldDisplayProfit = brand === null || brand === void 0 ? void 0 : brand.brand.showProfit;
    const goToManualMailing = (segment) => {
        navigate(ROUTES.MAILINGS_MANUAL_CREATE, { state: { segment } });
    };
    const goToAutoMailing = (segment) => {
        navigate(ROUTES.MAILINGS_AUTOMATIC_CREATE, { state: { segment } });
    };
    const goToOffer = (segment) => {
        navigate(ROUTES.OFFERS_CREATE, { state: { segment } });
    };
    const goToSegmentClients = (segment, conditions) => {
        navigate(ROUTES.CLIENTS, {
            state: { segmentId: segment === null || segment === void 0 ? void 0 : segment.id, conditions },
        });
    };
    const goToReport = () => {
        navigate(ROUTES.REPORTS, { state: { source: 'rfm' } });
    };
    const { openedSegments, openSegment, closeSegment } = useOpenedSegments();
    const { filtersTree, filters, saveFilters } = useFilters();
    const { getRecommendationSegment } = useRecommendationSegments();
    const handleOpenSegment = useCallback((rawId) => {
        const id = rawId;
        let scrollToSegment = true;
        let scrollToTop = false;
        if (!openedSegments.includes(id))
            openSegment(id, true);
        if (openedSegments.length === 1 && openedSegments.includes(id)) {
            closeSegment(id);
            scrollToSegment = false;
            scrollToTop = true;
        }
        setTimeout(() => {
            var _a, _b;
            if (scrollToSegment)
                (_a = document.getElementById(`rfm-recommendation-${id}`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                    behavior: 'smooth',
                });
            if (scrollToTop)
                (_b = document.getElementById('rfm-recommendations-panel')) === null || _b === void 0 ? void 0 : _b.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
        }, 100);
    }, [openedSegments]);
    const handleAction = useCallback((id, action) => {
        const segment = getRecommendationSegment(id);
        if (action === 'create-manual-mailing')
            goToManualMailing(segment);
        if (action === 'create-auto-mailing')
            goToAutoMailing(segment);
        if (action === 'create-offer')
            goToOffer(segment);
        if (action === 'view-clients')
            goToSegmentClients(segment, segment === null || segment === void 0 ? void 0 : segment.conditions);
    }, [getRecommendationSegment]);
    if (!brand)
        return null;
    return (_jsx(Container, { children: _jsx(RFMPageLayout, { thresholdPanel: _jsx(RFMThresholdPanelContainer, { currency: currency, isRFMEnabled: isRFMEnabled }), table: _jsx(RFMTableContainer, { onCreateReportClick: goToReport, currency: currency, shouldDisplayProfit: shouldDisplayProfit, isRFMEnabled: isRFMEnabled }), chart: _jsx(RFMChartContainer, { filters: filters, onOpenSegment: handleOpenSegment, shouldDisplayProfit: shouldDisplayProfit, isRFMEnabled: isRFMEnabled }), recommendations: _jsx(RFMRecommendations, { openedSegments: openedSegments, onOpenSegment: openSegment, onCloseSegment: closeSegment, onAction: handleAction, isLocked: !isRFMEnabled }), movements: _jsx(RFMMovementsPanelContainer, { filters: filters }), shopsFilter: _jsx(FiltersInput, { filtersTree: filtersTree, value: filters, onUpdate: saveFilters, buttonTheme: 'default', hasOperators: false, hasResetButton: false }), datePeriodSelect: _jsx(DatePeriodSelectContainer, { label: t('Расчеты за') }) }) }));
};
