import { useCallback } from 'react';
import { useGetRFMTableQuery } from '@/api/query/rfm';
import { getRFMSegmentCode } from '@/components/rfm/RFMData/RFMSegmentCodes';
export const useRecommendationSegments = () => {
    const { data } = useGetRFMTableQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const getRecommendationSegment = useCallback((id) => {
        const rfmSegment = data === null || data === void 0 ? void 0 : data.statsPerSegment.find((segment) => segment.code === getRFMSegmentCode(id));
        const filterSegment = data === null || data === void 0 ? void 0 : data.riskSegments.find((segment) => segment.code === getRFMSegmentCode(id));
        return rfmSegment || filterSegment;
    }, [data === null || data === void 0 ? void 0 : data.statsPerSegment]);
    return { getRecommendationSegment };
};
