import { useEffect, useRef } from 'react';
export const useDrawerFocus = (uuid) => {
    const container = useRef(null);
    useEffect(() => {
        let timeoutId;
        if (uuid)
            timeoutId = setTimeout(() => {
                var _a, _b;
                if (!((_a = container.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement)))
                    (_b = container.current) === null || _b === void 0 ? void 0 : _b.focus();
            }, 100);
        return () => clearTimeout(timeoutId);
    }, [uuid]);
    return { container };
};
