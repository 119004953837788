import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { RFMMovementName } from '../RFMMovementName/RFMMovementName';
import { RFMMovementsChartBar, } from './RFMMovementsChartBar';
import { RFMMovementsChartHeader } from './RFMMovementsChartHeader';
import styles from './styles.module.scss';
export const RFMMovementsChart = ({ type = 'all', rfmSegments = [], data = {}, selectedRFMSegment, onChangeSelectedRFMSegment, selectedRFMSegmentBar, onChangeSelectedRFMSegmentBar, sortSegments = true, isLocked, }) => {
    const selectSegment = (code) => () => {
        if (selectedRFMSegment == code)
            onChangeSelectedRFMSegment === null || onChangeSelectedRFMSegment === void 0 ? void 0 : onChangeSelectedRFMSegment(undefined);
        else
            onChangeSelectedRFMSegment === null || onChangeSelectedRFMSegment === void 0 ? void 0 : onChangeSelectedRFMSegment(code);
    };
    const selectLeftBar = (code) => (e) => {
        e.stopPropagation();
        if (selectedRFMSegment === code && selectedRFMSegmentBar === 'left')
            onChangeSelectedRFMSegmentBar === null || onChangeSelectedRFMSegmentBar === void 0 ? void 0 : onChangeSelectedRFMSegmentBar(undefined, undefined);
        else
            onChangeSelectedRFMSegmentBar === null || onChangeSelectedRFMSegmentBar === void 0 ? void 0 : onChangeSelectedRFMSegmentBar(code, 'left');
    };
    const selectRightBar = (code) => (e) => {
        e.stopPropagation();
        if (selectedRFMSegment === code && selectedRFMSegmentBar === 'right')
            onChangeSelectedRFMSegmentBar === null || onChangeSelectedRFMSegmentBar === void 0 ? void 0 : onChangeSelectedRFMSegmentBar(undefined, undefined);
        else
            onChangeSelectedRFMSegmentBar === null || onChangeSelectedRFMSegmentBar === void 0 ? void 0 : onChangeSelectedRFMSegmentBar(code, 'right');
    };
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles[type], {
            [styles.locked]: isLocked,
        }) }, { children: [_jsx(RFMMovementsChartHeader, { type: type }), rfmSegments.map((segment) => {
                const { left, right } = data[segment.code || ''] || {};
                return (_jsxs("div", Object.assign({ className: classNames(styles.row, styles.segmentRow, {
                        [styles.empty]: !left && !right,
                        [styles.selected]: selectedRFMSegment === segment.code,
                    }), onClick: left || right ? selectSegment(segment.code) : undefined }, { children: [_jsx("div", Object.assign({ className: styles['column-1'] }, { children: _jsx(RFMMovementName, { className: styles.segmentName, segment: segment }) })), _jsx("div", Object.assign({ className: classNames(styles['column-2'], {
                                [styles.withData]: !!left,
                                [styles.selected]: selectedRFMSegment === segment.code &&
                                    selectedRFMSegmentBar === 'left',
                            }), onClick: left ? selectLeftBar(segment.code) : undefined }, { children: left ? (_jsx(RFMMovementsChartBar, Object.assign({}, left, { sortSegments: sortSegments, sortSegmentsOrder: type === 'all' || type === 'to' ? 'asc' : 'desc' }))) : (_jsx("div", Object.assign({ className: styles.dash }, { children: "\u2013" }))) })), _jsx("div", Object.assign({ className: classNames(styles['column-3'], {
                                [styles.withData]: !!right,
                                [styles.selected]: selectedRFMSegment === segment.code &&
                                    selectedRFMSegmentBar === 'right',
                            }), onClick: right ? selectRightBar(segment.code) : undefined }, { children: right ? (_jsx(RFMMovementsChartBar, Object.assign({}, right, { sortSegments: sortSegments, sortSegmentsOrder: type === 'all' || type === 'from' ? 'desc' : 'asc' }))) : (_jsx("div", Object.assign({ className: styles.dash }, { children: "\u2013" }))) })), _jsx("div", { className: styles.line })] }), segment.id));
            })] })));
};
