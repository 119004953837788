import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetRFMHistoryQuery } from '@/api/query/rfm';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { compileFilters } from '@/services/filters/compileFilters';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
import { changePeriod } from '@/store/slice/appPeriod';
import { pct } from '@/utils/calculatePercent';
import { getBestAggInterval } from '@/utils/getBestAggInterval';
import { getRFMSegmentId } from '../RFMData/RFMSegmentCodes';
export const useChartData = (dataType, chartType, rawFilters, shouldSortChartData = false) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const filters = useMemo(() => compileFilters(rawFilters || []), [JSON.stringify(rawFilters)]);
    const bucketSize = getBestAggInterval({
        periodStart,
        periodEnd,
        isSparkline: false,
    });
    const { data } = useGetRFMHistoryQuery({
        periodStart,
        periodEnd,
        filters,
        bucketSize,
    });
    let chart = (data === null || data === void 0 ? void 0 : data.chart) || [];
    const segments = (data === null || data === void 0 ? void 0 : data.segments) || [];
    const total = data === null || data === void 0 ? void 0 : data.total;
    if (shouldSortChartData) {
        chart = [...chart];
        chart.sort((a, b) => {
            const aUnix = moment(a.date).unix();
            const bUnix = moment(b.date).unix();
            return aUnix - bUnix;
        });
    }
    const areaLabels = useMemo(() => chart.map((chartItem) => moment(chartItem.date)), [chart]);
    const areaDatasets = useMemo(() => segments.map((s) => ({
        id: getRFMSegmentId(s.code),
        label: t(s.name),
        color: s.color,
        data: chart.map((chartItem) => {
            const segmentGroup = chartItem.groups.find((g) => g.groupKey === s.id);
            return getValue(dataType, segmentGroup);
        }),
    })), [chart, segments, dataType]);
    const barsTotal = getValue(dataType, total);
    const barsSum = segments.reduce((sum, s) => sum + getValue(dataType, s), 0);
    const bars = useMemo(() => {
        const items = segments.map((s) => {
            const value = getValue(dataType, s);
            const percent = pct(value, barsSum);
            return {
                id: getRFMSegmentId(s.code),
                label: t(s.name),
                color: s.color,
                value,
                percent,
            };
        });
        items.sort((a, b) => b.value - a.value);
        return items;
    }, [chart, segments, dataType, barsSum]);
    const hasNoSearchResults = !chart.length && !!(rawFilters === null || rawFilters === void 0 ? void 0 : rawFilters.length);
    const hasNoData = !hasNoSearchResults && !chart.length;
    const shouldDisplayWrongDateWarning = dataType !== 'clientsCount' &&
        ((chartType === 'bars' && isBeforeDateThreshold(periodStart)) ||
            (chartType === 'area' && isBeforeDateThreshold(periodEnd)));
    const fixPeriod = () => {
        const start = dateThreshold;
        const end = isBeforeDateThreshold(periodEnd)
            ? moment(dateThreshold).add(1, 'day')
            : moment(periodEnd);
        dispatch(changePeriod({
            start: start.startOf('day'),
            end: end.endOf('day'),
        }));
    };
    return {
        hasNoSearchResults,
        hasNoData,
        areaLabels,
        areaDatasets,
        bucketSize,
        bars,
        barsTotal,
        shouldDisplayWrongDateWarning,
        fixPeriod,
    };
};
const getValue = (dataType, data) => {
    if (dataType === 'clientsCount')
        return (data === null || data === void 0 ? void 0 : data.segmentSize) || 0;
    if (dataType === 'purchasesCount')
        return (data === null || data === void 0 ? void 0 : data.purchaseCount) || 0;
    if (dataType === 'revenue')
        return (data === null || data === void 0 ? void 0 : data.revenue) || 0;
    if (dataType === 'arppu')
        return (data === null || data === void 0 ? void 0 : data.arppu) || 0;
    if (dataType === 'avgSale')
        return (data === null || data === void 0 ? void 0 : data.avgAmount) || 0;
    return 0;
};
const dateThreshold = moment('08.07.2023', 'DD.MM.YYYY');
const isBeforeDateThreshold = (date) => {
    return moment(date).isBefore(dateThreshold);
};
