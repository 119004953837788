import { ROUTES } from './routes';
export const ROUTE_NAMES = {
    [ROUTES.DASHBOARD]: 'Сводка',
    [ROUTES.RFM]: 'Smart RFM',
    [ROUTES.REPORTS]: 'Отчеты',
    [ROUTES.EXPORTS]: 'Выгрузки',
    [ROUTES.IMPORTS]: 'Импорт данных',
    [ROUTES.IMPORTS_EDIT]: 'Новый импорт',
    [ROUTES.CLIENTS]: 'Список клиентов',
    [ROUTES.SEGMENTS]: 'Сегменты',
    [ROUTES.PURCHASES]: 'Покупки клиентов',
    [ROUTES.EVENTS]: 'Все события',
    [ROUTES.MAILINGS_MANUAL]: 'Ручные рассылки',
    [ROUTES.MAILINGS_MANUAL_CREATE]: 'Ручная рассылка',
    [ROUTES.MAILINGS_MANUAL_EDIT]: 'Ручная рассылка',
    [ROUTES.MAILINGS_AUTOMATIC]: 'Автоматические рассылки',
    [ROUTES.MAILINGS_AUTOMATIC_CREATE]: 'Автоматическая рассылка',
    [ROUTES.MAILINGS_AUTOMATIC_EDIT]: 'Автоматическая рассылка',
    [ROUTES.FLOWS]: 'Цепочки',
    [ROUTES.FLOWS_CREATE]: 'Цепочка',
    [ROUTES.FLOWS_EDIT]: 'Цепочка',
    [ROUTES.ARBITRATION]: 'Арбитраж',
    [ROUTES.WALLET_CARDS]: 'Карты Wallet',
    [ROUTES.WALLET_CARDS_CREATE]: 'Карта Wallet',
    [ROUTES.WALLET_CARDS_EDIT]: 'Карта Wallet',
    [ROUTES.GEO_PUSH_CREATE]: 'Геопуш',
    [ROUTES.GEO_PUSH_EDIT]: 'Геопуш',
    [ROUTES.OFFERS]: 'Акции',
    [ROUTES.OFFERS_CREATE]: 'Акция',
    [ROUTES.OFFERS_EDIT]: 'Акция',
    [ROUTES.PROMOCODES]: 'Промокоды',
    [ROUTES.PROMOCODES_CREATE]: 'Промокод',
    [ROUTES.PROMOCODES_EDIT]: 'Промокод',
    [ROUTES.PROMOCODES_FRIEND]: 'Приведи друга',
    [ROUTES.PROMOCODES_FRIEND_CREATE]: 'Код',
    [ROUTES.PROMOCODES_FRIEND_EDIT]: 'Код',
    [ROUTES.GIFT_CARDS]: 'Подарочные карты',
    [ROUTES.GIFT_CARDS_CREATE]: 'Подарочная карта',
    [ROUTES.GIFT_CARDS_EDIT]: 'Подарочная карта',
    [ROUTES.GIFT_CARDS_INSTANCES]: 'Список выпущенных карт',
    [ROUTES.BILLING]: 'Детализация',
    [ROUTES.BILLING_INVOICES]: 'Счета',
    [ROUTES.SETTINGS_USERS]: 'Пользователи',
    [ROUTES.SETTINGS_SHOPS]: 'Точки продаж',
};
