import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as MUIModal } from '@mui/material';
import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Tabs } from '@/components/Tabs2/Tabs';
import { CloseButton } from './CloseButton';
import styles from './styles.module.scss';
export const Modal = memo((_a) => {
    var _b;
    var { className, isOnMountFocus = false, isOpen = false, isNotificationOfClosureOpen = false, onClose, notificationOfClosure, titleClassName, titleIcon, title = '', titleRightElement, titleTooltip, tabs = [], activeTabId = ((_b = tabs[0]) === null || _b === void 0 ? void 0 : _b.id) || '', onChangeActiveTab, bodyClassName, modalClassName, children, footerClassName, footer, size = 'normal', theme = 'light', isForm = false, hasNoPadding = false, hasNoOverflow = false, isConfirmDialog = false, } = _a;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [wrapperState, setWrapperState] = useState('normal');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    useEffect(() => {
        if (!isNotificationOfClosureOpen && tooltipOpen)
            setTooltipOpen(false);
    }, [isNotificationOfClosureOpen, tooltipOpen]);
    useEffect(() => {
        // Вызов нативного браузерного предупреждения о редиректе или обновлении страницы
        const beforeUnloadListener = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };
        if (isNotificationOfClosureOpen)
            addEventListener('beforeunload', beforeUnloadListener);
        if (!isOpen)
            removeEventListener('beforeunload', beforeUnloadListener);
        return () => {
            removeEventListener('beforeunload', beforeUnloadListener);
        };
    }, [isNotificationOfClosureOpen, isOpen]);
    const handleClose = notificationOfClosure && isNotificationOfClosureOpen
        ? () => {
            var _a;
            setTooltipOpen(!tooltipOpen);
            // получаем родительский элемент содержимого модалки
            (_a = document.getElementById('modal-of-app')) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
        }
        : onClose;
    useEffect(() => {
        if (isOpen) {
            setIsModalOpen(true);
            setTimeout(() => setWrapperState('open'), 0);
        }
        else {
            setWrapperState('closed');
            setTimeout(() => setIsModalOpen(false), 250);
            setTimeout(() => setWrapperState('normal'), 250);
        }
    }, [isOpen]);
    return (_jsx(MUIModal, Object.assign({ id: 'modal-of-app', disableEnforceFocus: true, disableAutoFocus: true, classes: {
            root: classNames(styles.modal, styles[`w-${size}`], modalClassName),
        }, open: isModalOpen, onClose: handleClose, BackdropProps: {
            open: wrapperState === 'open',
        }, "data-confirm-dialog": isConfirmDialog ? true : undefined }, { children: _jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles[wrapperState], styles[theme], className) }, { children: [title && (_jsxs("div", Object.assign({ className: classNames(styles.title, {
                        [styles.hasRightElement]: !!titleRightElement,
                    }, titleClassName) }, { children: [_jsxs("div", Object.assign({ className: classNames({
                                [styles.withIcon]: !!titleIcon,
                            }) }, { children: [titleIcon && _jsx("div", Object.assign({ className: styles.icon }, { children: titleIcon })), titleTooltip ? (_jsx(HelpTooltip, Object.assign({ title: titleTooltip }, { children: title }))) : (title)] })), titleRightElement] }))), tabs.length > 0 && (_jsx("div", Object.assign({ className: styles.tabsContainer }, { children: _jsx(Tabs, { className: styles.tabs, tabClassName: styles.tab, tabs: tabs, activeTabId: activeTabId, onChangeActiveTab: onChangeActiveTab }) }))), _jsx("div", Object.assign({ className: classNames(styles.body, {
                        [styles.form]: isForm,
                        [styles.noPadding]: hasNoPadding,
                        [styles.noOverflow]: hasNoOverflow,
                        [styles.noFooter]: !footer,
                    }, bodyClassName) }, { children: children })), footer && (_jsx("div", Object.assign({ className: classNames(styles.footer, footerClassName) }, { children: footer }))), _jsx(CloseButton, { isOnMountFocus: isOnMountFocus, setTooltipOpen: setTooltipOpen, tooltipOpen: tooltipOpen, handleClose: handleClose, onClose: onClose, notificationOfClosure: notificationOfClosure })] })) })));
});
