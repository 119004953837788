import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { useBrandRFMSegments } from '@/hooks/useBrandRFMSegments';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { RFMMovementsPanel } from '../RFMMovementsPanel/RFMMovementsPanel';
import styles from './styles.module.scss';
import { useMovementList } from './useMovementList';
export const RFMMovementsPanelContainer = ({ filters }) => {
    const { brand } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const rfmSegments = useBrandRFMSegments();
    const { movements } = useMovementList(filters);
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: _jsx(RFMMovementsPanel, { rfmSegments: rfmSegments, movements: movements, hasNoData: !movements.length, hasNoSearchResult: !movements.length && !!filters.length, isLocked: !isRFMEnabled }) })));
};
