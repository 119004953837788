import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompletePurchaseItems } from '@/components/Autocomplete/instances/AutocompletePurchaseItems';
import { QtyInput } from '@/components/QtyInput/QtyInput';
import { Trash } from '@/icons/Trash';
import { money } from '@/utils/numbers';
import { PurchaseItemFreePriceInput } from './PurchaseItemFreePriceInput';
import { PurchaseItemLockedPriceInput } from './PurchaseItemLockedPriceInput';
import styles from './styles.module.scss';
export const PurchaseItemInput = memo(({ items, onChangeItems, getProducts, hasPriceInputLocked = false, currency, }) => {
    const { t } = useTranslation();
    const changeItemProduct = (idx, product) => {
        if (!product)
            return;
        const newItems = [...items];
        if (idx === newItems.length - 1)
            newItems.push({ qty: 1, price: 0 });
        newItems[idx] = Object.assign(Object.assign({}, newItems[idx]), { product, price: parseFloat((product.redPrice || product.blackPrice || 0).toString()), isFreePrice: product.isFreePrice });
        onChangeItems(newItems);
    };
    const changeItemQty = (idx, qty) => {
        const newItems = [...items];
        newItems[idx] = Object.assign(Object.assign({}, newItems[idx]), { qty });
        onChangeItems(newItems);
    };
    const changeItemPrice = (idx, price) => {
        const newItems = [...items];
        newItems[idx] = Object.assign(Object.assign({}, newItems[idx]), { price });
        onChangeItems(newItems);
    };
    const removeItem = (idx) => {
        const newItems = [...items];
        newItems.splice(idx, 1);
        onChangeItems(newItems);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", { className: classNames(styles.column, styles.qty) }), _jsx("div", Object.assign({ className: classNames(styles.column, styles.name) }, { children: t('Наименование или артикул') })), _jsx("div", Object.assign({ className: classNames(styles.column, styles.count) }, { children: t('Кол-во') })), _jsx("div", Object.assign({ className: classNames(styles.column, styles.price) }, { children: t('Цена') })), _jsx("div", Object.assign({ className: classNames(styles.column, styles.amount) }, { children: t('Сумма') }))] })), _jsx("div", { children: items.map((item, idx) => (_jsxs("div", Object.assign({ className: styles.row }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.column, styles.qty) }, { children: [idx + 1, "."] })), _jsx("div", Object.assign({ className: classNames(styles.column, styles.name) }, { children: _jsx(AutocompletePurchaseItems, { value: item.product || null, onChange: (product) => changeItemProduct(idx, product), getItems: getProducts, shouldLoadInitialData: true }) })), _jsx("div", Object.assign({ className: classNames(styles.column, styles.count) }, { children: _jsx(QtyInput, { value: !item.qty ? '' : item.qty.toString(), onChange: (qty) => changeItemQty(idx, Number(qty)) }) })), hasPriceInputLocked ? (_jsx(PurchaseItemLockedPriceInput, { idx: idx, item: item, onChangeItemPrice: changeItemPrice, currency: currency })) : (_jsx(PurchaseItemFreePriceInput, { idx: idx, item: item, onChangeItemPrice: changeItemPrice, currency: currency })), _jsxs("div", Object.assign({ className: classNames(styles.column, styles.amount) }, { children: [item.product
                                    ? money({
                                        input: item.price * item.qty,
                                        withFraction: true,
                                        removeFractionZeros: false,
                                        currency,
                                    })
                                    : '-', !!item.product && (_jsx("button", Object.assign({ className: styles.trash, type: 'button', onClick: () => removeItem(idx) }, { children: _jsx(Trash, {}) })))] }))] }), `product-${idx}`))) })] }));
});
