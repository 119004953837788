import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { HighlightText } from '@/components/HighlightText/HighlightText';
import { LoadMoreDataButton } from '@/components/LoadMoreDataButton/LoadMoreDataButton';
import styles from './styles.module.scss';
import { ToogleNodeButton } from './ToogleNodeButton';
export const CheckboxTreeNode = memo(({ item, level, checkedItems, onChangeCheckedItems, onLoadMoreData, search, levelIndent, }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(typeof item.defaultExpanded !== 'undefined'
        ? checkedItems.length > 0 || !!search
            ? item.defaultExpanded
            : item.isFlat
        : !!((_a = item.items) === null || _a === void 0 ? void 0 : _a.length));
    const checkedItemIds = checkedItems.map((i) => i.id);
    const checked = checkedItemIds.includes(item.id);
    const hasChildren = item.items !== null;
    const childrenIds = ((_b = item.items) === null || _b === void 0 ? void 0 : _b.map((i) => i.id)) || [];
    const checkedChildren = checkedItemIds.filter((id) => childrenIds === null || childrenIds === void 0 ? void 0 : childrenIds.includes(id));
    const indeterminate = checkedChildren.length > 0;
    useEffect(() => {
        var _a;
        if (typeof item.defaultExpanded === 'undefined')
            setIsExpanded(!!((_a = item.items) === null || _a === void 0 ? void 0 : _a.length));
    }, [item.defaultExpanded, (_c = item.items) === null || _c === void 0 ? void 0 : _c.length]);
    const handleLoadMoreData = useCallback((reset) => {
        if (onLoadMoreData)
            onLoadMoreData(item, reset);
    }, [onLoadMoreData, item]);
    const handleToggle = useCallback(() => {
        setIsExpanded(!isExpanded);
        if (!isExpanded)
            handleLoadMoreData(true);
    }, [isExpanded, (_d = item.items) === null || _d === void 0 ? void 0 : _d.length]);
    const handleChange = () => {
        if (checked)
            onChangeCheckedItems(checkedItems.filter((i) => i.id !== item.id));
        else {
            setIsExpanded(false);
            onChangeCheckedItems([...checkedItems, item].filter((i) => !checkedChildren.includes(i.id)));
        }
    };
    const indent = level * levelIndent;
    const nextIndent = (level + 1) * levelIndent;
    return (_jsxs("li", Object.assign({ className: classNames(styles.item, {
            [styles.expanded]: isExpanded,
        }) }, { children: [!item.isFlat && (_jsxs("span", Object.assign({ className: styles.label, style: { paddingLeft: indent } }, { children: [hasChildren && (_jsx(ToogleNodeButton, { isExpanded: isExpanded && !checked, isDisabled: checked, onClick: handleToggle })), item.isCatalog ? (_jsx("span", Object.assign({ className: styles.catalogLabel }, { children: item.label }))) : (_jsx(Checkbox, Object.assign({ className: styles.checkbox, checked: checked, isIndeterminate: !checked && indeterminate, onChange: handleChange, preventScrollToInput: true }, { children: _jsx(HighlightText, { text: item.label, search: search }) })))] }))), hasChildren && !checked && (_jsxs(_Fragment, { children: [_jsx("ul", Object.assign({ className: styles.list }, { children: item.items.map((i) => (_jsx(CheckboxTreeNode, { item: i, level: item.isFlat ? level : level + 1, checkedItems: checkedItems, onChangeCheckedItems: onChangeCheckedItems, onLoadMoreData: onLoadMoreData, search: search, levelIndent: levelIndent }, i.id))) })), item.hasMoreData !== false && (_jsx("div", Object.assign({ className: styles.loadMore, style: { paddingLeft: nextIndent } }, { children: _jsx(LoadMoreDataButton, { className: styles.nodeLoadMoreButton, onClick: () => handleLoadMoreData(), isLoading: item.isLoadingMoreData, label: `${t('Еще из')} ${item.label}`, isOutlined: item.isLoadingMoreData && !((_e = item.items) === null || _e === void 0 ? void 0 : _e.length) }) })))] }))] })));
});
