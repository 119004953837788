import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Editor } from '../Editor/Editor';
import { TelegramTextEditor, } from '../TelegramTextEditor/TelegramTextEditor';
import { useMessageEditorVariables, } from './useMessageEditorVariables';
export const MessageEditor = memo(({ className, initialValue, onChange, placeholder, context, isTelegram = false, hasError = false, hasAutoFocus = false, hasLinkSupport, currency, onBlur, isDisabled, }) => {
    const { groups, variables, allVariables } = useMessageEditorVariables(context);
    if (isTelegram)
        return (_jsx(TelegramTextEditor, { className: className, initialValue: initialValue, onChange: onChange, groups: groups, variables: variables, allVariables: allVariables, placeholder: placeholder, hasError: hasError, hasAutoFocus: hasAutoFocus, hasLinkSupport: hasLinkSupport, currency: currency, onBlur: onBlur, isDisabled: isDisabled }));
    return (_jsx(Editor, { className: className, initialValue: initialValue, onChange: onChange, groups: groups, variables: variables, allVariables: allVariables, placeholder: placeholder, hasError: hasError, hasAutoFocus: hasAutoFocus, hasLinkSupport: hasLinkSupport, currency: currency, onBlur: onBlur, isDisabled: isDisabled }));
});
