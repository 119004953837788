import { $createVariableNode } from './VariableNode';
function nodeHasVariables(node, variables) {
    const text = node.getTextContent();
    const varIds = variables.map((v) => `!${v.id}`);
    const re = RegExp(varIds.join('|'));
    return text.match(re) !== null;
}
function findAndTransformVariables(node, variables) {
    const text = node.getTextContent();
    let found = false;
    for (let i = 0; i < variables.length; i += 1) {
        const variable = variables[i];
        const re = RegExp(`!${variable.id}`, 'g');
        let match;
        while ((match = re.exec(text)) !== null) {
            let targetNode;
            const idx = match.index;
            if (idx === 0) {
                ;
                [targetNode] = node.splitText(idx + variable.id.length + 1);
            }
            else {
                ;
                [, targetNode] = node.splitText(idx, idx + variable.id.length + 1);
            }
            if (!targetNode)
                continue;
            const variableNode = $createVariableNode(variable, {
                bold: targetNode.hasFormat('bold'),
                underline: targetNode.hasFormat('underline'),
                strikethrough: targetNode.hasFormat('strikethrough'),
                italic: targetNode.hasFormat('italic'),
                code: targetNode.hasFormat('code'),
            });
            targetNode.replace(variableNode);
            found = true;
        }
    }
    return found;
}
const transformTextNodeToVariable = (variables) => (node) => {
    if (nodeHasVariables(node, variables))
        findAndTransformVariables(node, variables);
};
export default transformTextNodeToVariable;
