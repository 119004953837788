import * as triggers from '@/const/mailing-triggers';
import { createEdge } from '../createEdge';
import { createNode } from '../createNode';
export const createLoopNode = (data) => {
    return createNode({
        type: 'loop',
        data,
        position: data.position || { x: 0, y: 0 },
        style: !data.position ? { opacity: 0 } : undefined,
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const createLoopNodeForMailing = (mailing) => {
    return createLoopNode({
        repeat: mailing.repeat ? 'infinity' : 'one-time',
    });
};
export const findLoopNodeForMailing = (nodes, mailing) => {
    const repeat = mailing.repeat ? 'infinity' : 'one-time';
    return nodes.find((n) => n.type === 'loop' && n.data.repeat === repeat);
};
export const createLoopNodesFromMailings = (branches, triggerType) => {
    const loopNodes = [];
    const loopEdges = [];
    const loopBranches = {};
    if (Number(triggerType) === triggers.TRIGGER_TYPE_SCHEDULE)
        return {
            loopNodes,
            loopEdges,
            loopBranches: branches,
        };
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const thisBranchNodes = [];
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                let loopNode = findLoopNodeForMailing(thisBranchNodes, mailing);
                if (!loopNode) {
                    loopNode = createLoopNodeForMailing(mailing);
                    loopNodes.push(loopNode);
                    thisBranchNodes.push(loopNode);
                    loopEdges.push(createEdge({
                        source: fromNodeId,
                        sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                        target: loopNode.id,
                    }));
                }
                if (!loopBranches[loopNode.id])
                    loopBranches[loopNode.id] = {};
                if (!loopBranches[loopNode.id]['no-handle'])
                    loopBranches[loopNode.id]['no-handle'] = [];
                loopBranches[loopNode.id]['no-handle'].push(mailing);
            }
        }
    }
    return { loopNodes, loopEdges, loopBranches };
};
