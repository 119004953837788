import { useTranslation } from 'react-i18next';
export const useTriggersEmailReceived = () => {
    const { t } = useTranslation();
    return [
        {
            id: 'opened',
            label: t('Открыл Email') + '?',
        },
        {
            id: 'clicked',
            label: t('Перешел по ссылке в Email') + '?',
        },
        {
            id: 'purchased',
            label: t('Сделал покупку') + '?',
        },
    ];
};
