export const customCss = `

.blockbuilder-tools-panel {
  background-color: #eaf4f6;
}

.tab-content {
  padding-bottom: 100px;
}

.cl-ph {
  background-color: #e6e1d8;
  color: #3a3a3a;
  padding: 1px 4px;
  border-radius: 6px;
}

.loading-custom-video {
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 15px;
  border: 1px solid rgb(153, 153, 153);
  padding: 25px 15px;
}

.error-custom-video {
  color: #da5845 !important;
}

`;
