import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const ViberFormUrl = () => {
    var _a;
    const { t } = useTranslation();
    const { selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const handleUpdateViberUrl = (e) => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _a === void 0 ? void 0 : _a.viberUrl) !== e.target.value)
            selectedNode.update({
                viber: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber), { viberUrl: e.target.value }),
            });
    };
    return (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(formStyles.label, formStyles.movedUp), htmlFor: 'channel-form-viber-url' }, { children: t('Ссылка') })), _jsx(Input, { id: 'channel-form-viber-url', defaultValue: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _a === void 0 ? void 0 : _a.viberUrl) || '', onBlur: handleUpdateViberUrl, placeholder: t('ссылка из кнопки и/или картинки'), hasError: !!(errors === null || errors === void 0 ? void 0 : errors.viberUrl), disabled: !isEditMode }), !!(errors === null || errors === void 0 ? void 0 : errors.viberUrl) && (_jsx("div", Object.assign({ className: formStyles.error }, { children: errors.viberUrl })))] }));
};
