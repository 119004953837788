import { useCallback, useEffect } from 'react';
export const useLastListState = () => {
    const appElement = document.getElementById('app');
    const handleScroll = useCallback(() => {
        window._scrollTop = (appElement === null || appElement === void 0 ? void 0 : appElement.scrollTop) || 0;
    }, []);
    const handleCurrentTab = (activeTabId) => {
        window._activeTabId = activeTabId;
    };
    useEffect(() => {
        if (window._scrollTop)
            appElement === null || appElement === void 0 ? void 0 : appElement.scrollTo(0, window._scrollTop);
        appElement === null || appElement === void 0 ? void 0 : appElement.addEventListener('scroll', handleScroll);
        return () => {
            appElement === null || appElement === void 0 ? void 0 : appElement.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleCleanData = () => {
        appElement === null || appElement === void 0 ? void 0 : appElement.removeEventListener('scroll', handleScroll);
        window._scrollTop = 0;
        setTimeout(() => {
            appElement === null || appElement === void 0 ? void 0 : appElement.scrollTo(0, 0);
        });
    };
    return {
        currentTab: window._activeTabId,
        handleCurrentTab,
        handleCleanData,
    };
};
